import axios from 'axios'

export default {
  namespaced: true,

  state: {
    loading: false,
    currentPage: 0,
    messages: [],
    numberMessages: 0,
    numberUnreadMessages: 0
  },

  getters: {
    // получить кол-во непрочитаннх сообщений
    getNumberUnreadMessages (state) {
      return state.numberUnreadMessages
    },

    getNumberMessages (state) {
      return state.numberMessages
    },

    getMessages (state) {
      return state.messages
    },

    getLoading (state) {
      return state.loading
    },

    isEnd (state) {
      return state.currentPage * 5 > state.numberMessages
    }
  },

  mutations: {
    setLoading (state, payload) {
      state.loading = payload
    },

    nextPage (state) {
      state.currentPage++
    },

    setNumberMessages (state, payload) {
      state.numberMessages = payload
    },

    pushMessages (state, messages) {
      if (!Array.isArray(messages)) {
        messages = [messages]
      }

      for (const message of messages) {
        if (state.messages.some(msg => msg.id === message.id)) {
          continue
        }

        message.is_show = false
        message.from_soket = false

        state.messages.push(message)
      }
    },

    soketPushMessage (state, message) {
      if (state.messages.some(msg => msg.id === message.id)) {
        return
      }

      if (typeof message.is_show === 'undefined') {
        message.is_show = false
      }

      state.messages.unshift(message)

      if (!message.read_date) {
        state.numberUnreadMessages++
      }

      state.numberMessages++
    },

    // устновить кол-во непрочитаннх сообщений
    setNumberUnreadMessages (state, payload) {
      state.numberUnreadMessages = payload
    },

    readAll (state) {
      for (const message of state.messages) {
        message.read_date = new Date().toISOString()
      }

      state.numberUnreadMessages = 0
    },

    readById (state, msgId) {
      const message = state.messages.find((msg) => msg.id === msgId)
      if (!message) {
        return
      }

      message.read_date = new Date().toISOString()

      state.numberUnreadMessages--

      if (state.numberUnreadMessages < 0) {
        state.numberUnreadMessages = 0
      }
    }
  },

  actions: {
    async firstLoad ({ dispatch, commit }) {
      commit('setLoading', true)

      await dispatch('loadNumberMessages')
      await dispatch('loadNumberUnreadMessages')

      const messages = await axios
        .post(`${config.api}/${config.notification_publisher}/messages`, {
          limit: 5,
          offset: 0,
          order: ['read_date:desc', 'create_date:desc']
        }, {
          hideNotification: true
        })
        .then((response) => response.data)
        .catch(() => [])

      commit('pushMessages', messages)
      commit('nextPage')

      commit('setLoading', false)
    },

    async loadMessages ({ state, commit }) {
      console.log('loadMessages', state.currentPage)

      if (state.currentPage * 5 > state.numberMessages) {
        return
      }

      try {
        commit('setLoading', true)

        // при подгрузке сообщений, необходимо исключить те, которые уже пришли из сокета
        const exceptMsgIds = state.messages.filter(msg => msg.from_soket).map(msg => msg.id)
        const where = exceptMsgIds.length > 0
          ? { and: [{ not_in: { id: exceptMsgIds } }] }
          : undefined

        const messages = await axios
          .post(`${config.api}/${config.notification_publisher}/messages`, {
            limit: 5,
            offset: state.currentPage * 5,
            order: ['read_date:desc', 'create_date:desc'],
            where
          }, {
            hideNotification: true
          })
          .then((response) => response.data)
          .catch(() => [])

        commit('pushMessages', messages)
        commit('nextPage')
      } catch (error) {
        console.log(error)
      } finally {
        commit('setLoading', false)
      }
    },

    async loadNumberMessages ({ commit }) {
      const count = await axios
        .post(`${config.api}/${config.notification_publisher}/messages`, {
          '*': { func: 'count' }
        }, {
          hideNotification: true
        })
        .then((response) => response.data[0]?.count || 0)
        .catch(() => 0)

      commit('setNumberMessages', count)
    },

    async loadNumberUnreadMessages ({ commit }) {
      const count = await axios
        .post(`${config.api}/${config.notification_publisher}/messages`, {
          '*': { func: 'count' },
          where: {
            and: [
              { is_null: 'read_date' }
            ]
          }
        }, {
          hideNotification: true
        })
        .then((response) => response.data[0]?.count || 0)
        .catch(() => 0)

      commit('setNumberUnreadMessages', count)
    },

    async readMessage ({ state, commit }, payload) {
      const msgId = typeof payload === 'number'
        ? payload
        : payload.id
      const message = state.messages.find((msg) => msg.id === msgId)

      if (!message) {
        return
      }

      await axios
        .put(`${config.api}/${config.notification_publisher}/messages/read`, {
          message_id: message.id
        }, {
          hideNotification: true
        })

      message.read_date = new Date().toISOString()

      commit('setNumberUnreadMessages', state.numberUnreadMessages - 1)
    },

    async readAllMessages ({ commit }) {
      await axios.put(`${config.api}/${config.notification_publisher}/messages/read-all`, null, {
        hideNotification: true
      })

      commit('readAll')
    }
  }
}
