<template>
  <div>
    <el-button
      @click="isShowSettingsWindow = true"
      type="text">Настройки импорта по умолчанию</el-button>
    <el-dialog
      title="Настройки импорта по умолчанию"
      :modal="true"
      :visible.sync="isShowSettingsWindow"
      :modalFade="true"
      :modalAppendToBody="false">
      <div>
        <el-form-item label="Система координат файла">
          <coordinate-system
            class="coordinateSystem"
            v-model="value.epsg"
            :clearable="true"
            @change-cs="epsgChange"
          ></coordinate-system>
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model="value.need_to_convert_closed_line_to_polygon" label="Преобразовать замкнутые контура в полигоны"></el-checkbox>
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model="value.swap_x_y_axes" label="Поменять оси местами после пересчёта координат"></el-checkbox>
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model="value.swap_axes_before_transform" label="Поменять оси местами до пересчёта координат"></el-checkbox>
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model="value.is_flattening_needed" label="Убрать z-координаты"></el-checkbox>
        </el-form-item>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import CoordinateSystem from '@/core/infrastructure/components/CoordinateSystem'
export default {
  name: 'MapDefaultImportSettings',
  components: { CoordinateSystem },
  props: {
    value: {
      type: Object,
    }
  },
  watch: {
    value: {
      handler: function (newValue, oldValue) {
        this.$emit('change', newValue)
      },
      deep: true
    }
  },
  data () {
    return {
      isShowSettingsWindow: false,
    }
  },
  methods: {
    epsgChange(value) {
      this.value.epsg = value
    }
  }
}
</script>

<style scoped>

</style>
