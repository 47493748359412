import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'
import { IDssConfirmation } from '@/core/domain/repository/modules/EDSQueryRepositoryInterface'

export namespace EDSAPI {
  export class GetHashes implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data

    constructor (registryId: number, recordId: number, certificate: string) {
      // this.path = `/registryservice/blabla?registry_id=${registryId}`;
    }
  }

  export class GetFields implements APIRequest<string> {
    response: string;
    path: string;
    params: any;
    method = HTTPMethod.POST;
    parse = (data: AxiosResponse) => data.data

    constructor (registryId: number, recordId: number, params: any) {
      this.path = `/registryservice/registry/records/${registryId}/commands/${recordId}`
      this.params = params
    }
  }

  export class GetFullCommandInfo implements APIRequest<string> {
    response: string;
    path: string;
    params: any;
    method = HTTPMethod.POST;
    parse = (data: AxiosResponse) => data.data

    constructor (recordId: number, commandId: number, params: any) {
      this.path = `/registryservice/registry/records/${recordId}/commands/${commandId}`
      this.params = params
    }
  }

  export class createFileWithAllInformation implements APIRequest<string> {
    response: string;
    path: string;
    params: any;
    method = HTTPMethod.POST;
    parse = (data: AxiosResponse) => data.data

    constructor (recordId: number, commandId: number, params: any) {
      this.path = `/registryservice/registry/records/${recordId}/commands/${commandId}`
      this.params = params
    }
  }

  export class generateArchiveAndSave implements APIRequest<string> {
    response: string;
    path: string;
    params: any;
    method = HTTPMethod.POST;
    parse = (response: AxiosResponse) => response.data

    constructor (registryId: number, recordId: number, params: any) {
      this.path = `/registryservice/registry/records/${registryId}/commands/${recordId}`
      this.params = params
    }
  }

  export class DssConfirmation implements APIRequest<any> {
    baseURL: string;
    response: any;
    path: string;
    params: IDssConfirmation;
    method = HTTPMethod.POST;
    parse = (data: AxiosResponse) => data.data;
    headers: any;
    useNewInstance = true;

    constructor (dssUrl: string, token: string, data: IDssConfirmation) {
      this.baseURL = dssUrl
      this.path = `/STS/v2.0/confirmation`
      this.params = data
      this.headers = {
        Authorization: 'Bearer ' + token
      }
    }
  }

  export class GetServerCertificates implements APIRequest<string> {
    response: string;
    path: string;
    params: any;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data

    constructor () {
      this.path = `/cryptoservice/signature/certificates?format=accent`
    }
  }
}
