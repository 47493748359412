<template>
  <el-select
    v-model="localModel"
    filterable
    clearable
    @change="onChange"
  >
    <el-option
      v-for="(item, index) in options"
      :key="index"
      :label="item.name"
      :value="item.id"
    ></el-option>
  </el-select>
</template>

<script>
// API
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { PluginAPI } from '@/services/LogicEditor/infrastructure/api/PluginAPI'

export default {
  name: 'editor-plugins',
  props: {
    value: {
      type: String,
      default: null
    },
    withoutPostfix: {
      type: Boolean
    }
  },
  data () {
    return {
      localModel: (this.value || '').split('/')[0],
      options: [],
      postfix: (this.value || '').split('/')[1]
    }
  },
  async mounted () {
    try {
      this.options = await APIClient.shared.request(new PluginAPI.GetPlugins())
    } catch (error) {
      this.options = []
      console.log('editor-plugins', error)
    }
  },
  methods: {
    onChange () {
      this.$emit('change', this.localModel + (this.postfix ? '/' + this.postfix : ''))
    }
  }
}
</script>
