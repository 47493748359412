<template>
  <div>
    <el-form-item label="Адрес сервиса">
      <el-input v-model="value.OGCFeatures.serviceUrl"></el-input>
    </el-form-item>
    <el-form-item label="Использовать прокси">
      <div style="display: flex;">
        <el-checkbox style="margin-right: 10px;" v-model="value.OGCFeatures.proxy.enable"></el-checkbox>
        <el-input v-if="value.OGCFeatures.proxy.enable" v-model="value.OGCFeatures.proxy.url"></el-input>
      </div>
    </el-form-item>
    <el-form-item label="Авторизация">
      <el-select
        v-model="value.OGCFeatures.authorizationMethod"
      >
        <el-option
          v-for="item in authorizationMethodOptions"
          :value="item.id"
          :label="item.name"
          :key="item.id"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item v-if="value.OGCFeatures.authorizationMethod === 'basic'" label="Логин">
      <el-input v-model="value.OGCFeatures.credentials.login"></el-input>
    </el-form-item>
    <el-form-item v-if="value.OGCFeatures.authorizationMethod === 'basic'" label="Пароль">
      <el-input v-model="value.OGCFeatures.credentials.password"></el-input>
    </el-form-item>
    <el-form-item v-if="value.OGCFeatures.serviceUrl" class="inline-form-item">
      <el-button @click="showLayerDialog">{{buttonLabel}}</el-button>
      <div style="margin-left: 10px;">{{this.value.OGCFeatures.layer ? this.value.OGCFeatures.layer.title : null}}</div>
    </el-form-item>
    <el-form-item label="Система координат">
      <coordinate-system
        :value="value.nativeCoordinateSystemId"
        @change-cs="value.nativeCoordinateSystemId = $event"
      ></coordinate-system>
    </el-form-item>

    <o-g-c-features-dialog
      :layers="layers"
      :selected-layer="value.OGCFeatures.layer"
      ref="dialog"
      @select-layer="selectLayer"
    ></o-g-c-features-dialog>
  </div>
</template>

<script>
  import axios, { AxiosInstance } from 'axios'
  import OGCFeaturesDialog from './OGCFeaturesDialog'
  import CoordinateSystem from '@/core/infrastructure/components/CoordinateSystem'

  const AUTHORIZATION_METHOD_OPTIONS = [
    {
      id: 'none',
      name: 'нет'
    },
    {
      id: 'basic',
      name: 'Логин пароль'
    }
  ]

  export default {
    name: 'OGCFeatures',
    components: { OGCFeaturesDialog, CoordinateSystem },
    props: {
      value: {
        type: Object,
        default: () => {
          return {
            OGCFeatures: {
              serviceUrl: null,
              authorizationMethod: null,
              credentials: {
                login: null,
                password: null
              },
              proxy: {
                enable: false,
                url: null
              },
              layer: null
            }
          }
        }
      }
    },
    data: function () {
      return {
        authorizationMethodOptions: AUTHORIZATION_METHOD_OPTIONS,
        layers: []
      }
    },
    computed: {
      buttonLabel: function () {
        return this.value.OGCFeatures.layer ? 'Выбрать другой слой' : 'Выбрать слой'
      }
    },
    methods: {
      async showLayerDialog () {
        this.layers = []
        const url = (this.value.OGCFeatures.proxy.enable && this.value.OGCFeatures.proxy.url)
          ? this.value.OGCFeatures.proxy.url : this.value.OGCFeatures.serviceUrl

        let config = { headers: {} }
        if (this.value.OGCFeatures.authorizationMethod === 'basic'
          && this.value.OGCFeatures.credentials.login
          && this.value.OGCFeatures.credentials.password) {
          config.headers['Authorization'] = 'Basic ' + btoa(this.value.OGCFeatures.credentials.login + ':' + this.value.OGCFeatures.credentials.password)
        }
        const me = this
        let layers = axios.create().get(
          url + '/collections',
          config
        ).then(r => {
          me.layers = r.data.collections.map(function (x) {
            return {
              name: x.name,
              title: x.title,
              description: x.description,
              href: (x.links.find(y=>y.title === 'Items') || {}).href
            }
          })
        })
        this.$refs['dialog'].show()

      },
      selectLayer (layer) {
        this.value.OGCFeatures.layer = layer
        console.log(this.value.OGCFeatures)
      }
    }
  }
</script>

<style scoped>
.inline-form-item /deep/ .el-form-item__content {
  display: flex;
}
</style>
