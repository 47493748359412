
import { Nullable } from '@/core/domain/type/types'
export * from './jsHelpers'

export const getUserFullName = function (user: any) {
  let { name, midname, surname } = user
  name = name || ''
  midname = midname || ''
  surname = surname || ''

  if (!midname || !surname) {
    return name
  } else {
    return `${surname} ${name.substring(0, 1)}. ${midname.substring(0, 1)}.`
  }
}

/**
 @param context - контекст компонента (this),
 @param registryId - id реестра,
 @param recordId - id записи
*/
export const getCardId = async (context: any, { registryId, recordId }:
  { registryId: number, recordId: Nullable<number> }): Promise<object> => {
  let url = `${context.$config.api}/registryservice/registry/${registryId}/card`
  if (recordId) {
    url = `${context.$config.api}/registryservice/registry/${registryId}/records/${recordId}/card`
  }

  return (await context.$http.get(url)).data[0]
}
export default class _localStorage {
  public static clearExcept (keys: string[]) {
    Object.keys(localStorage).forEach(key => {
      if (!keys.includes(key)) {
        localStorage.removeItem(key)
      }
    })
  }
}

export const generateGuid = () => {
  let d = new Date().getTime()
  if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
    d += performance.now()
  }
  return 'c_' + 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = (d + Math.random() * 16) % 16 | 0
    d = Math.floor(d / 16)
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
}

/**
 * Валидный JSON
 *
 * @param text
 */
export const isValidJson = function (text: any): boolean {
  if (typeof text !== 'string') {
    return false
  }

  try {
    JSON.parse(text)

    return true
  } catch (e) {
    return false
  }
}

export const jsonParse = function (text: any): any {
  if (typeof text !== 'string') {
    return text
  }

  try {
    return JSON.parse(text)
  } catch (e) {
    return text
  }
}

export const isPgArray = function (text: any): boolean {
  if (typeof text !== 'string') {
    return false
  }

  return (/\{.*\}/g).test(text)
}

export const pgArrayParse = function (text: string): any[] {
  try {
    return JSON.parse(text.replaceAll('{', '[').replaceAll('}', ']'))
  } catch {
    return []
  }
}

export function cloneObject<T> (value: T): T {
  return JSON.parse(
    JSON.stringify(value)
  )
}

export function enToRu (text: string): string {
  // индексы символов клавишь для ru и en, в нижнем и в верхних регистрах совпадают
  const en = '`qwertyuiop[]asdfghjkl;\'zxcvbnm,.~QWERTYUIOP{}ASDFGHJKL:"ZXCVBNM<>?'
  const ru = 'ёйцукенгшщзхъфывапролджэячсмитьбюЁЙЦУКЕНГШЩЗХЪФЫВАПРОЛДЖЭЯЧСМИТЬБЮ'

  for (let i = 0; i <= text.length; i++) {
    // сивол под индексом `i` целевой строки
    const char = text[i]
    // индекс символа из строки `en`
    const sIndex = en.indexOf(char)

    // если с индекс больше -1, тогда меняем найденный символ с англ. раскладки, на символ рус. раскладки
    if (sIndex >= 0) {
      text = text.replace(char, ru[sIndex])
    }
  }

  return text
}
