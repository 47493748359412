<template>
    <div style="height: 100%;">
      <el-row v-if="enableMeasureUnits && type != 'add' && ['Polygon', 'MultiPolygon', 'LineString', 'MultiLineString'].includes(featureType)" style="margin: 10px;">
        <el-radio-group
          class="measure-units"
          v-model="measureUnits"
          size="mini"
          @change="measureUnitsChange"
        >
          <el-radio-button :label="$locale.map_editor.vertex_edit_panel.units_m"></el-radio-button>
          <el-radio-button :label="$locale.map_editor.vertex_edit_panel.units_km"></el-radio-button>
          <el-radio-button v-if="featureType != 'LineString' && featureType != 'MultiLineString'" :label="$locale.map_editor.vertex_edit_panel.units_hectare"></el-radio-button>
        </el-radio-group>
        <div v-html="measureUnitsValue" class="measure-units-value"></div>
      </el-row>
      <el-row style="margin: 20px 10px 0 10px;">
        <el-col class="view-mode-select">
          <el-select v-model="viewMode" @change="viewOptionsChange">
            <el-option
              v-for="item in viewOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-col>
        <el-col v-if="type != 'view'" style="margin-left: 10px; width: initial;">
          <el-tooltip effect="dark" :content="$locale.map_editor.vertex_edit_panel.validate_geometry_button" placement="top">
            <el-button :disabled="vertices.length == 0" icon="el-icon-check" size="mini" circle @click="validateVertices"></el-button>
          </el-tooltip>
        </el-col>
        <el-col style="margin-left: 10px; width: initial;">
          <el-tooltip effect="dark" :content="$locale.map_editor.vertex_edit_panel.copy_coordinates_button" placement="top">
            <el-button :disabled="vertices.length == 0" icon="el-icon-document-copy" size="mini" circle @click="coordinatesToClipboard"></el-button>
          </el-tooltip>
        </el-col>
      </el-row>
      <coordinate-system
        :isDisabled="type == 'edit'"
        style="margin: 10px;"
        :value="srid"
        :options="srids"
        size="mini"
        @change-cs="srsChange">
      </coordinate-system>
      <el-row style="padding-bottom: 5px;">
        <el-col style="margin-left: 10px; width: initial;">
          <el-checkbox-group v-model="dmsMode" size="mini">
            <el-checkbox-button :disabled="viewMode == 'GeoJSON' || viewMode == 'WKT' || vertices.length == 0" @change="dmsModeChange">{{$locale.map_editor.vertex_edit_panel.dms}}</el-checkbox-button>
          </el-checkbox-group>
        </el-col>
        <el-col style="margin-left: 10px; width: initial;">
          <el-checkbox-group v-model="rotateAxis" size="mini">
            <el-checkbox-button :disabled="vertices.length == 0" @change="rotateAxisChange">{{$locale.map_editor.vertex_edit_panel.rotate_axis}}</el-checkbox-button>
          </el-checkbox-group>
        </el-col>
        <el-col v-if="type != 'view'" class="copy-coord-value">
          <el-checkbox-group v-model="copyCoordValues" size="mini">
            <el-tooltip effect="dark" :content="$locale.map_editor.vertex_edit_panel.copy_coordinates_mode_button" placement="top">
              <el-checkbox-button :disabled="viewMode != 'Graphic'">+=</el-checkbox-button>
            </el-tooltip>
          </el-checkbox-group>
        </el-col>
        <el-col v-if="type != 'view'" style="margin-left: 10px; width: initial;">
          <el-tooltip effect="dark" :content="$locale.map_editor.vertex_edit_panel.add_geometry_button" placement="top">
            <el-button :disabled="itemTypes.length == 0 || viewMode != 'Graphic'" icon="el-icon-plus" size="mini" circle @click="showCreateGeometryWindow"></el-button>
          </el-tooltip>
        </el-col>
        <el-col v-if="type != 'view'" style="margin-left: 10px; width: initial;">
          <el-tooltip effect="dark" :content="$locale.map_editor.vertex_edit_panel.clear_geometry_button" placement="top">
            <el-button :disabled="vertices.length == 0" icon="el-icon-delete" size="mini" circle @click="clearAllVertices"></el-button>
          </el-tooltip>
        </el-col>
      </el-row>
      <div class="select-panel" v-if="viewMode == 'Graphic'">
        <el-dialog
          v-if="type != 'view'"
          :visible.sync="isCreateGeometryVisible"
          width="30%"
          height="400px"
          :modal="false">
          <el-form :rules="createVertexRules" :model="geometryDto">
            <el-form-item prop="name" :label="$locale.main.fields.type">
              <el-select v-model="geometryDto.name">
                <el-option
                  v-for="item in itemTypes"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <span slot="footer">
            <el-button icon="el-icon-close" size="small" @click="isCreateGeometryVisible = false">{{$locale.main.button.cancel}}</el-button>
            <el-button icon="el-icon-success" size="small" @click="createGeometry" type="primary">{{$locale.main.button.save}}</el-button>
          </span>
        </el-dialog>
        <el-table
          :indent="0"
          class="custom_scrollbar"
          height="80%"
          v-loading="loading"
          :data="vertices"
          stripe
          border
          ref="verticesTable"
          row-key="id"
          current-row-key="id"
          :span-method="cellSpan"
          highlight-current-row
          :default-expand-all="true"
          @current-change="changeVertex"
          style="width: 100%;"
          >
            <el-table-column prop="id" :label="dmsMode ? $locale.map_editor.vertex_edit_panel.n_lat : 'X'" width="205" >
              <template slot-scope="scope">
                  <span style="font-weight: bold">{{ scope.row.name != "Coordinate" ? $locale.map_editor.geometry_types[scope.row.name.toLowerCase()] : "" }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="x" :label="dmsMode ? $locale.map_editor.vertex_edit_panel.e_lon : 'Y'" width="205">
              <template slot-scope="scope">
                <div class="coord-input-column" v-if="!dmsMode" style="width: 120px;">
                  <el-input
                    v-if="type != 'view' && scope.row.name == 'Coordinate'"
                    :id="'input_' + scope.$index + '_1'"
                    size="mini"
                    v-model="scope.row.x"
                    @keydown.native="validateCoordinate($event, true)"
                    @change="decInputChange">
                  </el-input>
                  <span v-if="type == 'view'">{{scope.row.xDisplay}}</span>
                </div>
                <div class="coord-input-column" v-else>
                  <el-row v-if="type != 'view' && scope.row.name == 'Coordinate'">
                    <el-col style="width: 45px;">
                      <el-input
                        :id="'input_' + scope.$index + '_1'"
                        size="mini"
                        v-model="scope.row.xD"
                        @keydown.native="validateCoordinate($event, true)"
                        @change="dmsInputChange">
                      <i slot="suffix">&deg;</i>
                      </el-input>
                    </el-col>
                    <el-col style="width: 45px;">
                      <el-input
                        :id="'input_' + scope.$index + '_2'"
                        size="mini"
                        v-model="scope.row.xM"
                        @keydown.native="validateCoordinate($event, true)"
                        @change="dmsInputChange">
                      <i slot="suffix">'</i>
                      </el-input>
                    </el-col>
                    <el-col style="width: 60px;">
                      <el-input
                        :id="'input_' + scope.$index + '_3'"
                        size="mini"
                        v-model="scope.row.xS"
                        @keydown.native="validateCoordinate($event, true)"
                        @change="dmsInputChange">
                      <i slot="suffix">''</i>
                      </el-input>
                    </el-col>
                  </el-row>
                  <span v-if="type == 'view'">
                    {{scope.row.xD}}&deg; {{scope.row.xM}}' {{scope.row.xS}}''
                  </span>
                </div>
              </template>
          </el-table-column>
          <el-table-column prop="y" :label="$locale.map_editor.vertex_edit_panel.number" width="60">
            <template slot-scope="scope">
              <div class="coord-input-column" v-if="!dmsMode" style="width: 120px;">
                <el-input
                  v-if="type != 'view' && scope.row.name == 'Coordinate'"
                  :id="'input_' + scope.$index + '_2'"
                  size="mini"
                  v-model="scope.row.y"
                  @keydown.native="validateCoordinate($event, true)"
                  @change="decInputChange">
                </el-input>
                <span v-if="type == 'view'">{{scope.row.yDisplay}}</span>
              </div>
              <div class="coord-input-column" v-else>
                <el-row v-if="type != 'view' && scope.row.name == 'Coordinate'">
                  <el-col style="width: 45px;">
                    <el-input
                      :id="'input_' + scope.$index + '_4'"
                      size="mini"
                      v-model="scope.row.yD"
                      @keydown.native="validateCoordinate($event, true)"
                      @change="dmsInputChange">
                      <i slot="suffix">&deg;</i>
                    </el-input>
                  </el-col>
                  <el-col style="width: 45px;">
                    <el-input
                      :id="'input_' + scope.$index + '_5'"
                      size="mini"
                      v-model="scope.row.yM"
                      @keydown.native="validateCoordinate($event, true)"
                      @change="dmsInputChange">
                      <i slot="suffix">'</i>
                    </el-input>
                  </el-col>
                  <el-col style="width: 60px;">
                    <el-input
                      :id="'input_' + scope.$index + '_6'"
                      size="mini"
                      v-model="scope.row.yS"
                      @keydown.native="validateCoordinate($event, true)"
                      @change="dmsInputChange">
                      <i slot="suffix">''</i>
                    </el-input>
                  </el-col>
                </el-row>
                <span v-if="type == 'view'">
                  {{scope.row.yD}}&deg; {{scope.row.yM}}' {{scope.row.yS}}''
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column width="90">
            <template slot-scope="scope">
              <div v-if="scope.row.name == 'Coordinate'">{{scope.row.num}}</div>
            </template>
          </el-table-column>
          <el-table-column v-if="type != 'view'" width="1">
            <template slot-scope="scope">
              <el-button v-if="unlockedRecord != null && unlockedRecord == scope.row.id && coordinateAddAllowed.includes(scope.row.name)" tabindex="-1" icon="el-icon-plus" size="mini" circle @click="addCoordinate"></el-button>
              <el-button v-if="unlockedRecord != null && unlockedRecord == scope.row.id" tabindex="-1" size="mini" circle icon="el-icon-delete" @click="deleteVertex(scope.row)"></el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- -->
      <div class="codemirror-editor" v-if="viewMode != 'Graphic'">
        <codemirror
          v-model="cmValue"
          :options="cmOption"
          @input="cmChange"
          />
      </div>
    </div>
  </template>

  <script type="ts">

  import { APIClient } from '@/core/infrastructure/api/APIClient'
  import { CoordinateSystemAPI } from '@/services/MapEditor/infrastructure/api/CoordinateSystemAPI'
  import CoordinateSystem from '@/core/infrastructure/components/CoordinateSystem'
  import { LoggerAPI } from '@/services/MapEditor/infrastructure/api/LoggerAPI';
  import MapManager from '@bingo_soft/mapmanager'
  import FeatureCollection from '@bingo_soft/mapmanager/src/Domain/Model/Feature/FeatureCollection'
  import 'codemirror/lib/codemirror.css'

  export default {
    name: 'VertexEditPanel',
    props: ['type', 'map', 'layer', 'feature', 'srsId', 'srsIds', 'vertexNumberingStyle'],
    components: {
      CoordinateSystem
    },
    watch: {
      cmValue: function() {
        if (!this.triggerCMValueChange) {
          return;
        }
        const feature = MapManager.createFeatureFromText(this.cmValue, this.viewMode, this.srid);
        this.loadVertices(feature, this.srid, false);
      },
    },
    data() {
      return {
        viewMode: 'Graphic',
        dmsMode: false,
        geometryDto: {
          name: 'Point'
        },
        itemTypesList: [{
          id: 'Point',
          name: this.$locale.map_editor.geometry_types.point
        },{
          id: 'MultiPoint',
          name: this.$locale.map_editor.geometry_types.multipoint
        },{
          id: 'LineString',
          name: this.$locale.map_editor.geometry_types.linestring
        },{
          id: 'MultiLineString',
          name: this.$locale.map_editor.geometry_types.multilinestring
        },{
          id: 'Polygon',
          name: this.$locale.map_editor.geometry_types.polygon
        },{
          id: 'MultiPolygon',
          name: this.$locale.map_editor.geometry_types.multipolygon
        },{
          id: 'GeometryCollection',
          name: this.$locale.map_editor.geometry_types.geometrycollection
        }],
        itemTypes: [],
        createVertexRules: {
          name: {
            required: true,
            message: this.$locale.errors.field_required,
            trigger: 'blur'
          },
          x: {
            required: true,
            message: this.$locale.errors.field_required,
            trigger: 'blur'
          },
          y: {
            required: true,
            message: this.$locale.errors.field_required,
            trigger: 'blur'
          }
        },
        verticesTable: null,
        coordinateAddAllowed: ['Point', 'LineString', 'Polygon', 'Coordinate'],
        loading: false,
        isCreateGeometryVisible: false,
        vertices: [],
        currentVertex: null,
        addedVertex: null,
        verticesToHighlight: new Map(),
        vertexHighlightStyle: null,
        collapsed: false,
        unlockedRecord: null,
        srids: Array.isArray(this.srsIds) ? this.srsIds : [],
        srid: this.srsId,
        featureType: null,
        cmValue: '',
        triggerCMValueChange: true,
        cmOption: {
          connect: 'align',
          mode: 'text/xml',
          lineNumbers: true,
          lineWrapping: true,
          collapseIdentical: false,
          highlightDifferences: true,
          readOnly: this.type == 'view'
        },
        viewOptions: [{
            id: 'Graphic',
            name: 'Графический'
          },{
            id: 'Text',
            name: 'Текст'
          },{
            id: 'GeoJSON',
            name: 'GeoJSON'
          },{
            id: 'WKT',
            name: 'WKT'
        }],
        rotateAxis: false,
        verticesText: '',
        measureUnits: this.$locale.map_editor.vertex_edit_panel.units_m,
        measureUnitsValue: null,
        copyCoordValues: true,
        enableMeasureUnits: true // сделать пропсом компонента!!
      }
    },
    methods: {
      // Interface related
      setTextValues() {
        if (this.viewMode == 'Graphic') {
            return;
        }
        if (this.viewMode == 'Text') {
          this.verticesText = '';
          this.verticesToText(this.vertices);
          this.cmValue = this.verticesText.trim();
        } else {
          const feature = MapManager.createFeatureFromVertices(this.vertices, this.srid, this.map.getSRSId());
          if (feature) {
            if (this.feature) {
              const olFeature = feature.getFeature();
              const geometry = olFeature.getGeometry();
              olFeature.setProperties(this.feature.getFeature().getProperties());
              // setProperties rewrites geometry of the feature, so write it back
              olFeature.setGeometry(geometry);
            }
            this.cmValue = MapManager.getGeometryAsText(feature, this.viewMode, this.map.getSRSId(), this.srid);
          }
        }
      },
      validateCoordinate(e) {
        if (['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(e.key)) {
          let nextInput = null;
          const splitted = e.target.id.split('_');
          const row = parseInt(splitted[1]);
          const col = parseInt(splitted[2]);
          const inputsInRow = this.dmsMode ? 6 : 2;
          if (e.key == 'ArrowRight' && e.target.selectionStart == e.target.value.length) {
            nextInput = document.getElementById('input_' + row.toString() + '_' + (col + 1).toString()) ||
              document.getElementById('input_' + (row + 1).toString() + '_1') ||
              document.getElementById('input_' + (row + 2).toString() + '_1');
          }
          if (e.key == 'ArrowLeft' && e.target.selectionStart == 0) {
            nextInput = document.getElementById('input_' + row.toString() + '_' + (col - 1).toString()) ||
              document.getElementById('input_' + (row - 1).toString() + '_' + inputsInRow.toString()) ||
              document.getElementById('input_' + (row - 2).toString() + '_' + inputsInRow.toString());
          }
          if (e.key == 'ArrowUp') {
            nextInput = document.getElementById('input_' + (row - 1).toString() + '_' + col.toString()) ||
              document.getElementById('input_' + (row - 2).toString() + '_' + col.toString());
          }
          if (e.key == 'ArrowDown') {
            nextInput = document.getElementById('input_' + (row + 1).toString() + '_' + col.toString()) ||
              document.getElementById('input_' + (row + 2).toString() + '_' + col.toString());
          }
          if (nextInput) {
            nextInput.focus();
          }
          return;
        }
        const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Tab', 'Backspace', 'Delete', 'Home', 'End'];
        if (!keysAllowed.includes(e.key)) {
          e.preventDefault();
        }
      },
      viewOptionsChange(val) {
        this.viewMode = val;
        this.triggerCMValueChange = false;
        this.setTextValues();
        this.applyGeometryTypes();
      },
      decInputChange() {
        this.calcDMS(this.vertices, this.currentVertex.id);
      },
      dmsInputChange() {
        this.calcDecimal(this.vertices, this.currentVertex.id);
      },
      measureUnitsChange(val) {
        const units = val == this.$locale.map_editor.vertex_edit_panel.units_km ? 'kilometers' : 'meters';
        if (this.featureType == 'LineString' || this.featureType == 'MultiLineString') {
          this.measureUnitsValue = MapManager.getLength(this.feature, units).toFixed(4);
          this.measureUnitsValue = this.$locale.map_editor.vertex_edit_panel.feature_length + ": " + this.measureUnitsValue + ' ' + val;
        } else {
          this.measureUnitsValue = MapManager.getArea(this.feature, units);
          if (val == this.$locale.map_editor.vertex_edit_panel.units_hectare) {
             this.measureUnitsValue = (this.measureUnitsValue / 10000);
          }
          this.measureUnitsValue = this.measureUnitsValue.toFixed(4);
          if (val != this.$locale.map_editor.vertex_edit_panel.units_hectare) {
              val += '<sup>2</sup>';
          }
          this.measureUnitsValue = this.$locale.map_editor.vertex_edit_panel.feature_area + ": " + this.measureUnitsValue + ' ' + val;
        }
      },
      cmChange(val) {
        const lines = val.split("\n");
        if (lines[0]) {
          lines[0] = lines[0].replace(/^\s+|\s+$/g, '');
          const parts = lines[0].split(' ');
          this.dmsMode = parts.length == 6;
        }
        this.triggerCMValueChange = true;
      },
      submit(callback) {
        this.close(callback);
        if (this.type != 'view') {
          if (this.viewMode == 'Graphic') {
            return this.saveGui();
          } else {
            return this.saveText();
          }
        }
      },
      saveGui() {
        if (this.vertices.length == 0) {
          return null;
        }
        let feature = null;
        if (this.type == 'edit') {
          feature = MapManager.updateFeatureFromVertices(this.vertices, this.feature, this.srid, this.map.getSRSId());
          if (!feature) {
              this.$message.error(this.$locale.map_editor.vertex_edit_panel.error_message_feature);
          }
        } else {
          feature = MapManager.createFeatureFromVertices(this.vertices, this.srid, this.map.getSRSId());
          if (feature) {
            const fc = new FeatureCollection([feature]);
            MapManager.addFeatures(this.map, this.layer, fc);
          } else {
            this.$message.error(this.$locale.map_editor.vertex_edit_panel.error_message_feature);
          }
          return feature;
       }
      },
      saveText() {
        if (this.cmValue.trim() == '') {
          return null;
        }
        let feature = null;
        if (this.type == "add") {
          feature = MapManager.createFeatureFromText(
            this.cmValue,
            this.viewMode,
            this.srid,
            this.map.getSRSId()
          );
          if (feature) {
            const fc = new FeatureCollection([feature]);
            MapManager.addFeatures(this.map, this.layer, fc);
          } else {
            this.$message.error(this.$locale.map_editor.vertex_edit_panel.error_message_feature);
          }
        } else if (this.type == "edit") {
            feature = MapManager.updateFeatureFromText(
              this.feature,
              this.cmValue,
              this.viewMode,
              this.srid,
              this.map.getSRSId()
            );
            if (!feature) {
              this.$message.error(this.$locale.map_editor.vertex_edit_panel.error_message_feature);
            }
        }
        return feature;
      },
      cellSpan(cell) {
        if (cell.columnIndex == 0 && cell.row.name == 'Coordinate') {
          return [0, 0];
        }
        if (cell.row.name != 'Coordinate' && cell.columnIndex == 2) {
          return [0, 0];
        }
      },
      changeVertex(currentRow) {
        this.currentVertex = currentRow;
        this.unlockedRecord = currentRow.id;
        if (this.type != 'add' && currentRow.name == 'Coordinate') {
          const olFeature = this.verticesToHighlight.get(currentRow.id);
          if (olFeature) {
              this.removeVerticesSelection();
              this.addVertexSelection(olFeature);
          }
        }
      },
      dmsModeChange() {
        this.setTextValues();
      },
      rotateAxisChange() {
        this.swapCoordinates(this.vertices);
        this.calcDMS(this.vertices);
        this.setTextValues();
      },
      async srsChange(newValue) {
        const data = await APIClient.shared.request(
          new CoordinateSystemAPI.GetCoordinateSystemBySrid(newValue)
        );
        if (Array.isArray(data)) {
            this.addCoordinateSystem(data[0].auth_name, data[0].auth_srid, data[0].proj4text);
        }
        // если координаты перевернуты, то перед трансформацией мы должны вернуть их в первоначальный вид
        if (this.rotateAxis) {
          this.swapCoordinates(this.vertices);
          this.transformDecimal(this.vertices, this.srid, newValue);
          this.swapCoordinates(this.vertices);
        } else {
          this.transformDecimal(this.vertices, this.srid, newValue);
        }
        this.calcDMS(this.vertices);
        this.setTextValues();
        this.srid = newValue;
      },
      close(callback) {
        if (typeof callback == 'function') {
          callback();
        }
        this.removeVerticesSelection();
        MapManager.clearVertexHighlight(this.map);
      },
      collapse() {
        if (!this.collapsed) {
          this.collapsed = true;
        }
      },
      applyGeometryTypes() {
        this.itemTypes = JSON.parse(JSON.stringify(this.itemTypesList));
        if (!this.vertices[0]) {
          return;
        }
        switch (this.vertices[0].name) {
          case 'Point':
          case 'LineString':
          case 'Polygon':
            this.itemTypes = [];
            this.geometryDto.name = '';
            break;
          case 'MultiPoint':
            this.itemTypes = this.itemTypes.filter(obj => obj.id == 'Point');
            this.geometryDto.name = 'Point';
            break;
          case 'MultiLineString':
            this.itemTypes = this.itemTypes.filter(obj => obj.id == 'LineString');
            this.geometryDto.name = 'LineString';
            break;
          case 'MultiPolygon':
            this.itemTypes = this.itemTypes.filter(obj => obj.id == 'Polygon');
            this.geometryDto.name = 'Polygon';
            break;
          case 'GeometryCollection':
            this.itemTypes = this.itemTypes.filter(obj => obj.id != 'GeometryCollection');
            this.geometryDto.name = 'Point';
            break;
          default:
        }
      },
      // Coords manipulation
      calcOrder(vertices) {
        if (Array.isArray(vertices)) {
          for (let i = 0; i < vertices.length; i += 1) {
            if (vertices[i].name == "Coordinate") {
              vertices[i].num = i + 1;
            } else if (Object.prototype.hasOwnProperty.call(vertices[i], "children")) {
              this.calcOrder(vertices[i].children);
            }
          }
        }
      },
      calcDMS(vertices, id) {
        for (let i = 0; i < vertices.length; i++) {
          if (vertices[i].name == "Coordinate") {
            if (id && vertices[i].id != id) {
              continue;
            }
            vertices[i].x = parseFloat(vertices[i].x);
            const xDMS = this.decimalToDMS(vertices[i].x);
            this.$set(vertices[i] ,"xD", xDMS.d);
            this.$set(vertices[i] ,"xM", xDMS.m);
            this.$set(vertices[i] ,"xS", xDMS.s);
            this.$set(vertices[i] ,"xDisplay", xDMS.display);
            vertices[i].y = parseFloat(vertices[i].y);
            const yDMS = this.decimalToDMS(vertices[i].y);
            this.$set(vertices[i] ,"yD", yDMS.d);
            this.$set(vertices[i] ,"yM", yDMS.m);
            this.$set(vertices[i] ,"yS", yDMS.s);
            this.$set(vertices[i] ,"yDisplay", yDMS.display);
          } else if (Object.prototype.hasOwnProperty.call(vertices[i], "children")) {
            this.calcDMS(vertices[i].children, id);
          }
        }
      },
      calcDecimal(vertices, id) {
        for (let i = 0; i < vertices.length; i++) {
          if (vertices[i].name == "Coordinate") {
            if (id && vertices[i].id != id) {
              continue;
            }
            vertices[i].x = this.dmsToDecimal(vertices[i]["xD"], vertices[i]["xM"], vertices[i]["xS"]);
            vertices[i].y = this.dmsToDecimal(vertices[i]["yD"], vertices[i]["yM"], vertices[i]["yS"]);
          } else if (Object.prototype.hasOwnProperty.call(vertices[i], "children")) {
            this.calcDecimal(vertices[i].children, id);
          }
        }
      },
      decimalToDMS(coordinate) {
        const d = Math.trunc(coordinate);
        const m = Math.trunc((coordinate - d) * 60);
        let s =  (coordinate - d - m / 60) * 3600;
        s = parseFloat(s.toFixed(3));
        const display = coordinate.toFixed(6);
        return {d: d, m: m, s: s, display: display};
      },
      dmsToDecimal(d, m, s) {
        return parseInt(d) + parseInt(m) / 60 + parseFloat(s) / 3600;
      },
      transformDecimal(vertices, fromSrid, toSrid) {
        for (let i = 0; i < vertices.length; i++) {
          if (vertices[i].name == "Coordinate") {
            const coord = MapManager.transformCoordinates([vertices[i].x, vertices[i].y], fromSrid, toSrid);
            vertices[i].x = coord[0];
            vertices[i].y = coord[1];
          } else if (Object.prototype.hasOwnProperty.call(vertices[i], "children")) {
            this.transformDecimal(vertices[i].children, fromSrid, toSrid);
          }
        }
      },
      swapCoordinates(vertices) {
        for (let i = 0; i < vertices.length; i++) {
          if (vertices[i].name == "Coordinate") {
              [vertices[i].x, vertices[i].y] = [vertices[i].y, vertices[i].x];
              [vertices[i].xDisplay, vertices[i].yDisplay] = [vertices[i].yDisplay, vertices[i].xDisplay];
          } else if (Object.prototype.hasOwnProperty.call(vertices[i], "children")) {
            this.swapCoordinates(vertices[i].children);
          }
        }
      },
      /* swapTextCoordinates(text) {
          let ret = "";
          text = text.replace(/^\s+|\s+$/g, '');
          const points = text.split("\n");
          points.forEach((item, index, arr) => {
              item = item.replace(/^\s+|\s+$/g, '');
              if (item == "") {
                  ret += "";
              } else {
                  let xy = item.split(" ");
                  xy = xy.filter(word => word != " " && word != "");
                  xy[0] = xy[0].replace(/^\s+|\s+$/g, '');
                  xy[1] = xy[1].replace(/^\s+|\s+$/g, '');
                  [xy[0], xy[1]] = [xy[1], xy[0]];
                  ret += xy.join(" ");
              }
              if (index != arr.length-1) {
                  ret += "\n";
              }
          });
          return ret;
      }, */
      verticesToText(vertices) {
        for (let i = 0; i < vertices.length; i++) {
          if (vertices[i].name == "Coordinate") {
              if (this.dmsMode)  {
                  this.verticesText += vertices[i].xD + " " + vertices[i].xM + "' " + vertices[i].xS + "'' " +
                                        vertices[i].yD + " " + vertices[i].yM + "' " + vertices[i].yS + "''\n";
              } else {
                  this.verticesText += vertices[i].x + " " + vertices[i].y + "\n";
              }
          } else {
            this.verticesText += "\n";
          }
          if (Object.prototype.hasOwnProperty.call(vertices[i], "children")) {
            this.verticesToText(vertices[i].children);
          }
        }
      },
      async coordinatesToClipboard() {
        this.verticesText = "";
        if (this.viewMode == 'Graphic') {
          this.verticesToText(this.vertices);
        } else {
          this.verticesText = this.cmValue;
        }
        await this.$copyText(this.verticesText.trim());
        this.$message.success(this.$locale.map_editor.vertex_edit_panel.copied_to_clipboard_message);
      },
      /* async coordinatesFromClipboard() {
        //const text = await navigator.clipboard.readText();
        const text = `
  43.89049606432362 56.33089528390539
      43.89009992313785 56.32932131376214
  43.89561289468528 56.32886375196472
  43.895447835366745 56.33190185882444
  43.89049606432362 56.33089528390539

  43.90145598356011             56.330523874040296
  43.9007297243921 56.328474019319685
  43.9061106469131 56.32845571702606
  43.906440765550165 56.33057877937199
  43.90145598356011 56.330523874040296
  `;
        //const feature = MapManager.createFeatureFromText(text, GeometryFormat.Text, this.srid);
        const feature = MapManager.createFeatureFromText(text, GeometryFormat.Text, this.srid, this.map.getSRSId());
        //this.vertices = MapManager.getVertices(feature);
        this.loadVertices(feature);
      } */
      // Geometry edit
      clearAllVertices() {
        this.vertices = [];
        this.cmValue = '';
        this.geometryDto.name = 'Point';
        this.applyGeometryTypes();
      },
      showCreateGeometryWindow() {
        //this.geometryDto.name = 'Point';
        this.isCreateGeometryVisible = true;
      },
      createGeometry() {
        switch(this.geometryDto.name) {
          case "MultiPoint":
          case "MultiLineString":
          case "MultiPolygon":
          case "GeometryCollection":
            this.createComplexGeometry();
            break;
          case "Polygon":
            this.createPolygonGeometry();
            break;
          case "Point":
            this.createPointGeometry();
            break;
          case "LineString":
            this.createLineStringGeometry();
            break;
          case "Point":
            this.createPointGeometry();
            break;
          default:
            break;
          }
          this.isCreateGeometryVisible = false;
          this.applyGeometryTypes();
      },
      createComplexGeometry() {
        if (this.vertices.length) {
          this.$message({
            message: this.$locale.main.message.invalid_operation,
            type: 'warning'
          });
          return;
        } else {
          this.vertices.push({
            id: this.generateGuid(),
            name: this.geometryDto.name,
            children: []
          });
        }
      },
      createPointGeometry() {
        this.createSimpleGeometry("MultiPoint");
      },
      createLineStringGeometry() {
        this.createSimpleGeometry("MultiLineString");
      },
      createSimpleGeometry(type) {
        if (this.vertices.length && (this.vertices[0].name == type || this.vertices[0].name == "GeometryCollection")) {
          this.vertices[0].children.push({
            id: this.generateGuid(),
            name: this.geometryDto.name,
            children: []
          });
        } else if (this.vertices.length == 0) {
          this.vertices.push({
            id: this.generateGuid(),
            name: this.geometryDto.name,
            children: []
          });
        } else {
          this.$message({
            message: this.$locale.main.message.invalid_operation,
            type: 'warning'
          });
          return;
        }
      },
      createPolygonGeometry() {
        if (this.vertices.length && (this.vertices[0].name == "MultiPolygon" || this.vertices[0].name == "GeometryCollection")) {
          this.vertices[0].children.push({
            id: this.generateGuid(),
            name: this.geometryDto.name,
            children: []
          });
        } else if (this.vertices.length == 0 || (this.vertices.length && this.vertices[0].name == "Polygon")) {
          this.vertices.push({
            id: this.generateGuid(),
            name: this.geometryDto.name,
            children: []
          });
        } else {
          this.$message({
            message: this.$locale.main.message.invalid_operation,
            type: 'warning'
          });
          return;
        }
      },
      addCoordinate() {
        this.geometryDto.name = 'Coordinate';
        const b = this.copyCoordValues && this.addedVertex;
        this.geometryDto = {
          name: 'Coordinate',
          x: b ? this.addedVertex.x : 0,
          y: b ? this.addedVertex.y : 0,
          xD: b ? this.addedVertex.xD : 0,
          xM: b ? this.addedVertex.xM : 0,
          xS: b ? this.addedVertex.xS : 0,
          yD: b ? this.addedVertex.yD : 0,
          yM: b ? this.addedVertex.yM : 0,
          yS: b ? this.addedVertex.yS : 0,
          xDisplay: b ? this.addedVertex.xDisplay : 0,
          yDisplay: b ? this.addedVertex.yDisplay : 0
        };
        this.createCoordinate();
      },
      createCoordinate() {
        if (this.currentVertex == null || !this.coordinateAddAllowed.includes(this.currentVertex.name)) {
          this.$message({
            message: this.$locale.main.message.invalid_operation,
            type: 'warning'
          });
          return;
        } else {
          let opType = this.currentVertex.name == 'Coordinate' ? 'coordinate' : 'geometry';
          this.addCoordinateRecursively(opType, this.vertices);
          this.calcOrder(this.vertices);
          if (this.dmsMode) {
            this.calcDecimal(this.vertices, this.addedVertex.id);
          } else {
            this.calcDMS(this.vertices, this.addedVertex.id);
          }
        }
      },
      addCoordinateRecursively(opType, vertices) {
        for (let i = 0; i < vertices.length; i += 1) {
          if (opType == "coordinate" && vertices[i].id == this.currentVertex.id) {
            vertices.splice(i + 1, 0, {
              id: this.generateGuid(),
              name: this.geometryDto.name,
              x: this.geometryDto.x,
              y: this.geometryDto.y,
              xD: this.geometryDto.xD,
              xM: this.geometryDto.xM,
              xS: this.geometryDto.xS,
              yD: this.geometryDto.yD,
              yM: this.geometryDto.yM,
              yS: this.geometryDto.yS
            });
            this.addedVertex = vertices[i+1];
            return;
          } else if (vertices[i].id == this.currentVertex.id) {
            vertices[i].children.push({
              id: this.generateGuid(),
              name: this.geometryDto.name,
              x: this.geometryDto.x,
              y: this.geometryDto.y,
              xD: this.geometryDto.xD,
              xM: this.geometryDto.xM,
              xS: this.geometryDto.xS,
              yD: this.geometryDto.yD,
              yM: this.geometryDto.yM,
              yS: this.geometryDto.yS
            });
            this.addedVertex = vertices[i].children[vertices[i].children.length-1];
            return;
          } else if (Object.prototype.hasOwnProperty.call(vertices[i], "children")) {
            this.addCoordinateRecursively(opType, vertices[i].children);
          }
        }
      },
      deleteVertex(vertex) {
        this.unlockedRecord = null;
        this.loading = true;
        this.$nextTick(() => {
          this.deleteVertexRecursively(this.vertices, vertex);
          this.calcOrder(this.vertices);
          this.geometryDto.name = 'Point';
          this.applyGeometryTypes();
          this.loading = false;
        });
      },
      deleteVertexRecursively(vertices, vertex) {
        if (Array.isArray(vertices)) {
          for (let i = 0; i < vertices.length; i += 1) {
            if (vertices[i].id == vertex.id) {
              vertices.splice(i, 1);
              return;
            }
            if (Object.prototype.hasOwnProperty.call(vertices[i], 'children')) {
              this.deleteVertexRecursively(vertices[i].children, vertex);
            }
          }
        }
      },
      validateVertices() {
        try {
          let feature = null;
          if (this.viewMode == 'Graphic') {
            feature = MapManager.createFeatureFromVertices(this.vertices, this.srid, this.map.getSRSId());
          } else {
            feature = MapManager.createFeatureFromText(this.cmValue, this.viewMode, this.srid, this.map.getSRSId());
          }
          const isValid = feature == null ? false : MapManager.isValid(feature, this.vertices[0].name);
          if (isValid) {
            this.$message.success(this.$locale.map_editor.vertex_edit_panel.validate_message_success);
          } else {
            this.$message.error(this.$locale.map_editor.vertex_edit_panel.validate_message_error);
          }
        } catch(e) {
          this.$message.error(this.$locale.map_editor.vertex_edit_panel.validate_message_error);
        }
      },
      // Vertex selection related
      addVertexSelection(olFeature) {
        const selection = this.$store.getters['Selection/getGlobalSelection'];
        if (selection) {
          const sf = selection.getFeatures();
          if (sf) {
            sf.push(olFeature);
          }
        }
      },
      removeVerticesSelection() {
        const selection = this.$store.getters['Selection/getGlobalSelection'];
        if (!selection) {
          return;
        }
        const sf = selection.getFeatures();
        if (!sf) {
          return;
        }
        const length = sf.getLength();
        for (let i = 0; i < length; i++)  {
          if (sf.item(i).get("vertexId")) {
            sf.removeAt(i);
          }
        }
      },
      selectVertex(id) {
        const data = this.getVertexById(id, this.vertices);
        if (data && data.vertex) {
          //this.verticesTable.toggleRowExpansion(data.parent, true);
          this.verticesTable.setCurrentRow(data.vertex);
        }
      },
      setMapClickHandler() {
        const olMap = this.map.getMap();
        olMap.on("click", (e) => {
          olMap.forEachFeatureAtPixel(e.pixel, (feature) => {
            const id = feature.get("vertexId");
            if (id) {
              this.selectVertex(id);
            }
          });
        });
      },
      addVerticesToFeature(map, vertices) {
        let isPolygonWithAddVertex = false
        let lastVertexIndex = vertices.length - 1
        let opts = {
          "point": {
            "marker_type": "simple_point",
            "color": "#000",
            "opacity": 100,
            "size": 3
          },
          "label": {
            "font": "9px Verdana",
            "offset": [10,10],
            "overflow": "false",
            "placement": "point",
            "rotate_with_view": "false",
            "rotation": 0,
            "fill": "#030303",
            "color": "#000",
            "stroke_width": 1
          }
        }
        if (this.vertexNumberingStyle) {
          this.vertexNumberingStyle.label?.forEach((item) => {
            let styleName = item.id
            opts.label[styleName] = item.value
          })
          this.vertexNumberingStyle.point?.forEach((item) => {
            let styleName = item.id
            opts.point[styleName] = item.value
          })
        }
        for (let i = 0; i < vertices.length; i++) {
            if (vertices[i].name == "Coordinate") {
              let firstVertex = [vertices[0].x, vertices[0].y].toString()
              let lastVertex = [vertices[lastVertexIndex].x, vertices[lastVertexIndex].y].toString()
              // Проверка что это полигон, где последняя вершина равна первой
              if (lastVertexIndex > 1 && firstVertex == lastVertex) {
                isPolygonWithAddVertex = true
              }
              let num = vertices[i].num.toString();
              if (i == 0 && isPolygonWithAddVertex) {
                num = num.concat(', ', vertices[lastVertexIndex].num.toString())
              } else if(i == lastVertexIndex && isPolygonWithAddVertex) {
                continue
              }
              const feature = MapManager.highlightVertex(map, [vertices[i].x, vertices[i].y],
                this.srid, opts, vertices[i].id, num);
              const olFeature = feature.getFeature();
              this.verticesToHighlight.set(olFeature.get("vertexId"), olFeature);
            } else if (Object.prototype.hasOwnProperty.call(vertices[i], "children")) {
              this.addVerticesToFeature(map, vertices[i].children);
            }
        }
      },
      getVertexById(id, vertices, parent) {
        for (let i = 0; i < vertices.length; i++) {
          if (vertices[i].name == 'Coordinate' && vertices[i].id == id) {
            return { vertex: vertices[i], parent: parent };
          } else if (Object.prototype.hasOwnProperty.call(vertices[i], "children")) {
            return this.getVertexById(id, vertices[i].children, vertices[i]);
          }
        }
      },
      // SRS related
      async loadCoordinateSystems() {
        const userId = this.$store.getters['Authorization/userId'];
        if (!userId) {
            return;
        }
        try {
          let data = await APIClient.shared.request(
            new CoordinateSystemAPI.GetCoordinateSystemsByUserId(userId)
          );
          if (Array.isArray(data)) {
            data.forEach(el => {
              if (el.auth_srid) {
                this.addCoordinateSystem(el.auth_name, el.auth_srid, el.proj4text);
              }
            });
          }
          data = await APIClient.shared.request(
            new CoordinateSystemAPI.GetCoordinateSystemBySrid(this.srid)
          );
          if (Array.isArray(data)) {
              this.addCoordinateSystem(data[0].auth_name, data[0].auth_srid, data[0].proj4text);
          }
        } catch(e) {
        }
      },
      addCoordinateSystem(authName, authSrid, proj4text) {
        const existing = this.srids.find((el) => { return el.srid == authSrid; });
        if (!existing) {
          let code = 'EPSG:' + authSrid;
          MapManager.addProjection(code, proj4text);
          if (authName != 'EPSG') {
            code = '(' + code + ') ' + authName;
          } else {
            const regExp = new RegExp('(?<=\\+title=)(.*?)(?=\\+)')
            let r = proj4text.match(regExp);
            if (r) {
              code = r[0] + ' (' + code + ')';
            }
          }
          this.srids.push({
            label: code,
            value: authSrid,
            srid: authSrid,
            auth_name: authName,
            auth_srid: authSrid,
            proj4text: proj4text
          });
        }
      },
      // Load
      loadVertices(feature, sourceSRSId, triggerChange = true) {
        this.vertices = MapManager.getVertices(feature, sourceSRSId, this.srid);
        this.calcDMS(this.vertices);
        this.calcOrder(this.vertices);
        if (triggerChange) {
          this.setTextValues();
        }
        MapManager.clearVertexHighlight(this.map);
        this.addVerticesToFeature(this.map, this.vertices);
      },
      // Log
      async log(value, name = 'Unknown variable') {
        const timestamp = performance.now();
        const obj = {
          timestamp: timestamp,
          datetime: new Date().toLocaleString('ru-RU'),
          name: name,
          value: value
        }
        try {
          const stringified = JSON.stringify(obj);
          await APIClient.shared.request(new LoggerAPI.AddToLog({timestamp: timestamp, message: stringified}));
        } catch(e) {
          obj.value = e.message;
          await APIClient.shared.request(new LoggerAPI.AddToLog({timestamp: timestamp, message: JSON.stringify(obj)}));
        }
      }
    },

    mounted() {
      /* if (this.layer) {
        this.vertexHighlightStyle = this.layer.getVertexHighlightStyle();
      } */
      if (this.feature) {
        this.loadVertices(this.feature, this.map.getSRSId());
        this.featureType = this.feature.getFeature().getGeometry().getType();
        this.measureUnitsChange('м');
      }
      this.setMapClickHandler();
      this.applyGeometryTypes();
      this.loadCoordinateSystems();
      this.verticesTable = this.$refs.verticesTable;
      if (this.type == 'add') {
          this.$store.commit('Selection/setGlobalSelection', null);
      }
    },
    beforeDestroy() {
      this.close(null)
    }
  }
  </script>

  <style scoped>
      .codemirror-editor {
        height: 83%;
      }
      .codemirror-editor /deep/ .CodeMirror-wrap, .codemirror-editor /deep/ .vue-codemirror {
        height: 100%;
      }
      .coord-input-column /deep/ .el-input--suffix .el-input__inner {
        padding-right: 0;
      }
      .coord-input-column /deep/ .el-input__inner {
        padding: 0 5px;
      }
      .measure-units /deep/ .el-radio-button__inner {
        padding: 5px;
      }
      .measure-units-value {
        font-size: 12px;
        display: inline-block;
        margin-left: 10px;
      }
      .measure-units-value /deep/ sup {
        font-size: 9px;
      }
      .view-mode-select {
        width: 60%;
      }
      /* .view-mode-select /deep/ input[type=text] {
        height: 30px;
      } */
      .copy-coord-value {
        margin-left: 30px;
        width: initial;
      }
  </style>
