<template>
  <div>
    <div>
      <el-button type="text" @click="addFilter" style="float: left; margin: 0px 0px 0px 10px;">Добавить фильтр</el-button>
      <el-checkbox label="Режим сортировки" v-model="moveMode" style="float: right"></el-checkbox>
      <div style="height: 1px; width: 1px; display: inline-block;"></div>
    </div>
    <draggable
      :list="filters"
      :move="checkMove"
      :disabled="!moveMode"
      class="draggable"
      @start="dragging = true"
      @end="dragging = false"
    >
      <div
        v-for="(item, index) in filters"
        :key="index"
        class="oneFilter"
      >
        <el-col :span="24">
          <el-col :span="7">
            <span style="margin: 10px">Поле</span>
          </el-col>
          <el-col :span="13">
            <select-tree
              :clearable="false"
              :dataTable="fields"
              :appendToBody="false"
              v-model="item.field"
              @input="inputHandler(item, $event)"
              v-if="source.type==='Registry'"
              size="mini"
              class="tree-mini"/>
            <el-input v-else v-model="item.field"></el-input>
          </el-col>
          <el-col :span="4">
            <el-button @click="deleteFilter(item)" type="text" style="margin: 0 13px;">Удалить</el-button>
          </el-col>
        </el-col>
        <el-col :span="24">
          <el-col :span="7">
            <span style="margin: 10px">Наименование</span>
          </el-col>
          <el-col :span="17">
            <el-input v-model="item.label"></el-input>
          </el-col>
        </el-col>
      </div>
    </draggable>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import Registry from '@/components/Models/EntityModel'
import SelectTree from '@/core/infrastructure/components/TreeSelect'
import { ObjectEditorAPI } from '@/core/infrastructure/api/modules/ObjectEditorAPI'
import { APIClient } from '@/core/infrastructure/api/APIClient'

const DEFAULT_FILTER = {
  type: null,
  field: null,
  label: null,
  condition: null
}

const EXCLUDE_FIELD_TYPES = [
  'geometry_field'
]

export default {
  name: 'DynamicMapFilters',
  components: { draggable, SelectTree },
  props: {
    dynamicFilters: [],
    source: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data: function () {
    return {
      filters: this.dynamicFilters,
      me: this,
      enabled: true,
      dragging: false,
      fields: [],
      moveMode: false
    }
  },
  async mounted () {
    if (this.source.type === 'Registry') {
      this.fields = await this.getRegistryFields(this.source.entityId)
    }
  },
  methods: {
    inputHandler (filter) {
      this.changeFieldHandler(filter)
    },
    async getRegistryFields (entityId) {
      let data = await APIClient.shared.request(
        new ObjectEditorAPI.GetEntities({
          'parent_id': entityId,
          'show_children': true,
          'properties[is_visible_in_grid]': true
        })
      )
      return data.data.filter(item => !EXCLUDE_FIELD_TYPES.includes(item.entity_type_id))
    },
    addFilter () {
      let newFilter = { ...DEFAULT_FILTER }
      if (this.source.type !== 'Registry') {
        newFilter.type = 'string_field'
      }
      this.filters.splice(this.filters, 0, newFilter)
    },
    allowDrop (draggingNode, dropNode, type) {
      return type !== 'inner'
    },
    checkMove (e) {
    },
    deleteFilter (filter) {
      this.dynamicFilters.splice(this.dynamicFilters.findIndex((item) => { return item.field === filter.field }), 1)
    },
    findFieldInArray (items, searchId) {
      let result = null
      for (let i = 0; i < items.length; i++) {
        if (result !== null) {
          return result
        }
        if (items[i].entity_type_id === 'field_group') {
          result = this.findFieldInArray(items[i].children, searchId)
        } else {
          if (items[i].id === searchId) {
            result = items[i]
          }
        }
      }
      return result
    },
    changeFieldHandler (filter) {
      let elem = this.findFieldInArray(this.fields, filter.field)
      if (!elem) {
        console.log('это невозможно')
        return
      }
      if (!filter.label) {
        this.$set(filter, 'label', elem.name)
      }
      this.$set(filter, 'type', elem.entity_type_id)
    }
  }
}
</script>

<style scoped>
  .tree-mini /deep/ .vue-treeselect__control {
    height: 28px;
  }
  .tree-mini /deep/ .vue-treeselect__single-value {
    padding-left: 9px;
    line-height: 28px;
    font-size: 12px;
  }
  .draggable {

  }
  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }
  .oneFilter {
    border: #1A1A1A;
    height: 56px;
    margin-bottom: 5px;
    border-bottom: 2px solid #f2f2f2;
  }
</style>
