import FilterBuilder, { EComponentTypes, EFilterTypes } from '@/components/InterfaceEditor/components/utils'
export default class XrefEditor {
  private value: any
  private input: HTMLInputElement
  private datalist: HTMLDataListElement;
  private axios: any
  private api_url: any
  private attributeId: number
  private options: [] = []
  private isMulti: boolean
  private stringParams: string

  init (params) {
    this.attributeId = params.colDef.field.replace(/^\D+/g, '').replace('_', '')
    this.value = params.value || []
    try {
      this.value = JSON.parse(params.value)
    } catch (e) {}

    this.axios = params.axios
    this.api_url = params.api_url
    this.isMulti = params.multi

    this.input = document.createElement('input')
    this.input.setAttribute('placeholder', 'Поиск...')
    this.input.setAttribute('autocomplete', 'off')
    this.input.style.cssText = 'padding:5px; box-sizing:border-box; width:100%; height: 100%;'
    this.datalist = document.createElement('datalist')
    this.input.multiple = !!this.isMulti
    // костыль для фильтрации столбца по значению другого столбца, чтобы не переделывать компонент editorFilter
    let filter = JSON.parse(JSON.stringify(params.colDef.columnFilters || []))
    filter.forEach(item => {
      if (item.type === 'registry_field') {
        item.type = EFilterTypes.constant
        let attributeData = null
        try {
          attributeData = JSON.parse(params.data[item.attribute])
          attributeData = attributeData[0].id
        } catch (e) {
          attributeData = params.data[item.attribute][0].id || params.data[item.attribute]
        }
        item.attribute = attributeData || null
      }
    })

    if (params.colDef.columnFilters) {
      const builder = new FilterBuilder(
        filter,
        params.context.componentParent.getModel(),
        params.context.componentParent.$store,
        EComponentTypes.xrefField
      )
      this.stringParams = '?'
      let filterArray = builder.buildAsRegistryService()
      let builtFilterArr = []
      filterArray.forEach((el, index) => {
        builtFilterArr.push(`filter[${index}]=${el}`)
      })
      this.stringParams += builtFilterArr.join('&')
    }

    // this.buildOptions([{ name: 'Загрузка...' }])

    this.input.addEventListener('input', this.handlerInput.bind(this))
    this.loadingOptions()
  }
  handlerInput (event: InputEvent):void {
    const inputElement = event.target as HTMLInputElement
    let nextValue = this.options.filter((item:any) => (item.name || '').toString().trim() === inputElement.value.trim())
    if (nextValue.length) this.value = nextValue
    if (inputElement.value === 'Пусто') this.value = []
  }

  loadingOptions () {
    this.input.setAttribute('placeholder', 'Загрузка...')
    this.axios.get(`${this.api_url}/registryservice/xref/${this.attributeId}/data${this.stringParams || ''}`)
      .then((response) => {
        this.options = response.data
        this.buildOptions([{ name: 'Пусто', id: '000' }, ...response.data])
      })
      .catch((error) => {
        console.log(error)
        this.input.setAttribute('placeholder', 'Поиск...')
        this.buildOptions([{ name: 'Ошибка загрузки' }])
      })
  }
  // Генерируем случайный id (кешируются элементы, в выпадашке одинаковые значения)
  generateUniqueId () {
    return Math.random().toString(36).substring(2, 9)
  }

  buildOptions (array) {
    this.input.innerHTML = ''
    const uniqueId = 'xref_options_' + this.generateUniqueId()
    this.input.setAttribute('list', uniqueId)
    this.datalist.setAttribute('id', uniqueId)
    // this.input.insertAdjacentElement('afterend', this.datalist)
    this.input.appendChild(this.datalist)
    // убрать треугольник в инпуте
    let styleElement = document.createElement('style')
    let styleText = 'input::-webkit-calendar-picker-indicator { display: none !important; }'
    styleElement.textContent = styleText
    styleElement.style.cssText = 'display: none;'
    this.input.insertAdjacentElement('beforebegin', styleElement)
    array.forEach((item) => {
      let option = document.createElement('option')
      option.value = item.name
      option.text = item.name
      option.label = ''
      option.selected = !!item.selected
      this.datalist.appendChild(option)
    })
    this.input.setAttribute('placeholder', 'Поиск...')
  }

  /* Component Editor Lifecycle methods */

  // gets called once when grid ready to insert the element
  getGui () {
    return this.input
  }

  // the final value to send to the grid, on completion of editing
  getValue () {
    // this simple editor doubles any value entered into the input
    return this.value
  }

  // after this component has been created and inserted into the grid
  afterGuiAttached () {
    this.input.focus()
  }
  // Gets called once by grid after editing is finished
  // if your editor needs to do any cleanup, do it here
  destroy (): void {
    this.input.removeEventListener('input', this.handlerInput.bind(this))
  }
}
