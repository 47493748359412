<template>
  <div>
    <span class="label">
      {{ label }}
    </span>
    <el-autocomplete
      style="width: 100%"
      size="mini"
      v-model="model"
      :disabled="disabled"
      :placeholder="placeholder"
      :fetch-suggestions="search"
      :trigger-on-focus="false"
      value-key="id"
      @focus="onFocus"
      @change="$emit('change', resultedValue); closePopup()"
      @select="$emit('change', resultedValue); closePopup()"
    >
      <template slot-scope="{ item }">
        <span style="float: left;">{{item.name}}</span>
        <span style="float: right;color: #7C838B;">{{item.description}}</span>
      </template>
    </el-autocomplete>

  </div>
</template>

<script>
export default {
  name: 'HouseField',
  inject: ['getPreviousValue'],

  props: {
    label: {
      type: String
    },
    type: {
      type: String
    },
    placeholder: {
      type: String,
      default: null
    },
    value: {
      type: Object | String | Number
    },
    addressType: {
      type: String,
      default: 'administrative'
    }
  },

  data () {
    return {
      model: null,
      options: [],
      isLoaded: false
    }
  },

  computed: {
    resultedValue () {
      //console.log('model', ''+this.model)
      if (!this.model) {
        return null
      }
      let object = {}
      let issetHouse = this.options.find((item) => item.id === this.model)

      if (issetHouse) {
        object = issetHouse
      } else {
        object.name = this.model
        object.id = null
      }
      object.previous = this.previousValue.id
      return object
    },
    disabled () {
      return !((this.type === 'subject') || this.previousValue)
    },
    previousValue () {
      return this.getPreviousValue('house')
    }
  },

  watch: {

    value: {
      deep: true,
      handler (value) {
        //console.log('house value', value)
        if (!value) {
          this.model = null
          return
        }
        //console.log('Нашлось?' + this.options.findIndex((item) => item.id === value.id))
        if (value && value.id && this.options.findIndex((item) => item.id === value.id) === -1) {
          this.options.push(value)
        }

        //this.model = value.name
        this.model = value && value.name ? value.name : value
        //this.$emit('input', this.resultedValue)
      }
    },

    previousValue (newValue, oldValue) {
      //console.log('previous value для дома изменилось', newValue, oldValue)
      if (newValue === oldValue) {
        return
      }
      this.$emit('change', null)
      this.model = null
      this.isLoaded = false
    }
  },

  methods: {
    async load () {
      // let data = await this.$http.get(`${this.$config.api}/fiasaddressservice/addresses/houses/${this.previousValue.guid}/house/`)
      let data = await this.$http.get(`${this.$config.api}/garaddressservice/houses/${this.previousValue.id}/${this.addressType}`)
      //fiasData = await this.$http.get(`${this.$config.api}/garaddressservice/addresses/houses/${this.previousValue.guid}/${this.addressType}`, { params })
      this.options = data.data.map((item) => {
        return {
          ...item,
          id: ''+item.id
        }
      })
      this.isLoaded = true
    },
    onFocus () {
      if (!this.isLoaded) {
        this.load()
      }
    },
    async search (query, callback) {
      //let results = query ? this.options.filter((item) => item.house.toLowerCase().indexOf(query.toLowerCase()) === 0) : this.options
      let results = query ? this.options.filter((item) => item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1) : this.options
      callback(results)
      results.length > 0 && this.$emit('open-popup')
    },
    closePopup () {
      // Задержка из-за v-clickoutside в AddressObjects
      setTimeout(() => this.$emit('close-popup'), 200)
    }
  }
}
</script>

<style scoped>
.label {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  color: #7D7D7D;
  display: block;
  padding: 5px 5px 0px 5px;
}
</style>
