import CellEditors from '@/core/infrastructure/components/Grid/infrastructure/service/CellEditors'

export interface IResultColumn {
  children;
  filter;
  cellRenderer: string;
  cellRendererParams: object;
  card: object;
  dashboard: object;
  clickType: string;
  htmlTemplate: string;
  cssStyle: string;
  cellClass: string;
  width;
  hide: boolean | undefined;
  action: object;

  [prop: string]: any;
}

export default class ResultColumnsBuilder {
  static requiredFields = [
    'headerName',
    'field',
    'type',
    'enableRowGroup',
    'filter',
    'filterParams',
    'floatingFilterComponent',
    'floatingFilterComponentParams'
  ]
  static build (columns: {
    children,
    isExtended,
    isStringSetFilter,
    columnType: string,
    clickType: string,
    htmlTemplate: string,
    cssStyle: string
    isFlex: number
    card: {registryId, type, queryRegistry: string}
    dashboard: object
    width
    hiddenCondition: string,
    action: object,
    editable: boolean,
    columnFilters: object,
    showLegend: boolean,
    sparklineType: string,
    addressType: string,
    objectLevel: number,
    isDoubleClick: boolean
    sparklineOptions: {
      percentageOptions: object,
      label: boolean,
      tooltip: boolean,
      fill: string,
      lineColor: string
    },
    floatPrecision: number,
    groupClose: boolean,
    groupOpen: boolean,
    isHidden: boolean,
    parentHidden: boolean,
    hideSeconds: boolean
    negativeValues: boolean
    filterVisibleOnly: boolean
  }[], context: any): IResultColumn[] {
    const result: IResultColumn[] = []
    let isEditor = context.isEditor() || false

    columns.forEach((column) => {
      let object: IResultColumn = {
        children: undefined,
        filter: undefined,
        cellRenderer: undefined,
        cellRendererParams: undefined,
        card: undefined,
        dashboard: undefined,
        clickType: undefined,
        htmlTemplate: undefined,
        cssStyle: undefined,
        cellClass: 'fontFamily',
        width: undefined,
        hide: undefined,
        action: undefined,
        editable: false,
        columnFilters: undefined,
        valueSetter: undefined,
        sparklineOptions: undefined,
        showLegend: false,
        groupClose: false,
        groupOpen: false,
        isDoubleClick: false,
        hideSeconds: false,
        negativeValues: false,
        filterVisibleOnly: false
      }
      for (let property in column) {
        if (!column.hasOwnProperty(property)) {
          continue
        }
        if (this.requiredFields.includes(property)) {
          object[property] = column[property]
        }
      }

      object.cellRenderer = column.columnType
      object.addressType = column.addressType
      object.objectLevel = column.objectLevel
      object.filterVisibleOnly = column.filterVisibleOnly
      // Двойной клик для действия
      object.isDoubleClick = column.isDoubleClick

      column.isHidden = column.parentHidden
      if (column.parentHidden) {
        object = Object.assign(object, { hide: true })
      } else {
        if (!isEditor) {
          if (column.hiddenCondition) {
            column.isHidden = this.parseColumnHiddenConditions(context, column.hiddenCondition)
          }
          object = Object.assign(object, { hide: column.isHidden })
        }
      }

      if (column.children) {
        column.children.forEach(el => {
          Object.assign(el, { isHidden: column.isHidden, parentHidden: column.isHidden })
        })
        object.children = ResultColumnsBuilder.build(column.children, context)
      }

      if (column.columnType === 'agSparklineCellRenderer') {
        if (column.sparklineType === 'percentage') {
          object.cellRenderer = 'percentageSparklineField'
          object.cellRendererParams = {
            percentageOptions: column.sparklineOptions?.percentageOptions || []
          }
          if (column.showLegend) {
            object.headerComponent = 'PercentageFieldHeader'
            object.headerComponentParams = {
              title: object.headerName,
              percentageOptions: column.sparklineOptions?.percentageOptions
            }
          }
        } else {
          object.cellRendererParams = {
            sparklineOptions: {
              type: column.sparklineType,
              fill: column.sparklineOptions?.fill,
              line: { stroke: column.sparklineOptions?.lineColor },
              label: {
                enabled: column.sparklineOptions?.label || false
              },
              tooltip: {
                enabled: column.sparklineOptions?.tooltip || false
              }
            }
          }
        }
      }
      if (column.cssStyle) {
        object.cssStyle = column.cssStyle
      }

      if (column.editable) {
        object.editable = true
        // console.log(CellEditors.build(column.columnType, context))
        object = Object.assign(object, CellEditors.build(column.columnType, context, column))
      }

      if (column.editable && column.columnFilters) {
        object = Object.assign(object, {
          columnFilters: column.columnFilters
        })
      }

      if (column.showLegend) {
        object = Object.assign(object, {
          showLegend: column.showLegend
        })
      }

      if (column.isExtended) {
        object = Object.assign(object, {
          autoHeight: true,
          wrapText: true
        })
      }
      if (column.isFlex) {
        object = Object.assign(object, {
          flex: 1
        })
      }
      if (column.width) {
        object.width = column.width + 31 /* Column menu */
      }

      if (column.isStringSetFilter) {
        object.filter = 'stringSetFilter'
      }
      if (!context?.readonly) {
        if (column.dashboard || (column.card && (column.card.registryId || column.card.type || column.card.queryRegistry))) {
          object.card = column.card
          object.dashboard = column.dashboard
          object.clickType = column.clickType
          // object.cellRendererParams = {
          //   clicked: function (field) {}
          // }
        } else if (column.action) {
          object = Object.assign(object, {
            action: column.action
          })
        }
      }

      if (column.columnType === 'htmlField') {
        object.htmlTemplate = column.htmlTemplate
      }

      if (column.columnType === 'floatField' && column.floatPrecision) {
        object = Object.assign(object, {
          floatPrecision: column.floatPrecision
        })
      }
      if (column.columnType === 'timeField' && column.hideSeconds) {
        object = Object.assign(object, {
          hideSeconds: column.hideSeconds
        })
      }
      if (column.groupClose) {
        object = Object.assign(object, { columnGroupShow: 'closed' })
      }
      if (column.groupOpen) {
        object = Object.assign(object, { columnGroupShow: 'open' })
      }

      result.push(object)
    })

    return result
  }
  static parseColumnHiddenConditions (context, hideCondition = ''): boolean {
    let condition = JSON.parse(JSON.stringify(hideCondition))

    let attributes = condition.match(/\{(.*?)\}/g) || []
    attributes.forEach((attribute) => {
      attribute = attribute.replace('{', '').replace('}', '')
      let value = context.getModel()[attribute]
      try {
        value = JSON.parse(value)
      } catch (e) {

      }
      if (value instanceof Array) {
        value = value.map(item => item.id || item).join(',')
      }
      if (value === '' || value === undefined) {
        value = 0
      }

      condition = condition.replace(`{${attribute}}`, value)
    })
    try {
      if (eval(condition)) {
        return true
      }
      return false
    } catch (e) {
      console.info(`invalid condition: ${hideCondition}, result: ${condition}`)
      return false
    }
  }
}
