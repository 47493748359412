
import Vue from 'vue'
import RegistrySelectTree from '@/core/infrastructure/components/RegistrySelectTree.vue'
import ObjectTree from '@/components/Common/ObjectTree.vue'
import { deepClone } from '@/components/InterfaceEditor/components/utils'

const defaultModel = () => deepClone({
  service_type: 'da_data',
  data_type: 'party',
  object_id: null,
  label_field_id: null,
  search_fields: [],
  fields: []
})

export default Vue.extend({
  name: 'editor-card-autocomplete',

  components: {
    RegistrySelectTree,
    ObjectTree
  },

  inject: ['getComponents'],

  props: {
    value: Object,
    default () {
      return defaultModel()
    }
  },

  watch: {
    model: {
      handler (value) {
        this.$emit('change', value)
      },
      deep: true
    }
  },

  computed: {
    components () {
      if (this.getComponents) {
        return this.getComponents()
      }

      return []
    }
  },

  data () {
    return {
      model: defaultModel(),

      serviceTypes: [
        { id: 'da_data', name: 'DaData.ru' },
        { id: 'accent', name: 'Акцент 2.0' }
      ],

      daDataTypes: [
        { id: 'party', name: 'Организации' },
        { id: 'bank', name: 'Банки' }
      ],

      daDataProperties: {
        party: [
          // Основное
          {
            label: 'Основное',
            children: [
              { value: 'inn', label: 'ИНН' },
              { value: 'kpp', label: 'КПП' },
              { value: 'ogrn', label: 'ОГРН' },
              { value: 'ogrn_date', label: 'Дата выдачи ОГРН' },
              { value: 'type', label: 'Тип организации (юр. лицо, ИП)' },
              { value: 'branch_count', label: 'Количество филиалов' },
              { value: 'branch_type', label: 'Тип подразделения' }
            ]
          },

          // Наименование
          {
            label: 'Наименование',
            children: [
              { value: 'name.full_with_opf', label: 'Полное наименование' },
              { value: 'name.short_with_opf', label: 'Краткое наименование' },
              { value: 'name.full', label: 'Полное наименование без ОПФ' },
              { value: 'name.short', label: 'Краткое наименование без ОПФ' }
            ]
          },

          // ФИО индивидуального предпринимателя
          {
            label: 'ФИО индивидуального предпринимателя',
            children: [
              { value: 'fio.surname', label: 'Фамилия' },
              { value: 'fio.name', label: 'Имя' },
              { value: 'fio.patronymic', label: 'Отчество' },
              { value: 'fio.fullName', label: 'ФИО' }
            ]
          },

          // Коды статистики (только для действующих головных организаций и ИП)
          {
            label: 'Коды статистики (только для действующих головных организаций и ИП)',
            children: [
              { value: 'okato', label: 'Код ОКАТО' },
              { value: 'oktmo', label: 'Код ОКТМО' },
              { value: 'okpo', label: 'Код ОКПО' },
              { value: 'okogu', label: 'Код ОКОГУ' },
              { value: 'okfs', label: 'Код ОКФС' },
              { value: 'okved', label: 'Код ОКВЭД' },
              { value: 'okved_type', label: 'Версия справочника ОКВЭД (2001 или 2014)' }
            ]
          },

          // Организационно-правовая форма
          {
            label: '',
            children: [
              { value: 'opf.code', label: 'Код ОКОПФ' },
              { value: 'opf.full', label: 'Полное название ОПФ' },
              { value: 'opf.short', label: 'Краткое название ОПФ' },
              { value: 'opf.type', label: 'Версия справочника ОКОПФ (99, 2012 или 2014)' }
            ]
          },

          // Руководитель
          {
            label: 'Руководитель',
            children: [
              { value: 'management.name', label: 'ФИО руководителя' },
              { value: 'management.post', label: 'Должность руководителя' }
            ]
          },

          // Адрес
          {
            label: 'Адрес',
            children: [
              { value: 'address.value', label: 'Адрес одной строкой для юр. лиц и ИП' },
              { value: 'address.unrestricted_value', label: 'Адрес одной строкой (полный, с индексом)' },
              { value: 'address.data.source', label: 'Адрес одной строкой как в ЕГРЮЛ' },
              { value: 'address.data.qc', label: 'Код проверки адреса (0 — адрес распознан уверенно, 1 или 3 — требуется ручная проверка)' }
            ]
          },

          // Состояние
          {
            label: 'Состояние',
            children: [
              { value: 'state.status', label: 'Статус организации' },
              { value: 'state.actuality_date', label: 'Дата последних изменений' },
              { value: 'state.registration_date', label: 'Дата регистрации' },
              { value: 'state.liquidation_date', label: 'Дата ликвидации' }
            ]
          },

          // Контакты
          {
            label: 'Контакты',
            children: [
              { value: 'phones', label: 'Номера телефонов (мн.)' },
              { value: 'emails', label: 'Эл. почтовые адреса (мн.)' }
            ]
          },

          // Дополнительные поля
          {
            label: 'Дополнительные поля',
            children: [
              { value: 'founders', label: 'Учредители компании' }
            ]
          }
        ],

        bank: [
          // Основное
          {
            label: 'Основное',
            children: [
              { value: 'name.payment', label: 'Платежное наименование' },
              { value: 'name.short', label: 'Краткое наименование' },
              { value: 'bic', label: 'БИК ЦБ РФ' },
              { value: 'swift', label: 'БИК в системе SWIFT' },
              { value: 'inn', label: 'ИНН' },
              { value: 'kpp', label: 'КПП' },
              { value: 'registration_number', label: 'Регистрационный номер в ЦБ РФ' },
              { value: 'correspondent_account', label: 'Корреспондентский счет в ЦБ РФ' },
              { value: 'treasury_accounts', label: 'Казначейские счета (для УФК)' },
              { value: 'opf.type', label: 'Тип кредитной организации' }
            ]
          },

          // Управление ЦБ РФ, к которому относится банк
          {
            label: 'Управление ЦБ РФ, к которому относится банк',
            children: [
              { value: 'cbr.name.payment', label: 'Наименование управления ЦБ РФ' },
              { value: 'cbr.bic', label: 'БИК управления ЦБ РФ' },
              { value: 'cbr.address.value', label: 'Адрес управления ЦБ РФ' }
            ]
          },

          // Адрес
          {
            label: 'Адрес',
            children: [
              { value: 'address.value', label: 'Адрес регистрации (может отличаться от БИК)' },
              { value: 'address.unrestricted_value', label: 'Адрес регистрации (с индексом, может отличаться от БИК)' },
              { value: 'address.data.source', label: 'Адрес одной строкой как в справочнике БИК' },
              { value: 'address.data.qc', label: 'Код проверки адреса (0 — адрес распознан уверенно, 1 или 3 — требуется ручная проверка)' }
            ]
          },

          // Состояние
          {
            label: 'Состояние',
            children: [
              { value: 'state.status', label: 'Статус организации' },
              { value: 'state.actuality_date', label: 'Дата актуальности изменений' },
              { value: 'state.registration_date', label: 'Дата регистрации' },
              { value: 'state.liquidation_date', label: 'Дата ликвидации' }
            ]
          }
        ]
      }
    }
  },

  mounted () {
    this.model = this.value || defaultModel()
  },

  methods: {
    serviceChange (value) {
      if (value === 'da_data') {
        this.model.data_type = this.daDataTypes[0].id
      }

      this.model.fields = []
    },

    objectChange (value) {
      this.model.label_field_id = null
      this.model.search_fields = []
      this.model.fields = []
    },

    addField () {
      this.model.fields.push({
        property: null,
        attribute: null,
        isXref: false
      })
    },

    deleteField (index) {
      this.model.fields.splice(index, 1)
    }
  }
})
