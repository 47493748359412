<template>
  <div class="string-input">
    <el-input
      v-model="inputValue"
      @change="emitChange"
      :size="size"
      :type="type"
      :rows="rows"
    ></el-input>
  </div>
</template>

<script>
export default {
  name: 'StringInput',
  props: {
    value: String,
    size: {
      type: String,
      default: 'small'
    },
    type: {
      type: String,
      default: 'text'
    },
    rows: {
      type: Number,
      default: 1
    }
  },

  data () {
    return {
      inputValue: this.value
    }
  },

  methods: {
    emitChange (val) {
      this.$nextTick(() => {
        this.$emit('input', val, this.inputValue)
      })
    }
  }
}
</script>
