<template>
  <div class="dashboard_form">
    <component :is="'style'" v-if="userCssClass">{{ userCssClass }}</component>
    <div class="constructor" v-if="isConstructorMode">
      <el-tooltip  class="item" style="margin-right: 10px;" effect="dark" :content="contentMessageForConstructorMode" placement="left">
        <i class="el-icon-s-tools" @click="openInEditor"></i>
      </el-tooltip>
      <el-tooltip class="item" effect="dark" :content="'Сохранить HTML'" placement="bottom">
        <i class="el-icon-bottom" @click="saveHTML"></i>
      </el-tooltip>
    </div>
    <responsive-previewer
      ref="designer"
      style="height: 100%"
      :style="settingStyle"
      :replication-callback="onBlockReplication"
      :tab-settings="tabSettings"
      @click="onClick"
      @tab-click="onTabClick"
    >
      <template v-slot:content="{ block }">
        <!-- @TODO Заменить на Cards -->
        <CardsWrapper
          v-if="block.properties.contentType === 'registry'"
          :ref="'registry_' + block.guid"
          :show-breadcrumbs="block.properties.showBreadcrumbs"
          :defaults="block.properties.contentDefaults"
          @close="$set(block.properties, 'contentType', null)"
        ></CardsWrapper>
        <component
          v-else-if="checkType(block.guid)"
          v-bind="_components[block.guid].properties"
          :replication-guid="(_components[block.guid].replication || {}).blockGuid"
          :replication-index="(_components[block.guid].replication || {}).index"
          :replicationFilter="replicationFilters.blocks"
          :ref="'component_' + block.guid"
          :guid="block.guid"
          :saveCardNow="saveCardNow"
          :openCardInWindow ="openCardInWindow"
          :is="_components[block.guid].type"
          :value="isReplicationContainer(_components[block.guid].replication)
              ? getReplicationModel(_components[block.guid].replication)[_components[block.guid].properties.name]
              : model[_components[block.guid].properties.name]"
          @input="isReplicationContainer(_components[block.guid].replication) ?
              null :
              $set(model, _components[block.guid].properties.name, $event)"
          @toggle-hide="toggleHideBlock(block.guid, $event)"
        >
        </component>
      </template>
    </responsive-previewer>
  <div v-show="false">
    <component
      v-for="(component, index) in requiredComponents"
      :key="'required_component_' + index"
      v-bind="component.properties"
      :ref="'required_component_' + component.guid"
      :guid="component.guid"
      :saveCardNow="saveCardNow"
      :is="component.type"
      :value="model[component.properties.name]"
    >
    </component>
  </div>
    <modal-window
      v-if="!modalWindow.isPopover"
      :active.sync="modalWindow.show"
      :width="modalWindow.width"
      :height="modalWindow.height"
      :title="modalWindow.title"
    >
      <interface-viewer
        :model="modalWindow.model"
        ref="modal_viewer"
      >
      </interface-viewer>
    </modal-window>
    <custom-popover
      v-if="modalWindow.isPopover && modalWindow.show"
      :active.sync="modalWindow.show"
      :position-x="modalWindow.position.x"
      :position-y="modalWindow.position.y"
      :width="modalWindow.width"
      :height="modalWindow.height"
    >
      <interface-viewer
        :model="modalWindow.model"
        ref="modal_viewer_popover"
      >
      </interface-viewer>
    </custom-popover>
  </div>
</template>

<script>
import VueDraggableResponsive from 'vue-draggable-responsive'
import main from '@/services/InterfaceEditor/mixins/main.js'
import replication from '@/services/InterfaceEditor/mixins/replication'

import Dashboard from '@/components/DashboardEditor/Models/Dashboard'
import RegistryCard from '@/components/RegistryCard'
import ActionExecutor from '@/core/infrastructure/service/ActionExecutor.ts'
import ModalWindow from '@/core/infrastructure/components/ModalWindow'
import CustomPopover from '@/core/infrastructure/components/CustomPopover'
import CardsWrapper from '@/components/Registry/CardsWrapper'
import refreshComponentsMixin from '@/components/InterfaceEditor/components/refreshComponentsMixin'
import CardListener from '@/services/InterfaceViewer/CardListener'

// import Registry from '@/components/Registry/index.vue' // Registry выдает ошибку: Unknown custom element: <Registry> - did you register the component correctly? For recursive components, make sure to provide the "name" option.

export default {
  name: 'InterfaceViewer',

  components: {
    RegistryCard,
    CardsWrapper,
    CustomPopover,
    ModalWindow,
    ResponsivePreviewer: VueDraggableResponsive.VueDraggableResponsivePreviewer,
    // Registry, // Ошибка при статическом импорте выше
    Registry: () => import('@/components/Registry/index.vue')
  },

  mixins: [main, replication, refreshComponentsMixin],

  inject: {
    /* Зависимости ActionExecutor @TODO убрать ненужные */
    getCard: {
      default: () => {}
    },
    getParentDashboard: {
      default: () => {}
    },
    getParentContext: {
      default: () => {}
    },
    addMainTab: {
      default: () => {}
    },
    updateTab: {
      default: () => {}
    },
    tabs: {
      default: () => {}
    },
    activeTab: {
      default: () => {}
    },
    closeTab: {
      default: () => {}
    },
    openedCards: {
      default: () => {}
    },
    cancelChanges: {
      default: () => {}
    },
    openRegistryCard: {
      default: () => {}
    },
    openDashboardCard: {
      default: () => {}
    },
    openTabModalWindow: {
      default: () => {}
    },
    addMainTabInWindow: {
      default: () => {}
    }
  },

  provide () {
    return {
      getContainersStore: this.getContainersStore,
      getInterfaceWrapper: this.getInterfaceWrapper,
      getComponents: this.getComponentsAsArray,
      $getModel: () => this.$$getModel,
      getReplicationComponents: this.getReplicationComponents,
      isEditor: () => {
        return false
      }
    }
  },

  props: {
    model: {
      type: Object,
      default: () => {
        return {}
      }
    },
    dashboardId: {
      type: Number
    },
    registryCard: {
      type: Object,
      default: () => {}
    },
    saveCardNow: {
      type: Boolean
    },
    openCardInWindow: {
      type: Boolean
    },
    invalidFields: {
      type: Array,
      default: () => []
    },
    blockedTabAliases: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      viewer: true,
      components: {},
      settings: {},
      conditions: [],
      width: null
    }
  },

  computed: {
    isConstructorMode () {
      return this.$store.getters['ConstructorMode/getConstructorMode']
    },
    $$getModel () {
      return this.model
    },

    contentMessageForConstructorMode () {
      if (this.dashboardId) {
        return `Открыть дашборд ID: ${this.dashboardId}`
      } else {
        return `
        Открыть карточку: Реестр ID: ${this.registryCard?.registryId},
        Карточка ID: ${this.registryCard?.cardId},
        Запись ID: ${this.registryCard?.recordId}
        `
      }
    },

    inCard () {
      return typeof this.getCard === 'function'
    }
  },

  watch: {
    invalidFields (value) {
      let invalidBlocks = []
      for (let key in this.$refs) {
        if (key.startsWith('required_component_') && this.$refs.hasOwnProperty(key)) {
          const cmp = this.$refs[key]
          if (value.includes(cmp.name || cmp[0]?.name)) {
            const blockGuid = key.replace('required_component_', '')
            invalidBlocks.push(blockGuid)
          }
        }
      }
      this.setRequiredBlocks(invalidBlocks)
    },
    actualConditionGuid (value) {
      if (value !== this.activeConditionGuid) {
        if (value) {
          const condition = this.conditions.find((item) => item.guid === value)
          this.loadInterface(condition.structure, true)
        } else {
          this.loadInterface(this.originalStructure)
        }
        this.activeConditionGuid = value
      }
    }
  },

  created () {
    this.updateWindowWidth()
    // window.addEventListener("resize", this.updateWindowWidth)
  },

  mounted () {
    let me = this
    this.getStructure().then((resolve) => {
      let cardListeners = resolve.cardListeners
      if (cardListeners) {
        cardListeners.forEach(async listener => {
          CardListener.on(listener.name, () => { ActionExecutor.execute(me, { readonly: false, action: listener.action }) }, this.activeTab() || 'a')
        })
      }
    })
    this.$set(this, 'currentViewer', this.$refs.designer)
    this.$set(this, 'tabSettingsService', this.currentViewer?.tabSettingsService)
  },

  destroyed () {
    // window.removeEventListener("resize", this.updateWindowWidth)
  },

  methods: {
    getReplicationComponents () {
      return this.replicationComponents
    },
    setRequiredBlocks (blocks) {
      this.$refs.designer.store.setRequiredTabs(blocks)
    },
    updateWindowWidth () {
      this.width = window.innerWidth
    },

    toggleHideBlock (guid, value) {
      if (!this.currentViewer) {
        return
      }

      const store = this.getContainersStore()
      const block = store.getByGuid(guid)
      if (!block) {
        return false
      }
      if (!value) {
        if (this.hiddenBlocks.includes(guid)) {
          return
        }
      }
      block.isHidden = value
    },

    getContainersStore () {
      return this.currentViewer.getStore()
    },

    getInterfaceWrapper () {
      return this
    },

    getDashboardComponents () {
      let refs = {}
      for (let key in this.$refs) {
        if (this.$refs.hasOwnProperty(key)) {
          refs[key] = [this.$refs[key]]
        }
      }

      return refs
    },

    clickPagination (guid) {
      const replicationBlockWithPagination = this.getContainersStore().refs.find(item => guid === item.guid)
      if (replicationBlockWithPagination && this.getModel()['pagination']) {
        this.$set(this.actualPaginationOffset, guid, this.getModel()['pagination'].value)
        this.refreshReplication(replicationBlockWithPagination.guid, { offset: this.getModel()['pagination'].value })
      }
    },

    scrollToTop (event) {
      let mainContainer = event.target.closest('.el-main.custom_scrollbar')
      if (!mainContainer) {
        mainContainer = event.target.closest('.dashboard_form')
      }
      if (mainContainer) {
        mainContainer.scrollTo({ top: 0, left: 0 })
      }
    },
    onTabClick ({ guid }) {
      if (!this.tabSettingsService) {
        return
      }
      const interactive = this.tabSettingsService.getInteractive(guid)
      if (!interactive) {
        return
      }

      ActionExecutor.execute(this, { readonly: false, action: interactive })
    },
    onClick ({ block, event }) {
      if (block.pagination) {
        this.clickPagination(block.pagination.replicationGuid)
      }
      if (!block.interactive) {
        return
      }
      if (block.interactive.action?.dashboard || block.interactive.action?.type === 'open_card') {
        this.scrollToTop(event)
      }
      let model = {}
      try {
        if (this.replicationBlocks[block.guid] && this.replicationData[this.replicationBlocks[block.guid].blockGuid]) {
          model = this.replicationData[this.replicationBlocks[block.guid].blockGuid][this.replicationBlocks[block.guid].index]
        }
      } catch (error) {
        console.warn('error in getting replication model', error)
      }

      // console.log('block', block)

      ActionExecutor.execute(this,
        { readonly: false, pluginName: block.interactive.pluginName, action: block.interactive.action, event: event },
        model)
    },

    async getDashboard () {
      let dashboard = await new Dashboard().find(this.dashboardId)
      return dashboard
    },

    async getCardId (registryId, recordId = null) {
      let url = `${this.$config.api}/registryservice/registry/${registryId}/card`
      if (recordId) {
        url = `${this.$config.api}/registryservice/registry/${registryId}/records/${recordId}/card`
      }
      let data = await this.$http.get(url)

      return data.data[0]
    },
    async getCardData (cardId) {
      let data = await this.$http.get(`${this.$config.api}/interfaceeditor/cards/${cardId}`)

      return data.data
    },
    // запрос на сохранение html
    async apiSaveHTML ({ typeTemplate, id, htmlTemplate }) {
      try {
        await this.$http.put(`${this.$config.api}/interfaceeditor/${typeTemplate}/${id}/html`, { html: htmlTemplate })
      } catch (error) {
        console.warn(`Неверные данные: typeTemplate: ${typeTemplate}, id: ${id}`)
        throw new Error()
      }
    },
    async saveHTML (event) {
      const typesTemplate = {
        dashboards: 'dashboards',
        cards: 'cards'
      }
      const clickedElement = event.target
      let parentElement = clickedElement.closest('.dashboard_form')?.innerHTML
      if (!parentElement) {
        console.warn('элемент не найден')
        return
      }
      try {
        if (this.dashboardId) {
          let dashBoardId = this.dashboardId
          await this.apiSaveHTML({ typeTemplate: typesTemplate.dashboards, id: dashBoardId, htmlTemplate: parentElement })
        }
        if (this.registryCard?.cardId) {
          let cardId = this.registryCard.cardId
          await this.apiSaveHTML({ typeTemplate: typesTemplate.cards, id: cardId, htmlTemplate: parentElement })
        }
      } catch (error) {
        console.log('ошибка сохранения HTML', error)
      }
    },
    // открыть в дашборд/карточку в редакторе
    async openInEditor () {
      if (this.dashboardId) {
        let { name } = await this.getDashboard()
        this.addMainTab({ name, componentType: 'DashboardEditorViewer', payload: { dashboardId: this.dashboardId } })
      } else {
        let { name } = await this.getCardData(this.registryCard.cardId)
        if (typeof this.addMainTabInWindow === 'function') {
          this.addMainTabInWindow({
            name: name || 'Ошибка в названии',
            componentType: 'CardEditor',
            payload: {
              registry_id: this.registryCard.registryId,
              card_id: this.registryCard.cardId
            }
          })
          return
        }
        this.addMainTab({
          name: name || 'Ошибка в названии',
          componentType: 'CardEditor',
          payload: {
            registry_id: +this.registryCard.registryId,
            card_id: +this.registryCard.cardId
          }
        })
      }
    },

    getComponentsAsArray () {
      let answer = []
      for (let key in this.components) {
        if (this.components.hasOwnProperty(key)) {
          answer.push(Object.assign(this.components[key], {
            name: `${this.components[key].group}/${this.components[key].initialType}`
          }))
        }
      }

      return answer
    },

    async getStructure () {
      if (typeof this.getCard === 'function') {
        return this.getCard().structure
      } else if (typeof this.getDashboard === 'function') {
        let { structure } = await this.getDashboard()
        return JSON.parse(structure)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.dashboard_form {
  width: 100%;
  height: 100%;
}
.constructor {
  position: absolute;
  right: 10px;
  top: 4px;
  z-index: 666;
  font-size: 25px;
  background: #fff;
  color: #898383;
  border-radius: 11%;
  padding: 2px;
  cursor: pointer;
}
</style>
