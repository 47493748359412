import ConditionsMixin from './conditions_mixin'

export default {
  mixins: [ConditionsMixin],
  props: {
    required: {
      type: Boolean,
      description: 'required',
      frozen: true
    },
    isRequired: {
      type: Object,
      editor: 'Conditions',
      options: { label: 'required' },
      default: () => {
        return {
          type: 'never',
          allowOldFormat: false,
          isComplex: true,
          condition_type: 'and',
          conditions: [],
          expressions: {
            logicalOperator: 'and',
            children: []
          }
        }
      }
    },
    requiredStyle: {
      type: String,
      editor: 'RequiredStyle',
      options: { label: 'required_style' },
      default: () => {
        return 'none'
      }
    }
  },
  computed: {
    _isRequired () {
      if (this.isHidden || this.isHiddenFromBlock || this.isHiddenFromTab) {
        return false
      }
      return this.checkConditions(this.isRequired)
    },
    validationNeeded () {
      return this._isRequired && !this.isEditor() && this.requiredStyle === 'border' && this.name
    }
  }
}
