<template>
  <div>
    <span class="label">
      {{ label }}
    </span>
    <el-autocomplete
      :disabled="disabled"
      style="width: 100%"
      size="mini"
      v-model="model"
      :placeholder="placeholder"
      :fetch-suggestions="search"
      :trigger-on-focus="false"
      value-key="id"
      @focus="onFocus"
      @change="$emit('change', resultedValue); closePopup()"
      @select="$emit('change', resultedValue); closePopup()"
    >
      <template slot-scope="{ item }">
        <span style="float: left;">{{item.name}}</span>
        <span style="float: right;color: #7C838B;">{{item.description}}</span>
      </template>
    </el-autocomplete>
  </div>
</template>

<script>
export default {
  name: 'flat-field',
  inject: ['getPreviousValue'],

  props: {
    label: {
      type: String
    },
    type: {
      type: String
    },
    placeholder: {
      type: String,
      default: null
    },
    value: {
      type: String | Object
    },
    addressObjectsValue: {
      type: Object
    },
    addressType: {
      type: String,
      default: 'administrative'
    }
  },

  data () {
    return {
      model: null,
      options: [],
      isLoaded: false
    }
  },

  computed: {
    disabled () {
      return !(this.previousValue) || this.disabledByExistingAddress
    },
    previousValue () {
      return this.getPreviousValue('flat')
    },
    disabledByExistingAddress () {
      if (this.previousValue && this.previousValue.is_added_address) {
        return true
      }
      return false
    },
    resultedValue () {
      //console.log('model', ''+this.model)
      if (!this.model) {
        return null
      }
      let object = {}
      let issetHouse = this.options.find((item) => item.id === this.model)

      if (issetHouse) {
        object = issetHouse
      } else {
        object.name = this.model
        object.id = null
      }
      object.previous = this.previousValue.id

      return object
    }
  },

  watch: {
    value: {
      deep: true,
      async handler (value) {
        //console.log('flat value', value)
        if (!value) {
          this.model = null
          return
        }
        //console.log('Нашлось fl?' + this.options.findIndex((item) => item.id === value.id))
        if (value && value.id && this.options.findIndex((item) => item.id === value.id) === -1) {
          this.options.push(value)
        }

        this.model = value.name || value
      }
    }
  },

  methods: {
    async load () {
      let params = {
        objectid: undefined,
        house: undefined,
        type: this.addressType
      }
      if (this.addressObjectsValue.house && this.addressObjectsValue.house.id) {
        //дом из ГАР
        params.objectid = this.addressObjectsValue.house.id
        params.house = null
      } else {
        //дом введен вручную
        params.objectid = this.addressObjectsValue.street.id
        params.house = this.addressObjectsValue.house
      }
      const url = `${this.$config.api}/garaddressservice/addresses/rooms?` + Object.entries(params).filter(x=>x[1]).map(x=>x[0]+'='+x[1]).join('&')
      const data = await this.$http.get(url)
      this.options = data.data.map(x=>{return {...x, id: ''+x.address_id}})
      this.isLoaded = true
    },
    onFocus () {
      this.load()
    },
    async search (query, callback) {
      let results = query ? this.options.filter(item => item.name.toLowerCase().indexOf(query.toLowerCase()) === 0) : this.options
      callback(results)
      results.length > 0 && this.$emit('open-popup')
    },
    closePopup () {
      // Задержка из-за v-clickoutside в AddressObjects
      setTimeout(() => this.$emit('close-popup'), 200)
    }
  }
}
</script>

<style scoped>
.label {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  color: #7D7D7D;
  display: block;
  padding: 5px 5px 0px 5px;
}
</style>
