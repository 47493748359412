<template>
  <div style="display: inline-block">
    <span :class="{ 'property_label': label }" style="display: inline-block;">
      {{ label ? $t(`interface_editor.${label}`) : '' }}
    </span>

    <el-tooltip
      v-if="options && options.tooltip && options.tooltip.show"
      :content="$t(`interface_editor.${options.tooltip.content}`)"
      :open-delay="300"
      placement="top-start"
      effect="dark"
    >
      <div slot="content" v-html="$t(`interface_editor.${options.tooltip.content}`)"></div>
      <i class="el-icon-question"></i>
    </el-tooltip>

    <el-autocomplete
      v-if="options.autocomplete"
      v-model="localModel"
      :fetch-suggestions="querySearch"
      :placeholder="options.placeholder"
      :clearable="options.clearable"
      @clear="localModel = null; $emit('change', localModel)"
      @select="$emit('change', localModel)"
      @input="$emit('change', localModel)"
    ></el-autocomplete>

    <el-select
      v-else
      v-model="localModel"
      :multiple="options.multiple"
      :disabled="options.disabled"
      filterable
      :clearable="options.clearable"
      :placeholder="options.placeholder"
      @clear="localModel = options.multiple ? [] : null; $emit('change', localModel)"
      @change="$emit('change', localModel)"
    >
      <el-option
        v-for="(item, index) in options.options"
        :key="index"
        :label="item.name"
        :value="item.id"
        v-show="!item.hidden">
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: 'editor-select',
  props: ['value', 'options', 'label'],
  data () {
    return {
      localModel: this.value
    }
  },
  methods: {
    clearValue () {
      this.localModel = undefined
    },

    querySearch (queryString, cb) {
      let { options } = this.options
      if (!Array.isArray(options)) {
        options = []
      }

      options = JSON.parse(
        JSON.stringify(options)
      )

      options = options.map(option => ({ value: option.name }))

      cb(
        queryString
          ? options.filter((option) => {
            return (option.value.toLowerCase().indexOf(queryString.toLowerCase()) !== -1)
          })
          : options
      )
    }
  }
}
</script>

<style scoped>

</style>
