import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'

export namespace APIDataSource {

  export class ExtendedObjectQuery implements APIRequest<[]> {
    response: [];
    path: string;
    method = HTTPMethod.POST;
    hideNotification = true;
    parse = (data: AxiosResponse) => data.data
    constructor (extendedObjectId: number, public params?: {}) {
      this.path = `/datawarehouseservice/extended_object/${extendedObjectId}`
    }
  }

  export class QueryQuery implements APIRequest<[]> {
    response: [];
    path: string;
    method = HTTPMethod.POST;
    hideNotification = true;
    parse = (data: AxiosResponse) => data.data
    constructor (queryId: number, public params?: {}) {
      this.path = `/datawarehouseservice/query/${queryId}`
    }
  }
  export class UniqueXrefQuery implements APIRequest<[]> {
    response: [];
    path: string;
    method = HTTPMethod.POST;
    hideNotification = true;
    parse = (data: AxiosResponse) => data.data.data
    constructor (registryId: number, public params?: {}) {
      this.path = `/registryservice/registry/${registryId}`
    }
  }
}
