export default class CardListener {
  private static listeners: { [index: string]: { [index: string]: CallableFunction[] } } = {}

  static on (event: string, callback: CallableFunction, key: string) {
    const listeners = this.getKeyListeners(key)
    if (!listeners.hasOwnProperty(event)) {
      listeners[event] = []
    }

    listeners[event].push(callback)
  }

  static emit (event: string, key: string) {
    (this.getKeyListeners(key)[event] || []).forEach(item => {
      item()
    })
  }

  static getKeyListeners (key: string) {
    if (!this.listeners.hasOwnProperty(key)) {
      this.listeners[key] = {}
    }

    return this.listeners[key]
  }
}
