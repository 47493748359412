import { Nullable } from '@/core/domain/type/types'

export interface IMemberProperties {
  query_field: string;

  query_parameters: {
    key: string;
    type: 'constant' | 'field';
    value: string | number;
  }[];
}

export interface IApprovalStage {
  id?: Nullable<number>;
  guid?: Nullable<string>;
  name: string;
  description: string|null;
  approval_id?: number;
  close_commands: number[];
  negative_commands: number[];
  approve_commands: number[];
  approve_button_id: number|null;
  approve_text: string|null;
  cancel_commands: number[];
  cancel_button_id: number|null;
  cancel_text: string|null;
  approval_cancel_stages: number[];
  approval_blocking_stages: number[];
  approval_stage_members: number[];
  member_field_id: number|null;
  member_state_id: number|null;
  member_query_id: number|null;
  member_properties: IMemberProperties|null;
  return_stage_id: number|null;
  is_approvable_by_all: boolean;
  is_expect_approvable_by_all: boolean;
  is_matched_inclusion_states: boolean;
  forced_approval_roles: number[];
  inclusion_states: number[];
  activity_additional_states: number[];
  row_order: number;
}

export class ApprovalStageDTO implements IApprovalStage {
  id?: Nullable<number>;
  guid?: Nullable<string>;
  name: string;
  description: string|null;
  approval_id?: number;
  close_commands: number[];
  negative_commands: number[];
  approve_commands: number[];
  approve_button_id: number|null;
  approve_text: string|null;
  cancel_commands: number[];
  cancel_button_id: number|null;
  cancel_text: string|null;
  approval_cancel_stages: number[];
  approval_blocking_stages: number[];
  approval_stage_members: number[];
  member_field_id: number|null;
  member_state_id: number|null;
  member_query_id: number|null;
  member_properties: IMemberProperties;
  return_stage_id: number|null;
  is_approvable_by_all: boolean;
  is_expect_approvable_by_all: boolean;
  is_matched_inclusion_states: boolean;
  forced_approval_roles: number[];
  inclusion_states: number[];
  activity_additional_states: number[];
  row_order: number;

  constructor (data: IApprovalStage) {
    if (!data.member_properties) {
      data.member_properties = {
        query_field: '',

        query_parameters: []
      }
    }

    this.id = data.id
    this.guid = data.guid
    this.name = data.name
    this.description = data.description
    this.approval_id = data.approval_id
    this.close_commands = data.close_commands
    this.negative_commands = data.negative_commands
    this.approve_commands = data.approve_commands
    this.approve_button_id = data.approve_button_id
    this.approve_text = data.approve_text
    this.cancel_commands = data.cancel_commands
    this.cancel_button_id = data.cancel_button_id
    this.cancel_text = data.cancel_text
    this.approval_cancel_stages = data.approval_cancel_stages
    this.approval_blocking_stages = data.approval_blocking_stages
    this.approval_stage_members = data.approval_stage_members
    this.member_field_id = data.member_field_id
    this.member_state_id = data.member_state_id
    this.member_query_id = data.member_query_id
    this.member_properties = data.member_properties
    this.return_stage_id = data.return_stage_id
    this.is_approvable_by_all = data.is_approvable_by_all
    this.is_expect_approvable_by_all = data.is_expect_approvable_by_all
    this.is_matched_inclusion_states = data.is_matched_inclusion_states
    this.forced_approval_roles = data.forced_approval_roles
    this.inclusion_states = data.inclusion_states
    this.activity_additional_states = data.activity_additional_states
    this.row_order = data.row_order
  }

  static create (name: Nullable<string> = null, approvalId: Nullable<number> = null, rowOrder: number = 0) {
    return new ApprovalStageDTO({
      name: name,
      description: null,
      approval_id: approvalId,
      close_commands: [],
      negative_commands: [],
      approve_commands: [],
      approve_button_id: null,
      approve_text: null,
      cancel_commands: [],
      cancel_button_id: null,
      cancel_text: null,
      approval_cancel_stages: [],
      approval_blocking_stages: [],
      approval_stage_members: [],
      member_field_id: null,
      member_state_id: null,
      member_query_id: null,
      member_properties: {
        query_field: '',
        query_parameters: []
      },
      return_stage_id: null,
      is_approvable_by_all: false,
      is_expect_approvable_by_all: false,
      is_matched_inclusion_states: true,
      forced_approval_roles: [],
      inclusion_states: [],
      activity_additional_states: [],
      row_order: rowOrder
    })
  }

  clone () {
    return new ApprovalStageDTO(this)
  }

  duplicate (rowOrder: number = 0) {
    return new ApprovalStageDTO({
      name: this.name + ' Copy',
      description: this.description + ' COPY',
      approval_id: this.approval_id,
      close_commands: this.close_commands,
      negative_commands: this.negative_commands,
      approve_commands: this.approve_commands,
      approve_button_id: this.approve_button_id,
      approve_text: this.approve_text,
      cancel_commands: this.cancel_commands,
      cancel_button_id: this.cancel_button_id,
      cancel_text: this.cancel_text,
      approval_cancel_stages: this.approval_cancel_stages,
      approval_blocking_stages: this.approval_blocking_stages,
      approval_stage_members: this.approval_stage_members,
      member_field_id: this.member_field_id,
      member_state_id: this.member_state_id,
      member_query_id: this.member_query_id,
      member_properties: this.member_properties,
      return_stage_id: this.member_state_id,
      is_approvable_by_all: this.is_approvable_by_all,
      is_expect_approvable_by_all: this.is_expect_approvable_by_all,
      is_matched_inclusion_states: this.is_matched_inclusion_states,
      forced_approval_roles: this.forced_approval_roles,
      inclusion_states: this.inclusion_states,
      activity_additional_states: this.activity_additional_states,
      row_order: rowOrder
    })
  }
}
