var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-form-item',[_c('span',{staticClass:"property_label"},[_vm._v(_vm._s(_vm.$t('interface_editor.component_editor.table.registry')))]),_c('registry-select',{attrs:{"append-to-body":true,"value":_vm.value.registryId},on:{"change":function($event){return _vm.$set(_vm.value, 'registryId', $event)}}})],1),_c('el-form-item',[_c('el-checkbox',{attrs:{"value":_vm.value.forAdding},on:{"change":function($event){return _vm.$set(_vm.value, 'forAdding', $event)}}},[_vm._v(" "+_vm._s(_vm.$t('interface_editor.component_editor.editor_frame.for_adding'))+" ")])],1),_c('el-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.value.forAdding),expression:"value.forAdding"}]},[_c('el-checkbox',{attrs:{"value":_vm.value.reOpenAfterAdd},on:{"change":function($event){return _vm.$set(_vm.value, 'reOpenAfterAdd', $event)}}},[_vm._v(" "+_vm._s(_vm.$t('interface_editor.component_editor.editor_frame.reopen_after_add'))+" ")])],1),_c('el-form-item',{directives:[{name:"show",rawName:"v-show",value:(!_vm.value.forAdding),expression:"!value.forAdding"}]},[_c('span',{staticClass:"property_label"},[_vm._v(_vm._s(_vm.$t('interface_editor.component_editor.editor_calendar.field_id')))]),_c('editor-select',{ref:"record_id",attrs:{"value":_vm.value.recordId,"options":{
              multiple: false,
              clearable: true,
              options: _vm.components
            }},on:{"change":function($event){return _vm.$set(_vm.value,'recordId', $event)}}})],1),_c('el-form-item',[_c('span',{staticClass:"property_label"},[_vm._v(_vm._s(_vm.$t('interface_editor.component_editor.editor_calendar.card_id')))]),_c('editor-select',{ref:"card_id",attrs:{"value":_vm.value.cardId,"options":{
              multiple: false,
              clearable: true,
              options: _vm.cards
            }},on:{"change":function($event){return _vm.$set(_vm.value,'cardId', $event)}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }