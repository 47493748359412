<template>
  <el-dialog
    title="Доступные слои"
    :append-to-body="true"
    :visible.sync="dialogVisible">
    <div class="main">
      <Split style="height: 500px">
        <split-area class="split-area" :size="50">
          <div
            v-for="layer in layers"
            :class="['layer', (activeLayer && (activeLayer.title === layer.title)) ? 'active' : null]"
            @click="activeLayer = layer"
          >{{layer.title}}</div>
        </split-area>
        <split-area class="split-area" :size="50">
          <div v-if="activeLayer">
            <div
              v-for="item in Object.entries(activeLayer)"
              class="active-layer-item"
            >
              <el-form-item :label="item[0]">
                {{item[1]}}
              </el-form-item>
            </div>
          </div>
        </split-area>
      </Split>
    </div>
    <div class="footer">
      <el-button @click="hide()">отмена</el-button>
      <el-button @click="selectLayer">выбрать</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import ALabel from '../../../basic/a-label'
  export default {
    name: 'OGCFeaturesDialog',
    components: { ALabel },
    props: {
      layers: {
        type: Array,
        default: () => {
          return []
        }
      },
      selectedLayer: {
        type: Object | null,
        default: () => {
          return null
        }
      }
    },
    data: function () {
      return {
        dialogVisible: false,
        activeLayer: this.selectedLayer
      }
    },
    methods: {
      show () {
        this.dialogVisible = true
      },
      hide () {
        this.dialogVisible = false
      },
      selectLayer () {
        this.$emit('select-layer', this.activeLayer)
        this.hide()
      }
    }
  }
</script>

<style scoped>
  .main {
    height: calc(100% - 50px);
  }
  .footer {
    height: 50px;
  }
  .active-layer-item {
    margin: 5px;
  }
  .split-area {

  }
  .layer {
    padding: 3px;
  }
  .layer.active {
    background-color: #a7d4f5;
  }
  .layer:hover {
    background-color: #EEF0F1;
  }
  .split-area /deep/ .el-form-item__label {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    color: #7D7D7D;
    display: block;
    padding: 5px;
  }
</style>
