import { ProcessCellForExportParams } from 'ag-grid-enterprise'
import { Nullable } from '@/core/domain/type/types'

export default class ExportValueFormatter {
  static getProcessCellCallback () {
    const formatter = new ExportValueFormatter()

    return formatter
      .processCellCallback
      .bind(formatter)
  }

  private processCellCallback (params: ProcessCellForExportParams) {
    const fieldType = params.column.getColDef().cellRenderer

    if (fieldType === 'xrefField' || fieldType === 'xrefMultiField') {
      return this.xrefField(params)
    } else if (fieldType === 'datetimeField') {
      return this.dateTimeField(params)
    } else if (fieldType === 'dateField') {
      return this.dateField(params)
    } else if (fieldType === 'fileField') {
      return this.fileField(params)
    } else if (fieldType === 'garAddressField') {
      return this.garAddressField(params)
    } else if (fieldType === 'garAddressMultiField') {
      return this.garAddressMultiField(params)
    } else if (fieldType === 'agGroupCellRenderer' && params.node && params.node.rowGroupColumn) {
      const subFieldType = params.node.rowGroupColumn.getColDef().cellRenderer
      const groupParams = { value: params.node.data[params.node.field] || null }

      if (subFieldType === 'xrefField' || subFieldType === 'xrefMultiField') {
        return ` -> ${this.xrefField(groupParams)}`
      } else if (subFieldType === 'datetimeField') {
        return ` -> ${this.dateTimeField(groupParams)}`
      } else if (subFieldType === 'dateField') {
        return ` -> ${this.dateField(groupParams)}`
      } else if (subFieldType === 'fileField') {
        return ` -> ${this.fileField(groupParams)}`
      } else if (fieldType === 'garAddressField') {
        return ` -> ${this.garAddressField(params)}`
      } else if (fieldType === 'garAddressMultiField') {
        return ` -> ${this.garAddressMultiField(params)}`
      }

      return params.value
    }

    return params.value
  }

  private xrefField ({ value }): Nullable<string> {
    const localValue = typeof value === 'string'
      ? JSON.parse(value)
      : value

    if (localValue && Array.isArray(localValue)) {
      return localValue.map((item) => item.name).join(', ')
    }

    return null
  }

  private dateTimeField ({ value }): Nullable<string> {
    if (value && value.length > 0) {
      const localValue = new Date(value).toLocaleString()
      if (localValue !== 'Invalid Date') {
        return localValue
      }
    }

    return value
  }

  private dateField ({ value }): Nullable<string> {
    if (!value) {
      return null
    }

    return new Intl
      .DateTimeFormat('ru')
      .format(
        new Date(value)
      )
  }

  private fileField ({ value }): Nullable<string> {
    const files = JSON.parse(value || '[]')
    if (files instanceof Array && files.length > 0) {
      return files
        .map(file => `${file.name}.${file.extension}`)
        .join(', ')
    }

    return null
  }

  private garAddressField ({ value }): Nullable<string> {
    if (!value) {
      return null
    }

    try {
      value = JSON.parse(value)
    } catch (error) {}

    if (value && Array.isArray(value)) {
      return value.map((item) => item.address).join(', ')
    }

    return value
  }

  private garAddressMultiField ({ value }): Nullable<string> {
    return this.garAddressField({ value })
  }
}
