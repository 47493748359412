<template>
  <div class="editor-auto-refresh">
    <el-col :span="24">
      <el-checkbox v-model="autoRefresh.isAllow" size="mini">
        {{$t('interface_editor.auto_refresh')}}
      </el-checkbox>
      <el-tooltip placement="top-start" :open-delay="400" style="margin-left: 5px">
          <div slot="content">
            {{ $t('interface_editor.component_editor.editor_auto_refresh.auto_refresh') }}
          </div>
          <i class="el-icon-question"></i>
      </el-tooltip>
    </el-col>

    <div :span="24" v-show="isShowSettings">
      <div style="display: inline-block;">{{ $t('interface_editor.component_editor.editor_auto_refresh.period') }}</div>
      <el-tooltip placement="top-start" :open-delay="400" style="margin-left: 5px">
          <div slot="content">
            {{ $t('interface_editor.component_editor.editor_auto_refresh.period_tooltip') }}
          </div>
          <i class="el-icon-question"></i>
      </el-tooltip>
      <el-input
        type="number"
        v-model="autoRefresh.seconds"
        :min="5"
        style="width: auto"
      ></el-input>

      <div style="display: inline-block;">{{ $t('interface_editor.component_editor.editor_auto_refresh.css_row') }}</div>
      <el-tooltip placement="top-start" :open-delay="400" style="margin-left: 5px">
          <div slot="content">
            {{ $t('interface_editor.component_editor.editor_auto_refresh.css_row_tooltip') }}
          </div>
          <i class="el-icon-question"></i>
      </el-tooltip>
      <el-input
        type="textarea"
        v-model="autoRefresh.css"
        style="width: auto"
      ></el-input>
    </div>
  </div>
</template>

<script>
export default {
  name: 'editor-auto-refresh',
  components: {},
  props: {
    value: {
      type: [Object, Array],
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      autoRefresh: {
        isAllow: false
      }
    }
  },
  computed: {
    isShowSettings () {
      return !!this.autoRefresh.isAllow
    }
  },
  watch: {
    autoRefresh: {
      handler (value) {
        this.$emit('change', value)
      },
      deep: true
    }
  },

  methods: {},

  mounted () {
    if (Object.keys(this.value).length) {
      this.autoRefresh = this.value
    }
  }
}
</script>

<style scoped>
.editor-auto-refresh {
  border-left: 2px solid skyblue;
  padding-left: 10px;
}
</style>
