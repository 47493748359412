<template>
  <el-form label-width="120px" label-position="top" size="mini">
    <!--Источник -->
    <el-form-item>
      <span class="property_label" style="display: inline-block">{{
        $t("interface_editor.component_editor.table.source_type")
      }}</span>
        <!-- JSON format: [{id: null, guid: null, name: null, directory: null, extension: null}] -->
      <el-tooltip :open-delay="300" placement="top-start" effect="dark">
        <div slot="content">{{ $t('interface_editor.component_editor.editor_video.format') }}<br>
        {{ $t('interface_editor.component_editor.editor_video.source') }}<br>
        </div>
        <i class="el-icon-question"></i>
      </el-tooltip>
      <el-select
        :value="localValue.sourceVideo.type"
        @change="$set(localValue.sourceVideo, 'type', $event), clearEntity($event)"
      >
        <el-option
          v-for="(item, index) in source"
          :key="index"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </el-form-item>

    <!-- registry -->
    <template v-if="localValue.sourceVideo.type === 'registry'">
      <el-form-item>
        <registry-select
          :label="'registry_id'"
          :value="value.sourceVideo.registryId"
          @change="
            $set(localValue.sourceVideo, 'registryId', $event),
              $set(localValue.sourceVideo, 'fieldId', null),
            changeRegistryId($event)
          "
        ></registry-select>
        <div v-show="localValue.sourceVideo.registryId">
          <span class="property_label">{{ $t('interface_editor.component_editor.editor_video.source_video') }}</span>
          <selectTree
            class="extraLine_select"
            :clearable="false"
            :standartTree="false"
            :dataTable="fieldsOfSource"
            v-model="localValue.sourceVideo.fieldId"
          />
          <span class="property_label">{{ $t('interface_editor.component_editor.editor_video.poster') }}</span>
          <selectTree
            class="extraLine_select"
            :clearable="false"
            :standartTree="false"
            :dataTable="fieldsOfSource"
            v-model="localValue.sourceVideo.fieldIdPoster"
          />
        </div>
      </el-form-item>
    </template>

    <!-- extend_object -->
    <template v-if="localValue.sourceVideo.type === 'extended_object'">
      <el-form-item>
        <span class="property_label">{{
          $t("interface_editor.component_editor.table.extended_object")
        }}</span>
         <el-select
          :value="localValue.sourceVideo.extendedObjectId"
          @change="
            $set(localValue.sourceVideo, 'extendedObjectId', $event),
              $set(localValue.sourceVideo, 'fieldId', null),
            changeExtendedId($event)
          "
          filterable
        >
          <el-option
            v-for="(item, index) in dataOfSource"
            :key="index"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
        <div v-show="localValue.sourceVideo.extendedObjectId">
          <span class="property_label">{{
            $t("interface_editor.component_editor.table.extended_object_fields")
          }}</span>
          <el-select
           v-model="localValue.sourceVideo.fieldId"
          filterable
        >
          <el-option
            v-for="(item, index) in fieldsOfSource"
            :key="index"
            :label="item"
            :value="item">
          </el-option>
        </el-select>
        <span class="property_label">{{ $t('interface_editor.component_editor.editor_video.poster') }}</span>
          <el-select
           v-model="localValue.sourceVideo.fieldIdPoster"
          filterable
        >
          <el-option
            v-for="(item, index) in fieldsOfSource"
            :key="index"
            :label="item"
            :value="item">
          </el-option>
        </el-select>
        </div>
      </el-form-item>
    </template>

    <!-- query -->
    <template v-if="localValue.sourceVideo.type === 'query'">
      <el-form-item>
        <span class="property_label">{{$locale.interface_editor.component_editor.table.request}}</span>
        <editor-select
          style="width: 100%"
          :value="localValue.sourceVideo.queryId"
          @change="$set(localValue.sourceVideo, 'queryId', $event)"
          :options="{
            multiple: false,
            options: dataOfSource,
          }"
        ></editor-select>
      </el-form-item>
      <el-form-item v-show="localValue.sourceVideo.queryId">
        <span class="property_label">{{ $t('interface_editor.component_editor.editor_video.alias') }}</span>
        <el-input
          type="text"
          size="small"
          v-model="localValue.sourceVideo.fieldId"
        ></el-input>
      </el-form-item>
      <el-form-item v-show="localValue.sourceVideo.queryId">
        <span class="property_label">{{ $t('interface_editor.component_editor.editor_video.poster') }}</span>
        <el-input
          type="text"
          size="small"
          v-model="localValue.sourceVideo.fieldIdPoster"
        ></el-input>
      </el-form-item>
    </template>

    <!-- constant -->
    <template v-if="localValue.sourceVideo.type === 'constant'">
      <el-form-item>
        <span class="property_label" style="display: inline-block;"  v-loading="loading">{{ $t('interface_editor.component_editor.editor_video.link_video') }}</span>
        <el-button size="mini" style="display: inline-block; margin-bottom: 10px;" @click="addVideo">{{ $t('main.button.add') }}</el-button>
        <template v-for="constant of localValue.sourceVideo.constants">
          <div :key="constant.id" style="border-left: 2px solid skyblue; padding-left: 5px; margin-bottom: 15px">
            <span class="property_label" style="display: inline-block;">{{ $t('interface_editor.component_editor.editor_video.down_video') }}</span>
            <el-input placeholder="/files/guid.extension" v-model="constant.constantVideo" >
              <template slot="append">
                <el-upload
                  :headers="getHeaders()"
                  class="upload-demo"
                  :ref="'uploadVideo' + constant.id"
                  :action="$config.api + '/registryservice/files/'"
                  :on-change="(file, fileList) => handleUploadChange(file, fileList, { isVideo: true, id: constant.id  })"
                  :http-request="() => handleSaveUpload({ isVideo: true, id: constant.id  })"
                  :file-list="fileList"
                  :show-file-list="false">
                    <el-button size="small"  icon="el-icon-download" type="primary" title="Загрузить видео"></el-button>
                </el-upload>
              </template>
            </el-input>
            <span class="property_label" style="display: inline-block;">{{ $t('interface_editor.component_editor.editor_video.down_poster') }}</span>
            <el-input placeholder="/files/guid.extension" v-model="constant.constantPoster" >
              <template slot="append">
                <el-upload
                  :headers="getHeaders()"
                  class="upload-demo"
                  :ref="'uploadPoster' + constant.id"
                  :action="$config.api + '/registryservice/files/'"
                  :on-change="(file, fileList) => handleUploadChange(file, fileList, { isVideo: false, id: constant.id  })"
                  :http-request="() => handleSaveUpload({ isVideo: false, id: constant.id  })"
                  :file-list="fileList"
                  :show-file-list="false">
                    <el-button size="small"  icon="el-icon-download" type="primary" title="Загрузить постер"></el-button>
                </el-upload>
              </template>
            </el-input>
            <div style="text-align: right">
              <el-button size="mini" style="display: inline-block; margin: 10px;" type="danger" @click="deleteVideo(constant.id)">Удалить</el-button>
            </div>
          </div>
        </template>

      </el-form-item>
    </template>

    <!-- field -->
    <template v-if="localValue.sourceVideo.type === 'field'">
      <el-form-item>
        <span class="property_label">{{ $t('interface_editor.component_editor.editor_video.string_cmp') }}</span>
        <el-select
          v-model="localValue.sourceVideo.attribute"
          filterable
          size="mini"
        >
          <el-option
            v-for="(item, index) in components.filter((cmp) => cmp.properties.name)"
            :key="index"
            :label="`${item.name} (${item.properties.name})`"
            :value="item.properties.name"
          ></el-option>
        </el-select>
      </el-form-item>
    </template>

    <el-form-item>
      <el-checkbox v-model="localValue.autoplay"></el-checkbox>
      <span class="property_label" style="display: inline-block">{{ $t('interface_editor.component_editor.editor_video.autoplay') }}</span>
      <!-- <el-tooltip  placement="top-start" :open-delay="300" effect="dark" :content="$t(`interface_editor.range_range_tooltip`)">
        <i class="el-icon-question"></i>
      </el-tooltip> -->
    </el-form-item>

    <el-form-item>
      <el-checkbox v-model="localValue.controls"></el-checkbox>
      <span class="property_label" style="display: inline-block">{{ $t('interface_editor.component_editor.editor_video.controls') }}</span>
      <!-- <el-tooltip  placement="top-start" :open-delay="300" effect="dark" :content="$t(`interface_editor.range_range_tooltip`)">
        <i class="el-icon-question"></i>
      </el-tooltip> -->
    </el-form-item>
  </el-form>
</template>
<script>
import RegistrySelect from '@/components/Common/RegistrySelectTreeWithSearch.vue'
import SelectTree from '@/core/infrastructure/components/TreeSelect.vue'
import EditorSelect from './editor-select'
// API
import Registry from '@/components/Models/EntityModel'
import Query from '@/components/BIEditor/Models/Query'
import ExtendedObject from '@/components/BIEditor/Models/ExtendedObject'

export default {
  name: 'editor-video',
  inject: ['getComponents'],
  props: {
    value: {
      type: Object,
      default: () => {
        return {}
      }
    },
    otherProperties: {
      type: Object
    },
    filters: {
      type: Array,
      editor: 'Filters'
    }
  },
  components: {
    RegistrySelect,
    EditorSelect,
    SelectTree
  },
  data () {
    return {
      dataOfSource: [],
      fieldsOfSource: [],
      fileList: [],
      source: [
        { id: 'registry', name: 'Реестр' },
        { id: 'extended_object', name: 'Расширенная таблица' },
        { id: 'query', name: 'Запрос' },
        { id: 'constant', name: 'Константа' },
        { id: 'field', name: 'Поле карточки' }
      ],
      components: this.getComponents(),
      loading: false,
      localValue: {
        autoplay: false,
        controls: true,
        sourceVideo: {
          type: null,
          registryId: null,
          extendedObjectId: null,
          fieldId: null,
          fieldIdPoster: null,
          constants: [{
            constantPoster: null,
            constantVideo: null,
            id: 1
          }
          ],
          attribute: null
        }
      }
    }
  },
  computed: {},
  methods: {
    deleteVideo (id) {
      this.localValue.sourceVideo.constants = this.localValue.sourceVideo.constants.filter(item => item.id !== id)
    },
    addVideo () {
      let id = 1
      if (!this.localValue.sourceVideo?.constants) {
        this.$set(this.localValue.sourceVideo, 'constants', [])
        console.log('net')
      }
      id = this.localValue.sourceVideo.constants.length + 1
      this.localValue.sourceVideo.constants.push({
        constantPoster: null,
        constantVideo: null,
        id: id
      })
    },
    async clearEntity (event) {
      console.log('clearEntity', event)
      this.localValue.sourceVideo = { type: event }
      this.fieldsOfSource = []
      this.dataOfSource = []
      this.dataOfSource = await this.getDataSource(event)
    },
    async changeRegistryId (registryId = null) {
      this.fieldsOfSource = await this.getRegistryFields(registryId)
    },
    async changeExtendedId (extendedId = null) {
      this.fieldsOfSource = await this.getExtendedFields(extendedId)
    },
    async getExtendedFields (id = null) {
      if (!id) return []
      try {
        let response = await this.$http.post(`${this.$config.api}/datawarehouseservice/extended_object/${id}`, { limit: 1 }, {
          hideNotification: true
        })
        return Object.keys(response.data[0])
      } catch (error) {
        console.log(error)
        return []
      }
    },
    async getRegistryFields (id = null) {
      if (!id) return []
      try {
        const res = await Registry.params({
          parent_id: id,
          show_children: true
        }).get()
        return res.data
      } catch (error) {
        console.log(error)
        return []
      }
    },
    async getDataSource (typeSource = null) {
      let response = []
      if (!typeSource) {
        return response
      }
      try {
        if (typeSource === 'extended_object') {
          response = await new ExtendedObject().$get()
          return response
        }
        if (typeSource === 'query') {
          response = await new Query().$get()
          return response
        }
        return response
      } catch (error) {
        console.log(error)
        return response
      }
    },
    // загрузка файла
    getHeaders () {
      return {
        Authorization: localStorage.getItem('user-token')
      }
    },
    handleUploadChange (file, fileList, options) {
      this.beforeUpload(file, fileList, options)
    },
    beforeUpload (file, fileList, { isVideo, id }) {
      if (isVideo) {
        if (!RegExp(/video/i).test(file.raw.type)) {
          console.log(fileList)
          this.$refs['uploadVideo' + id].uploadFiles = []
          this.$message.error('only video format MP4, WebM и Ogg')
          return false
        }
      }
      if (!isVideo) {
        if (!RegExp(/image/i).test(file.raw.type)) {
          console.log(fileList)
          this.$refs['uploadPoster' + id].uploadFiles = []
          this.$message.error('only image formay png, jpg, jpeg')
          return false
        }
      }
    },
    handleSaveUpload (options) {
      this.saveUpload(options)
    },
    async prepareFilePath (fileObj) {
      const fd = new FormData()
      fd.append('file', fileObj)
      let res = await this.$http.post(this.$config.api + '/registryservice/files', fd)
      let res1 = await this.$http.get(`${this.$config.api}/registryservice/files/${res.data.id}`)
      return res1
    },
    async saveUpload ({ isVideo, id }) {
      let index = this.localValue.sourceVideo.constants.findIndex(item => item.id === id)
      let video = isVideo
      let poster = !isVideo
      console.log(this.$refs)
      console.log(index)

      if (video && this.$refs['uploadVideo' + id][0].uploadFiles?.length) {
        this.loading = true
        let fileObj = this.$refs['uploadVideo' + id][0].uploadFiles[0].raw
        let res1 = await this.prepareFilePath(fileObj)
        this.localValue.sourceVideo.constants[index].constantVideo = `/files/${this.getFilePath(res1.data)}`
        this.loading = false
      }
      if (poster && this.$refs['uploadPoster' + id][0].uploadFiles?.length) {
        this.loading = true
        let fileObj = this.$refs['uploadPoster' + id][0].uploadFiles[0].raw
        let res1 = await this.prepareFilePath(fileObj)
        this.localValue.sourceVideo.constants[index].constantPoster = `/files/${this.getFilePath(res1.data)}`
        this.loading = false
      }
    }
  },
  watch: {
    localValue: {
      handler (value) {
        console.log(value)
        this.$emit('change', value)
      },
      deep: true
    }
  },
  async created () {
    if (Object.keys(this.value).length > 0) {
      this.localValue = Object.assign(this.localValue, this.value)
    }
    if (this.localValue.sourceVideo.type === 'registry') {
      this.fieldsOfSource = await this.getRegistryFields(this.localValue.sourceVideo.registryId)
      return
    }
    if (['extended_object', 'query'].includes(this.localValue.sourceVideo.type)) {
      this.dataOfSource = await this.getDataSource(this.localValue.sourceVideo.type)
    }

    if (this.localValue.sourceVideo.extendedObjectId) {
      this.changeExtendedId(this.localValue.sourceVideo.extendedObjectId)
    }
    console.log('created localValue', this.localValue)
    console.log('created value', this.value)
  },
  mounted () {}
}
</script>

<style scoped>
.format_tooltip {
  margin-bottom: 5px;
  padding: 5px;
  background-color: rgb(245 245 245);
  border-left: 2px solid rgb(210 212 94);
}
</style>
