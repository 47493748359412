import RegistryCard from '@/components/RegistryCard'
import ModelMixin from '@/components/InterfaceEditor/components/ModelMixin'
export default {
  mixins: [ModelMixin],
  inject: {
    openRegistryCard: {
      default: () => {}
    },
    openDashboardCard: {
      default: () => {}
    },
    getDashboardComponents: {
      default: () => {}
    },
    addMainTab: {
      default: () => {}
    }
  },
  components: {
    RegistryCard
  },
  methods: {
    openDashboard (params, data) {
      if (!params.id) {
        return
      }
      const defaults = {};
      (params.defaults || []).forEach((item) => {
        if (item.name && item.value) {
          defaults[item.value] = data[item.name]
        }
      })
      let model = Object.assign(JSON.parse(JSON.stringify(this.getModel())), defaults)

      if (params.isFullscreen) {
        this.openDashboardCard(params.id, params.window_title, null, model)
      } else {
        if (params.frameGuid) {
          let frame = (this.getDashboardComponents()[`component_${params.frameGuid}`] || [])[0]
          if (!frame) {
            console.warn('frame not found', params)
            return false
          }
          frame.openDashboard({
            dashboardId: params.id,
            title: params.window_title,
            breadcrumb: params.breadcrumbByButton
          })
          return
        }
        const h = this.$createElement
        let customClass = 'custom_scrollbar '
        if (params.window_width) {
          customClass += `dashboard_window_width_${params.window_width}`
        }
        this.$msgbox({
          title: params.window_title,
          customClass: customClass,
          message: h('dashboard', {
            props: {
              id: params.id,
              parentContext: this.context,
              model: model,
              msgbox: 'msgbox'
            },
            key: this.generateGuid()
          }),
          showCancelButton: false,
          showConfirmButton: false,
          closeOnClickModal: false
        })
      }
    },
    async getCardId (registryId, recordId = null) {
      let url = `${this.$config.api}/registryservice/registry/${registryId}/card`
      if (recordId) {
        url = `${this.$config.api}/registryservice/registry/${registryId}/records/${recordId}/card`
      }
      let data = await this.$http.get(url)

      return data.data[0]
    },
    openQueryCardInWindow (cardData, openCard, recordId, registryId) {
      const h = this.$createElement
      let customClass = 'custom_scrollbar '
      if (cardData.windowWidth) {
        customClass += `dashboard_window_width_${cardData.windowWidth}`
      }
      let me = this
      console.log(cardData, openCard, recordId, registryId)
      this.$msgbox({
        title: cardData.windowTitle,
        customClass: customClass,
        message: h('registry-card', { style: {
          height: cardData.windowHeight || ''
        },
        props: {
          registryId: registryId,
          cardId: openCard.id || openCard,
          cardName: openCard.name || '',
          recordId: recordId
        },
        on: {
          cancelChanges: function () {
            me.$msgbox.close()
          }
        },
        key: this.generateGuid() }),
        showCancelButton: false,
        showConfirmButton: false,
        closeOnClickModal: false
      })
    },
    async queryOpenCard (cardData, record) {
      let card, registryId, recordId
      registryId = record[cardData.queryRegistry]
      recordId = record[cardData.queryRecord]
      card = record[cardData.queryCard] || null
      if (!registryId || !recordId) {
        console.warn(`registryId: ${registryId}, recordId: ${recordId}`)
        return
      }
      if (!card) {
        card = await this.getCardId(registryId, recordId)
      }
      if (cardData.isWindow) {
        this.openQueryCardInWindow(cardData, card, recordId, registryId)
      } else {
        this.openRegistryCard({
          registryId: registryId,
          cardId: card.id || card,
          cardName: card.name || '',
          recordId: recordId
        })
      }
    },
    async openCard (cardData, recordData = null) {
      if (cardData.queryRegistry) {
        this.queryOpenCard(cardData, recordData)
        return
      }
      if (!cardData.registryId || !cardData.type) {
        console.warn(`wrong parameters: registryId ${cardData.registryId}, type: ${cardData.type}`)
        return false
      }
      const readOnly = cardData.type === 'read'
      let recordId = null
      let card = {}
      let initialData = {}
      cardData.cardId
        ? card = { id: cardData.cardId }
        : card = await this.getCardId(cardData.registryId, recordId)

      let defaults = this.getDefaultsForCard(cardData)
      defaults.forEach((item) => {
        initialData[item.key] = item.value
      })
      if (cardData.type === 'update' || cardData.type === 'read') {
        console.log(`type: ${cardData.type}`)
        if (cardData.constantRecordId) {
          recordId = cardData.constantRecordId
        } else if (cardData.fieldId) {
          if (recordData) {
            recordId = recordData[cardData.fieldId] || recordData[`attr_${cardData.fieldId}_`]
          } else {
            recordId = this.getModel()[cardData.fieldId]
          }
        }
        if (!recordId) {
          console.warn(`recordId doesn't set = ${cardData.fieldId}, constant = ${cardData.constantRecordId}`)
          return false
        }
        if (!cardData.cardId) {
          card = await this.getCardId(cardData.registryId, recordId)
          console.log(`cardId doesn't set. card by states`, card)
        }
        if (cardData.frameGuid) {
          let frame = (this.getDashboardComponents()[`component_${cardData.frameGuid}`] || [])[0]
          if (!frame) {
            console.warn('frame not found', cardData)
            return false
          }

          frame.openCard({
            cardId: cardData.cardId,
            registryId: cardData.registryId,
            recordId: recordId,
            readOnly: readOnly
          })
        } else if (cardData.isWindow) {
          this.openRegistryCardInWindow(cardData, card, readOnly, recordId, initialData)
        } else {
          console.log('openRegistryCard', card)
          this.openRegistryCard({
            registryId: cardData.registryId,
            cardId: card.id,
            cardName: '',
            recordId: recordId,
            initialData: initialData,
            registry: {
              readonly: readOnly,
              addRecord: () => {},
              updateRecord: async () => {
                if (cardData.updateListener) {
                  await cardData.updateListener()
                }
              }
            }
          })
        }
      }
    },
    openRegistryCardInWindow (cardData, cardid, readOnly, recordId, initialData) {
      const h = this.$createElement
      let customClass = 'custom_scrollbar '
      if (cardData.windowWidth) {
        customClass += `dashboard_window_width_${cardData.windowWidth}`
      }
      let me = this
      this.$msgbox({
        title: cardData.windowTitle,
        customClass: customClass,
        message: h('registry-card', { style: {
          height: cardData.windowHeight || ''
        },
        props: {
          cardId: cardid.id,
          registryId: cardData.registryId,
          readonly: readOnly,
          recordId: recordId,
          initialData: initialData,
          addMainTabInWindow: this.addMainTab
        },
        on: {
          cancelChanges: function () {
            me.$msgbox.close()
          }
        },
        key: this.generateGuid() }),
        showCancelButton: false,
        showConfirmButton: false,
        closeOnClickModal: false
      })
    },
    getDefaultsForCard (cardData) {
      let defaults = []
      if (cardData.defaults) {
        cardData.defaults.forEach((item) => {
          if (!item.type || item.type === 'field') {
            if (this.getModel()[item.attribute] && item.alias) {
              defaults.push({
                key: item.alias,
                value: this.getModel()[item.attribute]
              })
            }
          } else if (item.type === 'constant' && item.alias) {
            defaults.push({
              key: item.alias,
              value: item.attribute
            })
          } else if (item.type === 'current_user') {
            defaults.push({
              key: item.alias,
              value: this.$store.getters['Authorization/userId']
            })
          }
        })
      }

      return defaults
    }
  }
}
