<template>
  <div class="editor_conditions">
    <el-row>
      <span v-if="options.label" class="property_label">{{ $t(`interface_editor.${options.label}`) }}: </span>
      <el-radio-group style="float: right" v-model="localModel.type" size="mini">
        <el-radio-button label="never">{{$locale.interface_editor.component_editor.conditions.never}}</el-radio-button>
        <el-radio-button label="always">{{$locale.interface_editor.component_editor.conditions.always}}</el-radio-button>
        <el-radio-button label="condition">{{$locale.interface_editor.component_editor.conditions.condition}}</el-radio-button>
      </el-radio-group>
    </el-row>
    <el-row v-if="localModel.type === 'condition'" style="margin-top: 5px">
      <el-row>
        <el-button size="mini" @click="addCondition">{{$locale.interface_editor.component_editor.conditions.add_condition}}</el-button>
        <el-radio-group style="float: right" v-model="localModel.condition_type" size="mini">
          <el-radio-button label="and">{{$locale.interface_editor.component_editor.conditions.and}}</el-radio-button>
          <el-radio-button label="or">{{$locale.interface_editor.component_editor.conditions.or}}</el-radio-button>
        </el-radio-group>
      </el-row>
      <el-row v-for="(condition, index) in localModel.conditions" :key="index" class="wrapper_container">
        <!-- <el-col :span="8"> -->
          <el-select
            v-if="!options.sourceRegistry"
            v-model="condition.attribute"
            size="mini"
            filterable
          >
            <el-option
              v-for="(item, index) in components.filter((cmp) => cmp.properties.name)"
              :key="index"
              :label="(item.name.match(/attr_(.*?)_/g) || []).length ? `${item.name}` : `${item.name} ${item.properties.name}`"
              :value="item.properties.name">
            </el-option>
          </el-select>
          <selectTree
            v-if="options.sourceRegistry"
            ref="entity_tree"
            v-model="condition.attribute"
            :dataTable="options.fieldsRegistry"
            class="custom_scrollbar"
            :clearable="false"
            :standartTree="false"
            :appendToBody='false'
          />
        <!-- </el-col> -->
        <!-- <el-col :span="6"> -->
          <el-select
            v-model="condition.type"
            size="mini"
          >
            <el-option
              v-for="(item, index) in condition_types"
              :key="index"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        <!-- </el-col> -->
        <!-- <el-col :span="8"> -->
          <el-tooltip class="item" content="user_id и role_id нужно писать без скобок" placement="bottom">
            <el-input size="mini" v-show="['equals','not_equals', 'contains','not_contains', 'contains_in_array', 'not_contains_in_array'].includes(condition.type)" v-model="condition.value"></el-input>
          </el-tooltip>
        <!-- </el-col> -->
        <!-- <el-col :span="2" style="float: right;"> -->
          <el-button
            style="float: right;"
            @click="deleteCondition(index)"
            size="mini"
            type="danger"
          ><i class="el-icon-delete"></i></el-button>
        <!-- </el-col> -->
      </el-row>
    </el-row>
  </div>
</template>

<script>
import SelectTree from '@/core/infrastructure/components/TreeSelect'
export default {
  name: 'editor-conditions',
  components: {
    SelectTree
  },
  inject: ['getComponents'],
  props: {
    value: {
      type: Object
    },
    valueWatcher: {
      type: Boolean,
      default: true
    },
    options: {
      type: Object,
      default () {
        return {
          label: '',
          sourceRegistry: false,
          fieldsRegistry: []
        }
      }
    }
  },
  data () {
    return {
      localModel: this.value || {
        type: 'never'
      },
      components: this.getComponents(),
      condition_types: [
        {
          name: this.$locale.interface_editor.component_editor.conditions.equals,
          value: 'equals'
        },
        {
          name: this.$locale.interface_editor.component_editor.conditions.not_equals,
          value: 'not_equals'
        },
        {
          name: this.$locale.interface_editor.component_editor.conditions.is_null,
          value: 'is_null'
        },
        {
          name: this.$locale.interface_editor.component_editor.conditions.is_not_null,
          value: 'is_not_null'
        },
        {
          name: this.$locale.interface_editor.component_editor.conditions.contains,
          value: 'contains'
        },
        {
          name: this.$locale.interface_editor.component_editor.conditions.not_contains,
          value: 'not_contains'
        },
        {
          name: this.$locale.interface_editor.component_editor.conditions.contains_in_array,
          value: 'contains_in_array'
        },
        {
          name: this.$locale.interface_editor.component_editor.conditions.not_contains_in_array,
          value: 'not_contains_in_array'
        }
      ]
    }
  },
  watch: {
    value: {
      handler (value) {
        if (this.valueWatcher) {
          this.localModel = value
        }
      },
      deep: true
    },
    localModel: {
      handler (value) {
        this.$emit('change', value)
      },
      deep: true,
      immediate: true
    }
  },
  created () {
    this.restoreDataStructure()
  },
  methods: {
    restoreDataStructure () {
      if (!('conditions' in this.localModel) || !this.localModel.conditions) {
        this.$set(this.localModel, 'conditions', [])
      }

      if (!('condition_type' in this.localModel)) {
        this.$set(this.localModel, 'condition_type', 'and')
      }
    },
    deleteCondition (index) {
      this.localModel.conditions.splice(index, 1)
    },
    addCondition () {
      if (!Array.isArray(this.localModel.conditions)) {
        this.$set(this.localModel, conditions, [])
      }

      this.localModel.conditions.push({
        attribute: null,
        type: null,
        value: null
      })
    }
  }
}
</script>

<style scoped>
  .editor_conditions .wrapper_container{
    margin-bottom: 5px;
    padding: 5px;
    background-color: rgb(245 245 245);
    border-left: 2px solid rgb(210 212 94);
  }
</style>
