<template>
  <div style="width: 100%; height: 100%; padding: 10px;">
      <template v-if="sourceType!=='Registry'">
        <el-form-item :label="this.$locale.interface_editor.component_editor.map_card_interactive.display_name" class="label">
          <el-input v-model="value.standardCard.name"/>
        </el-form-item>
        <el-form-item label="Настройка свойств">
          <el-select v-model="value.standardCard.type" @change="typeChangeHandler">
            <el-option
              v-for="type in externalSourceTypes"
              :key="type.id"
              :label="type.value"
              :value="type.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Плагин" v-if="value.standardCard.type === 'plugin'">
          <editor-plugins v-model="value.standardCard.plugin" @change="$set(value.standardCard ,'plugin', $event)"></editor-plugins>
        </el-form-item>
      </template>
      <template v-if="sourceType==='Registry'">
        <el-form-item label="Наименование">
          <select-tree
            :clearable="true"
            ref="registry_labe_tree"
            :dataTable="selectFields"
            :appendToBody="false"
            v-model="value.standardCard.name"/>
        </el-form-item>
      </template>
    <template v-if="sourceType==='Registry' || (sourceType!== 'Registry' && value.standardCard.type === 'manual')">
      <el-form-item :label="this.$locale.interface_editor.component_editor.map_card_interactive.fields_from_card">
      <div>
        <el-dropdown trigger="click" class="add-button" @command="addField" size="mini">
          <span class="el-dropdown-link">
            {{ this.$locale.main.button.add }} <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="{type: 'layer'}">
              <i style="font-size: 16px;" class="el-icon-map-location"></i>
              {{ this.$locale.interface_editor.component_editor.map_card_interactive.field }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <el-tree
        node-key="guid"
        draggable
        :data="value.standardCard.fields"
        :expand-on-click-node="false"
        :allow-drop="allowDrop"
      >
          <span slot-scope="{ node, data }">
            <span class="label">{{ data.label }}</span>
            <el-button
              type="text"
              class="edit-button"
              @click.stop="edit(node, data)">
              {{ me.$locale.main.button.edit }}
            </el-button>
            <el-button
              type="text"
              class="delete-button"
              @click.stop="remove(node, data)">
              {{ me.$locale.main.button.delete }}
            </el-button>
          </span>
      </el-tree>

    </el-form-item>
      <el-dialog
        title="Добавление полей"
        :visible.sync="showAddField"
        :modalAppendToBody="false">
        <div style="max-height:600px;overflow-y:scroll;">
          <el-tree
            show-checkbox
            node-key="id"
            default-expand-all
            :data="allRegistryFields"
            :props="{ children: 'children', label: 'name' }"
            :expand-on-click-node="false"
            :default-checked-keys="value.standardCard.fields.map((item) => item.id)"
            @check-change="checkboxChange"
            ref="tree"
          >
          </el-tree>
        </div>
      </el-dialog>
      <el-dialog
        title="редактирование полей"
        :visible.sync="showEditField"
        :modal-append-to-body="false"
      >
        <span v-if="editedField.id !== null">
          {{ this.$locale.interface_editor.component_editor.map_card_interactive.field }} {{ editedField.id !== null ? editedField.name + '(attr_' + editedField.id + ')' : editedField.name }}
        </span>
        <span v-else>
          {{ this.$locale.interface_editor.component_editor.map_card_interactive.field }}
        <el-input v-model="editedField.name"></el-input>
        </span>
        <span>
          {{ this.$locale.interface_editor.component_editor.map_card_interactive.alias }}
          <el-input ref="inref" v-model="editedField.label"></el-input>
        </span>
        <span>
          Отображать как HTML
          <el-checkbox ref="as_html" v-model="editedField.asHtml"></el-checkbox>
        </span>
      </el-dialog>
    </template>
  </div>
</template>
<script>
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { ObjectEditorAPI } from '@/core/infrastructure/api/modules/ObjectEditorAPI'
import SelectTree from '@/core/infrastructure/components/TreeSelect'
import EditorPlugins from '@/components/InterfaceEditor/components/editor/editor-plugins'

const EXTERNAL_SOURCE_TYPES = [
  { id: 'manual', value: 'Вручную' },
  { id: 'plugin', value: 'Плагином' }
]

export default {
  components: {
    SelectTree,
    EditorPlugins
  },
  name: 'StandardCard',
  props: {
    value: {
      type: Object,
      standardCard: {
        type: Object,
        default () {
          return {
            id: null,
            name: null,
            fields: []
          }
        }
      }
    },
    entityId: {
      type: Number
    },
    sourceType: {
      type: String
    }
  },
  data () {
    return {
      selectFields: [],
      activeNode: {},
      me: this,
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      externalSourceTypes: EXTERNAL_SOURCE_TYPES,
      showAddField: false,
      showEditField: false,
      allRegistryFields: [],
      editedField: {}
    }
  },
  async mounted () {
    if (this.sourceType === 'Registry') {
      if (!this.entityId) {
        this.$message({
          type: 'error',
          dangerouslyUseHTMLString: true,
          message: 'Не выбран реестр'
        })
      }
      let fields = await this.getRegistryFields(this.entityId)
      this.selectFields = fields.map(this.formatAttrId)
      this.allRegistryFields = await this.getRegistryFields(this.entityId)
    }
  },
  methods: {
    typeChangeHandler (newValue) {
      if (newValue === 'plugin') {
        this.value.standardCard.fields.splice(0, this.value.standardCard.fields.length)
      }
    },
    remove (node, data) {
      this.value.standardCard.fields.splice(
        this.value.standardCard.fields.findIndex((item) => { return item.id === data.id }),
        1
      )
    },
    allowDrop (draggingNode, dropNode, type) {
      return type !== 'inner'
    },
    edit (node, data) {
      this.editedField = data
      this.showEditFieldPanel()
  },
    async getRegistryFields (entityId) {
      let data = await APIClient.shared.request(
        new ObjectEditorAPI.GetEntities({
          'parent_id': entityId,
          'show_children': true,
          'properties[is_visible_in_grid]': true
        })
      )
      return data.data
    },
    checkboxChange (checkedItem, checked) {
      if (checked) {
        checkedItem.label = checkedItem.name
        this.value.standardCard.fields.splice(this.value.standardCard.fields.length, 0, Object.assign({'asHtml': false}, checkedItem))
      } else {
        const index = this.value.standardCard.fields.findIndex((item) => { return item.id === checkedItem.id })
        if (index !== -1) {
          this.value.standardCard.fields.splice(index, 1)
        }
      }
    },
    async addField () {
      if (this.sourceType === 'Registry') {
        this.showAddFieldPanel()
      } else {
        this.value.standardCard.fields.splice(this.value.standardCard.fields.length, 0, {
          id: null,
          name: null,
          label: null
        })
      }
    },
    showAddFieldPanel() {
      this.showAddField = true
    },
    showEditFieldPanel() {
      this.showEditField = true
    },
    formatAttrId(field) {
      field.id = 'attr_' + field.id + '_'
      if (field.has_children) {
        field.children = field.children.map(this.formatAttrId)
        return field
      } else {
        return field
      }
    }
  }
}
</script>
<style scoped>
  .edit-button {
    cursor:pointer;
    color: #409EFF;
    font-size: 12px;
    margin-left: 15px;
    float: right;
  }
  .delete-button {
    cursor:pointer;
    font-size: 12px;
    margin-left: 15px;
    float: right;
    color: #F56C6C;
  }
  .el-tree /deep/ .el-tree-node__content {
    display: inline;
    width: calc(100% - 20px);
  }
</style>
