<template>
  <div>
    <el-select
      v-model="select_value"
      clearable
      multiple
      :key="generateGuid()"
    >
      <el-option
        v-for="(item, index) in components.filter(function (cmp) { return cmp.name && cmp.name === 'basic/Map/index' })"
        :key="item.guid"
        :label="`${item.name} (${item.properties ? item.properties.editorAlias : item.editorAlias})`"
        :value="item.guid"
      ></el-option>
    </el-select>
  </div>
</template>

<script>
  export default {
    name: 'MapSynchronizeControl',
    inject: ['getComponents'],
    props: {
      value: {
        type: Array | String,
        default: null
      }
    },
    data: function () {
      return {
        select_value: Array.isArray(this.value) ? this.value : (this.value ? [this.value] : []),
        coordinates_from_component: false,
        components: this.getComponents()
      }
    },
    watch: {
      value: {
        handler: function (newValue) {
          if (newValue !== this.select_value) {
            this.select_value = newValue
          }
        }
      },
      select_value: {
        handler: function(newValue) {
          this.$emit('change', newValue)
        }
      }
    }
  }
</script>

<style scoped>

</style>
