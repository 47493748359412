import axios, { AxiosResponse } from 'axios'
import CertificateModel from '@/core/infrastructure/components/EDS/models/CertificateModel'

// class GetDSSCertificatesApiRequest implements APIRequest<string> {
//   response: string;
//   path: string;
//
//   constructor (authorization: string) {
//
//   }
// }

// class DSSClient extends APIClient {
//   headers = {};
//   baseURL: string;
//   axios: Object;
//
//   constructor () {
//     super()
//     this.axios = axios.create({url: this.baseURL, })
//
//   }

//   setAuthorization (token: string) {
//     this.headers['Authorization'] = 'Bearer ' + token
//   }
// }

export default class DSSModel {
  token: string;
  apiURL: string;
  code: string;
  certificates: any[] = [];
  certsBase64: any = {};
  http: any

  constructor () {

  }

  connect (apiURL: string, token?: string) {
    this.http = axios.create({
      'baseURL': apiURL,
      'headers': {
        'X-Requested-With': 'DSSClientForAccent20',
        'Authorization': 'Bearer ' + token
      }
    })

    if (token) {
      this.token = token
    }
  }

  getToken (): string {
    return this.token
  }

  // async listCertificates () {
  //   // let request = new GetDSSCertificatesApiRequest(this.getToken())
  //   if (this.certificates.length < 1) {
  //     // return this.api.request(request)
  //     // .then((response) => { this.certificates = JSON.parse(response); return this.certificates })
  //   }
  //   return new Promise((resolve, reject) => resolve(this.certificates))
  // }

  async listCertificates () {
    if (this.certificates.length < 1) {
      let currentModel = this
      return this.http
        .request({
          url: '/SignServer/rest/api/v2/certificates'
        }).then((response: AxiosResponse): any[] => {
          let result: CertificateModel[] = []
          if (response.status === 200 && Array.isArray(response.data)) {
            console.log(response.data)
            for (let i = 0; i < response.data.length; i++) {
              let item = response.data[i]
              let model = new CertificateModel({
                'SerialNumber': item.SerialNumber,
                'Algorithm': item.HashAlgorithms[0],
                'AlgorithmOid': '',
                'HasPrivateKey': true,
                'IsValid': true,
                'Issuer': {},
                'IssuerName': '',
                'ProviderName': item.ProviderName,
                'ProviderType': item.ProviderType,
                'Subject': {},
                'SubjectName': item.DName,
                'Thumbprint': item.Thumbprint,
                'ValidFromDate': item.ValidFrom * 1000,
                'ValidToDate': item.ValidTo * 1000,
                'Type': 'DSS',
                'Id': item.ID
              })

              for (let s = 0; s < item.SubjectName.length; s++) {
                let oid = item.SubjectName[s]
                model.Subject[oid.StringIdentifier] = oid.Value
              }

              for (let s = 0; s < item.IssuerName.length; s++) {
                let oid = item.IssuerName[s]
                model.Issuer[oid.StringIdentifier] = oid.Value
                model.IssuerName += oid.StringIdentifier + '=' + oid.Value + ','
              }
              model.IssuerName = model.IssuerName.substr(0, -1)
              // model.CertificateBase64 = item.CertificateBase64
              this.certsBase64[item.Thumbprint] = item.CertificateBase64

              result.push(model)
            }
            // this.certificates = response.data
            return result
          }
        })
    }
    return new Promise((resolve, reject) => resolve(this.certificates))
  }

  redirectUrl (baseURL: string, client_id: string, type: string): URL {
    let url = new URL('STS/oauth/authorize', baseURL)

    let redirect_url = (client_id === 'ds-supd.mos.ru' ? 'https://ds-supd.mos.ru' : window.location.origin) +
        '/dss'

    url.searchParams.set('client_id', client_id)
    url.searchParams.set('response_type', type)
    url.searchParams.set('scope', 'dss')
    url.searchParams.set('redirect_uri', redirect_url)
    url.searchParams.set('resource', 'urn:cryptopro:dss:signserver:signserver')
    return url
  }
  async getCertificateBase64 (thumbprint: string): Promise<string> {
    return new Promise((resolve, reject) => resolve(this.certsBase64[thumbprint]))
  }

  setCode (code: string): void {
    this.code = code
  }

  getCode (): string {
    return this.code
  }
}
