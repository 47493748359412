import Vue from 'vue'
import './filterStyle.css'

export default Vue.extend({
  template: `
    <el-row class="ag-simple-filter-body-wrapper">
      <el-col>
        <el-select size="small" v-model="type" placeholder="Выбрать" style="width: 100%" class="ag-custom-component-popup">
          <el-option
            class="ag-custom-component-popup"
            v-for="item in items"
            :key="item.type"
            :label="item.name"
            :value="item.type">
          </el-option>
        </el-select>
      </el-col>
      <el-col>
        <el-date-picker
          v-show="['eq','lt','gt'].includes(type)"
          size="small"
          style="width: 100%"
          value-format="yyyy-MM-dd HH:mm"
          format="dd.MM.yyyy HH:mm"
          v-model="date"
          type="datetime"
          popper-class="ag-custom-component-popup"
          :picker-options="{ firstDayOfWeek: 1 }"
          @change="changed"
          placeholder="Дата+время">
        </el-date-picker>
        <el-date-picker
          v-show="['bt'].includes(type)"
          size="small"
          style="width: 100%"
          value-format="yyyy-MM-dd HH:mm"
          format="dd.MM.yyyy HH:mm"
          popper-class="ag-custom-component-popup"
          @change="changed"
          v-model="date_range"
          :picker-options="{ firstDayOfWeek: 1 }"
          type="datetimerange"
          range-separator="-">
        </el-date-picker>
      </el-col>
      <div class="ag-filter-apply-panel">
        <button type="button" class="ag-standard-button ag-filter-apply-panel-button" @click="apply">ОК</button>
        <button type="button" class="ag-standard-button ag-filter-apply-panel-button" @click="reset">Сбросить</button>
      </div>
    </el-row>
    `,
  name: 'dateTimeFilter',
  data: function () {
    return {
      type: null,
      date: null,
      date_range: [],
      items: this.$locale.registry.filter.fields.date_field.types
    }
  },
  methods: {
    reset () {
      this.date = this.type = null
      this.params.filterChangedCallback()
    },
    apply () {
      this.params.filterChangedCallback()
    },
    changed (val) {
      if (Array.isArray(val)) {
        this.date_range = val || null
      } else {
        this.data = val || null
      }
    },
    getModel () {
      if (this.type && (this.date || this.date_range.length)) {
        return { filterType: 'dateTime', type: this.isFilterActive() ? 'active' : 'non-active', value: { type: this.type, date: (this.date || this.date_range) } }
      }
      if (['blank', 'notBlank'].includes(this.type)) {
        return { filterType: 'time', type: this.isFilterActive() ? 'active' : 'non-active', value: { type: this.type, date: {} } }
      }
    },
    isFilterActive () {
      if (this.type && (this.date || this.date_range.length)) {
        return true
      }
      if (['blank', 'notBlank'].includes(this.type)) {
        return true
      }
      return false
    },
    setModel (value) {
      this.changed(value)
    },
    onFloatingFilterChanged (value) {
      this.setModel(value)
    }
  }
})
