<script type="ts">
import Treeselect from '@bingosoftnn/vue-treeselect'
import ElFormItem from 'element-ui/packages/form/src/form-item.vue'
import ElCollapse from 'element-ui/packages/collapse/src/collapse.vue'
import ElCollapseItem from 'element-ui/packages/collapse/src/collapse-item.vue'
import ElDialog from 'element-ui/packages/dialog/src/component.vue'
import ElRow from 'element-ui/packages/row/src/row.js'
import ElCol from 'element-ui/packages/col/src/col.js'
import ElSlider from 'element-ui/packages/slider/src/main.vue'
import ElColorPicker from 'element-ui/packages/color-picker/src/main.vue'
//import ElInput from 'element-ui/packages/input/src/input.vue'
import StringInput from '@/core/infrastructure/components/StringInput.vue'
import ElInputNumber from 'element-ui/packages/input-number/src/input-number.vue'
import InputOffset from '@/core/infrastructure/components/InputOffset.vue'
import PointIconSet from '@/core/infrastructure/components/PointIconSet.vue'
import LinestringPatternSet from '@/core/infrastructure/components/LinestringPatternSet.vue'
import FillPatternSet from '@/core/infrastructure/components/FillPatternSet.vue'
import InputAnchor from '@/core/infrastructure/components/InputAnchor.vue'
import EntitiesQuery from '@/services/MapEditor/application/query/EntitiesQuery'
import EntityByIdQuery from '@/services/MapEditor/application/query/EntityByIdQuery'
import EntitiesHandler from '@/services/MapEditor/application/handler/query/EntitiesHandler'
import EntityByIdHandler from '@/services/MapEditor/application/handler/query/EntityByIdHandler'
import EntitуQueryRepository from '@/services/MapEditor/infrastructure/domain/repository/EntitуQueryRepository'

export default {
  name: "PropertySet",
  inject: {
    getEventBus: {
      default: () => () => {
        return {
          $emit: () => {},
          $on: () => {}
        }
      }
    }
  },
  props: {
    items: {
      type: Array,
      default: () => []
    }, 
    dto: Object,
    readonly: {
      type: Boolean,
      default: false
    },
    mini: {
      type: Boolean,
      default: false
    },
    fieldProperties: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      entitiesHandler: new EntitiesHandler(new EntitуQueryRepository()),
      entityByIdHandler: new EntityByIdHandler(new EntitуQueryRepository()),
      selectOptions: {},
      selectValues: {},
      pointMarkerTypes: [{
        id: 'simple_point',
        name: this.$locale.map_editor.style_form.markers.simple_point
      },{
        id: 'image',
        name: this.$locale.map_editor.style_form.markers.image
      }],
      arrowTypes: [{
        id: 'begin',
        name: this.$locale.map_editor.style_form.arrows.begin
      },{
        id: 'end',
        name: this.$locale.map_editor.style_form.arrows.end
      },{
        id: 'both',
        name: this.$locale.map_editor.style_form.arrows.both
      },{
        id: 'none',
        name: this.$locale.map_editor.style_form.arrows.none
      }],
      booleans: [{
        id: 'true',
        name:  this.$locale.main.fields.true
      },{
        id: 'false',
        name:  this.$locale.main.fields.false
      }],
      placements: [{
        id: 'point',
        name:  this.$locale.map_editor.style_form.placements.point
      },{
        id: 'line',
        name: this.$locale.map_editor.style_form.placements.line
      }],
      textAlignTypes: [{
        id: 'left',
        name:  this.$locale.map_editor.style_form.text_align_types.left
      },{
        id: 'right',
        name:  this.$locale.map_editor.style_form.text_align_types.right
      },{
        id: 'center',
        name:  this.$locale.map_editor.style_form.text_align_types.center
      },{
        id: 'end',
        name:  this.$locale.map_editor.style_form.text_align_types.end
      },{
        id: 'start',
        name:  this.$locale.map_editor.style_form.text_align_types.start
      }],
      textBaseLines:[{
        id: 'bottom',
        name: this.$locale.map_editor.style_form.text_base_lines.bottom
      },{
        id: 'top',
        name: this.$locale.map_editor.style_form.text_base_lines.top
      },{
        id: 'middle',
        name: this.$locale.map_editor.style_form.text_base_lines.middle
      }],
      groups: {}
    }
  },
  watch: {
    isEntityLoading: (state) => state
  },
  computed: {
    isEntityLoading() {
      return this.$store.getters['Entity/isLoading'];
    }
  },
  methods: {
    createProperties() {
      return this.createFields();
    },
    createFields() {
      let fields = [];
      let groupsExist = false;
      let columnsExist = false;
      let groups = {};
      let columns = {};
      let maxColNumber = -1;
      this.items.forEach((field) => {
        let el;
        let groupExists = false;
        let columnExists = false;
        if (field.group_name) {
          if (!groups.hasOwnProperty(field.group_name)) {
            groups[field.group_name] = [];
          }          
          groupsExist = true;
          groupExists = true;
        }
        if (field.column_number && !this.mini) {
          let key;
          if (groupExists) {
            key = "col_" + field.group_name + "_" + field.column_number;
          } else {
            key = "col_" + field.column_number;
          }      
          if (!columns.hasOwnProperty(key)) {
            columns[key] = [];
          }
          if (maxColNumber < field.column_number) {
            maxColNumber = field.column_number;
          }
          columnsExist = true;
          columnExists = true;
        }
        switch(field.type) {
          case 'geometry_object':
            el = this.createGeometryObject(field);
            break;
          case 'geometry_field':
            el = this.createGeometryField(field);
            break;
          case 'map_editor.e_marker_type':
            el = this.createStaticComboField(field, 'pointMarkerTypes');
            break;
          case 'color':
            el = this.createColorPickerField(field);
            break;
          case 'varchar':
            el = this.createStringField(field);
            break;
          case 'opacity':
            el = this.createOpacityField(field);
            break;
          case 'integer':
          case 'float':
            el = this.createNumberField(field);
            break;
          case 'boolean':
            el = this.createStaticComboField(field, 'booleans');
            break;
          case 'e_label_align':
            el = this.createStaticComboField(field, 'textAlignTypes');
            break;
          case 'e_text_baseline':
            el = this.createStaticComboField(field, 'textBaseLines');
            break;
          case 'scale':
          case 'offset':
            el = this.createOffsetField(field);
            break;
          case 'anchor':
            el = this.createAnchorField(field);
            break;
          case 'icon_file':
            el = this.createIconSetField(field);
            break;
          case 'map_editor.e_arrow':
            el = this.createStaticComboField(field, 'arrowTypes');
            break;
          case 'e_label_placement':
            el = this.createStaticComboField(field, 'placements');
            break;
          case 'stroke_style':
            el = this.createStrokeSetField(field);
            break;
          case 'fill_style':
            el = this.createFillSetField(field);
            break;
          default:
            break;
        }
        if (el) {
          if (groupExists) {
            if (columnExists) {
              columns["col_" + field.group_name + "_" + field.column_number].push(el);
            } else {
              groups[field.group_name].push(el);
            }
          } else {
            if (columnExists) {
              columns["col_" + field.column_number].push(el);
            } else {
              fields.push(el);
            }
          }
        }
      });
      if (groupsExist) {
        for (let group in groups) {
          if (groups.hasOwnProperty(group)) {
            let resultFields = [];
            if (columnsExist) {
              let cols = [];
              for (let i = 1; i <= maxColNumber; i += 1) {
                let colKey = "col_" + group + "_" + i;
                let colEl = this.$createElement(ElCol, {
                  props: {
                    span: Math.floor(24 / maxColNumber)
                  }
                }, columns[colKey]); 
                cols.push(colEl);
              }
              resultFields.push(
                this.$createElement(ElRow, {}, cols) 
              );
            } else {
              resultFields = groups[group];
            }
            fields.push(
              this.$createElement('fieldset', {},[
                this.$createElement('legend', {}, [
                  this.$createElement('div', {
                    class: {
                      'el-form-item__label': true
                    },
                    style: {
                      "width": "100%",
                      "text-align": "left"
                    }
                  }, [
                    this.$locale.map_editor.style_form[group]
                  ])
                ]), resultFields
              ])
            );

          }
        }
      }
      return fields;
    },
    createOptions(name) {
      if (typeof this.selectOptions[name] == "undefined") {
        this.$set(this.selectOptions, name, []);
        this.$set(this.selectValues, name, null);
      }
    },
    createGeometryObject(field) { 
      return this.createSelectField(field, async (component) => {
        await this.getObjectList(field.id);
      });     
    },
    createGeometryField(field) { 
      return this.createSelectField(field, async (component) => {
        await this.getGeometryFieldList(field.id);
      });     
    },
    createStaticComboField(field, dataKey) {
      let props = typeof this.fieldProperties[field.name] !== 'undefined' ? this.fieldProperties[field.name] : {};
      let selectProperties = typeof props['selectProperties'] !== 'undefined' ? props['selectProperties'] : {};
      let initValueKey = this.getValueKey(field);
      this.selectOptions[field.id] = this.normalizeEntities(this[dataKey]);
      if (field.type == 'boolean') {
          field[initValueKey] = field[initValueKey].toString();
      }
      this.selectValues[field.id] = field[initValueKey];      
      this.setProperty(field.id, field[initValueKey]);
      return this.$createElement(ElFormItem, {
        props: {
          prop: field.id,
          label: this.$locale.map_editor.style_form[field.name]          
        },
        class: {
          'point-type': true
        }
      },[
        this.createSelectField(field, () => {               
        }, selectProperties)
      ]);
    },
    createColorPickerField(field) {
      this.createOptions(field.id);
      let initValueKey = this.getValueKey(field);
      let defaultValue = field[initValueKey];  
      this.inputChange(field.id, defaultValue);    
      return this.$createElement(ElFormItem, {
        props: {
          prop: field.id,
          label: this.$locale.map_editor.style_form[field.name]          
        }
      },[
        this.$createElement(ElColorPicker, {
          props: {
            value: defaultValue
          },
          class: {
            'color-picker': true
          },
          on: {
            input: (value) => {
              this.inputChange(field.id, value);
            }
          }
        })
      ]);
    },
    createOpacityField(field) {
      this.createOptions(field.id);
      let initValueKey = this.getValueKey(field);
      let defaultValue = parseInt(field[initValueKey]);
      this.inputChange(field.id, defaultValue);    
      return this.$createElement(ElFormItem, {
        props: {
          prop: field.id,
          label: this.$locale.map_editor.style_form[field.name]          
        }
      },[
        this.$createElement(ElSlider, {
          props: {
            value: defaultValue,
            showInput: false
          },
          class: {
            'opacity': true,
            'mini': this.mini
          },
          on: {
            input: (val) => {
              this.inputChange(field.id, val);
            }
          }
        })
      ]);
    },
    createStringField(field) {
      this.createOptions(field.id);
      let initValueKey = this.getValueKey(field);
      let defaultValue = field[initValueKey]
      this.setProperty(field.id, defaultValue);
      return this.$createElement(ElFormItem, {
        props: {
          prop: field.id,
          label: this.$locale.map_editor.style_form[field.name] || this.$locale.map_editor.source_form[field.name],   
        }
      },[
        this.$createElement(StringInput, {
          props: {
            value: defaultValue
          },
          class: {
            'size': true
          },
          on: {
            input: (val) => {
              this.inputChange(field.id, val);
            }
          }
        })
      ]);
    },
    createNumberField(field) {
      this.createOptions(field.id);
      let initValueKey = this.getValueKey(field);
      let defaultValue = Number(field[initValueKey]);
      this.setProperty(field.id, defaultValue);      
      return this.$createElement(ElFormItem, {
        props: {
          prop: field.id,
          label: this.$locale.map_editor.style_form[field.name],   
        }
      },[
        this.$createElement(ElInputNumber, {
          props: {
            value: defaultValue,
            min: -Infinity,
            max: Infinity,
            size: 'small'
          },
          class: {
            'size': true
          },
          on: {
            input: (val) => {
              this.inputChange(field.id, val);
            }
          }
        })
      ]);
    },
    createOffsetField(field) {
      this.createOptions(field.id);
      let initValueKey = this.getValueKey(field);
      let defaultValue = Array.isArray(field[initValueKey]) ? field[initValueKey] : JSON.parse(field[initValueKey]);
      this.inputChange(field.id, defaultValue);
      return this.$createElement(ElFormItem, {
        props: {
          prop: field.id,
          label: this.$locale.map_editor.style_form[field.name]          
        }
      },[
        this.$createElement(InputOffset, {
          props: {
            min: -Infinity,
            max: Infinity,
            size: 'small',
            isVertical: this.mini
          },
          class: {
            'size': true
          },
          on: {
            init: (callback) => {
              callback(defaultValue);
            },
            change: (x, y) => {
              this.inputChange(field.id, [x, y]);
            }
          }
        })
      ]);
    },
    createAnchorField(field) {
      this.createOptions(field.id);
      let initValueKey = this.getValueKey(field);
      let defaultValue = Array.isArray(field[initValueKey]) ? field[initValueKey] : JSON.parse(field[initValueKey]);
      this.inputChange(field.id, defaultValue);
      return this.$createElement(ElFormItem, {
        props: {
          prop: field.id,
          label: this.$locale.map_editor.style_form[field.name],   
        }
      },[
        this.$createElement(InputAnchor, {
          class: {
            'anchor': true
          },
          on: {
            init: (callback) => {
              callback(defaultValue);
            },
            change: (x, y) => {
              this.inputChange(field.id, [x, y]);
            }
          }
        })
      ]);
    },
    createIconSetField(field) {      
      this.createOptions(field.id);
      let initValueKey = this.getValueKey(field);
      let defaultValue = field[initValueKey] ? field[initValueKey] : {};
      this.inputChange(field.id, defaultValue);
      return this.$createElement(ElFormItem, {
        props: {
          prop: field.id,
          label: this.$locale.map_editor.style_form[field.name]
        }
      },[
        this.$createElement(PointIconSet, {
          props: {
            showControls: !this.mini,
            value: defaultValue  
          },
          on: {
            change: (val) => {
              this.inputChange(field.id, val);
            },
            addPointIcon: (callback) => {
              this.getEventBus().$emit('createForm', 'point_style_image', {'submitCallback': callback});
            }
          }
        })
      ]);
    },
    createStrokeSetField(field) {
      this.createOptions(field.id);
      let initValueKey = this.getValueKey(field);
      let defaultValue = typeof field[initValueKey] == "object" ? field[initValueKey] : {};
      return this.$createElement(ElFormItem, {
        props: {
          prop: field.id,
          label: this.$locale.map_editor.style_form[field.name],   
        }
      },[
        this.$createElement(LinestringPatternSet, {
          props: {
            showControls: !this.mini,
            value: defaultValue  
          },
          on: {
            change: (val) => {
              this.inputChange(field.id, val);
            },
            addLinestringPattern: (callback) => {
              this.getEventBus().$emit('createForm', 'linestring_style_pattern', {'submitCallback': callback});
            }
          }
        })
      ]);
    },
    createFillSetField(field) {
      this.createOptions(field.id);
      let initValueKey = this.getValueKey(field);
      let defaultValue = field[initValueKey];
      return this.$createElement(ElFormItem, {
        props: {
          prop: field.id,
          label: this.$locale.map_editor.style_form[field.name],   
        }
      },[
        this.$createElement(FillPatternSet, {
          props: {
            showControls: !this.mini,
            value: defaultValue
          },
          on: {
            change: (val) => {
              this.inputChange(field.id, val);
            }
          }
        })
      ]);
    },
    createSelectField(field, optionsCallback, selectProperties) {
      let props = selectProperties || {};
      this.createOptions(field.id);
      let component = this.$createElement(Treeselect, {
        props: {
          placeholder: this.$locale.main.fields.select,
          noOptionsText: this.isEntityLoading ? this.$locale.main.fields.loading_text : this.$locale.main.fields.no_options_text,
          loadingText: this.$locale.main.fields.loading_text,
          noResultsText: this.$locale.main.fields.no_results_text,
          normalizer: this.normalizeEntityList,
          searchable: true,
          cacheOptions: true,
          appendToBody: true,
          options: this.selectOptions[field.id],
          value: this.selectValues[field.id],
          zIndex: 99999,
          disabled: this.readonly || this.disableSelect(field.id),
          ...props
        },
        on: {
          open: () => {
            optionsCallback(component)
          },
          select: (record) => {
            this.selectChange(field.id, record);                  
          },
          input: (value) => {
            this.inputChange(field.id, value);
          }
        }
      });
      return this.$createElement(ElFormItem, {
        props: {
          prop: field.id,
          label: this.$locale.main.fields[field.name],
          rules: {
            required: !this.readonly && true,
            trigger: 'blur',
            validator: (rules, value, callback) => {
              if (component.data.props.value) {
                callback()
              } else {
                callback(new Error(this.$locale.main.message.required_field));
              }
            }
          }
        }
      }, [component]);
    },
    getValueKey(field) {
      if (field.hasOwnProperty("value")) {
        return "value";
      }
      return "default_value";
    },
    async getObjectList(optionsId) {
      let entities = await this.entitiesHandler.execute(
        new EntitiesQuery({
          type: "registry"
        })
      );       
      this.selectOptions[optionsId] = this.normalizeEntities(entities);
    },
    async getGeometryFieldList(optionsId) {
      let objectId = this.getProperty('geometry_object_id');
      if (objectId) {
        let entities = await this.entitiesHandler.execute(
          new EntitiesQuery({
            object_id: objectId,
            type: 'geometry_field'
          })
        );
        this.selectOptions[optionsId] = this.normalizeEntities(entities);
      }
    },
    setValues(values) {
      if (values.length) {
        for (let i = 0; i < values.length; i += 1) {
          if (typeof values[i].value !== 'undefined') {
            this.setValue(values[i].type, values[i].id, values[i].value);            
          }
        }
      }
    },
    setValue(type, name, value) {
      switch(type) {
        case 'geometry_object':
        case 'geometry_field':        
          this.setEntityValue(name, value);
          break;
        case 'map_editor.e_marker_type':
        case 'map_editor.e_arrow':       
          this.setStaticSelectValue(name, value);
          break;       
        default:
          break;
      }
    },
    async setEntityValue(name, value) {
      if (value !== null) {
        let entities = await this.entityByIdHandler.execute(
          new EntityByIdQuery(value)
        );
        this.selectOptions[name] = this.normalizeEntities(entities);
        this.selectValues[name] = value;
      } else {
        this.selectOptions[name] = [];
        this.selectValues[name] = null;
      }      
    },
    normalizeEntities(entities) {
      if (entities.data && entities.data instanceof Array) {
        return entities.data;
      }
      if (entities.data && typeof entities.data == 'object') {
        return [entities.data];
      }
      if (typeof entities.data == 'undefined') {
        return entities;
      }
      return [];
    },
    setStaticSelectValue(name, value) {
      this.selectOptions[name] = value;
    },
    inputChange(name, value) {
      this.setProperty(name, value);              
      this.clearRelatedOnInputChange(name, value);
      this.selectValues[name] = value;  
    },
    selectChange(name, value) {
      this.setProperty(name, value['id']);
      this.clearRelatedOnSelectChange(name, value['id']);
    },
    setProperty(name, value) {
      let propExists = false;
      for (let i = 0; i < this.dto.properties.length; i += 1) {
        if (this.dto.properties[i].id == name) {
          this.$set(this.dto.properties[i], 'value', value);
          propExists = true;
          break;
        }
      }
      if (!propExists) {
        for (let i = 0; i < this.items.length; i += 1) {
          if (this.items[i].id == name) {
            this.dto.properties.push({
              id: this.items[i].id,
              name: this.items[i].id,
              type: this.items[i].type,
              value: value
            });
            break;
          }
        }
      }
    },
    getProperty(name) {
      for (let i = 0; i < this.dto.properties.length; i += 1) {
        if (this.dto.properties[i].id == name) {
          return this.dto.properties[i].value;
        }
      }
      return null;
    },
    clearRelatedOnInputChange(name, value) {
      switch(name) {
        case 'geometry_object_id':
          this.clearGeometryField('geometry_field_id', value);
        default:
          break;
      }
    },
    clearRelatedOnSelectChange(name, value) {
      switch(name) {
        case 'geometry_object_id':
          this.resetGeometryField('geometry_field_id', value);
        default:
          break;
      }
    },
    clearGeometryField(name, value) {
      if (typeof value == 'undefined') {
        this.inputChange(name);
      }
    },
    async resetGeometryField(name) {
      await this.getGeometryFieldList(name);
      if (this.selectOptions[name].length == 1) {
        this.setProperty(name, this.selectOptions[name][0].id);
        this.selectValues[name] = this.selectOptions[name][0].id;        
      } else {
        this.selectValues[name] = undefined;
        this.setProperty(name);
      }
    },
    disableSelect(name) {
      switch(name) {
        case 'geometry_field_id':
          for (let i = 0; i < this.dto.properties.length; i += 1) {
            if (this.dto.properties[i].id == 'geometry_object_id') {
              return typeof this.dto.properties[i].value == 'undefined';
            }
          }
          return this.dto.properties.length == 0;
        default:
          return false;
      }
    },
    normalizeEntityList(node) {
      return {
        id: node.id,
        label: node.name
      };
    }
  },
  mounted () {
    this.getEventBus().$emit('propertiesMounted', this);
  },
  render (createElement) {
    return createElement("div", {
      class: {
        "property-set": true
      }
    }, this.createProperties());
  }
}

</script>