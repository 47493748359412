<template>
  <el-form v-model="value">
    <el-form-item class="label">
      <el-checkbox v-model="enabled" @change="enableChanged">Включить всплывающие подсказки</el-checkbox>
    </el-form-item>
    <template v-if="enabled">
      <el-form-item label="Содержимое подсказки" class="label">
        <div class="ckeditorDiv">
          <ckeditor ref="ckeditor" v-model="value.text" :editor="editor"></ckeditor>
        </div>
      </el-form-item>
      <el-form-item label="Диапазон видимости" class="label">
        <div class="slider">
          <el-slider
            v-model="value.range"
            range
            :max="20"
            :min="0"
            :marks="marks"
      ></el-slider>
        </div>
      </el-form-item>
      <el-form-item label="css контейнера" class="label">
        <codemirror ref="cssEditor" v-model="value.css" :options="codemirrorConfig" style="height: 150px;"></codemirror>
      </el-form-item>
    </template>
  </el-form>
</template>
<script>
import CKEditor from '@ckeditor/ckeditor5-vue2'
import InlineEditor from '@/components/HTMLEditor/ckeditor'
import CKEditorMixin from '@/components/InterfaceEditor/components/CKEditorMixin'

export default {
  name: 'popupPanel',
  components: {
    ckeditor: CKEditor.component
  },
  mixins: [CKEditorMixin],
  data () {
    return {
      editor: InlineEditor,
      codemirrorConfig: {
        tabSize: 2,
        mode: 'text/css',
        theme: 'base16-light',
        styleActiveLine: true,
        styleSelectedText: true
      },
      enabled: null,
      tmp: { text: '', css: '', range: [0, 20] },
      marks: {}
    }
  },
  props: {
    value: {
      type: Object
    }
  },
  mounted () {
    this.enabled = !(this.value.text === '' || this.value.text === null)
    for (let i = 2; i <= 18; i += 2) {
      this.marks[i] = '' + i
    }
  },
  methods: {
    enableChanged (val) {
      if (!val) {
        this.tmp.text = this.value.text
        this.tmp.css = this.value.css
        this.tmp.range = this.value.range
        this.value.text = ''
        this.value.css = ''
        this.value.range = [0, 20]
      } else {
        this.value.text = this.tmp.text
        this.value.css = this.tmp.css
      }
    }
  }
}
</script>
<style>
  .ck-body-wrapper {
    z-index: 99999;
  }
</style>
<style scoped>
  .ckeditorDiv {
    border: 1px solid #dcdfe6;
    border-radius: 6px;
  }
  .label {
    padding: 15px 0;
  }
  .slider {
    padding: 0 20px;
  }
</style>
