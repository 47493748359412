<template>
  <el-collapse class="editor-wrapper" value="editor-input-label">
    <el-collapse-item :title="$locale.interface_editor.component_editor.button_action.label" name="editor-input-label">
      <template slot="title">
        <span class="editor-property-label">{{ label ? label : $locale.interface_editor.component_editor.button_action.label }}</span>
        <!-- <el-tooltip placement="top-start" :open-delay="400">
          <div slot="content" v-html="''"></div>
          <i class="el-icon-question"></i>
        </el-tooltip> -->
      </template>

      <el-form label-width="120px" label-position="top" size="mini" class="editor_button">
        <!-- <el-form-item style="margin-top: 10px">
          <span class="property_label">{{ $locale.notify_editor.label.choose_icon }}</span>
          <el-select clearable size="mini" v-model="value.icon">
            <el-option
              v-for="item in icons"
              :key="item.value"
              :label="item.label"
              :value="item.value">
              <span style="float: left">{{ item.label }}</span>
              <span style="float: right;">
                <i style="font-size: 30px;width: 22px;height: 22px;" :class="item.value"></i>
              </span>
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item>
          <span class="property_label">{{ $locale.interface_editor.component_editor.button_action.action }}</span>
          <editor-select
            :value="value.type"
            @change="$set(value, 'type', $event)"
            :options="{
              multiple: false,
              clearable: true,
              options: [
                { id: 'open_dashboard', name: $locale.interface_editor.component_editor.button_action.open_dashboard },
                { id: 'execute_plugin', name: $locale.interface_editor.component_editor.button_action.start_plugin },
                { id: 'open_report', name: $locale.interface_editor.component_editor.button_action.open_report },
                { id: 'open_document', name: $locale.interface_editor.component_editor.button_action.open_document },
                { id: 'open_xml', name: $locale.interface_editor.component_editor.button_action.open_xml },
                { id: 'open_json', name: $t('interface_editor.component_editor.button_action.open_json') },
                { id: 'print_page', name: $locale.interface_editor.component_editor.button_action.print_page },
                { id: 'autofill_fields', name: $locale.interface_editor.component_editor.button_action.autofill_fields },
                { id: 'execute_command', name: $locale.interface_editor.component_editor.button_action.execute_command },
                { id: 'open_card', name: $locale.interface_editor.component_editor.button_action.open_card },
                { id: 'save_card', name: $locale.interface_editor.component_editor.button_action.save_card, hidden: !this.getRegistryId() },
                { id: 'close_card', name: $locale.interface_editor.component_editor.button_action.close_card, hidden: !this.getRegistryId() },
                { id: 'close_dashboard', name: $locale.interface_editor.component_editor.button_action.close_dashboard, hidden: this.getRegistryId() },
                { id: 'open_history', name: $locale.interface_editor.component_editor.button_action.open_history, hidden: !this.getRegistryId() },
                { id: 'open_url', name: $locale.interface_editor.component_editor.button_action.open_url },
                { id: 'refresh_card', name: $locale.interface_editor.component_editor.button_action.refresh_card },
                { id: 'etl_export', name: $locale.interface_editor.component_editor.button_action.etl_export },
                { id: 'open_window', name: $t('interface_editor.component_editor.button_action.open_window')},
                { id: 'refresh_replication', name: $t('interface_editor.component_editor.button_action.refresh_replication')},
                { id: 'etl_import', name: $t('interface_editor.component_editor.button_action.execute_import')},
                { id: 'open_tab_in_block', name: $t('interface_editor.component_editor.button_action.open_tab_in_block')},
                { id: 'refresh_components', name: $t('interface_editor.component_editor.button_action.refresh_components')},
                { id: 'close_window', name: $t('interface_editor.component_editor.button_action.close_window')},
                { id: 'register_user', name: $t('interface_editor.component_editor.button_action.register_user')},
                { id: 'delete_records', name: $t('interface_editor.component_editor.button_action.delete_records')},
                { id: 'copy_to_clipboard', name: $t('interface_editor.component_editor.button_action.copy_to_clipboard')},
                { id: 'complex', name: $t('interface_editor.component_editor.button_action.complex')},
                { id: 'execute_listener', name: $t('interface_editor.component_editor.button_action.execute_listener')},
                { id: 'from_process_task', name: $t('interface_editor.component_editor.button_action.from_process_task')}
              ]
            }"
          ></editor-select>
        </el-form-item>

        <template v-if="value.type === 'open_card'">
          <editor-open-card
            :value="value"
            :is-headers="isHeaders"
            :source-type="sourceType"
            :source-id="sourceId"
            :is-button="isButton"
            :registry-attrs="registryAttrs"
          >
          </editor-open-card>
          <el-form-item>
            <span class="property_label">{{ $t('interface_editor.component_editor.button_action.refresh_replication_blocks') }}</span>
            <el-select v-model="value.refreshReplication.blocks" multiple clearable>
              <el-option
                  v-for="(item, index) in replicationBlocks"
                  :key="index"
                  :label="item.name"
                  :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </template>

        <el-form-item v-if="(value.type === 'open_card' && (value.card.type === 'add' || value.card.type === 'update'))">
          <span class="property_label">{{ $locale.interface_editor.component_editor.button_action.refresh_selected_components }}</span>
          <el-select v-model="value.card.componentsGuid" clearable multiple>
            <el-option
              v-for="(item, index) in components"
              :key="index"
              :label="item.group === 'basic' ? item.properties.editorAlias : item.properties.name"
              :value="item.guid">
              <span style="float: left">{{ item.initialType }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.group === 'basic' ? item.properties.editorAlias : item.properties.name }}</span>
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item v-if="value.type === 'etl_import'">
          <span class="property_label">{{ $locale.interface_editor.component_editor.button_action.refresh_selected_components }}</span>
          <el-select v-model="value.etl_import.componentsGuid" clearable multiple>
            <el-option
              v-for="(item, index) in components"
              :key="index"
              :label="item.group === 'basic' ? item.properties.editorAlias : item.properties.name"
              :value="item.guid">
              <span style="float: left">{{ item.initialType }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.group === 'basic' ? item.properties.editorAlias : item.properties.name }}</span>
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item v-if="value.type === 'etl_import'">
          <span class="property_label">{{ $locale.interface_editor.component_editor.button_action.execute_plugin_after_import }}</span>
          <editor-plugins :value="value.etl_import.pluginAfterImport" @change="$set(value.etl_import ,'pluginAfterImport', $event)"></editor-plugins>
        </el-form-item>

        <el-form-item v-if="value.type === 'open_card'">
          <el-checkbox v-model="value.card.isFastCard"/>
          <span class="property_label" style="display: inline-block">{{ $locale.interface_editor.component_editor.button_action.fast_card }}</span>
        </el-form-item>

        <template v-if="value.type === 'execute_command'">
          <el-form-item>
            <span class="property_label">{{ $locale.logic_editor.command.headline }}</span>
            <el-select
              :value="value.command.id"
              value-key="id"
              filterable
              @change="$set(value.command ,'id', $event )"
            >
              <el-option
                v-for="(item, index) in commands"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item v-if="value.type === 'execute_command'">
            <span class="property_label">{{ $locale.interface_editor.component_editor.button_action.success_text }}</span>
            <el-input v-model="value.command.success_text"/>
          </el-form-item>

          <el-form-item v-if="value.type === 'execute_command'">
            <span class="property_label">{{ $locale.interface_editor.component_editor.button_action.failure_text }}</span>
            <el-input v-model="value.command.failure_text"/>
          </el-form-item>
        </template>

        <el-form-item v-if="executePluginAllowedActions.includes(value.type)">
          <span class="property_label">{{ $locale.interface_editor.component_editor.table.plugin }}</span>
          <editor-plugins :value="value.plugin" @change="$set(value ,'plugin', $event)"></editor-plugins>

          <el-checkbox v-model="value.command.is_card_close"/>
          <span class="property_label" style="display: inline-block">{{ $locale.interface_editor.component_editor.button_action.close_card }}</span>

          <el-form-item>
            <span class="property_label">{{ $locale.interface_editor.component_editor.button_action.refresh_selected_components }}</span>
            <el-select v-model="value.command.componentsGuid" clearable multiple>
              <el-option
                v-for="(item, index) in components"
                :key="index"
                :label="item.group === 'basic' ? item.properties.editorAlias : item.properties.name"
                :value="item.guid">
                <span style="float: left">{{ item.initialType }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{ item.group === 'basic' ? item.properties.editorAlias : item.properties.name }}</span>
              </el-option>
            </el-select>
          </el-form-item>
        </el-form-item>

        <el-form-item v-if="(value.command || {}).is_card_close">
          <span class="property_label" style="display: inline-block;">{{ $locale.interface_editor.component_editor.button_action.card_close_type }}</span>
          <editor-select
            :value="value.command.close_type"
            @change="$set(value.command ,'close_type', $event)"
            :options="{
              multiple: false,
              options: [
                { id: 'current_card', name: $locale.interface_editor.component_editor.button_action.current_card },
                { id: 'parent_card', name: $locale.interface_editor.component_editor.button_action.parent_card }
              ]
            }"
          ></editor-select>
        </el-form-item>

        <el-form-item v-if="value.type === 'execute_command'">
          <el-form-item>
            <el-checkbox v-model="value.command.is_save_card_before_command"/>
            <span class="property_label" style="display: inline-block">{{ $t('interface_editor.component_editor.button_action.save_card_before_command') }}</span>
          </el-form-item>

          <el-form-item>
            <el-checkbox v-model="value.command.is_save_card"/>
            <span class="property_label" style="display: inline-block">{{ $t('interface_editor.component_editor.button_action.save_card') }}</span>
          </el-form-item>
        </el-form-item>

        <template v-if="value.type === 'open_report'">
          <editor-report :value="value" />
          <editor-filters
            style="margin-top: 10px"
            :value="value.filters"
            :registry-attrs="registryAttrs"
            title="Переменные"
            type="variable"
            @change="$set(value ,'filters', $event)"/>
        </template>

        <template v-if="value.type === 'open_document'">
          <editor-document :value="value" type="document" />
          <el-form-item>
            <span class="property_label">{{ $locale.interface_editor.component_editor.button_action.refresh_selected_components }}</span>
            <el-select v-model="value.reports.componentsGuid" clearable multiple>
              <el-option
                v-for="(item, index) in components"
                :key="index"
                :label="item.group === 'basic' ? item.properties.editorAlias : item.properties.name"
                :value="item.guid">
                <span style="float: left">{{ item.initialType }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{ item.group === 'basic' ? item.properties.editorAlias : item.properties.name }}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <editor-filters
            style="margin-top: 10px"
            :value="value.filters"
            title="Параметры"
            type="variable"
            @change="$set(value ,'filters', $event)"
          />
        </template>

        <template v-if="value.type === 'open_xml'">
          <editor-document :value="value" type="xml" />
          <editor-filters style="margin-top: 10px" :value="value.filters" @change="$set(value ,'filters', $event)"/>
        </template>

        <template v-if="value.type === 'open_json'">
          <editor-document :value="value" type="json" />
          <editor-filters style="margin-top: 10px" :value="value.filters" @change="$set(value ,'filters', $event)"/>
        </template>

        <template v-if="value.type === 'print_page'">
          <editor-print-page @change="$set(value.printSettings, 'settings', $event)" :value="value.printSettings" :containers="containers" />
        </template>

        <template v-if="value.type === 'autofill_fields'">
          <editor-autofill-fields @change="$set(value.autofillSettings, 'settings', $event)" :value="value.autofillSettings" />
        </template>

        <template v-if="value.type === 'open_dashboard'">
          <el-form-item>
            <span class="property_label">{{ $t('interface_editor.component_editor.button_action.dashboard_source_type.type') }}</span>
            <el-radio-group v-model="value.dashboard.dashboardIdSource" size="mini">
              <el-radio-button label="dashboard">{{ $t('interface_editor.component_editor.button_action.dashboard_source_type.dashboard') }}</el-radio-button>
              <el-radio-button label="source" :disabled="!isHeaders">{{ $t('interface_editor.component_editor.button_action.dashboard_source_type.source') }}</el-radio-button>
              <el-radio-button label="component">{{ $t('interface_editor.component_editor.button_action.dashboard_source_type.component') }}</el-radio-button>
            </el-radio-group>
            <div v-if="value.dashboard.dashboardIdSource === 'dashboard'">
              <span class="property_label">{{ $locale.interface_editor.component_editor.button_action.dashbord }}</span>
              <editor-dashboards @change="$set(value.dashboard ,'id', $event)" :value="value.dashboard.id" ></editor-dashboards>
            </div>
            <div v-if="value.dashboard.dashboardIdSource === 'source'">
              <span class="property_label">{{ $t('interface_editor.component_editor.button_action.dashboard_source_type.id_from_source') }}</span>
              <editor-select
                :value="value.dashboard.id"
                @change="$set(value.dashboard ,'id', $event);$set(value.dashboard ,'dashboardIdSource', 'source')"
                :options="{
                  multiple: false,
                  clearable: true,
                  options: registryAttrs
                }"
              ></editor-select>
            </div>
            <div v-if="value.dashboard.dashboardIdSource === 'component'">
              <span class="property_label">{{ $t('interface_editor.component_editor.button_action.dashboard_source_type.id_from_component') }}</span>
              <el-select
                :value="value.dashboard.id"
                @change="$set(value.dashboard ,'id', $event);$set(value.dashboard ,'dashboardIdSource', 'component')"
                clearable
              >
                <el-option
                  v-for="(item, index) in components.filter((cmp) => cmp.properties.name)"
                  :key="index"
                  :label="(item.name.match(/attr_(.*?)_/g) || []).length ? `${item.name}` : `${item.name} ${item.properties.name}`"
                  :value="item.properties.name"
                ></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item>
            <span class="property_label" style="display: inline-block;">{{ $locale.interface_editor.component_editor.editor_open_card.container }}</span>
            <el-tooltip placement="top-start" :open-delay="400">
              <div slot="content" v-html="$locale.interface_editor.component_editor.editor_open_card.tooltip.container"></div>
              <i class="el-icon-question"></i>
            </el-tooltip>
            <el-select v-model="value.dashboard.containerAlias" clearable>
              <el-option
                v-for="(item, index) in containers"
                :key="index"
                :label="item.alias"
                :value="item.alias"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <span class="property_label">{{ $locale.interface_editor.component_editor.button_action.frame }}</span>
            <el-select v-model="value.dashboard.frameGuid" clearable>
              <el-option
                v-for="(item, index) in components.filter((item) => item.name === 'basic/a-frame')"
                :key="index"
                :label="item.name"
                :value="item.guid"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <editor-filters
              :value="value.dashboard.fields"
              :registry-attrs="registryAttrs"
              @change="$set(value.dashboard ,'fields', $event)"
              :title="$locale.main.fields.default"
              :show-xref-option="false"/>
          </el-form-item>

          <el-form-item class="checkbox">
            <el-checkbox v-model="value.dashboard.breadcrumbByButton"></el-checkbox>
            <span class="property_label" style="display: inline-block;">{{ $locale.interface_editor.component_editor.button_action.breadcrumb }}</span>
          </el-form-item>

          <el-form-item class="checkbox">
            <el-checkbox v-model="value.dashboard.isNewTab"></el-checkbox>
            <span class="property_label" style="display: inline-block;">{{ $locale.interface_editor.component_editor.button_action.is_new_tab }}</span>
          </el-form-item>

          <el-form-item v-show="value.dashboard.isNewTab">
            <span class="property_label" style="display: inline-block;">Название вкладки</span>
            <el-input v-model="value.dashboard.newTabName" />
          </el-form-item>

          <el-form-item>
            <span class="property_label">{{ $locale.interface_editor.component_editor.button_action.window_title }}</span>
            <el-input v-model="value.dashboard.window_title"/>
          </el-form-item>

          <el-form-item class="checkbox">
            <el-checkbox v-model="value.dashboard.isFullscreen"></el-checkbox>
            <span class="property_label" style="display: inline-block;">{{ $locale.etl_editor.tooltip.full_screen }}</span>
          </el-form-item>

          <el-form-item>
            <span class="property_label">{{ $locale.interface_editor.component_editor.button_action.window_width }}</span>
            <editor-select
              :value="value.dashboard.window_width"
              @change="$set(value.dashboard ,'window_width', $event)"
              :options="{
                multiple: false,
                options: [
                  { id: '25', name:'25%' },
                  { id: '50', name:'50%' },
                  { id: '75', name:'75%' },
                  { id: '100', name:'100%' },
                ]
              }"
            ></editor-select>

            <span class="property_label">{{ $locale.interface_editor.component_editor.button_action.window_height }} (ex: 400px)</span>
            <el-input :value="value.dashboard.windowHeight" @input="$set(value.dashboard ,'windowHeight', $event); $set(value.card ,'windowHeight', $event)"></el-input>
          </el-form-item>
        </template>

        <el-form-item v-if="value.type === 'save_card'" class="checkbox">
          <el-checkbox v-model="value.saveCard.isCloseAfterSave"></el-checkbox>
          <span class="property_label" style="display: inline-block;">{{ $locale.interface_editor.component_editor.button_action.close_card }}</span>

          <el-form-item>
            <span class="property_label" style="display: inline-block;">{{ $locale.interface_editor.component_editor.button_action.card_close_type }}</span>
            <editor-select
              :value="value.saveCard.close_type"
              @change="$set(value.saveCard ,'close_type', $event)"
              :options="{
                multiple: false,
                options: [
                  { id: 'current_card', name: $locale.interface_editor.component_editor.button_action.current_card },
                  { id: 'parent_card', name: $locale.interface_editor.component_editor.button_action.parent_card }
                ]
              }"
            ></editor-select>
          </el-form-item>
        </el-form-item>

        <el-form-item v-if="value.type === 'open_url'">
          <span class="property_label">{{ $t('interface_editor.component_editor.button_action.url_attr') }}</span>
          <el-select v-model="value.url.urlAttr" clearable>
            <el-option
              v-for="(item, index) in components"
              :key="index"
              :label="item.name"
              :value="item.properties.name"
            >
              <span style="float: left">{{ item.initialType }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.group === 'basic' ? item.properties.editorAlias : item.properties.name }}</span>
            </el-option>
          </el-select>

          <span class="property_label" style="display: inline-block">{{ $locale.interface_editor.component_editor.button_action.url }}</span>
          <el-input v-model="value.url.url"/>

          <span class="property_label">{{ $locale.interface_editor.component_editor.button_action.url_open_type }}</span>
          <el-select v-model="value.url.openType" clearable>
            <el-option
              v-for="(item, index) in urlOpenOptions"
              :key="index"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item v-if="value.type === 'refresh_card'">
          <!-- Пока хз, нужен ли этот функционал, по умолчанию скрыто -->
          <el-form-item v-show="false">
            <el-checkbox v-model="value.refreshCard.isSaveCardBeforeRefresh"></el-checkbox>
            <span class="property_label" style="display: inline-block;">{{ $locale.interface_editor.component_editor.button_action.is_save_card_before_refresh }}</span>
          </el-form-item>

          <el-form-item>
            <span class="property_label">{{ $locale.interface_editor.component_editor.button_action.warn_message.on_edit }}</span>
            <el-input v-model="value.refreshCard.warnMessage.onEdit"/>
          </el-form-item>

          <el-form-item>
            <span class="property_label">{{ $locale.interface_editor.component_editor.button_action.warn_message.on_read }}</span>
            <el-input v-model="value.refreshCard.warnMessage.onRead"/>
          </el-form-item>
        </el-form-item>

        <el-form-item v-if="value.type === 'etl_export'">
          <el-select v-model="value.etl_export.task_id" clearable filterable>
            <el-option
              v-for="(item, index) in etl_tasks"
              :key="index"
              :label="item.name"
              :value="item.id"
            >
              <span style="float: left">{{ item.name }}</span>
              <span style="float: right; color: #030303">id: {{ item.id }}</span>
            </el-option>
          </el-select>

          <editor-filters
            :value="value.etl_export.filters"
            @change="$set(value.etl_export ,'filters', $event)"
            :title="$locale.main.fields.default"
            :show-xref-option="false"/>
        </el-form-item>

        <template v-if="value.type === 'open_window'">
          <el-form-item>
            <span class="property_label">{{ $t('interface_editor.component_editor.button_action.window_settings.choose') }}</span>
            <el-select v-model="value.window.guid" clearable filterable>
              <el-option
                v-for="(item) in windows"
                :key="item.guid"
                :label="item.name"
                :value="item.guid"
              >
                <span style="float: left">{{ item.name }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">
                  {{ `${item.width.value}${item.width.type} x ${item.height.value}${item.height.type}` }}
                </span>
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <span class="property_label">{{ $t('interface_editor.component_editor.button_action.window_settings.title') }}</span>
            <el-input v-model="value.window.title"/>
          </el-form-item>
          <el-form-item>
            <el-checkbox v-model="value.window.popover"/>
            <span class="property_label" style="display: inline-block">{{ $t('interface_editor.component_editor.button_action.window_settings.popover') }}</span>
          </el-form-item>
          <el-form-item>
            <el-checkbox v-model="value.window.isClearWindowModel"/>
            <span class="property_label" style="display: inline-block">{{ $t('interface_editor.component_editor.button_action.window_settings.clear_window_model') }}</span>

            <div v-if="value.window.isClearWindowModel">
              <span class="property_label" style="display: inline-block;">
                {{ $t('interface_editor.component_editor.button_action.window_settings.components_attr') }}
                <el-tooltip placement="top-start" :open-delay="400" style="color: black">
                  <div slot="content">
                    {{ $t('interface_editor.component_editor.button_action.window_settings.clear_window_model_tooltip') }}
                  </div>
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </span>
              <el-input v-model="value.window.componentsAttr"/>
            </div>
          </el-form-item>
        </template>

        <template v-if="value.type === 'refresh_replication'">
          <el-form-item>
            <span class="property_label">{{ $t('interface_editor.component_editor.button_action.refresh_replication_blocks') }}</span>
            <el-select v-model="value.refreshReplication.blocks" multiple clearable>
              <el-option
                v-for="(item, index) in replicationBlocks"
                :key="index"
                :label="item.name"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </template>

        <template v-if="value.type === 'etl_import'">
          <el-form-item>
            <span class="property_label">{{ $t('interface_editor.component_editor.button_action.execute_import') }}</span>
            <el-form-item>
              <el-select v-model="value.etl_import.task_id" clearable filterable>
                <el-option
                  v-for="(item, index) in etl_tasks"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                >
                  <span style="float: left">{{ item.name }}</span>
                  <span style="float: right; color: #030303">id: {{ item.id }}</span>
                </el-option>
              </el-select>

              <span class="property_label">{{ $t('etl_editor.header.constant_fields_filter_title') }}</span>
              <editor-filters
                :value="value.etl_import.fields"
                @change="$set(value.etl_import ,'fields', $event)"
                :title="$locale.main.fields.default"
                :show-xref-option="false"
                :is-import="true"/>
            </el-form-item>
            <el-form-item>
              <span class="property_label">{{ $t('interface_editor.component_editor.button_action.etl_import_commands.on_success_import') }}</span>
              <el-select
                :value="value.etl_import.commandIdAfterSuccess"
                value-key="id"
                filterable
                @change="$set(value.etl_import ,'commandIdAfterSuccess', $event )"
              >
                <el-option
                  v-for="(item, index) in commands"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                >
                  <span style="float: left">{{ item.name + ` (id: ${item.id})` }}</span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <span class="property_label">{{ $t('interface_editor.component_editor.button_action.etl_import_commands.on_failure_import') }}</span>
              <el-select
                :value="value.etl_import.commandIdAfterFailure"
                value-key="id"
                filterable
                @change="$set(value.etl_import ,'commandIdAfterFailure', $event )"
              >
                <el-option
                  v-for="(item, index) in commands"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                >
                  <span style="float: left">{{ item.name + ` (id: ${item.id})` }}</span>
                </el-option>
              </el-select>
            </el-form-item>
<!--            <el-form-item>
              <span class="property_label">{{ $t('interface_editor.component_editor.button_action.etl_import_commands.for_records') }}</span>
              <el-select
                :value="value.etl_import.commandIdForRecords"
                value-key="id"
                filterable
                @change="$set(value.etl_import ,'commandIdForRecords', $event )"
              >
                <el-option
                  v-for="(item, index) in allCommands"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                >
                  <span style="float: left">{{ item.name + ` (id: ${item.id})` }}</span>
                </el-option>
              </el-select>
            </el-form-item>-->
          </el-form-item>
        </template>

        <template v-if="value.type === 'open_tab_in_block'">
          <el-form-item>
            <span class="property_label">{{ $t('interface_editor.component_editor.button_action.block') }}</span>
            <el-select v-model="value.open_tab_in_block.block" clearable>
              <el-option
                v-for="(item) in allBlocks"
                :key="item.guid"
                :label="item.alias"
                :value="item.guid"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <span class="property_label">{{ $t('interface_editor.component_editor.button_action.tab') }}</span>
            <el-select v-model="value.open_tab_in_block.tab" clearable>
              <el-option
                v-for="(item) in block_tabs"
                :key="item.guid"
                :label="item.name"
                :value="item.guid"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-checkbox v-model="value.open_tab_in_block.executeTabAction"/>
            <span class="property_label" style="display: inline-block">{{ $t('interface_editor.component_editor.button_action.execute_tab_action') }}</span>
          </el-form-item>
        </template>

        <template v-if="value.type === 'refresh_components'">
          <el-select v-model="value.refresh_components.components" filterable size="mini" multiple clearable>
            <el-option
              v-for="(item, index) in components.filter((cmp) => cmp.properties.name)"
              :key="index"
              :label="`${item.name} (${item.properties.name})`"
              :value="item.guid"
            ></el-option>
          </el-select>
          <div><em>**Если выбран компонент "реестр", "аналитика" или "таблица" и в фильтрах есть <b>"отложенный фильтр"</b>, то он применится при нажатии.</em></div>
        </template>

        <template v-if="value.type === 'delete_records'">
          <el-radio-group v-model="recordsDeleteType" size="mini" @change="$set(value.delete_records, 'deleteType', $event)">
            <el-radio-button label="component">{{ $t('interface_editor.component_editor.button_action.delete_records_hints.component') }}</el-radio-button>
            <el-radio-button label="current_record">{{ $t('interface_editor.component_editor.button_action.delete_records_hints.current_record') }}</el-radio-button>
          </el-radio-group>
          <el-form-item v-if="recordsDeleteType === 'component'">
            <span class="property_label" style="display: inline-block;">
              {{ $t('interface_editor.component_editor.button_action.dashboard_source_type.component') }}
              <el-tooltip placement="top-start" :open-delay="400" style="color: black">
                <div slot="content">
                  {{ $t('interface_editor.component_editor.button_action.delete_records_hints.allowed_components_hint') }}
                  <p>{{ $t('interface_editor.component_editor.button_action.delete_records_hints.fields_hint') }}</p>
                </div>
                <i class="el-icon-question"></i>
            </el-tooltip>
            </span>
            <el-select v-model="value.delete_records.componentGuid" filterable size="mini" clearable>
              <el-option
                v-for="(item, index) in components.filter((cmp) => deleteRecordsAllowedComponents.includes(cmp.initialType))"
                :key="index"
                :label="`${item.name} (${item.properties.name})`"
                :value="item.guid"
              ></el-option>
            </el-select>

            <span class="property_label">
              {{ $t('interface_editor.component_editor.editor_open_card.source_table_attrs') }}
              <el-tooltip placement="top-start" :open-delay="400" style="color: black">
                <div slot="content">
                  {{ $t('interface_editor.component_editor.button_action.delete_records_hints.attrs_hint') }}
                </div>
                <i class="el-icon-question"></i>
              </el-tooltip>
            </span>
            <editor-select
              ref="delete_record_id"
              :value="value.delete_records.recordId"
              @change="$set(value.delete_records ,'recordId', $event)"
              :options="{
                multiple: false,
                clearable: true,
                options: registryAttrs
              }"
            ></editor-select>

            <span class="property_label">{{ $locale.interface_editor.component_editor.editor_calendar.registry_id }}</span>
            <editor-select
              ref="delete_registry_id"
              :value="value.delete_records.registryId"
              @change="$set(value.delete_records ,'registryId', $event)"
              :options="{
                multiple: false,
                clearable: true,
                options: registryAttrs
              }"
            ></editor-select>
          </el-form-item>
        </template>

        <template v-if="value.type === 'copy_to_clipboard'">
          <span class="property_label" style="display: inline-block;">{{ $t('interface_editor.component_editor.button_action.dashboard_source_type.component') }}</span>
          <el-select v-model="value.copy_to_clipboard.componentGuid" filterable size="mini" clearable>
            <el-option
              v-for="(item, index) in components.filter((cmp) => !copyToClipboardExcludedComponents.includes(cmp.initialType))"
              :key="index"
              :label="`${item.name} (${item.properties.name})`"
              :value="item.guid"
            ></el-option>
          </el-select>
        </template>

        <template v-if="value.type === 'execute_listener'">
          <el-input v-model="value.listeners.listenerName"></el-input>
        </template>

        <template v-if="value.type === 'complex'">
          <span class="property_label" style="display: inline-block;">{{ $t('interface_editor.component_editor.button_action.dashboard_source_type.component') }}</span>

          <el-select v-model="value.copy_to_clipboard.componentGuid" filterable size="mini" clearable>
            <el-option
              v-for="(item, index) in components.filter((cmp) => !copyToClipboardExcludedComponents.includes(cmp.initialType))"
              :key="index"
              :label="`${item.name} (${item.properties.name})`"
              :value="item.guid"
            ></el-option>
          </el-select>
        </template>
      </el-form>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
import EditorSelect from './editor-select'
import EditorDashboards from './editor-dashboards'
import EditorFilters from './editor-filters'
import EditorOpenCard from './editor-open-card.vue'
import EditorDocument from './editor-document'
import EditorReport from './editor-report'
import Icons from '@/components/MenuEditor/components/el-icons'
import EditorPlugins from '@/components/InterfaceEditor/components/editor/editor-plugins'
import Registry from '@/components/Models/EntityModel'
import EditorPrintPage from '@/components/InterfaceEditor/components/editor/editor-print-page.vue'
import EditorAutofillFields from '@/components/InterfaceEditor/components/editor/editor-autofill-fields.vue'

export default {
  name: 'editor-button-action',
  components: {
    EditorPlugins,
    EditorSelect,
    EditorDashboards,
    EditorFilters,
    EditorOpenCard,
    EditorDocument,
    EditorReport,
    EditorPrintPage,
    EditorAutofillFields
  },
  inject: {
    getRegistryId: {
      default: () => null
    },
    getComponents: {
      default: () => {
        return []
      }
    },
    getWindows: {
      default: () => () => []
    },
    getContainersStore: {
      default: () => () => null
    }
  },

  props: {
    value: {
      type: [Array, Object],
      default () {
        return {}
      }
    },
    options: {
      type: Object
    },
    label: {
      type: String
    },
    sourceId: {
      type: [Number, String]
    },
    isHeaders: {
      type: Boolean
    },
    sourceType: {
      type: String
    },
    oldDashboardAction: {
      type: [Object, Array]
    },
    oldActionCard: {
      type: [Object, Array]
    },
    isButton: {
      type: Boolean
    }
  },

  data () {
    return {
      components: this.getComponents(),
      containersStore: this.getContainersStore(),
      commands: [],
      allCommands: [],
      windows: this.getWindows(),
      icons: Icons.map(el => ({ value: el, label: el })),
      urlOpenOptions: [
        {
          name: 'Новая вкладка',
          value: 'newTab'
        },
        {
          name: 'Текущая вкладка',
          value: 'currentTab'
        },
        {
          name: 'Новое окно',
          value: 'newWindow'
        }
      ],
      etl_tasks: null,
      registryAttrs: [],
      block_tabs: [],
      executePluginAllowedActions: [
        'execute_command',
        'execute_plugin'
      ],
      recordsDeleteType: this.value?.delete_records?.deleteType ?? 'current_record',
      deleteRecordsAllowedComponents: ['AnalyticTable/index', 'Registry'],
      copyToClipboardExcludedComponents: ['AnalyticTable/index', 'Registry']
    }
  },

  computed: {
    containers () {
      if (!this.containersStore) {
        return
      }

      return this.containersStore.getFlat().filter(container => container.alias != null)
    },
    replicationBlocks () {
      if (!this.getContainersStore()) {
        return []
      }

      const store = this.getContainersStore()

      const blocks = store.getFlat()

      return blocks.reduce((array, block) => {
        if (block.replication && block.replication !== 'None') {
          array.push({
            name: block.alias || block.guid,
            value: block.guid
          })
        }

        return array
      }, [])
    },
    allBlocks () {
      if (!this.getContainersStore()) {
        return []
      }

      const store = this.getContainersStore()

      return store.getFlat()
    }
  },

  watch: {
    value: {
      handler (value) {
        this.$emit('change', value)
      },
      deep: true
    },
    'value.type': {
      handler (value) {
        this.$set(this.value, 'filters', [])
        if (value === 'execute_command') {
          this.$set(this.value, 'command', {
            id: null,
            success_text: null,
            failure_text: null,
            is_card_close: null,
            close_type: null,
            is_save_card: null,
            is_reopen_card: null,
            componentsGuid: [],
            is_save_card_before_command: false
          })
        } else if (value === 'open_dashboard') {
          this.$set(this.value, 'dashboard', {
            id: null,
            isFullscreen: false,
            window_width: 25,
            windowHeight: null,
            window_title: null,
            breadcrumbByButton: true,
            dashboardIdSource: null
          })
        } else if (value === 'execute_plugin') {
          this.$set(this.value, 'command', {
            is_card_close: null
          })
        } else if (value === 'open_card') {
          this.$set(this.value, 'card', {
            registryId: null,
            recordId: null,
            type: null,
            fieldId: null,
            queryRegistry: null,
            queryCard: null,
            queryRecord: null,
            constantRecordId: null,
            cardId: null,
            isWindow: false,
            windowWidth: 25,
            windowTitle: null,
            defaults: [],
            isFastCard: false,
            componentsGuid: []
          })
          this.$set(this.value, 'refreshReplication', {
            blocks: []
          })
        } else if (value === 'open_report') {
          this.$set(this.value, 'reports', {
            id: null,
            guid: null,
            name: null
          })
        } else if (value === 'open_document') {
          this.$set(this.value, 'reports', {
            id: null,
            guid: null,
            name: null,
            formatType: null,
            viewType: null,
            registryId: null,
            assocFieldId: null,
            openSavedVersion: false,
            reportName: null,
            componentsGuid: []
          })
        } else if (value === 'open_xml') {
          this.$set(this.value, 'reports', {
            id: null,
            guid: null,
            name: null,
            formatType: 'xml',
            viewType: null,
            registryId: null,
            assocFieldId: null,
            openSavedVersion: false
          })
        } else if (value === 'open_json') {
          this.$set(this.value, 'reports', {
            id: null,
            guid: null,
            name: null,
            formatType: 'json',
            viewType: null,
            registryId: null,
            assocFieldId: null,
            openSavedVersion: false
          })
        } else if (value === 'print_page') {
          this.$set(this.value, 'printSettings', {
            id: null,
            guid: null,
            name: null
          })
        } else if (value === 'autofill_fields') {
          this.$set(this.value, 'autofillSettings', {
            id: null,
            guid: null,
            name: null
          })
        } else if (value === 'saveCard') {
          if (!('saveCard' in this.value)) {
            this.$set(this.value, 'saveCard', {
              isCloseAfterSave: false,
              saveCard: null
            })
          }
        } else if (value === 'open_url') {
          if (!('url' in this.value)) {
            this.$set(this.value, 'url', {
              url: null,
              openType: null
            })
          }
        } else if (value === 'refresh_card') {
          if (!('refreshCard' in this.value)) {
            this.$set(this.value, 'refreshCard', {
              warnMessage: {
                onEdit: null,
                onRead: null
              },
              isSaveCardBeforeRefresh: false
            })
          }
        } else if (value === 'etl_export') {
          if (!('etl_export' in this.value)) {
            this.$set(this.value, 'etl_export', {
              etl_export: {
                task_id: null,
                tasks: [],
                filters: []
              }
            })
          }
        } else if (value === 'open_window') {
          if (!('window' in this.value)) {
            this.$set(this.value, 'window', {
              guid: null
            })
          }
        } else if (value === 'refresh_replication') {
          if (!('window' in this.value)) {
            this.$set(this.value, 'refreshReplication', {
              blocks: []
            })
          }
        } else if (value === 'etl_import') {
          this.$set(this.value, 'etl_import', {
            task_id: null,
            fields: [],
            componentsGuid: [],
            commandIdAfterSuccess: null,
            commandIdAfterFailure: null,
            pluginAfterImport: null
          })
        } else if (value === 'open_tab_in_block') {
          this.$set(this.value, 'open_tab_in_block', {
            block: null,
            tab: null,
            executeTabAction: false
          })
        } else if (value === 'refresh_components') {
          this.$set(this.value, 'refresh_components', {
            components: []
          })
        } else if (value === 'delete_records') {
          this.$set(this.value, 'delete_records', {
            isBulkDelete: false,
            recordId: null,
            registryId: null,
            componentGuid: null
          })
        } else if (value === 'copy_to_clipboard') {
          this.$set(this.value, 'copy_to_clipboard', {
            componentGuid: null
          })
        } else if (value === 'execute_listener') {
          this.$set(this.value, 'execute_listener', {
            listenerName: ''
          })
        }
      }
    },
    'value.etl_import.task_id': {
      handler (value) {
        if (value !== null && typeof value === 'number') {
          this.$http.get(`${this.$config.api}/etleditor/task_objects/${value}`)
            .then((response) => {
              if (response.data.loader_object_id) {
                this.$http.get(`${this.$config.api}/v2/logiceditor/logic?entity_id=${response.data.loader_object_id}`)
                  .then((response) => {
                    if (response.data[0]) {
                      this.$http.get(`${this.$config.api}/v2/logiceditor/commands?logic_id=${response.data[0].id}`)
                        .then((res) => {
                          this.commands = res.data
                        })
                    }
                  })
              }
            })
        }
      }
    },
    'value.open_tab_in_block.block': {
      handler (guid) {
        let blockWithTabs = this.getContainersStore().getByGuid(guid)
        this.$set(this.value.open_tab_in_block, 'tab', null)

        if (typeof blockWithTabs === 'undefined' || typeof blockWithTabs.tabs === 'undefined') {
          this.block_tabs = []
        } else {
          this.block_tabs = blockWithTabs.tabs.list
        }
      }
    },
    'value.dashboard.dashboardIdSource': {
      handler (value) {
        this.$set(this.value.dashboard, 'id', null)
      }
    },
    'value.delete_records.componentGuid': {
      handler (value) {
        this.$set(this.value.delete_records, 'recordId', null)
        this.$set(this.value.delete_records, 'registryId', null)
        if (this.$refs.delete_record_id || this.$refs.delete_registry_id) {
          this.$refs.delete_record_id.clearValue()
          this.$refs.delete_registry_id.clearValue()
        }
        this.determineDeleteRecordsActionEntitySource(this.value.delete_records)
      }
    }
  },

  created () {
    this.restoreInvalidDataStructure()
  },

  async mounted () {
    if (this.getRegistryId()) {
      this.$http.get(`${this.$config.api}/v2/logiceditor/logic?entity_id=${this.getRegistryId()}`)
        .then((response) => {
          if (response.data[0]) {
            this.$http.get(`${this.$config.api}/v2/logiceditor/commands?logic_id=${response.data[0].id}`)
              .then((res) => {
                this.commands = res.data
              })
          }
        })
    } else {
      this.$http.get(`${this.$config.api}/v2/logiceditor/commands`)
        .then((res) => {
          this.commands = res.data
          this.allCommands = res.data
        })
    }
    this.$http.get(`${this.$config.api}/etleditor/tasks?order=name:asc`)
      .then((response) => {
        this.etl_tasks = response.data
      })

    await this.determineEntitySourceType(this.sourceId, this.sourceType)

    if (this.value.type === 'open_tab_in_block') {
      let blockWithTabs = this.getContainersStore().getByGuid(this.value.open_tab_in_block.block)

      if (typeof blockWithTabs === 'undefined' || typeof blockWithTabs.tabs === 'undefined') {
        this.block_tabs = []
      } else {
        this.block_tabs = blockWithTabs.tabs.list
      }
    }

    if (this.value.type === 'delete_records') {
      await this.determineDeleteRecordsActionEntitySource(this.value.delete_records)
    }
  },

  methods: {
    restoreInvalidDataStructure () {
      if (Array.isArray(this.value)) {
        this.$emit('change', Object.assign({}, this.value))
      }
      if (!('saveCard' in this.value)) {
        this.$set(this.value, 'saveCard', {
          isCloseAfterSave: false
        })
      }
      if (this.value.type === 'open_card') {
        if (!('refreshReplication' in this.value)) {
          this.$set(this.value, 'refreshReplication', {
            blocks: []
          })
        }
      }
      if (this.value.type === 'open_dashboard') {
        if (!this.value.dashboard.dashboardIdSource) {
          this.$set(this.value.dashboard, 'dashboardIdSource', 'dashboard')
        }
      }
    },

    async getRegistryAttrsRecursive (entities) {
      let data = []
      let me = this

      entities.map(async function (entity) {
        if (entity.has_children && entity.entity_type_id === 'field_group') {
          let temp = await me.getRegistryAttrsRecursive(entity.children)
          temp.map(function (item) {
            data.push(item)
          })
        } else {
          let attr = `attr_${entity.id}_`
          data.push({
            id: attr,
            name: attr
          })
        }
      })

      data.filter(item => {
        return item !== undefined
      })

      return data
    },
    /**
     * Хелпер действия delete_records
     * @returns {Promise<void>}
     */
    async determineDeleteRecordsActionEntitySource (action) {
      let sourceComponent = this.components.find((item) => item.guid === action.componentGuid)
      let sourceId, sourceType

      if (sourceComponent) {
        this.$set(this.value.delete_records, 'componentType', sourceComponent.initialType)
      }

      if (sourceComponent?.properties?.hasOwnProperty('registryId') && sourceComponent.properties.registryId) {
        sourceId = sourceComponent.properties.registryId
        sourceType = 'registry'
        console.log('delete type - registry')
      } else if (sourceComponent?.properties?.hasOwnProperty('source') && sourceComponent.properties.source.type === 'query') {
        sourceId = sourceComponent.properties.source.entityId
        sourceType = sourceComponent.properties.source.type
        console.log('delete type - query')
      } else if (sourceComponent?.properties?.hasOwnProperty('source') && sourceComponent.properties.source.type === 'extended_object') {
        sourceId = sourceComponent.properties.source.entityId
        sourceType = sourceComponent.properties.source.type
        console.log('delete type - extended_object')
      }

      await this.determineEntitySourceType(sourceId, sourceType)
    },

    /**
     * @param sourceId <number>
     * @param sourceType <string>
     * @returns {Promise<void>}
     */
    async determineEntitySourceType (sourceId, sourceType) {
      if (typeof sourceType === 'string') {
        sourceType = sourceType.toLowerCase()
      }
      if (sourceId && sourceType === 'registry') {
        let response = await Registry.params({ parent_id: sourceId, show_children: true }).$get()
        this.registryAttrs = await this.getRegistryAttrsRecursive(response)
      } else if (sourceId && sourceType === 'query') {
        await this.$http.post(
          `${this.$config.api}/datawarehouseservice/query/${sourceId}`,
          { limit: 1 },
          { hideNotification: true }
        ).then(response => {
          this.registryAttrs = Object.entries(
            response.data[0]
          ).map(function (item) {
            return { id: item[0], name: item[0] }
          }).filter(item => {
            return item !== undefined
          })
        })
      } else if (sourceId && sourceType === 'extended_object') {
        let response = await this.$http.get(`${this.$config.api}/bieditor/extended_objects/${sourceId}`,
          null,
          { hideNotification: true }
        )
        this.registryAttrs = response.data.extended_object_fields.map(function (item) { return { id: item.name, name: item.name } })
      }
    }
  }
}
</script>

<style type="text/css">
  .el-select {
    width: 100%;
  }
  .editor_button .checkbox .el-form-item__content {
    display: inline-block;
}
</style>
