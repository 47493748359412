import { FluxStandardAction } from '@/core/domain/type/types'
import { ApprovalDTO } from '@/services/LogicEditor/domain/model/Approval'

export enum ActionTypes {
  GET_APPROVAL_STAGES = 'get_approval_stages',
  GET_APPROVAL_BY_GUID = 'get_approval_by_guid',
  CREATE_APPROVAL = 'create_approval',
  UPDATE_APPROVAL = 'update_approval',
  DELETE_APPROVAL = 'delete_approval'
}

export class GetApprovalStagesByApprovalId implements FluxStandardAction {
  type = 'Approval/' + ActionTypes.GET_APPROVAL_STAGES
  constructor (public approvalId: number, public payload: string[][] | object) {}
}

export class GetApprovalByGuid implements FluxStandardAction {
  type = 'Approval/' + ActionTypes.GET_APPROVAL_BY_GUID
  constructor (public guid: string) {}
}

export class CreateApproval implements FluxStandardAction {
  type = 'Approval/' + ActionTypes.CREATE_APPROVAL
  constructor (public payload: ApprovalDTO) {}
}

export class UpdateApproval implements FluxStandardAction {
  type = 'Approval/' + ActionTypes.UPDATE_APPROVAL
  constructor (public payload: ApprovalDTO) {}
}

export class DeleteApproval implements FluxStandardAction {
  type = 'Approval/' + ActionTypes.DELETE_APPROVAL
  constructor (public guid: string) {}
}
