<template>
  <div class="expression-builder">
    <expression-builder-group
      v-model="modelValue"
      :index="0"
      :maxDepth="maxDepth"
      :depth="depth"
      :size-control="sizeControl"
      :value-types="valueTypes"
      :operators="operators"
    ></expression-builder-group>
  </div>
</template>

<script>
import Vue from 'vue'
import ExpressionBuilderGroup from './ExpressionBuilderGroup.vue'
import { cloneObject } from '@/helpers'

export default Vue.extend({
  name: 'ExpressionBuilder',

  components: {
    ExpressionBuilderGroup
  },

  props: {
    value: {
      type: Object,
      default () {
        return {
          logicalOperator: 'and',
          children: []
        }
      }
    },

    valueTypes: {
      type: Array,
      default () {
        return [
          { value: 'attribute', label: 'Attribute', input: 'string' },
          { value: 'constant', label: 'Constant', input: 'string' }
        ]
      }
    },

    maxDepth: {
      type: Number,
      default: 5,
      validator: function (value) {
        return value >= 1
      }
    },

    sizeControl: {
      type: String,
      default: 'mini'
    },

    operators: {
      type: Array,
      default () {
        return [
          { value: 'eq', label: 'Equals' },
          { value: 'neq', label: 'Not Equals' },
          { value: 'in', label: 'In' },
          { value: 'notIn', label: 'Not In' },
          { value: 'gt', label: 'Gt' },
          { value: 'gte', label: 'Gte' },
          { value: 'lt', label: 'Lt' },
          { value: 'lte', label: 'Lte' }
        ]
      }
    }
  },

  watch: {
    modelValue: {
      handler: function (value) {
        if (JSON.stringify(value) !== JSON.stringify(this.value)) {
          this.$emit('input', cloneObject(value))
        }
      },
      deep: true
    },

    value: {
      handler: function (value) {
        if (JSON.stringify(value) !== JSON.stringify(this.modelValue)) {
          this.modelValue = cloneObject(value)
        }
      },
      deep: true
    }
  },

  data () {
    return {
      depth: 1,

      modelValue: this.value || {
        logicalOperator: 'and',
        children: []
      }
    }
  }
})
</script>

<style lang="scss">
.expression-builder {
  .vqb-label {
    display: block;
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 10px;
    box-sizing: border-box;
    width: 100%;
    color: #606266;
  }

  & > .el-textarea .el-textarea__inner {
    font-size: 14px;
    font-weight: 400;
    color: #409EFF;
  }

  & .vqb-group {
    padding: 5px 20px;
    position: relative;
    background-color: #f5f5f5;
    border-left: 2px solid #909090;

    & label {
      color: #ff5722;
    }

    & .rule-actions {
      margin-bottom: 20px;
    }

    &:before {
      content: " ";
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 5px solid #d2d45e;
      position: absolute;
      left: 0;
      top: 13px;
    }
  }

  & .vqb-rule {
    margin-top: 15px;
    margin-bottom: 15px;
    border-color: #ddd;
    padding: 15px;
    position: relative;
    background-color: #ececec;
    border-left: 2px solid #ddd;

    & label {
      margin-right: 10px;
      color: #409eff;
    }

    &:before {
      content: " ";
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 5px solid #ddd;
      position: absolute;
      left: 0;
      top: 22px;
    }
  }

  & .vqb-rule,
  & .vqb-group {
    padding-right: 0;
  }

  & .vqb-group.depth-1 {
    border-left: 2px solid #fcbe53;

    &:before {
      border-left-color: #fcbe53;
    }
  }

  & .vqb-group.depth-1 .vqb-rule,
  & .vqb-group.depth-2 {
    border-left: 2px solid #d2d45e;

    &:before {
      border-left-color: #d2d45e;
    }
  }

  & .vqb-group.depth-2 .vqb-rule,
  & .vqb-group.depth-3 {
    border-left: 2px solid #8bc34a;

    &:before {
      border-left-color: #8bc34a;
    }
  }

  & .vqb-group.depth-3 .vqb-rule,
  & .vqb-group.depth-4 {
    border-left: 2px solid #20bae9;

    &:before {
      border-left-color: #20bae9;
    }
  }

  & .vqb-group.depth-4 .vqb-rule,
  & .vqb-group.depth-5 {
    border-left: 2px solid #0071d4;

    &:before {
      border-left-color: #0071d4;
    }
  }

  & .vqb-group.depth-5 .vqb-rule {
    border-left: 2px solid #ff5722;

    &:before {
      border-left-color: #ff5722;
    }
  }

  & .close {
    opacity: 1;
    color: rgb(150,150,150);
  }

  & div:not(.input-group) .el-input-number {
    width: 100%;
  }

  & .vqb-child {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
</style>
