<template>
  <el-radio :label="index" border>
      <p>
        <span>{{
          certificate.Subject.O ? certificate.Subject.O : "--------"
        }}</span>
      </p>
      <p>Издатель: {{ certificate.Issuer.CN }}</p>
      <p>Кому выдан: {{ certificate.Subject.CN }}</p>
      <p>{{ certificate.Subject.SN ? ('Ответственный: ' + certificate.Subject.SN + ' ' + certificate.Subject.G) : '' }}</p>
      <p>
        Действителен: с {{ certificate.ValidFromDate }} по
        {{ certificate.ValidToDate }}
      </p>
      <p style="color: red"> {{ $locale.logic_editor_v2.certificate_types[certificate.Type] }}  </p>
  </el-radio>
</template>

<script>
export default {
  props: ['certificate', 'index']
}
</script>

<style scoped>
.el-radio.is-bordered+.el-radio.is-bordered {
     margin-left: 0;
}
</style>
