import Vue from 'vue'
import openCardMixin from '@/components/InterfaceEditor/components/openCard_mixin'
import clickMixin from '@/core/infrastructure/components/Grid/infrastructure/components/CellRenderer/clickMixin'
export default Vue.extend({
  mixins: [openCardMixin, clickMixin],
  name: 'stringField',
  template: `
        <div v-loading="loading" :class="{'hover_effect': clickTable}" :style="this.params.colDef.cssStyle">
          <span @[event]="btnClickedHandler">
            {{this.params.value}}
          </span>
      </div>
    `,
  mounted () {
  }
})
