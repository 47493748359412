<template>
  <div>
    <span class="label">
      {{ label }}
    </span>
    <el-input
      style="width: 100%"
      size="mini"
      v-model="model"
      :placeholder="placeholder"
      :trigger-on-focus="false"
      value-key="value"
      :disabled="disabled"
      @change="$emit('change', model)"
    >
      <template slot-scope="{ item }">
        {{ item.value }}
      </template>
    </el-input>
  </div>
</template>

<script>
export default {
  name: 'text-field',
  inject: ['getPreviousValue'],
  props: {
    label: {
      type: String
    },
    type: {
      type: String
    },
    placeholder: {
      type: String,
      default: null
    },
    value: {
      type: String
    }
  },
  data () {
    return {
      model: null
    }
  },
  watch: {
    value: {
      deep: true,
      handler (newValue) {
        this.model = newValue
        this.$emit('input', this.model)
      }
    }
  },
  computed: {
    disabled () {
      return !(this.previousValue) || this.disabledByExistingAddress
    },
    previousValue () {
      return this.getPreviousValue()
    },
    disabledByExistingAddress () {
      if (this.previousValue && this.previousValue.is_added_address) {
        return true
      }
      return false
    },
  }

}
</script>

<style scoped>
.label {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  color: #7D7D7D;
  display: block;
  padding: 5px 5px 0px 5px;
}
</style>
