<template>
  <el-collapse class="editor-wrapper" value="editor-input-label">
    <el-collapse-item :title="$t('interface_editor.component_editor.editor_gantt_bryntum.calendar_source')" name="editor-tasks-source">
      <el-form>
        <!-- источник -->
        <el-collapse-item
          :title="$t('interface_editor.component_editor.editor_gantt_bryntum.source')"
          name="editor-source">
          <el-form-item>
            <span class="property_label">{{ $t('interface_editor.component_editor.table.source_type') }}</span>
            <editor-select
              :value="value.taskSource.entityType"
              @change="$set(value.taskSource, 'entityType', $event)"
              :options="{
              multiple: false,
               options: dataSourceTypes
            }"
            ></editor-select>
          </el-form-item>
          <el-form-item v-if="value.taskSource.entityType === 'registry'">
            <registry-select
              :append-to-body="true"
              :label="$t('interface_editor.component_editor.table.registry')"
              :value="value.taskSource.entityId"
              @change="$set(value.taskSource, 'entityId', $event)"
            ></registry-select>
          </el-form-item>
          <el-form-item v-if="value.taskSource.entityType === 'query'">
            <span class="property_label">{{ $t('interface_editor.component_editor.table.request') }}</span>
            <editor-select
              :value="value.taskSource.entityId"
              @change="$set(value.taskSource ,'entityId', $event)"
              :options="{
                multiple: false,
                options: entityTypeData
              }"
            ></editor-select>
          </el-form-item>
        </el-collapse-item>
        <!-- свойства сопоставления -->
        <el-collapse-item
          :title="$t('interface_editor.component_editor.editor_gantt_bryntum.comparison')"
          name="comparison_task"
        >
          <!--Ключевое поле-->
          <el-form-item>
            <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.key_field') }}</span>
            <editor-select
              :value="value.taskSource.keyField"
              @change="$set(value.taskSource ,'keyField', $event)"
              :options="{
            multiple: false,
            options: attrs,
            clearable: true
          }"
            ></editor-select>
          </el-form-item>
          <!--id потомка -->
          <el-form-item>
            <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.parent_field') }}</span>
            <editor-select
              :value="value.taskSource.parentIdField"
              @change="$set(value.taskSource ,'parentIdField', $event)"
              :options="{
            multiple: false,
            options: attrs,
            clearable: true
          }"
            ></editor-select>
          </el-form-item>
          <!-- название -->
          <el-form-item>
            <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.name') }}</span>
            <editor-select
              :value="value.taskSource.nameField"
              @change="$set(value.taskSource ,'nameField', $event)"
              :options="{
            multiple: false,
            options: attrs,
            clearable: true
          }"
            ></editor-select>
          </el-form-item>
          <!-- процент выполнения -->
          <el-form-item>
            <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.percent_done') }}</span>
            <editor-select
              :value="value.taskSource.percentDoneField"
              @change="$set(value.taskSource ,'percentDoneField', $event)"
              :options="{
            multiple: false,
            options: attrs,
            clearable: true
          }"
            ></editor-select>
          </el-form-item>
          <!-- длительность -->
          <el-form-item>
            <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.duration') }}</span>
            <editor-select
              :value="value.taskSource.duration"
              @change="$set(value.taskSource ,'duration', $event)"
              :options="{
            multiple: false,
            options: attrs,
            clearable: true
          }"
            ></editor-select>
          </el-form-item>
          <!-- трудозатраты -->
          <el-form-item>
            <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.effort') }}</span>
            <editor-select
              :value="value.taskSource.effort"
              @change="$set(value.taskSource ,'effort', $event)"
              :options="{
            multiple: false,
            options: attrs,
            clearable: true
          }"
            ></editor-select>
          </el-form-item>
          <!-- тип ограничения -->
          <el-form-item>
            <div>
              <span>{{ $t('interface_editor.component_editor.editor_gantt_bryntum.constraint_type') }}</span>
              <el-tooltip class="item" effect="dark" :content="tooltips.constraintType">
                <i class="el-icon-question"></i>
              </el-tooltip>
            </div>
            <editor-select
              :value="value.taskSource.constraintType"
              @change="$set(value.taskSource ,'constraintType', $event)"
              :options="{
            multiple: false,
            options: attrs,
            clearable: true
          }"
            ></editor-select>
          </el-form-item>
          <!-- дата ограничения -->
          <el-form-item>
            <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.constraint_date') }}</span>
            <editor-select
              :value="value.taskSource.constraintDate"
              @change="$set(value.taskSource ,'constraintDate', $event)"
              :options="{
            multiple: false,
            options: attrs,
            clearable: true
          }"
            ></editor-select>
          </el-form-item>
          <!-- ручное планирование -->
          <el-form-item>
            <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.manually_scheduled') }}</span>
            <editor-select
              :value="value.taskSource.isManuallyScheduledField"
              @change="$set(value.taskSource ,'isManuallyScheduledField', $event)"
              :options="{
            multiple: false,
            options: attrs,
            clearable: true
          }"
            ></editor-select>
          </el-form-item>
          <!-- не активна -->
          <el-form-item>
            <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.inactive') }}</span>
            <editor-select
              :value="value.taskSource.inactive"
              @change="$set(value.taskSource ,'inactive', $event)"
              :options="{
            multiple: false,
            options: attrs,
            clearable: true
          }"
            ></editor-select>
          </el-form-item>
          <!-- порядковый номер -->
          <el-form-item>
            <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.index_number') }}</span>
            <editor-select
              :value="value.taskSource.indexNumber"
              @change="$set(value.taskSource ,'indexNumber', $event)"
              :options="{
            multiple: false,
            options: attrs,
            clearable: true
          }"
            ></editor-select>
          </el-form-item>
          <!-- иерархический номер -->
          <el-form-item>
            <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.hierarchy_number') }}</span>
            <editor-select
              :value="value.taskSource.hierarchyNumber"
              @change="$set(value.taskSource ,'hierarchyNumber', $event)"
              :options="{
            multiple: false,
            options: attrs,
            clearable: true
          }"
            ></editor-select>
          </el-form-item>
          <!-- Последователи -->
          <el-form-item>
            <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.successors') }}</span>
            <editor-select
              :value="value.taskSource.successors"
              @change="$set(value.taskSource ,'successors', $event)"
              :options="{
            multiple: false,
            options: attrs,
            clearable: true
          }"
            ></editor-select>
          </el-form-item>
          <!-- Предшественники -->
          <el-form-item>
            <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.predecessors') }}</span>
            <editor-select
              :value="value.taskSource.predecessors"
              @change="$set(value.taskSource ,'predecessors', $event)"
              :options="{
            multiple: false,
            options: attrs,
            clearable: true
          }"
            ></editor-select>
          </el-form-item>
          <!-- Статус -->
          <el-form-item>
            <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.status') }}</span>
            <editor-select
              :value="value.taskSource.status"
              @change="$set(value.taskSource ,'status', $event)"
              :options="{
            multiple: false,
            options: attrs,
            clearable: true
          }"
            ></editor-select>
          </el-form-item>
          <!-- граница проекта -->
          <el-form-item>
            <div>
              <span>{{ $t('interface_editor.component_editor.editor_gantt_bryntum.project_constraint_resolution') }}</span>
              <el-tooltip class="item" effect="dark" :content="tooltips.projectConstraintResolution">
                <i class="el-icon-question"></i>
              </el-tooltip>
            </div>
            <editor-select
              :value="value.taskSource.projectConstraintResolution"
              @change="$set(value.taskSource ,'projectConstraintResolution', $event)"
              :options="{
            multiple: false,
            options: attrs,
            clearable: true
          }"
            ></editor-select>
          </el-form-item>
        </el-collapse-item>
        <el-collapse-item
          :title="$t('interface_editor.component_editor.editor_gantt_bryntum.dates')"
          name="dates"
        >
          <!--Плановая дата начала-->
          <el-form-item>
            <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.plan_start_date') }}</span>
            <editor-select
              :value="value.taskSource.planStartDate"
              @change="$set(value.taskSource, 'planStartDate', $event)"
              :options="{
                multiple: false,
                options: attrs,
            clearable: true
              }"
            ></editor-select>
          </el-form-item>
          <!--Плановая дата окончания-->
          <el-form-item>
            <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.plan_end_date') }}</span>
            <editor-select
              :value="value.taskSource.planEndDate"
              @change="$set(value.taskSource, 'planEndDate', $event)"
              :options="{
                multiple: false,
                options: attrs,
            clearable: true
              }"
            ></editor-select>
          </el-form-item>
          <!--Фактическая дата начала-->
          <el-form-item>
            <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.fact_start_date') }}</span>
            <editor-select
              :value="value.taskSource.factStartDate"
              @change="$set(value.taskSource, 'factStartDate', $event)"
              :options="{
                multiple: false,
                options: attrs,
            clearable: true
              }"
            ></editor-select>
          </el-form-item>
          <!--Фактическая дата окончания-->
          <el-form-item>
            <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.fact_end_date') }}</span>
            <editor-select
              :value="value.taskSource.factEndDate"
              @change="$set(value.taskSource, 'factEndDate', $event)"
              :options="{
                multiple: false,
                options: attrs,
            clearable: true
              }"
            ></editor-select>
          </el-form-item>
        </el-collapse-item>
        <!-- фильтры календарной части -->
        <el-collapse-item
          :title="$t('interface_editor.component_editor.editor_gantt_bryntum.filters')"
          name="editor_filters"
        >
          <editor-filters
            :value="value.taskSource.filters"
            @change="$set(value.taskSource ,'filters', $event)"
          ></editor-filters>
        </el-collapse-item>
      </el-form>
    </el-collapse-item>
    <!-- табличная часть -->
    <el-collapse-item
      :title="$t('interface_editor.component_editor.editor_gantt_bryntum.table_source')"
      name="editor-table-source">
      <el-form-item>
        <span class="property_label">{{ $t('interface_editor.component_editor.table.column') }}</span>
        <editor-registry-headers
          :is-gantt="true"
          :value="value.tableSource.columns"
          :otherProperties="{list: {}}" :options="{ objectId: value.taskSource.entityId }"
          @change="$set(value.tableSource, 'columns', $event)"
        ></editor-registry-headers>
      </el-form-item>
    </el-collapse-item>
    <!-- Таблица связей -->
    <el-collapse-item
      :title="$t('interface_editor.component_editor.editor_gantt_bryntum.tasks_relation')"
      name="editor_relations"
    >
      <el-form-item>
        <span class="property_label">{{ $t('interface_editor.component_editor.table.source_type') }}</span>
        <editor-select
          :value="value.tasksRelation.entityType"
          @change="$set(value.tasksRelation, 'entityType', $event)"
          :options="{
              multiple: false,
               options: dataSourceTypes,
            clearable: true
            }"
        ></editor-select>
      </el-form-item>
      <el-form-item v-if="value.tasksRelation.entityType === 'registry'">
        <registry-select
          :append-to-body="true"
          :label="$t('interface_editor.component_editor.table.registry')"
          :value="value.tasksRelation.entityId"
          @change="$set(value.tasksRelation, 'entityId', $event)"
        ></registry-select>
      </el-form-item>
      <!-- ключевое поле -->
      <el-form-item>
        <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.key_field') }}</span>
        <editor-select
          :value="value.tasksRelation.keyField"
          @change="$set(value.tasksRelation ,'keyField', $event)"
          :options="{
            multiple: false,
            options: relationAttrs,
            clearable: true
          }"
        ></editor-select>
      </el-form-item>
      <!-- id откуда -->
      <el-form-item>
        <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.parent_field') }}</span>
        <editor-select
          :value="value.tasksRelation.fromAttr"
          @change="$set(value.tasksRelation ,'fromAttr', $event)"
          :options="{
            multiple: false,
            options: relationAttrs,
            clearable: true
          }"
        ></editor-select>
      </el-form-item>
      <!-- id куда -->
      <el-form-item>
        <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.child') }}</span>
        <editor-select
          :value="value.tasksRelation.toAttr"
          @change="$set(value.tasksRelation ,'toAttr', $event)"
          :options="{
            multiple: false,
            options: relationAttrs,
            clearable: true
          }"
        ></editor-select>
      </el-form-item>
      <!-- тип связи -->
      <el-form-item>
        <div>
          <span>{{ $t('interface_editor.component_editor.editor_gantt_bryntum.relation_type') }}</span>
          <el-tooltip class="item" effect="dark" :content="tooltips.relationType">
            <i class="el-icon-question"></i>
          </el-tooltip>
        </div>
        <div>
          <editor-select
            :value="value.tasksRelation.type"
            @change="$set(value.tasksRelation ,'type', $event)"
            :options="{
            multiple: false,
            options: relationAttrs,
            clearable: true
          }"
          ></editor-select>
        </div>
      </el-form-item>
      <!-- фильтры связей -->
      <el-collapse-item
        :title="$t('interface_editor.component_editor.editor_gantt_bryntum.filters')"
        name="editor_filters"
      >
        <editor-filters
          :value="value.tasksRelation.filters"
          @change="$set(value.tasksRelation ,'filters', $event)"
        ></editor-filters>
      </el-collapse-item>
      <!-- значения по умолчанию -->
      <el-form-item>
        <editor-filters
          :value="value.defaultValuesForRelations"
          @change="$set(value ,'defaultValuesForRelations', $event)"
          :title="$t('main.fields.default')"
          :show-xref-option="false"/>
      </el-form-item>
    </el-collapse-item>
    <!-- Ресурсы (таблица для сохранения) -->
    <el-collapse-item
      :title="$t('interface_editor.component_editor.editor_gantt_bryntum.resource_relations')"
      name="editor_resource_relations"
    >
      <el-form-item>
        <span class="property_label">{{ $t('interface_editor.component_editor.table.source_type') }}</span>
        <editor-select
          :value="value.resourceRelations.entityType"
          @change="$set(value.resourceRelations, 'entityType', $event)"
          :options="{
              multiple: false,
              options: dataSourceTypes,
              clearable: true
            }"
        ></editor-select>
      </el-form-item>
      <el-form-item v-if="value.resourceRelations.entityType === 'registry'">
        <registry-select
          :append-to-body="true"
          :label="$t('interface_editor.component_editor.table.registry')"
          :value="value.resourceRelations.entityId"
          @change="$set(value.resourceRelations, 'entityId', $event)"
        ></registry-select>
      </el-form-item>
      <!-- ключевое поле -->
      <el-form-item>
        <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.key_field') }}</span>
        <editor-select
          :value="value.resourceRelations.keyField"
          @change="$set(value.resourceRelations ,'keyField', $event)"
          :options="{
            multiple: false,
            options: resourceRelationAttrs,
            clearable: true
          }"
        ></editor-select>
      </el-form-item>
      <!-- ссылка на задачу -->
      <el-form-item>
        <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.task') }}</span>
        <editor-select
          :value="value.resourceRelations.taskId"
          @change="$set(value.resourceRelations ,'taskId', $event)"
          :options="{
            multiple: false,
            options: resourceRelationAttrs,
            clearable: true
          }"
        ></editor-select>
      </el-form-item>
      <!-- ссылка на ссправочник ресурсов -->
      <el-form-item>
        <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.resource_reference') }}</span>
        <editor-select
          :value="value.resourceRelations.resourceReferenceId"
          @change="$set(value.resourceRelations ,'resourceReferenceId', $event)"
          :options="{
            multiple: false,
            options: resourceRelationAttrs,
            clearable: true
          }"
        ></editor-select>
      </el-form-item>
      <!-- занятость в задаче -->
      <el-form-item>
        <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.units') }}</span>
        <editor-select
          :value="value.resourceRelations.units"
          @change="$set(value.resourceRelations ,'units', $event)"
          :options="{
            multiple: false,
            options: resourceRelationAttrs,
            clearable: true
          }"
        ></editor-select>
      </el-form-item>
    </el-collapse-item>
    <!-- Ресурсы  -->
    <el-collapse-item
      :title="$t('interface_editor.component_editor.editor_gantt_bryntum.resources')"
      name="editor_resources">
      <el-form-item>
        <span class="property_label">{{ $t('interface_editor.component_editor.table.source_type') }}</span>
        <editor-select
          :value="value.resources.entityType"
          @change="$set(value.resources, 'entityType', $event)"
          :options="{
              multiple: false,
              options: dataSourceTypes,
              clearable: true
            }"
        ></editor-select>
      </el-form-item>
      <el-form-item v-if="value.resources.entityType === 'registry'">
        <registry-select
          :append-to-body="true"
          :label="$t('interface_editor.component_editor.table.registry')"
          :value="value.resources.entityId"
          @change="$set(value.resources, 'entityId', $event)"
        ></registry-select>
      </el-form-item>
      <!-- ключевое поле -->
      <el-form-item>
        <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.key_field') }}</span>
        <editor-select
          :value="value.resources.keyField"
          @change="$set(value.resources ,'keyField', $event)"
          :options="{
            multiple: false,
            options: resourceAttrs,
            clearable: true
          }"
        ></editor-select>
      </el-form-item>
      <!-- название -->
      <el-form-item>
        <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.name') }}</span>
        <editor-select
          :value="value.resources.name"
          @change="$set(value.resources ,'name', $event)"
          :options="{
            multiple: false,
            options: resourceAttrs,
            clearable: true
          }"
        ></editor-select>
      </el-form-item>
      <!-- урл картинки -->
      <el-form-item>
        <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.image_url') }}</span>
        <editor-select
          :value="value.resources.imageUrl"
          @change="$set(value.resources ,'imageUrl', $event)"
          :options="{
            multiple: false,
            options: resourceAttrs,
            clearable: true
          }"
        ></editor-select>
      </el-form-item>
      <!-- Владелец ресурса -->
      <el-form-item>
        <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.resource_owner') }}</span>
        <editor-select
          :value="value.resources.resourceOwner"
          @change="$set(value.resources ,'resourceOwner', $event)"
          :options="{
            multiple: false,
            options: resourceAttrs,
            clearable: true
          }"
        ></editor-select>
      </el-form-item>
      <!-- Тип ресурса -->
      <el-form-item>
        <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.resource_type') }}</span>
        <editor-select
          :value="value.resources.resourceType"
          @change="$set(value.resources ,'resourceType', $event)"
          :options="{
            multiple: false,
            options: resourceAttrs,
            clearable: true
          }"
        ></editor-select>
      </el-form-item>
      <!-- фильтры календарной части -->
      <el-collapse-item
        :title="$t('interface_editor.component_editor.editor_gantt_bryntum.filters')"
        name="editor_resource_filters"
      >
        <editor-filters
          :value="value.resources.filters"
          @change="$set(value.resources ,'filters', $event)"
        ></editor-filters>
      </el-collapse-item>
    </el-collapse-item>
    <!-- Базовые настройки -->
    <el-collapse-item
      :title="$t('interface_editor.component_editor.editor_gantt_bryntum.base_settings')"
      name="editor_gantt_settings"
    >
      <!-- редактирование диаграммы -->
      <el-form-item>
        <el-checkbox v-model="value.isDiagramEditing"/>
        <span class="property_label" style="display: inline-block;">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.is_diagram_editing') }}</span>
      </el-form-item>
      <!-- раскрытие дерева задач по умолчанию -->
      <el-form-item>
        <el-checkbox v-model="value.isTaskTreeExpanded"/>
        <span class="property_label" style="display: inline-block;">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.is_task_tree_expanded') }}</span>
      </el-form-item>
      <el-form-item>
        <editor-filters
          :value="value.defaultValuesForTask"
          @change="$set(value ,'defaultValuesForTask', $event)"
          :title="$t('main.fields.default')"
          :show-xref-option="false"/>
      </el-form-item>
      <!-- Действия при клике внутри настроек -->
      <el-form-item>
        <editor-button-action
          :value='value.chartItemAction'
          :label="$t('interface_editor.component_editor.editor_gantt_new.chart_item_action')"
          :source-id="value.chartItemAction.card.registryId"
          source-type="registry"
          :is-headers="true"
          @change="$set(value, 'chartItemAction', $event)">
        </editor-button-action>
      </el-form-item>
      <!-- сохранение всех строк диаграммы при сохранении карточки -->
      <el-form-item>
        <el-checkbox v-model="value.isSaveGanttTasksWhenSavingCard"/>
        <span class="property_label" style="display: inline-block;">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.is_save_gantt_tasks_when_saving_card') }}</span>
      </el-form-item>
      <!-- дефолтные столбцы ганта -->
      <el-form-item>
        <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.default_columns') }}</span>
        <editor-select
          :value="value.defaultColumns"
          @change="$set(value, 'defaultColumns', $event)"
          :options="{
              multiple: true,
              options: defaultColumns,
              clearable: true
            }"
        ></editor-select>
      </el-form-item>
      <!-- упрвление отображением базовых кнопок на тулбаре -->
      <el-form-item>
        <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.default_toolbar_elements') }}</span>
        <editor-select
          :value="value.defaultToolbarElements"
          @change="$set(value, 'defaultToolbarElements', $event)"
          :options="{
              multiple: true,
              options: defaultToolbarElementsData,
              clearable: true
            }"
        ></editor-select>
      </el-form-item>
      <!-- упрвление кастомными кнопками в меню задачи -->
      <el-form-item>
        <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.custom_task_menu_elements') }}</span>
        <editor-select
          :value="value.customTaskMenuElements"
          @change="$set(value, 'customTaskMenuElements', $event)"
          :options="{
              multiple: true,
              options: customTaskMenuElements,
              clearable: true
            }"
        ></editor-select>
      </el-form-item>
      <!-- автосохранение по таймеру -->
      <el-form-item>
        <el-checkbox v-model="value.isAutoSavingEnabled"/>
        <span class="property_label" style="display: inline-block;">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.auto_saving_on_timer') }}</span>

        <div v-if="value.isAutoSavingEnabled">
          <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.seconds_timer') }}</span>
          <el-input v-model="value.autoSaveSecondsTiming"/>
        </div>
      </el-form-item>
      <el-form-item>
        <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.default_task_duration') }}</span>
        <el-input-number v-model="value.taskDurationDefaultValue"/>
      </el-form-item>
      <el-form-item>
        <div>
          <span>Ограничения задач</span>
          <el-tooltip class="item" effect="dark" :content="tooltips.taskRestrictions">
            <i class="el-icon-question"></i>
          </el-tooltip>
        </div>
        <editor-select
          :value="value.taskRestrictionsQueryId"
          @change="$set(value ,'taskRestrictionsQueryId', $event)"
          :options="{
                multiple: false,
                options: queries,
                clearable: true
              }"
        ></editor-select>
        <editor-filters
          title="Параметры запроса"
          :value="value.taskRestrictionsQueryParameters"
          :isFieldConstantAndUser="true"
          @change="$set(value ,'taskRestrictionsQueryParameters', $event)"
          type="parameter"
        ></editor-filters>
      </el-form-item>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
import EditorSelect from '@/components/InterfaceEditor/components/editor/editor-select'
import RegistrySelect from '@/components/Common/RegistrySelectTreeWithSearch'
import EditorFilters from './editor-filters'
import EditorRegistryHeaders from './editor-registry-headers'
import EditorButtonAction from './editor-button-action'
import { buildFilters } from '@/components/InterfaceEditor/components/utils'

export default {
  name: 'editor-gantt-bryntum',
  components: {
    RegistrySelect,
    EditorSelect,
    EditorFilters,
    EditorRegistryHeaders,
    EditorButtonAction
  },
  props: {
    value: {
      type: Object
    }
  },
  watch: {
    value: {
      handler (value) {
        this.$emit('change', value)
      },
      deep: true
    },
    'value.taskSource.entityType': {
      async handler (type) {
        if (type !== this.value.taskSource.entityType) {
          this.$set(this.value.taskSource, 'planStartDate', null)
          this.$set(this.value.taskSource, 'planEndDate', null)
          this.$set(this.value.taskSource, 'factStartDate', null)
          this.$set(this.value.taskSource, 'factEndDate', null)
          this.$set(this.value.taskSource, 'keyField', null)
          this.$set(this.value.taskSource, 'nameField', null)
          this.$set(this.value.taskSource, 'parentIdField', null)
          this.$set(this.value.taskSource, 'percentDoneField', null)
          this.$set(this.value.taskSource, 'filters', [])
          this.$set(this.value.taskSource, 'isManuallyScheduledField', null)
          this.$set(this.value.taskSource, 'duration', null)
          this.$set(this.value.taskSource, 'effort', null)
          this.$set(this.value.taskSource, 'constraintType', null)
          this.$set(this.value.taskSource, 'projectConstraintResolution', null)
          this.$set(this.value.taskSource, 'constraintDate', null)
          this.$set(this.value.taskSource, 'inactive', null)

          this.$set(this.value.tableSource, 'columns', [])

          this.$set(this, 'entityTypeData', [])
          this.$set(this, 'attrs', [])
          this.$set(this, 'relationAttrs', [])
          this.$set(this, 'resourceAttrs', [])

          if (type === 'query' || type === 'extended_object') {
            this.entityTypeData = await this.getDataByEntityType(type)
          }
        }
      }
    },
    'value.taskSource.entityId': {
      async handler (entityId) {
        let type = this.value.taskSource.entityType
        let attrs = await this.getAttrs(entityId, type)
        if (attrs) {
          this.$set(this, 'attrs', Object.entries(attrs[0]).map(function (item) { return { id: item[0], name: item[0] } }))
        }
      }
    },
    'value.tasksRelation.entityId': {
      async handler (entityId) {
        let type = this.value.tasksRelation.entityType
        let attrs = await this.getAttrs(entityId, type)
        if (attrs) {
          this.$set(this, 'relationAttrs', Object.entries(attrs[0]).map(function (item) { return { id: item[0], name: item[0] } }))
        }
      }
    },
    'value.resourceRelations.entityId': {
      async handler (entityId) {
        let type = this.value.resourceRelations.entityType
        let attrs = await this.getAttrs(entityId, type)
        if (attrs) {
          this.$set(this, 'resourceRelationAttrs', Object.entries(attrs[0]).map(function (item) { return { id: item[0], name: item[0] } }))
        }
      }
    },
    'value.resources.entityId': {
      async handler (entityId) {
        let type = this.value.resources.entityType
        let attrs = await this.getAttrs(entityId, type)
        if (attrs) {
          this.$set(this, 'resourceAttrs', Object.entries(attrs[0]).map(function (item) { return { id: item[0], name: item[0] } }))
        }
      }
    }
  },
  data () {
    return {
      dataSourceTypes: [
        { id: 'extended_object', name: 'Расширенная таблица' },
        { id: 'query', name: 'Запросы' },
        { id: 'registry', name: 'Реестр' }
      ],
      entityTypeData: [],
      attrs: [],
      relationAttrs: [],
      tooltips: {
        relationType: 'Значения: 1 - начало к началу; 2 - начало к концу; 3 - конец к началу; 4 - конец к концу',
        constraintType: 'Значения: 1 - окончание не раньше, 2 - окончание не позднее, 3 - фиксированное окончание, 4 - фиксированное начало, 5 - начало не раньше, 6 - начало не позднее, 7 - как можно скорее, 8 - как можно позднее, 9 - нет',
        taskRestrictions: 'Запрос должен возращать массив json, для которых будет запрещено редактирование задач. Формат: [{"id": 1, "disabled_add": true}]',
        projectConstraintResolution: 'Значения: 1 - игнорировать; 2 - соблюдать; 3 - спросить пользователя'
      },
      defaultColumns: [
        { id: 'rownumber', name: 'Автонумерация строки' },
        { id: 'wbs', name: 'Автонумерация задач' }
      ],
      customTaskMenuElements: [
        { id: 'summaryTask', name: 'Суммарная задача' }
      ],
      resourceAttrs: [],
      resourceRelationAttrs: [],
      defaultToolbarElementsData: [
        { id: 'addTaskButton', name: 'Добавить задачу' },
        { id: 'undoRedo', name: 'Откат действия' },
        { id: 'expandAllButton', name: 'Свернуть/развернуть группировку' },
        { id: 'zoomInOut', name: 'Кнопки зума' },
        { id: 'projectStartDateField', name: 'Дата старта проекта' },
        { id: 'importMPPFilePicker', name: 'Импорт MPP' },
        { id: 'fullscreen', name: 'Кнопка полного экрана' },
        { id: 'saveButton', name: 'Кнопка сохранения' },
        { id: 'settingsList', name: 'Настройки' },
        { id: 'refreshButton', name: 'Кнопка обновления' }
      ],
      queries: []

    }
  },
  beforeMount () {
    if (this.value.hasOwnProperty('editorProperties')) {
      this.$set(this, 'value', this.value.editorProperties)
    }
  },
  async mounted () {
    let type = this.value.taskSource.entityType
    let relationEntityType = this.value.tasksRelation.entityType
    let resourceRelationsEntityType = this.value.resourceRelations.entityType
    let resourceEntityType = this.value.resources.entityType

    if (type) {
      let taskEntityId = this.value.taskSource.entityId
      this.entityTypeData = await this.getDataByEntityType(type)

      let attrs = await this.getAttrs(taskEntityId, type)
      if (attrs) {
        this.$set(this, 'attrs', Object.entries(attrs[0]).map(function (item) { return { id: item[0], name: item[0] } }))
      }
    }

    if (relationEntityType) {
      let relationEntityId = this.value.tasksRelation.entityId

      let relationAttrs = await this.getAttrs(relationEntityId, relationEntityType)
      if (relationAttrs) {
        this.$set(this, 'relationAttrs', Object.entries(relationAttrs[0]).map(function (item) { return { id: item[0], name: item[0] } }))
      }
    }

    if (resourceRelationsEntityType) {
      let resourceRelationEntityId = this.value.resourceRelations.entityId

      let resourceRelationAttrs = await this.getAttrs(resourceRelationEntityId, resourceRelationsEntityType)
      if (resourceRelationAttrs) {
        this.$set(this, 'resourceRelationAttrs', Object.entries(resourceRelationAttrs[0]).map(function (item) { return { id: item[0], name: item[0] } }))
      }
    }

    if (resourceEntityType) {
      let resourceEntityId = this.value.resources.entityId

      let resourceAttrs = await this.getAttrs(resourceEntityId, resourceEntityType)
      if (resourceAttrs) {
        this.$set(this, 'resourceAttrs', Object.entries(resourceAttrs[0]).map(function (item) { return { id: item[0], name: item[0] } }))
      }
    }
    this.queries = await this.getDataByEntityType('query')
  },
  methods: {
    async getDataByEntityType (type) {
      let data = []
      switch (type) {
        case 'extended_object':
          data = await this.$http.get(`${this.$config.api}/bieditor/extended_objects`)
          break
        case 'query':
          data = await this.$http.get(`${this.$config.api}/bieditor/queries`)
          break
      }

      return data.data
    },
    async getAttrs (entityId, sourceType) {
      let result
      let response

      switch (sourceType) {
        case 'query':
          response = await this.$http.post(
            `${this.$config.api}/datawarehouseservice/query/${entityId}`,
            { limit: 1 },
            { hideNotification: true }
          )
          result = response.data
          break
        case 'registry':
          response = await this.$http.post(
            `${this.$config.api}/registryservice/registry/${entityId}`,
            { limit: 1 },
            { hideNotification: true }
          )
          result = response.data.data
          break
      }
      return result
    }
  }
}
</script>

<style scoped>

</style>
