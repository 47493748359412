<template>
  <div class="style-form">
    <div v-bind:class="{ 'el-tabs': !mini , 'el-tabs--left': !mini}">
      <div class="el-tabs__header is-left" v-if="!mini">
        <div class="el-tabs__nav-wrap is-left">
          <div class="el-tabs__nav-scroll">
            <div class="style-preview" v-if="type != 'label'"> 
              <LayerIcon v-if="dto" :properties="dto.properties" :type="type" />             
            </div>
          </div>
        </div>
      </div>
      <div class="el-tabs__content">
        <div role="tabpanel" id="pane-0" aria-labelledby="tab-0" class="el-tab-pane" style="">
          <el-form :rules="formRules" :ref="formId" v-if="dto" class="style-form-inner-body custom_scrollbar" style="overflow-y: auto;" :model="dto">
            <el-form-item v-if="!mini" prop="name" :label="$locale.main.fields.name">
              <el-input v-model="dto.name" autocomplete="off"></el-input>        
            </el-form-item>
            <el-form-item v-if="!mini" prop="description" :label="$locale.main.fields.description">
              <el-input type="textarea" v-model="dto.description" autocomplete="off"></el-input>        
            </el-form-item>
            <PropertySet v-if="typeof properties !='undefined' && properties.length" :field-properties="fieldProperties" ref="propertySet" :items="properties" :dto="dto" :mini="mini"/>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="ts">
import Style, { StyleDTO } from '@/services/MapEditor/domain/model/Style/Style'
import StyleCreateCommand from '@/services/MapEditor/application/command/StyleCreateCommand'
import StyleCreateHandler from '@/services/MapEditor/application/handler/command/StyleCreateHandler'
import StyleUpdateCommand from '@/services/MapEditor/application/command/StyleUpdateCommand'
import StyleUpdateHandler from '@/services/MapEditor/application/handler/command/StyleUpdateHandler'
import StyleTypePropertiesQuery from '@/services/MapEditor/application/query/StyleTypePropertiesQuery'
import StyleTypePropertiesHandler from '@/services/MapEditor/application/handler/query/StyleTypePropertiesHandler'
import StyleByGuidQuery from '@/services/MapEditor/application/query/StyleByGuidQuery'
import StyleByGuidHandler from '@/services/MapEditor/application/handler/query/StyleByGuidHandler'
import StyleQueryRepository from '@/services/MapEditor/infrastructure/domain/repository/StyleQueryRepository'
import StyleCommandRepository from '@/services/MapEditor/infrastructure/domain/repository/StyleCommandRepository'
import PropertySet from '@/services/MapEditor/infrastructure/components/PropertySet/index.vue'
import LayerIcon from '@/core/infrastructure/components/LayerIcon.vue'

export default {
  name: 'StyleForm',
  props: ['formId', 'parentFormId', 'projectId', 'projectGuid', 'style_', 'submitCallback', 'styleCreatedCallback', 'styleUpdatedCallback', 'type', 'mini', 'value'],
  components: {
    PropertySet,
    LayerIcon
  },
  inject: {
    getEventBus: {
      default: () => () => {
        return {
          $emit: () => {},
          $on: () => {}
        }
      }
    }
  },
  watch: {
    styleCreated: function (location) {
      this.styleByGuidHandler.execute(
        new StyleByGuidQuery(
          location.replace('/styles/', '')
        )
      ).then((styleDto) => {
        let style = Style.create(styleDto);
        if (typeof this.styleCreatedCallback == 'function') {
          this.styleCreatedCallback(style);
        }
        this.getEventBus().$emit('styleCreated', style, this.parentFormId, this.type);        
      }); 
    },
    dto: {
      handler: function(curDto) {
        this.$emit('input', curDto.properties);
      },
      deep: true
    }
  },
  data() {
    return {
      styleCreateHandler: new StyleCreateHandler(new StyleCommandRepository()),
      styleUpdateHandler: new StyleUpdateHandler(new StyleCommandRepository()),
      styleByGuidHandler: new StyleByGuidHandler(new StyleQueryRepository()),
      styleTypePropertiesHandler: new StyleTypePropertiesHandler(new StyleQueryRepository()),
      labelPosition: "left",
      types: null,
      modelValue: this.value,
      dto: null,
      styleType: null,
      properties: [],
      fieldProperties: {
        marker_type: {
          selectProperties: {
            clearable: false
          }
        },
        arrow: {
          selectProperties: {
            clearable: false
          }
        }
      },
      defaultFormRules: {
        name: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        },
        type: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'blur'
        }
      },
      formRules: {
        name: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        },
        type: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'blur'
        }
      }
    }
  },
  computed: {
    styleCreated() {
      return this.$store.getters['Style/getLocation'];
    }
  },
  methods: {
    updateRules() {
      let rules = this.defaultFormRules;
      this.properties.forEach((prop) => {
        rules[prop.id] = {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: this.triggerType(prop.type)
        }
      });
      this.formRules = rules;
    },
    triggerType(name) {
      return 'change';
    },
    async submit(callback) {
      let form = this.$refs[this.formId];
      form.validate(valid => {
        if (valid) {
          if (typeof this.style_ !== 'undefined') {
            this.style_.setName(this.dto.name);
            this.style_.setType(this.dto.type);
            this.style_.setProperties(this.dto.properties);
            this.styleUpdateHandler.execute(
              new StyleUpdateCommand(
                this.style_.getGuid(),
                this.style_.getName(),
                this.style_.getDescription(),
                this.style_.getType(),
                this.style_.getProperties()
              )
            ).then((response) => {
              if (typeof callback == 'function') {
                callback();
              }
              if (typeof this.styleUpdatedCallback == 'function') {
                this.styleUpdatedCallback(this.style_);
              }
              this.getEventBus().$emit('styleUpdated', this.style_);
            });
          } else {
            let style = Style.create(this.dto);
            this.styleCreateHandler.execute(
              new StyleCreateCommand(
                style.getName(),
                style.getDescription(),
                style.getType(),
                style.getProperties(),
                this.projectId
              )
            ).then((response) => {
              if (typeof callback == 'function') {
                callback(response);
              }
            });
          }
        }
      });
    },
    propertiesMounted(properties) {
      if (typeof this.style_ !== 'undefined' && this.$refs.propertySet == properties) {
        properties.setValues(this.style_.getProperties());
      } else if (this.modelValue) {
        properties.setValues(this.modelValue);
      }
    },
    async generateDefaultStyle(type) {
      return await this.styleTypePropertiesHandler.execute(
        new StyleTypePropertiesQuery(type)
      );
    },
    fillProperties(source, destination) {
      destination.forEach((prop) => {
        const sourceProp = source.find(el => el.id == prop.id);
        if (typeof sourceProp !== "undefined") {
          prop.value = sourceProp.value;    
        } else {
          prop.value = prop.default_value;    
        }
      });
      return destination;
    }
  },
  async mounted() {
    const styleTypeProperties = await this.styleTypePropertiesHandler.execute(
      new StyleTypePropertiesQuery(this.type)
    );
    if (typeof this.style_ !== 'undefined') {
      const styleProperties = this.style_.getProperties();
      this.properties = this.fillProperties(styleProperties, styleTypeProperties);
      this.dto = new StyleDTO ({
        id: this.style_.getId(),
        guid: this.style_.getGuid(),
        name: this.style_.getName(),
        description: this.style_.getDescription(),
        type: this.style_.getType(),
        properties: this.properties
      });
    } else {
      if (this.modelValue) {
        /* сунуть в миграции
INSERT INTO "map_editor"."style_type_properties" VALUES ('point', 'background_color', '#FFFFFF', 3, NULL, NULL);
UPDATE "map_editor"."style_type_properties" SET row_order = 4 WHERE style_type_id = 'point' AND style_property_id = 'opacity'
UPDATE "map_editor"."style_type_properties" SET row_order = 5 WHERE style_type_id = 'point' AND style_property_id = 'size'
UPDATE "map_editor"."style_type_properties" SET row_order = 6 WHERE style_type_id = 'point' AND style_property_id = 'rotation'
UPDATE "map_editor"."style_type_properties" SET row_order = 7 WHERE style_type_id = 'point' AND style_property_id = 'offset'
UPDATE "map_editor"."style_type_properties" SET row_order = 8 WHERE style_type_id = 'point' AND style_property_id = 'anchor'
UPDATE "map_editor"."style_type_properties" SET row_order = 9 WHERE style_type_id = 'point' AND style_property_id = 'icon_file'
UPDATE "map_editor"."style_type_properties" SET row_order = 10 WHERE style_type_id = 'point' AND style_property_id = 'cluster_distance'
UPDATE "map_editor"."style_type_properties" SET row_order = 11 WHERE style_type_id = 'point' AND style_property_id = 'cluster_size'
UPDATE "map_editor"."style_type_properties" SET row_order = 12 WHERE style_type_id = 'point' AND style_property_id = 'cluster_color'
UPDATE "map_editor"."style_type_properties" SET row_order = 13 WHERE style_type_id = 'point' AND style_property_id = 'cluster_opacity'
UPDATE "map_editor"."style_type_properties" SET row_order = 14 WHERE style_type_id = 'point' AND style_property_id = 'cluster_font'
UPDATE "map_editor"."style_type_properties" SET row_order = 15 WHERE style_type_id = 'point' AND style_property_id = 'cluster_text_color'
        */
        // если в настройках стиля появился новый параметр, то стили, которые уже сохранены в БД, не показывают его
        // поэтому мы берем все параметры стиля из БД (styleTypeProperties) с дефолтными значениями и заменяем дефолтные значения
        // значениями уже существующих в БД параметров
        const newModelValue = structuredClone(styleTypeProperties);
        for (let i = 0; i < newModelValue.length; i++) {
          const value = this.modelValue.find(modelItem => modelItem.id === newModelValue[i].id);
          if (value) {
            newModelValue[i] = value;
          }
        }
        this.properties = newModelValue;
      } else {
        this.properties = styleTypeProperties;
      }
      this.dto = new StyleDTO({
        type: this.type,
        properties: this.properties
      });
    }
    this.getEventBus().$on('propertiesMounted', this.propertiesMounted);
  }
}
</script>
<style src="./StyleForm.css"></style>
