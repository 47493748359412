<template>
  <div class="editor-expression">
    <div class="editor-expression__label">
      {{ localLabel }}

      <el-tooltip class="item" effect="dark" placement="top" :open-delay="500">
        <div slot="content" v-html="query"></div>

        <i class="el-icon-question"></i>
      </el-tooltip>
    </div>

    <div class="editor-expression__container">
      <div class="editor-expression__description">
        <el-dropdown @command="onSelect" trigger="click" size="small">
          <span class="el-dropdown-link">
            {{ description }} <i class="el-icon-arrow-down el-icon--right"></i>
          </span>

          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="never" :class="{ 'selected': localValue.type === 'never' }">
              {{ $t('interface_editor.component_editor.conditions.never') }}
            </el-dropdown-item>

            <el-dropdown-item command="always" :class="{ 'selected': localValue.type === 'always' }">
              {{ $t('interface_editor.component_editor.conditions.always') }}
            </el-dropdown-item>

            <el-dropdown-item command="condition" :class="{ 'selected': localValue.type === 'condition' }">
              {{ $t('interface_editor.component_editor.conditions.condition') }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>

      <div v-if="localValue.type === 'condition'" class="editor-expression__edit">
        <el-button type="primary" plain icon="el-icon-edit" class="el-button--square" size="mini" @click="onEdit"></el-button>
      </div>
    </div>

    <el-dialog
      v-if="editValue"
      :title="localLabel"
      :visible.sync="visible"
      :modal="false"
      :close-on-click-modal="false"
      class="custom-dialog__wrapper"
      custom-class="custom-dialog"
      @close="onClose"
      width="50%"
    >
      <span class="expression-form custom_scrollbar">
        <el-form ref="form" :model="editValue" label-position="top" size="mini">
          <el-form-item :label="$t('interface_editor.conditions')">
            <el-switch
              v-if="editValue.allowOldFormat"
              class="switch-complex"
              v-model="editValue.isComplex"
              active-text="Сложные"
              inactive-text="Простые"
            ></el-switch>

            <ExpressionBuilder
              v-if="editValue.isComplex"
              v-model="editValue.expressions"
              :operators="complexOperators"
              :value-types="valueTypes"
              :child-expanded="true"
            ></ExpressionBuilder>

            <template v-else>
              <div class="conditions">
                <el-button type="success" size="mini" class="el-button--square" icon="el-icon-plus" @click="addCondition"></el-button>

                <el-radio-group v-model="editValue.condition_type" size="mini">
                  <el-radio-button label="and">{{ $t('interface_editor.component_editor.conditions.and') }}</el-radio-button>
                  <el-radio-button label="or">{{ $t('interface_editor.component_editor.conditions.or') }}</el-radio-button>
                </el-radio-group>
              </div>

              <el-row
                v-for="(condition, index) in editValue.conditions"
                :key="index"
                class="conditions-inline"
                :gutter="10"
                type="flex"
              >
                <el-col>
                  <el-select
                    v-if="!options.sourceRegistry"
                    v-model="condition.attribute"
                    size="mini"
                    filterable
                  >
                    <el-option
                      v-for="(item, index) in components"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>

                  <selectTree
                    v-if="options.sourceRegistry"
                    ref="entity_tree"
                    v-model="condition.attribute"
                    :dataTable="options.fieldsRegistry"
                    class="custom_scrollbar"
                    :clearable="false"
                    :standartTree="false"
                    :appendToBody='false'
                  />
                </el-col>

                <el-col>
                  <el-select
                    v-model="condition.type"
                    size="mini"
                  >
                    <el-option
                      v-for="(item, index) in operators"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-col>

                <el-col>
                  <el-tooltip class="item" content="user_id и role_id нужно писать без скобок" placement="top" :open-delay="500">
                    <el-input
                      v-model="condition.value"
                      size="mini"
                      :disabled="!['equals','not_equals', 'contains','not_contains', 'contains_in_array', 'not_contains_in_array'].includes(condition.type)"
                    ></el-input>
                  </el-tooltip>
                </el-col>

                <el-col :span="1" style="float: right;">
                  <el-button
                    style="float: right;"
                    @click="removeCondition(index)"
                    size="mini"
                    type="danger"
                    class="el-button--square"
                    icon="el-icon-delete"
                  ></el-button>
                </el-col>
              </el-row>
            </template>
          </el-form-item>
        </el-form>
      </span>

      <span slot="footer" class="dialog-footer">
        <el-button @click="onCancel()">
          {{ $t('main.button.cancel') }}
        </el-button>

        <el-button type="primary" @click="onAccept()" :disabled="acceptDisabled">
          {{ $t('main.button.accept') }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import ExpressionBuilder from './ExpressionBuilder'
import { cloneObject } from '@/helpers'
import { EditorExpressionMixin } from '@/components/InterfaceEditor/components/editor/Condition/EditorExpressionMixin'
import SelectTree from '@/core/infrastructure/components/TreeSelect'

export default Vue.extend({
  name: 'EditorExpression',

  mixins: [EditorExpressionMixin],

  inject: ['getComponents'],

  components: {
    ExpressionBuilder,
    SelectTree
  },

  props: {
    registryFields: {
      type: Array,
      default () {
        return []
      }
    }
  },

  computed: {
    acceptDisabled () {
      if (!this.editValue || !this.localValue) {
        return true
      }

      return JSON.stringify(this.localValue) === JSON.stringify(this.editValue)
    },

    description () {
      if (this.localValue.type === 'never') {
        return this.$t('interface_editor.component_editor.conditions.never')
      } else if (this.localValue.type === 'always') {
        return this.$t('interface_editor.component_editor.conditions.always')
      } else {
        return this.$t('interface_editor.component_editor.conditions.condition')
      }
    },

    query () {
      if (this.localValue.type !== 'condition') {
        return '<p>Нет условий</p>'
      }

      let query = ''

      if (this.localValue.isComplex) {
        query = this.buildComplexQuery({
          type: 'group',
          expression: this.localValue.expressions
        })
      } else {
        query = this.buildQuery(this.localValue.conditions, this.localValue.condition_type)
      }

      return `<p>Условия</p><pre>${query}</pre>`
    }
  },

  data () {
    return {
      visible: false,
      editValue: null,
      historyValue: null
    }
  },

  methods: {
    onSelect (value) {
      this.localValue.type = value
    },

    onAccept () {
      // this.localValue = cloneObject(this.editValue)
      this.$emit('change', cloneObject(this.editValue))
    },

    onEdit () {
      this.visible = true
      this.editValue = cloneObject(this.localValue)
      this.historyValue = cloneObject(this.localValue)
    },

    onClose () {
      this.visible = false
      this.editValue = null
      this.historyValue = null
    },

    onCancel () {
      this.visible = false

      // this.localValue = cloneObject(this.historyValue)
      this.$emit('change', cloneObject(this.historyValue))

      this.editValue = null
      this.historyValue = null
    },

    addCondition () {
      if (this.editValue === null) {
        return
      }

      if (!this.editValue.isComplex) {
        if (!this.editValue.conditions) {
          this.editValue.conditions = []
        }

        this.editValue.conditions.push({
          attribute: null,
          type: null,
          value: null
        })
      }
    },

    removeCondition (index) {
      if (this.editValue === null) {
        return
      }

      if (!this.editValue.isComplex) {
        this.editValue.conditions.splice(index, 1)
      }
    },

    buildQuery (expressions, logicalOperator = 'and') {
      const toExprString = function (attribute, value, operator, operatorCode) {
        if (['contains', 'not_contains', 'contains_in_array', 'not_contains_in_array'].includes(operatorCode)) {
          return `${attribute} ${operator} (${value})`
        } else if (['is_null', 'is_not_null'].includes(operatorCode)) {
          return `${attribute} ${operator}`
        } else {
          return `${attribute} ${operator} ${value}`
        }
      }

      const query = []

      for (const { attribute = 'None', type = 'equals', value = 'null' } of expressions) {
        const operator = this.toQueryOperator(type)

        query.push(
          [
            '  (',
            toExprString(attribute, value, operator, type),
            ')'
          ].join('')
        )
      }

      return [
        '(',
        query.join(` ${logicalOperator}\n`),
        ')'
      ].join('\n')
    },

    buildComplexQuery (expr, logicalOperator = 'and', depth = 0) {
      const { type, expression } = expr
      const query = []

      const tabs = Array.apply(null, { length: depth * 2 }).map(v => ' ').join('')

      if (type === 'group') {
        const subQuery = []
        for (const child of expression.children) {
          subQuery.push(
            this.buildComplexQuery(child, expression.logicalOperator, depth + 1)
          )
        }

        query.push(
          [
            `${tabs}(`, subQuery.join(` ${expression.logicalOperator}\n`), `${tabs})`
          ].join('\n')
        )
      } else {
        query.push(
          [
            tabs,
            '(',
            this.toQueryExpr(expression),
            ')'
          ].join('')
        )
      }

      return query.join(` ${logicalOperator}\n`)
    },

    toQueryOperator (operatorCode) {
      let operator = '='
      if (operatorCode === 'neq' || operatorCode === 'not_equals') {
        operator = '<>'
      } else if (operatorCode === 'lt') {
        operator = '<'
      } else if (operatorCode === 'lte') {
        operator = '<='
      } else if (operatorCode === 'gt') {
        operator = '>'
      } else if (operatorCode === 'gte') {
        operator = '>='
      } else if (operatorCode === 'in') {
        operator = 'IN'
      } else if (operatorCode === 'not_in') {
        operator = 'NOT IN'
      } else if (operatorCode === 'contains' || operatorCode === 'contains_in_array') {
        operator = 'CONTAINS'
      } else if (operatorCode === 'not_contains' || operatorCode === 'not_contains_in_array') {
        operator = 'NOT CONTAINS'
      } else if (operatorCode === 'is_null') {
        operator = 'IS NULL'
      } else if (operatorCode === 'is_not_null') {
        operator = 'IS NOT NULL'
      }
      return operator
    },

    toQueryExpr (expr) {
      const operator = this.toQueryOperator(expr.operator)

      let x = expr.x
      if (expr.xType === 'currentUser') {
        x = 'User'
      } else if (expr.xType === 'currentRole') {
        x = 'Role'
      } else if (expr.xType === 'currentDate') {
        x = 'Date'
      }

      let y = expr.y
      if (expr.yType === 'currentUser') {
        y = 'User'
      } else if (expr.yType === 'currentRole') {
        y = 'Role'
      } else if (expr.yType === 'currentDate') {
        y = 'Date'
      }

      if (['in', 'not_in'].includes(expr.operator)) {
        return `${x} ${operator} (${y})`
      } else if (['contains', 'not_contains', 'contains_in_array', 'not_contains_in_array'].includes(expr.operator)) {
        return `${x} ${operator} (${y})`
      } else if (['is_null', 'is_not_null'].includes(expr.operator)) {
        return `${x} ${operator}`
      } else {
        return `${x} ${operator} ${y}`
      }
    }
  }
})
</script>

<style lang="scss">
.expression-form {
  display: block;
  height: 40vh;
  padding: 10px;

  .conditions {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .conditions-inline {
    margin-bottom: 15px;
  }

  .switch-complex {
    position: absolute;
    right: 0;
    top: -34px;
  }
}

.editor-expression {
  .el-dropdown-link {
    cursor: pointer;
  }
}

.editor-expression__label {
  font-weight: 500;
}

.editor-expression__label, .editor-expression__description {
  line-height: 28px;
}

.editor-expression__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
</style>
