<template>
    <div class="editor-document">
      <el-form-item>
        <span class="property_label">{{ $t('interface_editor.component_editor.button_action.document') }}</span>

        <report-select-tree v-model="document.id" @change="selectDoc" :type="type"></report-select-tree>
      </el-form-item>

      <template v-if="value.reports.id">
        <el-form-item>
          <span class="property_label">
            Источник шаблона
          </span>

          <el-select v-model="document.templateSource" placeholder="Выберите тип источника" @change="selectTemplateSource">
            <el-option
              v-for="item in templateSources"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <template v-if="document.templateSource === 'field'">
          <el-form-item>
            <span class="property_label">
              Файловое поле с шаблоном
            </span>

            <el-select v-model="document.templateFieldId" filterable placeholder="Выберите файловое поле" @change="selectTemplateFieldId">
              <el-option
                v-for="item in associateFields"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <span class="property_label">
              Компонент с id записи
            </span>

            <el-select v-model="document.templateComponentId" filterable placeholder="Выберите компонент" @change="selectTemplateComponentId">
              <el-option
                v-for="item in components"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </template>

        <el-form-item v-if="type === 'document'">
          <span class="property_label">{{ $t('interface_editor.component_editor.document.format_type') }}</span>

          <el-select
            v-model="document.formatType"
            @change="selectDocFormatType"
          >
            <el-option
              v-for="item in formatTypes"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <span class="property_label">{{ $t('interface_editor.component_editor.document.view_type') }}</span>

          <el-select
            v-model="document.viewType"
            @change="selectDocType"
          >
            <el-option
              v-for="item in viewTypes"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item v-if="hasAlternativeSources">
          <span class="property_label">{{ $t('interface_editor.component_editor.document.alternative_sources') }}</span>

          <el-row
            v-for="(source, index) in alternativeSources"
            :key="index"
            :gutter="10"
          >
            <el-col :span="12">
              <el-input
                  :value="source.properties.name"
                  :title="source.properties.name"
                  :disabled="true"
              ></el-input>
            </el-col>

            <el-col :span="12">
              <el-select v-model="document.alternativeSources[index].value" filterable>
                <el-option
                    v-for="item in associateFields"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                ></el-option>
              </el-select>
            </el-col>

            <el-col :span="12">
              <el-checkbox
                  v-model="document.alternativeSources[index].isArchive"
                  title="Файл в архиве"
              >
                Архив
              </el-checkbox>
            </el-col>

            <el-col :span="12">
              <el-input
                  v-model="document.alternativeSources[index].filename"
                  placeholder="Имя XML файла в архиве"
                  title="Имя XML файла в архиве"
              ></el-input>
            </el-col>
          </el-row>
        </el-form-item>

        <el-form-item>
          <span class="property_label">
            {{ $t('interface_editor.component_editor.document.associate_registry_field') }}
          </span>

          <el-select
            v-model="document.assocFieldId"
            clearable
            filterable
            @change="selectAssocField"
          >
            <el-option
              v-for="item in associateFields"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item v-if="document.assocFieldId !== null">
          <el-checkbox v-model="document.openSavedVersion" @change="checkedSavedVersion">
            {{ $t('interface_editor.component_editor.document.open_saved_version') }}
          </el-checkbox>
        </el-form-item>

        <el-form-item v-if="document">
          <span class="property_label">{{ $t('interface_editor.component_editor.button_action.tab_name') }}</span>
          <el-input
            v-model="tabName"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <span class="property_label">
            {{ $t('interface_editor.component_editor.button_action.report_name') }}
            <el-tooltip placement="top-start" :open-delay="400" style="color: black">
              <div slot="content">
                {{ $t('interface_editor.component_editor.button_action.open_report_tooltip.main_tooltip') }}
                <p> {{ $t('interface_editor.component_editor.button_action.open_report_tooltip.example_attr') }} </p>
                <p> {{ $t('interface_editor.component_editor.button_action.open_report_tooltip.example_date') }} </p>
                <p> {{ $t('interface_editor.component_editor.button_action.open_report_tooltip.example_format') }} </p>
                <p> {{ $t('interface_editor.component_editor.button_action.open_report_tooltip.notice') }} </p>
                <p> {{ $t('interface_editor.component_editor.button_action.open_report_tooltip.full_example') }} </p>
                <a href="https://momentjs.com/docs/#/displaying/format/">
                  <br> {{ $t('interface_editor.component_editor.button_action.open_report_tooltip.format_href') }}
                </a>
              </div>
              <i class="el-icon-question"></i>
            </el-tooltip>
          </span>
          <el-input
            v-model="reportName"
            placeholder="Имя файла"
          ></el-input>
        </el-form-item>
      </template>
    </div>
</template>

<script>
import Report from '@/components/ReportEditor/Models/Reports'
import ReportSelectTree from '@/components/Common/ReportSelectTree'

export default {
  name: 'editor-document',

  components: {
    ReportSelectTree
  },

  inject: {
    getRegistryId: {
      default: () => null
    },
    getComponents: {
      default: () => ([])
    }
  },

  props: {
    value: Object,
    type: String
  },

  watch: {
    'value.reports': {
      handler: function (value) {
        this.document = value
      }
    },
    tabName: {
      handler: function (value) {
        this.$set(this.value.reports, 'tabName', value)
      }
    },
    reportName: {
      handler: function (value) {
        this.$set(this.value.reports, 'reportName', value)
      }
    }
  },

  computed: {
    hasAlternativeSources () {
      return this.currentDocument && this.currentDocument.report_sources.some(rs => rs.report_source_type_id === 'xml')
    },

    alternativeSources () {
      if (!this.currentDocument) {
        return []
      }

      return this.currentDocument.report_sources.filter(rs => rs.report_source_type_id === 'xml')
    },

    components () {
      return this.getComponents()
        .filter((cmp) => cmp.properties.name)
        .map(item => ({
          label: `${item.name} (${item.properties.name})`,
          value: item.properties.name
        }))
    }
  },

  data () {
    return {
      document: {
        id: null,
        guid: null,
        name: null,
        formatType: null,
        viewType: null,
        assocFieldId: null,
        openSavedVersion: false,
        templateSource: 'service',
        templateFieldId: null,
        templateComponentId: null,
        alternativeSources: [],
        reportName: null
      },
      templateSources: [
        { id: 'service', name: 'Редактор отчётов' },
        { id: 'field', name: 'Файловое поле' }
      ],
      currentDocument: null,
      associateFields: [],
      formatTypes: [
        { id: 'docx', name: 'DOC' },
        { id: 'pdf', name: 'PDF' },
        { id: 'odt', name: 'ODT' }
      ],
      viewTypes: [
        { id: 'download', name: this.$t('interface_editor.component_editor.document.document_view_type.download_file') },
        { id: 'open', name: this.$t('interface_editor.component_editor.document.document_view_type.open_file') }
      ],
      tabName: this.value?.reports?.tabName || this.value?.reports?.name,
      reportName: this.value.reports?.reportName
    }
  },

  async mounted () {
    this.$http
      .get(`${this.$config.api}/objecteditor/entities?object_id=${this.getRegistryId()}`)
      .then(res => {
        this.associateFields = res.data.data.filter(field => field.entity_type_id === 'file_field')
      })

    if (typeof this.value.reports !== 'undefined') {
      this.document = Object.assign(this.document, this.value.reports)

      if (this.document.id) {
        this.currentDocument = await Report.$find(this.document.id)
      }

      if (this.hasAlternativeSources) {
        // Дополняем конфигурацию новыми альтернативными источниками
        for (const rs of this.alternativeSources) {
          // Если в конфигурации отсутствует новый альтернативный источник
          // Тогда добавляем его в конфигурацию
          if (!this.document.alternativeSources.some(rs2 => rs2.id === rs.id)) {
            this.document.alternativeSources.push({
              id: rs.id,
              sourceType: rs.report_source_type_id,
              value: null,
              filename: null,
              isArchive: false
            })
          }
        }

        // Удаляем из конфигурации удалённые эльтернативные источники
        for (const [index, rs] of this.document.alternativeSources.entries()) {
          // Если в основном списке доступных источников, отсутствует ранее добавленный источник в конфигурацию
          // Тогда удалём этот источник из конфигурации
          if (!this.alternativeSources.some(rs2 => rs2.id === rs.id)) {
            this.document.alternativeSources.splice(index, 1)
          }
        }
      }
    }
  },

  methods: {
    selectTemplateSource (value) {
      this.$set(this.value.reports, 'templateSource', value)

      if (value !== 'field') {
        this.document.templateFieldId = null
        this.document.templateComponentId = null

        this.$set(this.value.reports, 'templateFieldId', null)
        this.$set(this.value.reports, 'templateComponentId', null)
      }
    },

    async selectDoc (value) {
      this.currentDocument = await Report.$find(value)

      const alternativeSources = []
      if (this.hasAlternativeSources) {
        for (const rs of this.alternativeSources) {
          alternativeSources.push({
            id: rs.id,
            sourceType: rs.report_source_type_id,
            value: null,
            filename: null,
            isArchive: false
          })
        }
      }

      if (this.currentDocument) {
        this.$set(
          this.value,
          'reports',
          {
            id: this.currentDocument.id,
            name: this.currentDocument.name,
            guid: this.currentDocument.guid,
            formatType: ['xml', 'json'].includes(this.type)
              ? this.type
              : this.document.formatType,
            viewType: this.document.viewType,
            registryId: this.getRegistryId(),
            assocFieldId: this.document.assocFieldId,
            openSavedVersion: this.document.openSavedVersion,
            templateSource: this.document.templateSource,
            templateFieldId: this.document.templateFieldId,
            templateComponentId: this.document.templateComponentId,
            alternativeSources
          }
        )
      }
    },

    selectTemplateFieldId (value) {
      this.$set(this.value.reports, 'templateFieldId', value)
    },

    selectTemplateComponentId (value) {
      this.$set(this.value.reports, 'templateComponentId', value)
    },

    selectDocType (value) {
      this.$set(this.value.reports, 'viewType', value)
    },

    selectDocFormatType (value) {
      this.$set(this.value.reports, 'formatType', value)
    },

    selectAssocField (value) {
      if (value === '') {
        this.document.assocFieldId = value = null
        this.document.openSavedVersion = false

        this.$set(this.value.reports, 'openSavedVersion', this.document.openSavedVersion)
      }
      this.$set(this.value.reports, 'assocFieldId', value)
    },

    checkedSavedVersion (value) {
      this.$set(this.value.reports, 'openSavedVersion', value)
    }
  }
}
</script>

<style type="text/css"></style>
