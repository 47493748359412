import Vue from 'vue'
import openCardMixin from '@/components/InterfaceEditor/components/openCard_mixin'
import clickMixin from '@/core/infrastructure/components/Grid/infrastructure/components/CellRenderer/clickMixin'
export default Vue.extend({
  mixins: [openCardMixin, clickMixin],
  name: 'htmlField',
  template: `
      <div :class="{'hover_effect': clickTable}" :style="this.params.colDef.cssStyle">
        <div @[event]="btnClickedHandler" v-html="parceHtmlTemplate"></div>
      </div>
    `,
  data () {
    return {
      value: null
    }
  },
  computed: {
    parceHtmlTemplate () {
      if (this.params.colDef.htmlTemplate) {
        let label = this.params.colDef.htmlTemplate
        let attributes = this.params.colDef.htmlTemplate.match(/\{{(.*?)\}}/g) || []
        attributes.forEach((attribute) => {
          attribute = attribute.replace('{{', '').replace('}}', '')
          let value = this.params.data[attribute]
          try {
            value = JSON.parse(value)
          } catch (e) {}
          if (value instanceof Array) {
            value = value.map(item => item.name).join(',')
          }
          label = label.replace(`{{${attribute}}}`, value ?? '')
        })

        return label
      } else {
        return ''
      }
    }
  },
  mounted () { }
})
