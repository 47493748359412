<template>
    <div :style="`width: 100%; height: 100%;${componentStyle};${computedStyle}`" @click="select">
        <span v-if="isDesigner()">{{ name }}</span>
        <v-runtime-template
                v-else
                :template="template"
        ></v-runtime-template>
    </div>
</template>

<script>
import VRuntimeTemplate from 'v-runtime-template'
import properties from './mixins/properties'
import conditions from './mixins/conditions'
import PluginFactory from '@/core/infrastructure/service/PluginFactory'
export default {
  name: 'text-label',
  components: {
    VRuntimeTemplate
  },
  mixins: [properties, conditions],
  inject: {
    openRegistryCard: {
      default: () => {}
    },
    loadData: {
      default: () => {}
    },
    isDesigner: {
      default: () => {
        return () => {
          return false
        }
      }
    },
    getDashboardComponents: {
      default: () => {}
    },
    getCard: {
      default: () => {}
    },
    getParentContext: {
      default: () => {}
    },
    addMainTab: {
      default: () => {}
    },
    openDashboardCard: {
      default: () => {}
    },
    forceUpdateSettingsPanel: {
      default: () => () => {}
    },
    // зависимости от a-button
    getParentDashboard: {
      default: () => {}
    },
    getViewer: {
      default: () => {
        return {}
      }
    },
    getModel: {
      default: () => {}
    },
    updateTab: {
      default: () => {}
    },
    tabs: {
      default: () => {}
    },
    activeTab: {
      default: () => {}
    },
    closeTab: {
      default: () => {}
    },
    openedCards: {
      default: () => {}
    },
    cancelChanges: {
      default: () => {}
    },
    openTabModalWindow: {
      default: () => {}
    },
    getContainersStore: {
      default: () => () => {}
    },
    getInterfaceWrapper: {
      default: () => () => {}
    }
  },
  computed: {
    template () {
      return `<span :class="{'interactive': interactive.type !== null}" @click="onClick">${this.text}</span>`
    },
    name () {
      return this.alias ? this.alias : this.text
    }
  },
  props: {
    alias: {
      type: String,
      default: '',
      description: 'Псевдоним'
    },
    text: {
      type: String,
      default: '',
      description: 'Текст'
    },
    componentStyle: {
      type: String,
      default: '',
      description: 'Стили'
    },
    interactive: {
      type: Object,
      default () {
        return {
          type: null,
          properties: {}
        }
      },
      editor: 'InteractiveEditor'
    }
  },
  methods: {
    select () {
      this.$emit('select')
    },
    async getRegistryCardId (registryId, recordId) {
      let data = await this.$http.get(`${this.$config.api}/registryservice/registry/${registryId}/records/${recordId}/card`)
      return data.data[0]
    },
    async onClick (event) {
      switch (this.interactive.type) {
        case 'card':
          if (!this.interactive.properties.objectField || !this.interactive.properties.idField) {
            console.warn(`bad properties: objectField: ${this.interactive.properties.objectField}, idField: ${this.interactive.properties.idField}`)
            return false
          }
          let objectId = this.data[this.interactive.properties.objectField]
          let id = this.data[this.interactive.properties.idField]
          if (!objectId || !id) {
            console.warn(`bad data: objectField: ${this.interactive.properties.objectField}, idField: ${this.interactive.properties.idField}`)
            return false
          }
          let card = await this.getRegistryCardId(objectId, id)
          if (!card) {
            console.warn(`bad cardId`)
            return false
          }
          this.openRegistryCard({
            registryId: objectId,
            cardId: card.id,
            cardName: card.name,
            recordId: id,
            initialData: {},
            registry: {
              readonly: false,
              addRecord: () => {},
              updateRecord: async (recordId) => {
                if (objectId === this.data.object_id) {
                  this.loadData()
                }
              }
            }
          })
          break
        case 'plugin':
          let pluginName = this.interactive.properties.plugin
          if (!pluginName) {
            console.warn('Плагин не обнаружен', pluginName)
          }

          const plugin = await PluginFactory.build(
            this.$config.project,
            pluginName,
            this,
            event,
            this.data
          )
          plugin.execute()
          break
        default:
          return false
      }
    }
  }
}
</script>

<style scoped>
.interactive:hover {
    text-decoration: underline;
}
</style>
