<template>
  <div>
    <el-button
      @click="isShowStyleWindow = true"
      type="text">
      {{ this.buttonName }}
    </el-button>
    <el-dialog
      title="Настройка стиля"
      :modal="true"
      :visible.sync="isShowStyleWindow"
      :modalFade="true"
      :modalAppendToBody="false">
      <div style="height: 500px; display: flex; flex-direction: column;">
        <div style="display: flex; justify-content: space-between; margin: 10px 0">
          <el-radio-group v-model="activeStyleType" size="small">
            <el-radio-button v-for="type in styleTypes"
                             :key="type.id"
                             :label="type.id">
              {{ type.name }}
            </el-radio-button>
          </el-radio-group>
          <el-button
              type="text"
              @click="setDefaultSettings">
            Сбросить
          </el-button>
        </div>
        <style-form
          v-if="activeStyleType"
          v-model="value[activeStyleType]"
          :mini="true"
          :type="activeStyleType"
          :key="`inscription_${activeStyleType}_${componentKey}`"/>

      </div>
    </el-dialog>
  </div>
</template>

<script>
import StyleForm from '@/services/MapEditor/infrastructure/components/StyleForm/index.vue'
const STYLE_TYPES = [
  { id: 'point', name: 'Точка' },
  { id: 'linestring', name: 'Линия' },
  { id: 'polygon', name: 'Полигон' },
  { id: 'label', name: 'Лейбл' }
]
export default {
  name: 'MapGeometryStyle',
  components: { StyleForm },
  props: {
    value: {
      type: Object,
    },
    options: {
      type: Object,
    }
  },
  watch: {
    value: {
      handler: function (newValue, oldValue) {
        this.$emit('change', newValue)
      },
      deep: true
    }
  },
  data () {
    return {
      activeStyleType: Object.keys(this.value)[0],
      isShowStyleWindow: false,
      componentKey: 1
    }
  },
  computed: {
    styleTypes () {
      let defaultTypes = JSON.parse(JSON.stringify(STYLE_TYPES))
      let types = []
      defaultTypes.forEach((item) => {
        if (Object.keys(this.value).includes(item.id)) {
          types.push(item)
        }
      })
      return types
    },
    buttonName () {
      return this.options?.buttonName ? this.options.buttonName : 'Настройка стиля'
    }
  },
  methods: {
    setDefaultSettings() {
      let defaultSettings = {}
      Object.keys(this.value).forEach((item) => {
        defaultSettings[item] = null
      })
      this.activeStyleType = ''
      this.$emit('change', defaultSettings)
      this.componentKey += +1;
    }
  }
}
</script>

<style scoped>

</style>
