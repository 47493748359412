<template>
  <el-row style="margin-bottom: 5px" class="editor-options">
    <span class="property_label" style="display: inline-block;">{{ $t(`interface_editor.component_editor.options.option`) }}</span>
      <el-row class="condition">
        <el-col :span="10">
          <el-tooltip placement="left" :content="$t(`interface_editor.active_tooltip`)" effect="dark">
            <el-input v-model="newValue.activeText"></el-input>
          </el-tooltip>
        </el-col>
        <el-col :span="14">
          <el-tooltip placement="top" effect="dark" :content="$t(`interface_editor.options_edit_user_switcher`)">
            <el-input v-model="newValue.activeValue"></el-input>
          </el-tooltip>
        </el-col>
      </el-row>
    <el-row class="condition">
      <el-col :span="10">
        <el-tooltip placement="left" :content="$t(`interface_editor.inactive_tooltip`)" effect="dark">
          <el-input v-model="newValue.inactiveText"></el-input>
        </el-tooltip>
      </el-col>
      <el-col :span="14">
        <el-tooltip placement="bottom" effect="dark" :content="$t(`interface_editor.options_edit_user_switcher`)">
          <el-input v-model="newValue.inactiveValue"></el-input>
        </el-tooltip>
      </el-col>
    </el-row>
  </el-row>
</template>

<script>
export default {
  name: 'editor-options-switcher',
  props: {
    value: {
      type: Object,
      default () {
        return {}
      }
    },
    otherProperties: {
      type: Object
    }
  },
  computed: {
    newValue () {
      return this.value
    }
  },
  data () {
    return {
    }
  },
  watch: {
    newValue: {
      handler (value) {
        this.$emit('change', value)
      },
      deep: true
    }
  },
  mounted () {
    // Сохранение старых настроек
    if (this.value) {
      this.$set(this.newValue, 'activeText', this.value.activeText)
      this.$set(this.newValue, 'inactiveText', this.value.inactiveText)
    }
  }
}
</script>

<style lang="scss">
  .editor-options {
    .condition {
      background-color: #f5f5f5;
      margin-bottom: 8px;
      border-left: 2px solid green;
      padding: 4px;
    }
  }
</style>
