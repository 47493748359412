import { Model as BaseModel } from 'vue-api-query'

export default class Model extends BaseModel {
  #cacheTTL = 0;
  
  // define a base url for a REST API
  baseURL () {
    return `${config.api}/objecteditor`
  }

  // implement a default request method
  request (config) {
    if (this.#cacheTTL) {
      config.cacheTTL = this.#cacheTTL
    }

    return this.$http.request(config)
  }

  cache (ttl) {
    if (ttl) {
      this.#cacheTTL = ttl
    }
    return this
  }
}
