import Vue from 'vue'
import openCardMixin from '@/components/InterfaceEditor/components/openCard_mixin.js'
import RegistryCard from '@/components/RegistryCard'
import clickMixin from '@/core/infrastructure/components/Grid/infrastructure/components/CellRenderer/clickMixin'
export default Vue.extend({
  mixins: [openCardMixin, clickMixin],
  components: { RegistryCard },
  name: 'floatField',
  template: `
        <div :class="{'hover_effect': clickTable}" :style="this.params.colDef.cssStyle">
          <span @[event]="btnClickedHandler">
            {{this.params.valueFormatted || normalizedValue}}
          </span>
      </div>
    `,
  computed: {
    normalizedValue () {
      if (typeof this.params.value === 'string') {
        let fixed = +parseFloat(this.params.value).toFixed(2)
        return fixed.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      }
      if (typeof this.params.value === 'number') {
        let fixed = this.params.value.toFixed(2)
        return fixed.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      }
      return null
    }
  }
})
