<template>
  <div class="vqb-rule">
    <div class="vqb-label" v-html="localLabel"></div>

    <div class="vqb-edit">
      <div class="vqb-edit__input">
        <el-input
          v-if="xInputType === 'string'"
          v-model="model.x"
          placeholder="X"
        ></el-input>

        <el-select
          v-else-if="xInputType === 'items'"
          v-model="model.x"
          placeholder="X"
          filterable
        >
          <el-option
            v-for="item in xInputItems"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>

        <el-input
          v-else
          placeholder="X"
          :disabled="true"
        ></el-input>
      </div>

      <div class="vqb-edit__input">
        <el-select
          v-model="model.xType"
          placeholder="X Type"
        >
          <el-option
            v-for="operator in valueTypes"
            :key="operator.value"
            :value="operator.value"
            :label="operator.label"
          ></el-option>
        </el-select>
      </div>

      <div class="vqb-edit__input">
        <el-select
          v-model="model.operator"
          placeholder="Operator"
          filterable
        >
          <el-option
            v-for="operator in operators"
            :key="operator.value"
            :value="operator.value"
            :label="operator.label"
          ></el-option>
        </el-select>
      </div>

      <div class="vqb-edit__input">
        <el-select
          v-model="model.yType"
          placeholder="Y Type"
          filterable
          :disabled="isNullableOperator"
        >
          <el-option
            v-for="operator in valueTypes"
            :key="operator.value"
            :value="operator.value"
            :label="operator.label"
          ></el-option>
        </el-select>
      </div>

      <div class="vqb-edit__input">
        <el-input
          v-if="yInputType === 'string'"
          v-model="model.y"
          placeholder="Y"
          filterable
          :disabled="isNullableOperator"
        ></el-input>

        <el-select
          v-else-if="yInputType === 'items'"
          v-model="model.y"
          placeholder="Y"
          :disabled="isNullableOperator"
        >
          <el-option
            v-for="item in yInputItems"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>

        <el-input
          v-else
          placeholder="Y"
          disabled
        ></el-input>
      </div>

      <div class="vqb-edit__actions">
        <el-tooltip :open-delay="300" class="item" effect="dark" content="Удалить выражение" placement="top">
          <el-button
            size="mini"
            type="danger"
            @click="remove"
            icon="el-icon-delete"
            class="el-button--square"
          ></el-button>
        </el-tooltip>
      </div>
    </div>
  </div>
</template>

<script type="ts">
import Vue from 'vue'
import { cloneObject } from '@/helpers'

export default Vue.extend({
  name: 'ExpressionBuilderExpression',

  props: {
    value: {
      type: Object,
      required: true
    },

    index: {
      type: Number,
      default: 0
    },

    sizeControl: {
      type: String,
      default: 'mini'
    },

    valueTypes: {
      type: Array,
      default () {
        return []
      }
    },

    operators: {
      type: Array,
      default () {
        return []
      }
    }
  },

  watch: {
    value: {
      handler: function (value) {
        if (JSON.stringify(value) !== JSON.stringify(this.model)) {
          this.model = cloneObject(value)
        }
      },
      deep: true
    },

    model: {
      handler: function (value) {
        if (JSON.stringify(value) !== JSON.stringify(this.value)) {
          this.$emit('input', cloneObject(value))
        }
      },
      deep: true
    }
  },

  computed: {
    xValueType () {
      const valueType = this.valueTypes.find(vt => vt.value === this.model.xType)
      if (!valueType) {
        return null
      }

      return valueType
    },

    yValueType () {
      const valueType = this.valueTypes.find(vt => vt.value === this.model.yType)
      if (!valueType) {
        return null
      }

      return valueType
    },

    xInputItems () {
      if (!this.xValueType) {
        return []
      }

      return this.xValueType.items || []
    },

    yInputItems () {
      if (!this.yValueType) {
        return []
      }

      return this.yValueType.items || []
    },

    xInputType () {
      if (!this.xValueType) {
        return null
      }

      return this.xValueType.input || null
    },

    yInputType () {
      if (!this.yValueType) {
        return null
      }

      return this.yValueType.input || null
    },

    isNullableOperator () {
      return ['is_null', 'is_not_null'].includes(this.model.operator)
    },

    localLabel () {
      let operator = '='
      if (this.model.operator === 'neq') {
        operator = '<>'
      } else if (this.model.operator === 'lt') {
        operator = '<'
      } else if (this.model.operator === 'lte') {
        operator = '<='
      } else if (this.model.operator === 'gt') {
        operator = '>'
      } else if (this.model.operator === 'gte') {
        operator = '>='
      } else if (this.model.operator === 'in') {
        operator = 'IN'
      } else if (this.model.operator === 'not_in') {
        operator = 'NOT IN'
      } else if (this.model.operator === 'is_null') {
        operator = 'IS NULL'
      } else if (this.model.operator === 'is_not_null') {
        operator = 'IS NOT NULL'
      }

      let x = this.model.x
      if (this.model.xType === 'currentUser') {
        x = this.$store.getters['Authorization/userId']
      } else if (this.model.xType === 'currentRole') {
        x = this.$store.getters['Authorization/roleId']
      } else if (this.model.xType === 'currentDate') {
        x = new Date().toJSON().slice(0, 10)
      }

      let y = this.model.y
      if (this.model.yType === 'currentUser') {
        y = this.$store.getters['Authorization/userId']
      } else if (this.model.yType === 'currentRole') {
        y = this.$store.getters['Authorization/roleId']
      } else if (this.model.yType === 'currentDate') {
        y = new Date().toJSON().slice(0, 10)
      }

      if (['in', 'not_in'].includes(this.model.operator)) {
        return `<b>${x}</b> <b>${operator}</b> <b>(${y})</b>`
      } else if (['is_null', 'is_not_null'].includes(this.model.operator)) {
        return `<b>${x}</b> <b>${operator}</b>`
      } else {
        return `<b>${x}</b> <b>${operator}</b> <b>${y}</b>`
      }
    }
  },

  data () {
    return {
      model: this.value || {
        x: 'attr_N_',
        xType: 'attribute',
        operator: 'eq',
        y: '',
        yType: 'constant'
      }
    }
  },

  mounted () {
    this.model = this.value
  },

  methods: {
    remove () {
      this.$confirm(
        'Do you really want to delete the expression?',
        'Warning',
        {
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }
      ).then(() => {
        this.$emit('child-deletion-requested', this.index)
      }).catch(() => null)
    }
  }
})
</script>
