<template>
  <div v-show="selectedComponentRequiredType && selectedComponentRequiredType !== 'never'">
    <el-popover
      ref="requiredHelperPopover"
      trigger="hover"
      placement="left">
      <div class="helper_popup">
        <p>
          Если настройка отличается от "Отсутствует", то при открытии карточки поле сразу будет выделяться выбранным способом:
        </p>
        <img src="../../../../../public/Helpers/required_settings_helper.gif" alt="Image">
      </div>
    </el-popover>
    <div class="editor-expression__label">
      Настройки обязательности
      <span v-popover:requiredHelperPopover><i class="el-icon-question"></i></span>
    </div>

    <div class="editor-expression__container">
          <div class="editor-expression__description">
        <el-dropdown @command="onSelect" trigger="click" size="small">
              <span class="el-dropdown-link">
                {{ description }} <i class="el-icon-arrow-down el-icon--right"></i>
              </span>

          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="asterisk" :class="{ 'selected': requiredSettings === 'asterisk' }">
              Астериск
            </el-dropdown-item>

            <el-dropdown-item command="border" :class="{ 'selected': requiredSettings === 'border' }">
              Рамка
            </el-dropdown-item>

            <el-dropdown-item command="none" :class="{ 'selected': requiredSettings === 'none' }">
              Отсутствует
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>

import required_properties_mixin from '@/components/InterfaceEditor/components/required_properties_mixin'
export default {
  name: 'editor-required-style',
  inject: ['getComponents'],
  mixins: [required_properties_mixin],
  data () {
    return {
      requiredSettings: this.value || 'none'
    }
  },
  props: {
    value: {
      type: String,
      default () {
        return 'none'
      }
    }
  },
  computed: {
    description () {
      if (this.requiredSettings === 'asterisk') {
        return 'Астериск'
      } else if (this.requiredSettings === 'border') {
        return 'Рамка'
      } else {
        return 'Отсутствует'
      }
    },
    selectedComponentRequiredType () {
      let components = this.getComponents()
      if (components) {
        let selectedComponent = components.filter(cmp => cmp.isSelected)[0]
        if (selectedComponent) return selectedComponent.properties?.isRequired?.type
      }
      return ''
    }
  },
  watch: {
    selectedComponentRequiredType (val) {
      if (val === 'never') {
        this.requiredSettings = 'none'
        this.$emit('change', this.requiredSettings)
      }
    }
  },
  methods: {
    onSelect (value) {
      this.requiredSettings = value
      this.$emit('change', this.requiredSettings)
    }
  }
}
</script>

<style scoped>
.helper_popup {
  width: 350px;
  word-break: break-word;
  padding: 10px
}
.el-dropdown-link {
  cursor: pointer;
}
</style>
