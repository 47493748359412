import Vue from 'vue'
import openCardMixin from '@/components/InterfaceEditor/components/openCard_mixin'
import clickMixin from '@/core/infrastructure/components/Grid/infrastructure/components/CellRenderer/clickMixin'
export default Vue.extend({
  mixins: [openCardMixin, clickMixin],
  name: 'timeField',
  template: `
        <div :class="{'hover_effect': clickTable}" :style="this.params.colDef.cssStyle">
          <span @[event]="btnClickedHandler">
            {{ parsedValue }}
          </span>
      </div>
    `,
  computed: {
    parsedValue () {
      if (this.params.colDef.hideSeconds) {
        if (this.params?.value?.length > 5) {
          return this.params?.value?.slice(0, -3)
        }
      }
      return this.params.value
    }
  }
})
