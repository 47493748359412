<template>
  <div class="editor-button-action">
    <div class="editor-button-action__label">
      {{ $t('interface_editor.component_editor.button_action.label') }}
    </div>

    <div class="editor-button-action__container">
      <div class="editor-button-action__description">
        {{ selectValue }}
      </div>

      <div class="editor-button-action__edit">
        <el-button type="primary" plain icon="el-icon-edit" class="el-button--square" size="mini" @click="onEdit"></el-button>
      </div>
    </div>

    <el-dialog
      v-if="editValue"
      :title="selectEditValue"
      :visible.sync="visible"
      :modal="false"
      :close-on-click-modal="false"
      class="custom-dialog__wrapper"
      custom-class="custom-dialog"
      @close="onClose"
      width="50%"
    >
      <span class="editor-button-action-from custom_scrollbar">
        <ButtonActionForm
          v-model="editValue"
          :is-button="isButton"
          :is-headers="isHeaders"
          :source-id="sourceId"
          :source-type="sourceType"
          :actions="actions"
        ></ButtonActionForm>
      </span>

      <span slot="footer" class="dialog-footer">
        <el-button @click="onCancel()">
          {{ $t('main.button.cancel') }}
        </el-button>

        <el-button type="primary" @click="onAccept()" :disabled="acceptDisabled">
          {{ $t('main.button.accept') }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import { cloneObject } from '@/helpers'
import ButtonActionForm from './ButtonActionForm.vue'

export default Vue.extend({
  name: 'EditorButtonAction',

  components: {
    ButtonActionForm
  },

  props: {
    value: Object,

    sourceId: [Number, String],
    isHeaders: Boolean,
    sourceType: String,
    isButton: Boolean
  },

  inject: {
    getRegistryId: {
      default: () => null
    }
  },

  watch: {
    value: {
      handler: function (value) {
        if (JSON.stringify(value) !== JSON.stringify(this.localValue)) {
          this.localValue = cloneObject(value)
        }
      },
      deep: true
    },

    localValue: {
      handler: function (value) {
        if (JSON.stringify(value) !== JSON.stringify(this.value)) {
          this.$emit('change', cloneObject(value))
        }
      },
      deep: true
    }
  },

  computed: {
    selectValue () {
      const action = this.actions.find(a => a.id === this.localValue.type)
      if (action) {
        return action.name
      }

      return 'Не задано'
    },

    selectEditValue () {
      if (!this.editValue) {
        return ''
      }

      const action = this.actions.find(a => a.id === this.editValue.type)
      if (action) {
        return action.name
      }

      return 'Не задано'
    },

    acceptDisabled () {
      if (!this.editValue || !this.localValue) {
        return true
      }

      return JSON.stringify(this.localValue) === JSON.stringify(this.editValue)
    }
  },

  data () {
    return {
      localValue: this.value,
      visible: false,
      editValue: null,
      // acceptDisabled: true,
      historyValue: null,

      actions: [
        { id: 'open_dashboard', name: this.$t('interface_editor.component_editor.button_action.open_dashboard') },
        { id: 'execute_plugin', name: this.$t('interface_editor.component_editor.button_action.start_plugin') },
        { id: 'open_report', name: this.$t('interface_editor.component_editor.button_action.open_report') },
        { id: 'open_document', name: this.$t('interface_editor.component_editor.button_action.open_document') },
        { id: 'open_xml', name: this.$t('interface_editor.component_editor.button_action.open_xml') },
        { id: 'open_json', name: this.$t('interface_editor.component_editor.button_action.open_json') },
        { id: 'print_page', name: this.$t('interface_editor.component_editor.button_action.print_page') },
        { id: 'autofill_fields', name: this.$t('interface_editor.component_editor.button_action.autofill_fields') },
        { id: 'execute_command', name: this.$t('interface_editor.component_editor.button_action.execute_command') },
        { id: 'open_card', name: this.$t('interface_editor.component_editor.button_action.open_card') },
        { id: 'save_card', name: this.$t('interface_editor.component_editor.button_action.save_card'), hidden: !this.getRegistryId() },
        { id: 'close_card', name: this.$t('interface_editor.component_editor.button_action.close_card'), hidden: !this.getRegistryId() },
        { id: 'close_dashboard', name: this.$t('interface_editor.component_editor.button_action.close_dashboard'), hidden: this.getRegistryId() },
        { id: 'open_history', name: this.$t('interface_editor.component_editor.button_action.open_history'), hidden: !this.getRegistryId() },
        { id: 'open_url', name: this.$t('interface_editor.component_editor.button_action.open_url') },
        { id: 'refresh_card', name: this.$t('interface_editor.component_editor.button_action.refresh_card') },
        { id: 'etl_export', name: this.$t('interface_editor.component_editor.button_action.etl_export') },
        { id: 'open_window', name: this.$t('interface_editor.component_editor.button_action.open_window') },
        { id: 'refresh_replication', name: this.$t('interface_editor.component_editor.button_action.refresh_replication') },
        { id: 'etl_import', name: this.$t('interface_editor.component_editor.button_action.execute_import') },
        { id: 'open_tab_in_block', name: this.$t('interface_editor.component_editor.button_action.open_tab_in_block') },
        { id: 'refresh_components', name: this.$t('interface_editor.component_editor.button_action.refresh_components') },
        { id: 'close_window', name: this.$t('interface_editor.component_editor.button_action.close_window') },
        { id: 'register_user', name: this.$t('interface_editor.component_editor.button_action.register_user') },
        { id: 'delete_records', name: this.$t('interface_editor.component_editor.button_action.delete_records') },
        { id: 'copy_to_clipboard', name: this.$t('interface_editor.component_editor.button_action.copy_to_clipboard') },
        { id: 'execute_listener', name: this.$t('interface_editor.component_editor.button_action.execute_listener') },
        { id: 'complex', name: this.$t('interface_editor.component_editor.button_action.complex') }
      ]
    }
  },

  methods: {
    onAccept () {
      // this.localValue = cloneObject(this.editValue)
      this.$emit('change', cloneObject(this.editValue))
    },

    onEdit () {
      this.visible = true
      this.editValue = cloneObject(this.localValue)
      this.historyValue = cloneObject(this.localValue)
    },

    onClose () {
      this.visible = false
      this.editValue = null
      this.historyValue = null
    },

    onCancel () {
      this.visible = false

      // this.localValue = cloneObject(this.historyValue)
      this.$emit('change', cloneObject(this.historyValue))

      this.editValue = null
      this.historyValue = null
    }
  }
})
</script>

<style lang="scss">
.editor-button-action-from {
  display: block;
  height: 40vh;
  padding: 10px;
}

.editor-button-action {
  .el-dropdown-link {
    cursor: pointer;
  }
}

.editor-button-action__label {
  font-weight: 500;
}

.editor-button-action__label, .editor-button-action__description {
  line-height: 28px;
}

.editor-button-action__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
</style>
