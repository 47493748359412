import Vue from 'vue'
import { getUserFullName } from '../../helpers'
const vm = new Vue()
const connectWS = () => {
  vm.$connect()
}
const disconnectWS = () => {
  vm.$disconnect()
}
export default {
  state: {
    isConnected: false,
    message: null,
    reconnectError: false
  },

  mutations: {
    SOCKET_ONOPEN (state, event) {
      console.warn('соединение c SOCKET установлено')
      Vue.prototype.$socket = event.currentTarget
      state.isConnected = true
    },
    SOCKET_ONCLOSE (state, event) {
      console.warn('соединение c SOCKET разорвано')
      state.isConnected = false
    },
    SOCKET_ONERROR (state, event) {
      console.error(state, event)
    },
    // default handler called for all methods
    SOCKET_ONMESSAGE (state, message) {
      // console.log(message)
      try {
        const parse = JSON.parse(message.data)
        parse.is_show = false
        parse.from_soket = true

        if (!parse.author) {
          parse.author = {
            id: 0,
            name: 'Система',
            midname: '',
            surname: '',
            avatar: null
          }
        }

        parse.author.fullname = getUserFullName(parse.author)

        state.message = parse
      } catch (error) {
        state.message = null
      }
    },
    // mutations for reconnect methods
    SOCKET_RECONNECT (state, count) {
      console.info(state, count)
    },
    SOCKET_RECONNECT_ERROR (state) {
      state.reconnectError = true
    }
  },

  getters: {
    message: function (state) {
      return state.message
    },

    isConnected: function (state) {
      return state.isConnected
    }
  },

  actions: {
    sendMessage: function (context, message) {
      Vue.prototype.$socket.send(message)
    },

    WSConnect ({ commit, state }) {
      // disconnect for localhost
      disconnectWS()
      connectWS()
    },

    WSDisconnect ({ commit, state }) {
      disconnectWS()
    }
  }
}
