import { Nullable } from '@/core/domain/type/types'

export default class UserCreateCommand {
  private roleId: number;
  private name: string;
  private midname: Nullable<string>;
  private surname: Nullable<string>;
  private avatar: Nullable<File>;
  private login: Nullable<string>;
  private password: Nullable<string>;
  private email: Nullable<string>;
  private isAdmin: Nullable<boolean>;
  private isBlocked: Nullable<boolean>;
  private isSystem: Nullable<boolean>;
  private apiKey: Nullable<string>;
  private systemIps: Array<string>;
  private phone: Nullable<number>;
  private twoFactorAuthType: Nullable<string>;
  private telegramId: Nullable<number>;
  private telegramUsername: Nullable<string>;
  private isVerified: Nullable<boolean>;
  private systemUrls: Array<string>;

  constructor (
    roleId: number,
    name: string,
    midname: Nullable<string>,
    surname: Nullable<string>,
    avatar: Nullable<File>,
    login: Nullable<string>,
    password: Nullable<string>,
    email: Nullable<string>,
    isAdmin: Nullable<boolean> = false,
    isBlocked: Nullable<boolean> = false,
    isSystem: Nullable<boolean> = false,
    apiKey: Nullable<string>,
    systemIps: Array<string>,
    phone: Nullable<number>,
    twoFactorAuthType: Nullable<string>,
    telegramId: Nullable<number>,
    telegramUsername: Nullable<string>,
    isVerified: Nullable<boolean>,
    systemUrls: Array<string>
  ) {
    this.roleId = roleId
    this.name = name
    this.midname = midname
    this.surname = surname
    this.avatar = avatar
    this.login = login
    this.password = password
    this.email = email
    this.isAdmin = isAdmin
    this.isBlocked = isBlocked
    this.isSystem = isSystem
    this.apiKey = apiKey
    this.systemIps = systemIps
    this.phone = phone
    this.twoFactorAuthType = twoFactorAuthType
    this.telegramId = telegramId
    this.telegramUsername = telegramUsername
    this.isVerified = isVerified
    this.systemUrls = systemUrls
  }

  getClassName (): string {
    return 'UserCreateCommand'
  }

  getRoleId (): number {
    return this.roleId
  }

  getName (): string {
    return this.name
  }

  getMidname (): Nullable<string> {
    return this.midname
  }

  getSurname (): string {
    return this.surname
  }

  getAvatar (): Nullable<File> {
    return this.avatar
  }

  getLogin (): string {
    return this.login
  }

  getEmail (): Nullable<string> {
    return this.email
  }

  getPassword (): string {
    return this.password
  }

  getIsAdmin (): boolean {
    return this.isAdmin
  }

  getIsBlocked (): boolean {
    return this.isBlocked
  }

  getIsSystem (): boolean {
    return this.isSystem
  }

  getApiKey (): string {
    return this.apiKey
  }

  getSystemIps (): Array<string> {
    return this.systemIps
  }

  getPhone (): Nullable<number> {
    return this.phone
  }

  getTwoFactorAuthType (): Nullable<string> {
    return this.twoFactorAuthType
  }

  getTelegramId (): Nullable<number> {
    return this.telegramId
  }

  getTelegramUsername (): Nullable<string> {
    return this.telegramUsername
  }

  getIsVerified (): boolean {
    return this.isVerified
  }

  getSystemUrls (): Array<string> {
    return this.systemUrls
  }
}
