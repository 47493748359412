import Vue from 'vue'
import { cloneObject } from '../../../../../helpers'

const createDefaultValue = () => cloneObject({
  type: 'never',
  allowOldFormat: false,
  isComplex: true,
  condition_type: 'and',
  conditions: [],
  expressions: {
    logicalOperator: 'and',
    children: []
  }
})

export const EditorExpressionMixin = Vue.extend({
  inject: ['getComponents'],

  props: {
    value: {
      type: Object,
      default () {
        return createDefaultValue()
      }
    },

    label: String,

    options: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  computed: {
    localLabel () {
      if (this.label) {
        return this.$t('interface_editor.' + this.label)
      }

      if (this.options && this.options.label) {
        return this.$t('interface_editor.' + this.options.label)
      }

      return 'Label'
    },

    description () {
      if (this.localValue.type === 'never') {
        return this.$t('interface_editor.component_editor.conditions.never')
      } else if (this.localValue.type === 'always') {
        return this.$t('interface_editor.component_editor.conditions.always')
      } else {
        return this.$t('interface_editor.component_editor.conditions.condition')
      }
    },

    complexOperators () {
      return [
        { value: 'eq', label: 'Равно' },
        { value: 'neq', label: 'Не равно' },
        { value: 'gt', label: 'Больше' },
        { value: 'gte', label: 'Больше либо равно' },
        { value: 'lt', label: 'Меньше' },
        { value: 'lte', label: 'Меньше либо равно' },
        { value: 'in', label: 'Содержит' },
        { value: 'not_in', label: 'Не содержит' },
        { value: 'is_null', label: 'Пусто' },
        { value: 'is_not_null', label: 'Не пусто' }
      ]
    },

    operators () {
      const localePath = 'interface_editor.component_editor.conditions'

      return [
        {
          label: this.$t(localePath + '.equals'),
          value: 'equals'
        },
        {
          label: this.$t(localePath + '.not_equals'),
          value: 'not_equals'
        },
        {
          label: this.$t(localePath + '.is_null'),
          value: 'is_null'
        },
        {
          label: this.$t(localePath + '.is_not_null'),
          value: 'is_not_null'
        },
        {
          label: this.$t(localePath + '.contains'),
          value: 'contains'
        },
        {
          label: this.$t(localePath + '.not_contains'),
          value: 'not_contains'
        },
        {
          label: this.$t(localePath + '.contains_in_array'),
          value: 'contains_in_array'
        },
        {
          label: this.$t(localePath + '.not_contains_in_array'),
          value: 'not_contains_in_array'
        }
      ]
    },

    types () {
      return [
        {
          value: 'never',
          label: this.$t('interface_editor.component_editor.conditions.never')
        },
        {
          value: 'always',
          label: this.$t('interface_editor.component_editor.conditions.always')
        },
        {
          value: 'expression',
          label: this.$t('interface_editor.component_editor.conditions.condition')
        }
      ]
    },

    components () {
      return this.getComponents()
        .filter((cmp) => cmp.properties.name)
        .map(cmp => {
          let label
          if (cmp.initialType) {
            label = cmp.initialType
          }

          if (cmp.properties.label) {
            label = cmp.properties.label
          }

          if (cmp.properties.editorAlias) {
            label = cmp.properties.editorAlias
          }

          return {
            label: `${label} (${cmp.properties.name})`,
            value: cmp.properties.name
          }
        })
    },

    valueTypes () {
      return [
        { value: 'component', label: 'Элемент формы', input: 'items', items: this.components },
        { value: 'attribute', label: 'Атрибут источника данных', input: 'string', items: [] },
        { value: 'constant', label: 'Константа', input: 'string', items: [] },
        { value: 'currentUser', label: 'Текущий пользователь', input: false, items: [] },
        { value: 'currentRole', label: 'Текущая роль', input: false, items: [] },
        { value: 'currentDate', label: 'Текущая дата', input: false, items: [] }
      ]
    }
  },

  watch: {
    value: {
      handler: function (value) {
        if (JSON.stringify(value) !== JSON.stringify(this.localValue)) {
          this.localValue = this.fixValue(this.value)
        }
      },
      deep: true
    },

    localValue: {
      handler: function (value) {
        if (JSON.stringify(value) !== JSON.stringify(this.value)) {
          this.$emit('change', value)
        }
      },
      deep: true,
      immediate: true
    }
  },

  data () {
    return {
      localValue: this.fixValue(this.value)
    }
  },

  methods: {
    fixValue (value) {
      // Значение по умолчанию
      // Должен включить новый виджет построения условия
      const defaultValue = createDefaultValue()

      // Если покаким то причинам значение из свойства неопеделено
      if (!value) {
        return defaultValue
      }

      // Для старых настроек это условие будет истенным
      // Старому объекту с настройками будут присвоены новые свойства, но новый виджет будет отключен
      if (typeof value.isComplex === 'undefined') {
        const fix = Object.assign({}, defaultValue, value)
        fix.allowOldFormat = true
        fix.isComplex = false
        return fix
      }

      return Object.assign({}, defaultValue, value)
    }
  }
})
