import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest';
import { HTTPMethod } from '@/core/infrastructure/api/APIClient';
import { ProjectDTO } from '@/services/MapEditor/domain/model/Project/Project'
import { SourceDTO } from '@/services/MapEditor/domain/model/Source/Source'
import { LayerDTO } from '@/services/MapEditor/domain/model/Layer/Layer'
import { StyleDTO } from '@/services/MapEditor/domain/model/Style/Style'
import { CoordinateSystemDTO } from '@/services/MapEditor/domain/model/Geometry/CoordinateSystem'

export namespace ProjectAPI {

    export class CountProjects implements APIRequest<ProjectDTO> {
        response: ProjectDTO;
        path: string;
        method = HTTPMethod.POST;
        parse = (data: AxiosResponse) => data.data
        constructor(public params: string[][]) {
            this.path = '/mapeditor/projects?*[func]=count'
        }
    }

    export class GetProjects implements APIRequest<ProjectDTO> {
        response: ProjectDTO;
        path = '/mapeditor/projects';
        method = HTTPMethod.POST;
        parse = (data: AxiosResponse) => data.data
        constructor(public params: string[][]) {
            this.path += "?";
            if (params.hasOwnProperty("limit")) {
                this.path += `limit=${params['limit']}&`;
            }
            if (params.hasOwnProperty("offset")) {
                this.path += `offset=${params['offset']}`;
            }
        }
    }

    export class GetProjectByGuid implements APIRequest<ProjectDTO> {
        response: ProjectDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(guid: string) {
            this.path = `/mapeditor/projects/${guid}`;
        }
    }

    export class GetSourceByGuid implements APIRequest<SourceDTO> {
        response: SourceDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(guid: string) {
            this.path = `/mapeditor/sources/${guid}`;
        }
    }

    export class AddProject implements APIRequest<string> {
        response: string;
        path = '/mapeditor/projects';
        method = HTTPMethod.POST;
        parse = (data: AxiosResponse) => data.headers.location
        constructor(public params: ProjectDTO) {}
    }

    export class UpdateProject implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.PUT;
        parse = (data: AxiosResponse) => data.data
        constructor(public params: ProjectDTO) {
            this.path = `/mapeditor/projects/${params.guid}`;
        }
    }

    export class DeleteProject implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.DELETE;
        parse = (data: AxiosResponse) => data.data
        constructor(guid: string) {
            this.path = `/mapeditor/projects/${guid}`;
        }
    }

    export class GetProjectSources implements APIRequest<Array<SourceDTO>> {
        response: Array<SourceDTO>;
        path: string;
        method = HTTPMethod.POST;
        parse = (data: AxiosResponse) => data.data
        constructor(projectGuid: string, public params: string[][]) {
            this.path = `/mapeditor/projects/${projectGuid}/sources`;
        }
    }

    export class GetProjectLayers implements APIRequest<Array<LayerDTO>> {
        response: Array<LayerDTO>;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(projectGuid: string) {
            this.path = `/mapeditor/projects/${projectGuid}/layers`;
        }
    }

    export class GetProjectStyles implements APIRequest<Array<StyleDTO>> {
        response: Array<StyleDTO>;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(projectGuid: string, payload: string[][]) {
            this.path = `/mapeditor/projects/${projectGuid}/styles?` +  (new URLSearchParams(payload).toString())
        }
    }

    export class GetProjectCoordinateSystems implements APIRequest<Array<CoordinateSystemDTO>> {
        response: Array<CoordinateSystemDTO>;
        path: string;
        method = HTTPMethod.POST;
        parse = (data: AxiosResponse) => data.data
        constructor(projectGuid: string, public params: string[][]) {
            this.path = `/mapeditor/projects/${projectGuid}/coordinate_systems`;
        }
    }

    export class GetProjectById implements APIRequest<ProjectDTO> {
        response: ProjectDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(id: number) {
            this.path = `/mapeditor/projects/${id.toString()}`;
        }
    }

    export class GetUserDefaultProject implements APIRequest<object> {
        response: object;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(userId: number) {
            this.path = `/mapeditor/projectdefault/user/${userId.toString()}`;
        }
    }

    export class PutUserDefaultProject implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.POST;
        parse = (data: AxiosResponse) => data.data
        constructor(projectGuid:string, userId: number) {
            this.path = `/mapeditor/projectdefault/${projectGuid}/user/${userId.toString()}`;
        }
    }

    export class DeleteUserDefaultProject implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.DELETE;
        parse = (data: AxiosResponse) => data.data
        constructor(projectGuid:string, userId: number) {
            this.path = `/mapeditor/projectdefault/${projectGuid}/user/${userId.toString()}`;
        }
    }

}