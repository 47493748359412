<template>
  <div>
    <el-button type="text" @click="dialogSettingsVisible = true">{{ $locale.main.button.edit }}</el-button>
    <el-dialog
      :fullscreen="true"
      :title="$locale.interface_editor.component_editor.editor_drop_menu.edit"
      :modal="false"
      :visible.sync="dialogSettingsVisible">
      <el-button class="button_block" size="small" @click="addItem">
        {{ $locale.interface_editor.component_editor.editor_drop_menu.add }}
      </el-button>
      <el-collapse style="height: auto; overflow-y: auto" class="custom_scrollbar">
        <el-collapse-item v-for="(item, index) in items" :key="item.id">
          <template slot="title">
            <div style="display: flex; align-items: center;">
              <el-button @click.prevent.stop="moveItemUp(index)" size="small">
                <i class="el-icon-top"></i>
              </el-button>
              <el-button @click.prevent.stop="moveItemDown(index)" size="small">
                <i class="el-icon-bottom"></i>
              </el-button>
              <span style="margin-left: 10px">{{ (index + 1) + '. ' + item.name }}</span>
            </div>
          </template>
          <div class="main_block">
            <el-form-item :label="$locale.interface_editor.component_editor.editor_drop_menu.name">
              <el-input type="text" size="small" v-model="item.name"></el-input>
            </el-form-item>

            <el-form-item :label="$locale.interface_editor.component_editor.editor_drop_menu.tooltip">
              <el-input type="text" size="small" v-model="item.tooltip"></el-input>
            </el-form-item>

            <el-form-item>
              <buttonAction :options='options' :value='item' @change="changeType(index, $event)"></buttonAction>
            </el-form-item>

            <el-form-item :label="$locale.interface_editor.component_editor.editor_drop_menu.isAllow"
                          style="display: inline-flex;">
              <el-checkbox style="margin-left: 10px;" :value="item.isAllow"
                           @change="$set(item ,'isAllow', $event)"></el-checkbox>
            </el-form-item>
            <el-form-item style="background: skyblue; padding: 10px">
              <EditorConditions v-model="item.isHidden"
                                :options="{label: $locale.interface_editor.component_editor.editor_drop_menu.is_hidden}"/>
            </el-form-item>

            <el-form-item v-if="item.type ==='execute_plugin'">
              <plugin :value="item.plugin" @change="$set(item ,'plugin', $event)"></plugin>
            </el-form-item>

            <el-form-item>
              <div style="display: flex; align-items: center;">
                <el-button style="margin-left: auto" @click="removeItem(item.id)" type="danger" size="small">
                  {{ $locale.interface_editor.component_editor.editor_drop_menu.remove }}
                </el-button>
              </div>
            </el-form-item>
          </div>
        </el-collapse-item>
      </el-collapse>
    </el-dialog>
  </div>
</template>

<script>
import buttonAction from './editor-button-action.vue'
// import buttonAction from '@/components/InterfaceEditor/components/editor/ButtonAction/EditorButtonAction.vue'
import plugin from './editor-plugins'
// import EditorConditions from '@/components/InterfaceEditor/components/editor/Condition/EditorExpression'
import EditorConditions from '@/components/InterfaceEditor/components/editor/editor-conditions'

export default {
  name: 'editor-dropdown-menu',
  components: {
    buttonAction,
    plugin,
    EditorConditions
  },
  props: ['value', 'options'],
  data () {
    return {
      dialogSettingsVisible: false,
      items: this.value || [{ isHidden: { type: 'never', condition_type: 'and', conditions: [] }, id: Math.random() }]
    }
  },
  watch: {
    dialogSettingsVisible: {
      handler (value) {
        if (!value) {
          this.$emit('change', this.items)
        }
      }
    }
  },
  mounted () {
    if (this.value) {
      this.value.forEach(el => {
        if (el.isHidden === undefined) {
          this.$set(el, 'isHidden', {
            type: 'never',
            condition_type: 'and',
            conditions: []
          })
        }
        this.$set(el, 'id', Math.random())
      })
    }
  },
  methods: {
    moveItemUp (index) {
      if (index > 0) {
        const item = this.items.splice(index, 1)[0]
        this.items.splice(index - 1, 0, item)
      }
    },

    moveItemDown (index) {
      if (index < this.items.length - 1) {
        const item = this.items.splice(index, 1)[0]
        this.items.splice(index + 1, 0, item)
      }
    },
    addItem () {
      this.items.push({
        name: '',
        type: '',
        id: Math.random(),
        dashboard: {},
        card: {
          registryId: null,
          type: null,
          fieldId: null,
          constantRecordId: null,
          cardId: null,
          isWindow: false,
          windowWidth: 25,
          windowTitle: null,
          defaults: []
        },
        plugin: '',
        isAllow: false,
        isHidden: { type: 'never', condition_type: 'and', conditions: [] }
      })
    },
    removeItem (id) {
      this.items = this.items.filter(item => item.id !== id)
    },
    changeType (index, value) {
      if (value.type === 'open_dashboard' && value.plugin !== '') {
        value.plugin = ''
      }
    }
  }
}
</script>

<style scoped>

.button_block {
  display: block;
  margin-bottom: 10px;
}

.main_block {
  margin-top: 10px;
  background-color: #f5f5f5;
  border-left: 2px solid #d2d45e;
  padding: 20px;
}

</style>
