import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import EDSQueryRepositoryInterface, { IDssConfirmation } from '@/core/domain/repository/modules/EDSQueryRepositoryInterface'

import { APIClient } from '@/core/infrastructure/api/APIClient'
import { EDSAPI } from '@/core/infrastructure/api/modules/EDSAPI'
import CertificateModel from '@/core/infrastructure/components/EDS/models/CertificateModel'

export default class EDSQueryRepository extends AbstractQueryRepository implements EDSQueryRepositoryInterface {
  getHashes (registryId: number, recordId: number, certificate: string): Promise<any> {
    return APIClient.shared.request(new EDSAPI.GetHashes(registryId, recordId, certificate))
  }

  getSigningFields (registryId: number, recordId: number, params: any): Promise<any> {
    return APIClient.shared.request(new EDSAPI.GetFields(registryId, recordId, params))
  }

  getFullCommandInfo (recordId: number, commandId: number, params: any): Promise<any> {
    return APIClient.shared.request(new EDSAPI.GetFullCommandInfo(recordId, commandId, params))
  }

  createFileWithAllInformation (recordId: number, commandId: number, params: any): Promise<any> {
    return APIClient.shared.request(new EDSAPI.createFileWithAllInformation(recordId, commandId, params))
  }

  generateArchiveAndSave (registryId: number, recordId: number, params: any): Promise<any> {
    return APIClient.shared.request(new EDSAPI.generateArchiveAndSave(registryId, recordId, params))
  }

  dssConfirmation (dssUrl: string, token: string, data: IDssConfirmation): Promise<any> {
    return APIClient.shared.request(new EDSAPI.DssConfirmation(dssUrl, token, data))
  }

  // async uploadSigningDataToDSS (registryId: number, recordId: number, params: any): Promise<Response> {
  //   return APIClient.shared.request(new EDSAPI.generateArchiveAndSave(registryId, recordId, params))
  // }

  async getServerCertificates () {
    return APIClient.shared.request(new EDSAPI.GetServerCertificates()).then((resp) => {
      const certificates = []
      if (!resp.hasOwnProperty('Certificates') || !resp.hasOwnProperty('Count') || resp.Count < 1) {
        return certificates
      }
      Object.getOwnPropertyNames(resp.Certificates).forEach((property) => {
        const certificate = resp.Certificates[property]
        const certificateModel = new CertificateModel(
          certificate
        )
        certificateModel.IssuerName = certificateModel.Issuer['O']
        certificateModel.SubjectName = certificateModel.Subject['O']
        certificateModel.IsValid = true
        certificateModel.Server = true
        certificateModel.Type = 'server'
        certificates.push(certificateModel)
      })
      return certificates
    })
  }

  signFileByServer (): Promise<any> {
    return new Promise(() => {

    })
  }
}
