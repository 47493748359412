export interface IModel {
  [attribute: string]: any;
}

export interface ICardComponentProperties {
  // Имя атрибута/переменной
  name: string;

  // Значение по умолчанию. Присутствует в некоторых компонентах
  defaultValue?: string | null;

  // Дополнительные свойства компонентов реестра
  // Отсутствует в базовых компонентах
  registry_properties?: {
    id: string;
    type: string;
    value: any;
  }[];

  // Прочие свойства
  [prop: string]: any;
}

export interface ICardComponent {
  initialType: string;
  name: string;
  guid: string;
  rendered: boolean;
  properties: ICardComponentProperties;
}

export interface ICardStructureV1 {
  components: ICardComponent[];
  version?: number;
}

export interface ICardStructureV2 {
  components: {
    [componentGuid: string]: ICardComponent;
  };
  blocks: any[];
  settings: {
    [prop: string]: any;
  };
  blockSettings: {
    [prop: string]: any;
  };
  conditions: any[];
  tabSettings: {
    [prop: string]: any;
  };
  windows: any[];
  routes: any[];
  version: number;
  operations: any[];
  titleForAdd: string;
  titleForEdit: string;
  listeners: {
    update_event: string | null;
  };
  btnCloseHidden: boolean;
  confirmCloseCard: boolean;
  confirmCloseCardText: string;
  buttonSave: {
    text: string;
    closeCardOnEdit: boolean;
    closeCardOnAdd: boolean;
    btnSaveHidden: boolean;
  }
}

export type ICardStructure = ICardStructureV1 | ICardStructureV2;

export interface ICard {
  id: number;
  name: string;
  alias: string | null;
  is_default: boolean;
  uses_alternative_source: false;
  card_fields: number[];
  structure: ICardStructure;
}

export async function getStringDefaultValue (defaultValue: string | null, ctx: any) {
  if (defaultValue === '{{user_id}}') {
    return ctx.$store.getters['Authorization/userId']
  } else if (defaultValue === '{{role_id}}') {
    return ctx.$store.getters['Authorization/roleId']
  } else if (/user.attr_[0-9]+_/i.test(defaultValue)) {
    return ctx.$store.getters['Authorization/userAttributeData'](defaultValue.match(/attr_[0-9]+_/gi)[0])
  // } else if (Array.isArray(defaultValue)) {
  //   const temp = []
  //   defaultValue.forEach((item) => {
  //     temp.push(item.name || item)
  //   })
  //   return temp.join(', ')
  } else if (/{{url:(.?)+}}/i.test(defaultValue)) {
    let value = defaultValue.replace('{{url:', '')
    value = value.replace('}}', '')
    const params = new URLSearchParams(document.location.search)
    return params.get(value) || null
  }

  return defaultValue
}

export async function getSelectDefaultValue (defaultValue: string | null, props: ICardComponentProperties, ctx: any) {
  // несколько значений по умолчанию
  if (defaultValue === '{{user_id}}') {
    return ctx.$store.getters['Authorization/userId']
  } else if (defaultValue === '{{role_id}}') {
    return ctx.$store.getters['Authorization/roleId']
  } else if (/user.attr_[0-9]+_/i.test(defaultValue)) {
    return ctx.$store.getters['Authorization/userAttributeData'](defaultValue.match(/attr_[0-9]+_/gi)[0])
  }

  let value

  try {
    // Могли указать JSON: "[1, 2, 3]"
    value = JSON.parse(defaultValue)
  } catch (e) {
    // Если JSON кривой, пробуем просто распарсить как строку с запятыми: "1, 2, 3"
    // split - парсим
    // map - трансформируем каждый элемент в число, либо null
    // filter - удаляем все null значения
    value = defaultValue
      .split(',')
      .map(v => +v.trim() || null)
      .filter(v => v !== null)
  }

  // Мог сработать JSON.parse, который может вернуть boolean, string, number, object, array, null
  // Преобразуем это дело в число, либо null и в зависимости от результата соберём массив
  // +{} = NaN; +'asd123' = NaN; +false = 0; +true = 1; ...;
  if (!Array.isArray(value) || (typeof value === 'object' && !Array.isArray(value))) {
    const prepareValue = +value || null // (NaN или 0 || null) = null

    value = prepareValue ? [prepareValue] : []
  }

  return props.multiple
    ? value
    : value[0] || null
}

export function getDateDefaultValue (defaultValue: string | null, days: number | null, ctx: any) {
  const date = new Date()
  date.setDate(date.getDate() + days)

  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
}

export async function getDefaultValue (componentType: string, defaultValue: string | null, props: ICardComponentProperties, ctx: any) {
  if (!defaultValue) {
    return null
  }

  try {
    if (componentType === 'a-string') {
      return getStringDefaultValue(defaultValue, ctx)
    } else if (componentType === 'select-new' || componentType === 'a-select') {
      return getSelectDefaultValue(defaultValue, props, ctx)
    } else if (componentType === 'a-date') {
      const { defaultValueModifier = null } = props

      return getDateDefaultValue(defaultValue, +defaultValueModifier || 0, ctx)
    } else if (componentType === 'a-radio') {
      return defaultValue || null
    } else {
      return null
    }
  } catch (e) {
    console.error('Ошибка заполнения модели значениями по умолчанию: ' + componentType + '. ' + e)

    return null
  }
}

/**
 * Проходит по каждому компоненту, смотрит свойство "атрибут".
 * Если свойство атрибут заполнено и в this.data нет такого атрибута, тогда добавляет его со значением по умолчанию.
 */
export async function getDefaultValues (structure: ICardStructure, ctx: any): Promise<IModel> {
  const { components = [] } = structure

  const model: IModel = {}

  const isEmpty = function (name: string, model: IModel) {
    return name && (typeof model[name] === 'undefined' || model[name] === null)
  }

  if (Array.isArray(components)) {
    // Если массив, то это список компонентов первой версии редактора Array<ICardComponent>
    for (const { initialType: componentType, properties } of components) {
      const { name = null, defaultValue = null } = properties

      if (isEmpty(name, model)) {
        model[name] = await getDefaultValue(componentType, defaultValue, properties, ctx)
      }
    }
  } else {
    // Если объект, то это список компонетов в виде объекта { [componentGuid: string]: Component }
    for (const componentKey of Object.keys(components)) {
      if (!components.hasOwnProperty(componentKey)) {
        continue
      }

      const { initialType: componentType, properties } = components[componentKey]
      const { name = null, defaultValue = null } = properties

      if (properties) {
        if (isEmpty(name, model)) {
          model[name] = await getDefaultValue(componentType, defaultValue, properties, ctx)
        }
      }
    }
  }

  return model
}

export function cloneObject (data: any): any {
  return JSON.parse(JSON.stringify(data))
}
