<template>
  <div>
    <el-button type="text" @click="dialogSettingsVisible = true">{{ $locale.main.button.edit }}</el-button>

    <el-dialog :title="$locale.interface_editor.component_editor.map_tools.title" :modal="false"
               :visible.sync="dialogSettingsVisible"
               class="window">
      <el-popover trigger="click" placement="bottom" v-model="addButtonDialogVisible">
        <div>
          <div style="margin-bottom: 5px">
            <span class="title">{{ $t('interface_editor.component_editor.map_tools.icons') }}</span>
            <el-select
              v-model="icon"
              size="mini"
              clearable
            >
              <el-option
                v-for="(icon, index) in icons"
                :key="index"
                :label="icon.label"
                :value="icon.value">
                <span style="float: left">{{ icon.label }}</span>
                <div style="float: right;font-size: 30px;width: 22px;height: 22px"><font-awesome-icon :icon="icon.value"/></div>
              </el-option>
            </el-select>
          </div>
          <span class="title">{{ $t('interface_editor.component_editor.map_tools.custom_button_title') }}</span>
          <div style="margin-bottom: 5px">
            <el-input v-model="customButtonName"></el-input>
          </div>
          <div>
            <el-button
              :disabled="canAdd"
              @click="addButton"
            >
              {{ $t('interface_editor.component_editor.map_tools.add_button') }}
            </el-button>
          </div>
        </div>
        <el-button type="primary" size="small" slot="reference" @click="1">
          {{ $t('main.button.add') }}
        </el-button>
      </el-popover>
      <el-row>
        <el-col :span="12">
          <table width="100%" style="border-spacing: 0px;">
            <draggable v-model="tools" group="tools" style="display:contents;">
              <tr v-for="(tool, index) in tools" :key="index" @click="activeTool = tool">
                <td style="width: 10%">
                  <font-awesome-icon :icon="tool.icon"/>
                </td>
                <td style="width: 80%" :class="{'active-tool': activeTool && activeTool.id === tool.id}">
                  {{ locale(tool) }}
                </td>
                <td style="width: 10%">
                  <el-button v-if="tool.isCustom" type="danger" icon="el-icon-delete" size="mini" @click="deleteButton(index)"></el-button>
                </td>
                <td style="width: 10%">
                  <el-tag type="info" size="mini" v-if="tool.hidden">Скрыто</el-tag>
                </td>
              </tr>
            </draggable>
          </table>
        </el-col>
        <el-col :span="12" v-if="activeTool" :key="activeTool.id">
          <div class="title">Настройки</div>
          <el-form size="mini" style="padding: 10px">
            <el-row>
              <span style="margin-right: 10px">Скрыть</span>
              <el-checkbox v-model="activeTool.hidden"></el-checkbox>
            </el-row>
            <el-row v-if="!activeTool.hidden">
              <span style="margin-right: 10px">Отображать только в полноэкранном режиме</span>
              <el-checkbox v-model="activeTool.showOnlyOnFullscreen"></el-checkbox>
            </el-row>
            <el-row v-if="activeTool.isCustom">
              <span style="margin-right: 10px">Название кнопки</span>
              <el-input v-model="activeTool.customButtonName"></el-input>
            </el-row>
            <el-row v-if="optionsToHide[activeTool.id]">
              <span style="margin-right: 10px">Скрыть пункты выпадающего списка</span>
              <el-select
                v-model="activeTool.hidedOptions"
                multiple
                nullable
              >
                <el-option
                  v-for="item in optionsToHide[activeTool.id]"
                  :label="item.name"
                  :value="item.id"
                  :key="item.id"
                ></el-option>
              </el-select>
            </el-row>
            <br>
            <component v-if="activeTool.editor" :is="activeTool.editor" v-model="activeTool"></component>
          </el-form>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faLayerGroup, faSearch, faRulerCombined, faPen, faGlobe, faExpandArrowsAlt, faCompressArrowsAlt, faMapMarkerAlt, faTrashAlt, faVectorSquare, faCamera, faFileImport, faFileExport, faCheck } from '@fortawesome/free-solid-svg-icons'
import draggable from 'vuedraggable'
import PositionOnEditor from './PositionOnEditor'
import BindingRadius from './BindingRadius'
import SearchLongFieldEditor from './SearchLongFieldEditor'
import ImportFeaturesEditor from './ImportFeaturesEditor'
import CustomButtonEditor from './CustomButton'
library.add(faLayerGroup, faSearch, faRulerCombined, faPen, faGlobe, faExpandArrowsAlt, faCompressArrowsAlt, faMapMarkerAlt, faTrashAlt, faVectorSquare, faCamera, faFileImport, faFileExport, faCheck)

export default {
  components: {
    FontAwesomeIcon,
    draggable,
    PositionOnEditor,
    BindingRadius,
    SearchLongFieldEditor,
    ImportFeaturesEditor,
    CustomButtonEditor
  },
  name: 'map-tools',
  props: {
    value: {
      type: Object
    }
  },
  data () {
    return {
      dialogSettingsVisible: false,
      activeTool: undefined,
      tools: [
        { id: 'layers', hidden: false, showOnlyOnFullscreen: false, icon: 'layer-group', index: 1 },
        { id: 'search', hidden: true, showOnlyOnFullscreen: false, icon: 'search', index: 2 },
        { id: 'change-selection-type', hidden: false, showOnlyOnFullscreen: false, icon: 'vector-square', hidedOptions: [], index: 3 },
        { id: 'measurement', hidden: false, showOnlyOnFullscreen: false, icon: 'ruler-combined', hidedOptions: [], index: 4 },
        { id: 'position-on', hidden: false, showOnlyOnFullscreen: false, icon: 'search', hidedOptions: [], index: 5, editor: 'PositionOnEditor', properties: {} },
        { id: 'drawing-geometry', hidden: false, showOnlyOnFullscreen: false, icon: 'pen', hidedOptions: [], index: 6, editor: 'BindingRadius', properties: {} },
        { id: 'delete-features', hidden: false, showOnlyOnFullscreen: false, icon: 'trash-alt', index: 7 },
        { id: 'google-street-view', hidden: false, showOnlyOnFullscreen: false, icon: 'globe', index: 8 },
        { id: 'print', hidden: false, showOnlyOnFullscreen: false, icon: 'camera', index: 9 },
        { id: 'import-features', hidden: false, showOnlyOnFullscreen: false, icon: 'file-import', hidedOptions: [], index: 10, editor: 'ImportFeaturesEditor', properties: {} },
        { id: 'export-features', hidden: false, showOnlyOnFullscreen: false, icon: 'file-export', hidedOptions: [], index: 11 },
        { id: 'expand', hidden: false, showOnlyOnFullscreen: false, icon: 'expand-arrows-alt', index: 12 },
        { id: 'search-component', hidden: true, showOnlyOnFullscreen: false, icon: 'pen', index: 13, component: 'SearchLongField', editor: 'SearchLongFieldEditor', properties: {}, width: 3 }
      ],
      icons: [
        { value: 'layer-group', label: 'layer-group' },
        { value: 'search', label: 'search' },
        { value: 'vector-square', label: 'vector-square' },
        { value: 'ruler-combined', label: 'ruler-combined' },
        { value: 'map-marker-alt', label: 'map-marker-alt' },
        { value: 'pen', label: 'pen' },
        { value: 'trash-alt', label: 'trash-alt' },
        { value: 'globe', label: 'globe' },
        { value: 'camera', label: 'camera' },
        { value: 'file-import', label: 'file-import' },
        { value: 'file-export', label: 'file-export' },
        { value: 'expand-arrows-alt', label: 'expand-arrows-alt' }
      ],
      optionsToHide: {
        'change-selection-type': [
          { id: 'singleclick', name: 'точкой' },
          { id: 'rectangle', name: 'прямоугольником' },
          { id: 'polygon', name: 'многоугольником' },
          { id: 'circle', name: 'окружностью' },
          { id: 'intersection', name: 'поиск пересечений' },
          { id: 'rosreestr', name: 'сведения Росреестра' }
        ],
        'measurement': [
          { id: 'distance', name: 'Дистанция' },
          { id: 'area', name: 'Площадь' },
          { id: 'reset', name: 'Очистить' }
        ],
        'position-on': [
          { id: 'coordinates', name: 'Координаты' },
          { id: 'address', name: 'Адрес' },
          { id: 'cadastral_number', name: 'Поиск в Росреестре' },
          { id: 'defineAddress', name: 'Определение адреса' }
        ],
        'drawing-geometry': [
          { id: 'Point', name: 'Точка' },
          { id: 'LineString', name: 'Линия' },
          { id: 'Polygon', name: 'Полигон' },
          { id: 'Circle', name: 'Окружность' },
          { id: 'Vertices', name: 'По координатам' },
          { id: 'Edit', name: 'Редактировать' },
          { id: 'EditByVertices', name: 'Редактирование по вершинам' },
          { id: 'CreateTextBox', name: 'Создать надпись' },
          { id: 'DeleteTextBoxes', name: 'Удалить все надписи' },
          { id: 'Snap', name: 'Привязка к вершинам' }
        ],
        'import-features': [
          { id: 'file-dxf', name: 'Файл' },
          { id: 'string-wkt', name: 'Строка wkt'}
        ],
        'export-features': [
          { id: 'vector', name: 'Экспорт векторов' },
          { id: 'png', name: 'Экспорт карты в PNG' },
          { id: 'geoTiff', name: 'Экспорт карты в GeoTiff' }
        ]
      },
      addButtonDialogVisible: false,
      icon: null,
      customButtonName: ''
    }
  },
  mounted () {
    this.tools.forEach((item, index) => {
      if (this.value[item.id]) {
        this.$set(item, 'hidden', this.value[item.id].hidden)
        this.$set(item, 'index', this.value[item.id].index)
        this.$set(item, 'showOnlyOnFullscreen', this.value[item.id].showOnlyOnFullscreen)
        if (this.value[item.id].properties && Object.keys(this.value[item.id].properties).length > 0) {
          this.$set(item, 'properties', this.value[item.id].properties)
        }
        if (this.value[item.id].width) {
          this.$set(item, 'width', this.value[item.id].width)
        }
        if (this.value[item.id].hidedOptions) {
          this.$set(item, 'hidedOptions', this.value[item.id].hidedOptions)
        }
      }
    })

    Object.entries(this.value).forEach((item, index) => {
      if (item[0].match('custom_button')) {
        item[1].id = item[0]
        item[1].editor = 'CustomButtonEditor'
        this.tools.push(item[1])
      }
    })

    this.tools = this.tools.sort((a, b) => {
      return a.index - b.index
    })
  },
  computed: {
    result () {
      let object = {}
      this.tools.forEach((item, index) => {
        object[item.id] = {
          hidden: item.hidden,
          showOnlyOnFullscreen: item.showOnlyOnFullscreen,
          index: index + 1,
          properties: item.properties,
          width: item.width ?? undefined,
          isCustom: item.isCustom,
          icon: item.icon,
          customButtonName: item.customButtonName,
          hidedOptions: item.hidedOptions
        }
      })
      return object
    },
    canAdd () {
      return this.customButtonName.length === 0
    }
  },
  watch: {
    tools: {
      deep: true,
      handler () {
        this.$emit('change', this.result)
      }
    }
  },
  methods: {
    addPropertiesRecursively (object, property) {
      Object.entries(property).forEach((item) => {
        // item[0] - key item[1] - value
        console.log(object.toString(), item[0], item[1])
        if (typeof item[1] === 'object' && item[1] !== null && Object.keys(item[1]).length > 0) {
          object[item[0]] = this.addPropertiesRecursively(object[item[0]], item[1])
        } else {
          object[item[0]] = item[1]
        }
      })
      return object
    },
    addButton () {
      this.tools.push({
        id: `custom_button_` + this.generateGuid(),
        hidden: false,
        showOnlyOnFullscreen: false,
        icon: this.icon,
        index: this.tools.length + 1,
        editor: 'CustomButtonEditor',
        properties: {},
        isCustom: true,
        customButtonName: this.customButtonName
      })

      this.icon = null
      this.customButtonName = ''
    },
    deleteButton (index) {
      this.activeTool = undefined
      this.tools.splice(index, 1)
    },
    locale (button) {
      if (button.isCustom) {
        return button.customButtonName
      }
      return this.$locale.interface_editor.component_editor.map_tools[button.id]
    }
  }
}
</script>

<style scoped>
.window > /deep/ .el-dialog {
  width: 60%;
}
td {
  border-bottom: 1px solid #dcdfe6;
  padding: 8px;
  cursor: pointer;
}
td:last-child {
  border-right: 1px solid #dcdfe6;
}
.active-tool {
  font-weight: bold;
}
.active-tool ~ td {
  border-right: none;
}
.title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  color: #0D0F11;
  padding-left: 15px;
  padding-top: 2px;
  padding-bottom: 4px;
}
</style>
