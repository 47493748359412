export default {
  inject: {
    // потому что inject есть в InterfaceViewer, конфликтуют имена
    _getReplicationComponents: {
      from: 'getReplicationComponents',
      default: () => () => {
        return {}
      }
    }
  },
  methods: {
    refreshComponent (component) {
      if (typeof component[0].loadData === 'function') {
        component[0].loadData()
      } else if (component[0].$refs.hasOwnProperty('table')) {
        component[0].$refs.table.$refs.tableBody.$refs.grid.load()
      } else if (component[0].$refs.hasOwnProperty('grid')) {
        component[0].$refs.grid.load()
      } else if (component[0].$refs.hasOwnProperty('Atree')) {
        component[0].loadEntities().then(response => {
          this.$set(component[0], 'data', response)
        })
      } else if (typeof component[0].initCalendar === 'function') {
        component[0].initCalendar().then()
      } else if (typeof component[0].defaultValue !== 'undefined' && /user.attr_[0-9]+_/i.test(component[0].defaultValue)) {
        this.$store.getters['Authorization/userAttributeData'](component[0].defaultValue.match(/attr_[0-9]+_/gi)[0])
          .then((response) => {
            component[0].onInput(response)
          })
      }
    },
    async refreshComponents (components) {
      if (typeof components !== 'undefined' && components.length > 0) {
        let componentsAttrs = []
        let replicationComponents = {}
        if (typeof this.getReplicationComponents === 'function') {
          replicationComponents = this.getReplicationComponents() || {}
        } else if (typeof this._getReplicationComponents === 'function') {
          replicationComponents = this._getReplicationComponents() || {}
        }
        components.forEach((component) => {
          let vueComponent = this.getDashboardComponents()[`component_${component}`]
          if (typeof vueComponent !== 'undefined' && typeof vueComponent[0] !== 'undefined') {
            this.refreshComponent(vueComponent)
            if (this.getDashboardComponents()[`component_${component}`][0].hasOwnProperty('source')) {
              componentsAttrs.push(this.getDashboardComponents()[`component_${component}`][0].source.valueField.name)
            } else {
              componentsAttrs.push(this.getDashboardComponents()[`component_${component}`][0].name)
            }
          }

          let replicatedComponents = []
          Object.keys(replicationComponents).forEach((guid) => {
            if (replicationComponents.hasOwnProperty(guid)) {
              if (replicationComponents[guid].replication?.oldBlockGuid === component) {
                replicatedComponents.push(guid)
              }
            }
          })
          replicatedComponents.forEach((guid) => {
            let vueComponent = this.getDashboardComponents()[`component_${guid}`]
            if (typeof vueComponent !== 'undefined' && typeof vueComponent[0] !== 'undefined') {
              this.refreshComponent(vueComponent)
            }
          })
        })

        let card = typeof this.getCard === 'function' ? this.getCard() : null
        if (card && typeof card !== 'undefined') {
          if (typeof card.registryId !== 'undefined' && typeof card.recordData.id !== 'undefined') {
            let response = await this.$http.get(`${this.$config.api}/registryservice/registry/${card.registryId}/card/${card.recordData.id}?card_id=${card.cardId}`)
            if (response) {
              componentsAttrs.forEach((component) => {
                let value = response.data[component]
                let parsedValue
                try {
                  parsedValue = JSON.parse(value)
                } catch (e) {
                }
                if (typeof parsedValue === 'object') {
                  value = parsedValue.map(el => el.id)
                }
                this.$set(this.getModel(), component, value)
                this.$set(card.getModel(), component, value)
              })
            }
          }
        }
      }
    }
  }
}
