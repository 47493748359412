<template>
  <div style="height: 100%">
    <cards
      ref="cards"
      v-show="openedCards.length > 0"
      :opened-cards="openedCards"
      :show-breadcrumb="showBreadcrumbs"
      :show-card="showCard"
      :show-back-breadcrumb="showBackBreadcrumb"
      :show-close-card-btn="showCloseCardBtn"
      :show-block-btn="showBlockBtn"
      :show-breadcrumb-empty="showBreadcrumbEmpty"
      :breadcrumb-by-button="breadcrumbByButton"
      :preventUserCard="preventUserCard"
      @cancelChanges="cancelChanges"
      @back="openedCards = openedCards.slice(0, $event + 1)"
      @back-registry="showCard=false; openedCards = []"
      :style="breadcrumbByButton ? 'height: calc(100% - 15px);padding-top: 15px' : 'height: 100%'"
    ></cards>
  </div>
</template>

<script>
export default {
  name: 'CardsWrapper',
  props: {
    showBreadcrumbs: {
      type: Boolean,
      default: true
    },
    showBackBreadcrumb: {
      type: Boolean,
      default: true
    },
    showCloseCardBtn: {
      type: Boolean,
      default: true
    },
    showBlockBtn: {
      type: Boolean,
      default: true
    },
    showBreadcrumbEmpty: {
      type: Boolean,
      default: true
    },
    breadcrumbByButton: {
      type: Boolean,
      default: true
    },
    preventUserCard: {
      type: Boolean,
      default: false
    },
    defaults: {
      type: Object
    },
    openCard: {
      type: Object,
      default () {
        return {}
      }
    },
    openDashboard: {
      type: Object,
      default () {
        return {}
      }
    },
    isOpenInNewtab: {
      type: Boolean,
      default: false
    }
  },
  components: { Cards: () => import('@/components/Registry/Cards.vue') },
  mounted () {
    if (this.defaults?.type === 'card') {
      let { card_id, registry_id, record_id } = this.defaults.properties
      if (card_id && registry_id && record_id) {
        this.$nextTick(() => {
          this.openRegistryCard({
            recordId: record_id,
            cardId: card_id,
            registryId: registry_id,
            preventUserCard: true
          })
        })
        return
      }
    }
    if (this.openCard?.cardId) {
      this.openRegistryCard(this.openCard)
    } else if (this.openDashboard?.dashboardId) {
      let { dashboardId, name, recordId, initialData } = this.openDashboard
      this.openDashboardCard(dashboardId, name, recordId, initialData)
    }
  },
  data () {
    return {
      openedCards: [],
      showCard: false
    }
  },
  computed: {
    computedShowCard () {
      return this.showCard
    }
  },
  watch: {
    showCard (value) {
      if (!value) {
        this.$emit('close')
      }
    }
  },
  provide () {
    return {
      getRegistryRecordId: () => null,
      getRegistryId: () => null,
      openRegistryCard: this.openRegistryCard,
      openDashboardCard: this.openDashboardCard,
      getParentDashboard: () => this,
      $computedShowCard: () => this.computedShowCard,
      cancelChanges: this.cancelChanges
    }
  },
  inject: {
    closeTab: {
      default: () => () => {}
    },
    activeTab: {
      default: () => () => {}
    }
  },
  methods: {
    /**
     * @param {object} params
     * @param {number} params.depth
     * @param {boolean} params.confirmCloseCard - В общих настройках карточки галка "Уведомлять при закрытии карточки"
     * @param {string} params.confirmCloseCardText - Текста сообщения от пользователя
     */
    cancelChanges (params = { depth: null, confirmCloseCard: false, confirmCloseCardText: '' }) {
      let { depth, confirmCloseCard, confirmCloseCardText } = params
      if (depth) {
        this.openedCards = this.openedCards.slice(0, this.openedCards.length - depth)
        if (this.openedCards.length === 0) {
          this.showCard = false
        }
      } else {
        if (this.openedCards.length === 1) {
          this.showCard = false
          this.openedCards = []
          if (this.isOpenInNewtab) {
            this.closeTab(this.activeTab())
          }
        } else {
          this.openedCards = this.openedCards.slice(0, this.openedCards.length - 1)
        }
      }
    },
    openDashboardCard (dashboardId, name, recordId = null, initialData = {}) {
      if (!dashboardId) {
        this.$notify.error({
          title: this.$locale.main.message.error,
          message: this.$locale.main.message.not_saved
        })

        return false
      }
      this.showCard = true

      const issetIndex = this.openedCards.findIndex((item) => {
        return item.id === dashboardId
      })
      if (issetIndex !== -1) {
        if ((issetIndex + 1) === this.openedCards.length) {
          this.openedCards.splice(issetIndex, 1)
          this.$nextTick(() => {
            this.openedCards.push({
              id: dashboardId,
              recordId: recordId,
              name: name || 'Назад',
              initialData: initialData,
              isDashboard: true
            })
          })
        } else {
          this.openedCards = this.openedCards.slice(0, issetIndex + 1)
        }
      } else {
        this.openedCards.push({
          id: dashboardId,
          recordId: recordId,
          name: name || 'Назад',
          initialData: initialData,
          isDashboard: true
        })
      }
    },
    openRegistryCard ({
      registryId,
      cardId,
      cardName,
      recordId = null,
      initialData = {},
      registry = null,
      readOnly,
      preventUserCard = false
    }) {
      if (!cardId || !registryId) {
        this.$notify.error({
          title: this.$locale.main.message.error,
          message: this.$locale.main.message.not_saved
        })

        return false
      }
      this.showCard = true
      const issetIndex = this.openedCards.findIndex((item) => {
        return item.id === cardId && item.recordId === recordId
      })
      if (issetIndex !== -1) {
        this.openedCards = this.openedCards.slice(0, issetIndex + 1)
      } else {
        this.openedCards.push({
          id: cardId,
          registryId: registryId,
          recordId: recordId,
          name: cardName,
          initialData: initialData,
          registry: registry,
          readonly: readOnly,
          preventUserCard: preventUserCard
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
