<template>
  <el-form class="button-action-form" label-position="top" size="mini">
    <el-form-item :label="$t('interface_editor.component_editor.button_action.action')">
      <el-select v-model="localValue.type" filterable clearable>
        <el-option
          v-for="item in actions"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
    </el-form-item>

    <template v-if="localValue.type === 'open_card'">
      <editor-open-card
        :value="localValue"
        :is-headers="isHeaders"
        :source-type="sourceType"
        :source-id="sourceId"
        :is-button="isButton"
        :registry-attrs="registryAttrs"
      ></editor-open-card>

      <el-form-item :label="$t('interface_editor.component_editor.button_action.refresh_replication_blocks')">
        <el-select v-model="localValue.refreshReplication.blocks" multiple clearable>
          <el-option
            v-for="(item, index) in replicationBlocks"
            :key="index"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
    </template>

    <el-form-item
      v-if="(localValue.type === 'open_card' && (localValue.card.type === 'add' || localValue.card.type === 'update'))"
      :label="$t('interface_editor.component_editor.button_action.refresh_selected_components')"
    >
      <el-select v-model="localValue.card.componentsGuid" clearable multiple>
        <el-option
          v-for="(item, index) in components"
          :key="index"
          :label="item.group === 'basic' ? item.properties.editorAlias : item.properties.name"
          :value="item.guid">
          <span style="float: left">{{ item.initialType }}</span>
          <span style="float: right; color: #8492a6; font-size: 13px">{{ item.group === 'basic' ? item.properties.editorAlias : item.properties.name }}</span>
        </el-option>
      </el-select>
    </el-form-item>

    <el-form-item :label="$t('interface_editor.component_editor.button_action.refresh_selected_components')" v-if="localValue.type === 'etl_import'">
      <el-select v-model="localValue.etl_import.componentsGuid" clearable multiple>
        <el-option
          v-for="(item, index) in components"
          :key="index"
          :label="item.group === 'basic' ? item.properties.editorAlias : item.properties.name"
          :value="item.guid">
          <span style="float: left">{{ item.initialType }}</span>
          <span style="float: right; color: #8492a6; font-size: 13px">{{ item.group === 'basic' ? item.properties.editorAlias : item.properties.name }}</span>
        </el-option>
      </el-select>
    </el-form-item>

    <el-form-item :label="$t('interface_editor.component_editor.button_action.execute_plugin_after_import')" v-if="localValue.type === 'etl_import'">
      <editor-plugins :value="localValue.etl_import.pluginAfterImport" @change="localValue.etl_import.pluginAfterImport = $event"></editor-plugins>
    </el-form-item>

    <el-form-item v-if="localValue.type === 'open_card'">
      <el-checkbox v-model="localValue.card.isFastCard">
        {{ $t('interface_editor.component_editor.button_action.fast_card') }}
      </el-checkbox>
    </el-form-item>

    <template v-if="localValue.type === 'execute_command'">
      <el-form-item :label="$t('logic_editor.command.headline')">
        <el-select
          :value="localValue.command.id"
          value-key="id"
          filterable
          @change="localValue.command.id = $event"
        >
          <el-option
            v-for="(item, index) in commands"
            :key="index"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label="$t('interface_editor.component_editor.button_action.success_text')" v-if="localValue.type === 'execute_command'">
        <el-input v-model="localValue.command.success_text"/>
      </el-form-item>

      <el-form-item :label="$t('interface_editor.component_editor.button_action.failure_text')" v-if="localValue.type === 'execute_command'">
        <el-input v-model="localValue.command.failure_text"/>
      </el-form-item>
    </template>

    <template v-if="executePluginAllowedActions.includes(localValue.type)">
      <el-form-item :label="$t('interface_editor.component_editor.table.plugin')">
        <editor-plugins :value="localValue.plugin" @change="localValue.plugin = $event"></editor-plugins>
      </el-form-item>

      <el-form-item :label="$t('interface_editor.component_editor.button_action.card_close_type')">
        <el-select v-model="localValue.command.close_type" filterable clearable>
          <el-option
            v-for="item in [
              { id: 'current_card', name: $t('interface_editor.component_editor.button_action.current_card') },
              { id: 'parent_card', name: $t('interface_editor.component_editor.button_action.parent_card') }
            ]"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-checkbox v-model="localValue.command.is_card_close">
          {{ $t('interface_editor.component_editor.button_action.close_card') }}
        </el-checkbox>
      </el-form-item>

      <el-form-item :label="$t('interface_editor.component_editor.button_action.refresh_selected_components')">
        <el-select v-model="localValue.command.componentsGuid" clearable multiple>
          <el-option
            v-for="(item, index) in components"
            :key="index"
            :label="item.group === 'basic' ? item.properties.editorAlias : item.properties.name"
            :value="item.guid">
            <span style="float: left">{{ item.initialType }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.group === 'basic' ? item.properties.editorAlias : item.properties.name }}</span>
          </el-option>
        </el-select>
      </el-form-item>

      <editor-filters
        style="margin-top: 10px"
        :value="localValue.filters"
        :registry-attrs="registryAttrs"
        title="Параметры"
        type="variable"
        @change="localValue.filters = $event"
      />
    </template>

    <el-form-item v-if="localValue.type === 'execute_command'">
      <el-form-item>
        <el-checkbox v-model="localValue.command.is_save_card_before_command">
          {{ $t('interface_editor.component_editor.button_action.save_card_before_command') }}
        </el-checkbox>
      </el-form-item>

      <el-form-item>
        <el-checkbox v-model="localValue.command.is_save_card">
          {{ $t('interface_editor.component_editor.button_action.save_card') }}
        </el-checkbox>
      </el-form-item>
    </el-form-item>

    <template v-if="localValue.type === 'open_report'">
      <editor-report :value="localValue" />
      <editor-filters
        style="margin-top: 10px"
        :value="localValue.filters"
        :registry-attrs="registryAttrs"
        title="Переменные"
        type="variable"
        @change="localValue.filters = $event"/>
    </template>

    <template v-if="localValue.type === 'open_document'">
      <editor-document :value="localValue" type="document" />

      <el-form-item :label="$t('interface_editor.component_editor.button_action.refresh_selected_components')">
        <el-select v-model="localValue.reports.componentsGuid" clearable multiple>
          <el-option
            v-for="(item, index) in components"
            :key="index"
            :label="item.group === 'basic' ? item.properties.editorAlias : item.properties.name"
            :value="item.guid">
            <span style="float: left">{{ item.initialType }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.group === 'basic' ? item.properties.editorAlias : item.properties.name }}</span>
          </el-option>
        </el-select>
      </el-form-item>

      <editor-filters
        style="margin-top: 10px"
        :value="localValue.filters"
        title="Параметры"
        type="variable"
        @change="localValue.filters = $event"
      />
    </template>

    <template v-if="localValue.type === 'open_xml'">
      <editor-document :value="localValue" type="xml" />
      <editor-filters style="margin-top: 10px" :value="localValue.filters" @change="localValue.filters = $event"/>
    </template>

    <template v-if="value.type === 'open_json'">
      <editor-document :value="value" type="json" />
      <editor-filters style="margin-top: 10px" :value="value.filters" @change="$set(value ,'filters', $event)"/>
    </template>

    <template v-if="localValue.type === 'print_page'">
      <editor-print-page @change="localValue.printSettings.settings = $event" :value="localValue.printSettings" :containers="containers" />
    </template>

    <template v-if="localValue.type === 'autofill_fields'">
      <editor-autofill-fields @change="localValue.autofillSettings.settings = $event" :value="localValue.autofillSettings" />
    </template>

    <template v-if="localValue.type === 'open_dashboard'">
      <el-form-item :label="$t('interface_editor.component_editor.button_action.dashboard_source_type.type')">
        <el-radio-group v-model="localValue.dashboard.dashboardIdSource" size="mini">
          <el-radio-button label="dashboard">{{ $t('interface_editor.component_editor.button_action.dashboard_source_type.dashboard') }}</el-radio-button>
          <el-radio-button label="source" :disabled="!isHeaders">{{ $t('interface_editor.component_editor.button_action.dashboard_source_type.source') }}</el-radio-button>
          <el-radio-button label="component">{{ $t('interface_editor.component_editor.button_action.dashboard_source_type.component') }}</el-radio-button>
        </el-radio-group>

        <div v-if="localValue.dashboard.dashboardIdSource === 'dashboard'">
          <el-form-item :label="$t('interface_editor.component_editor.button_action.dashbord')">
            <editor-dashboards @change="localValue.dashboard.id = $event" :value="localValue.dashboard.id" ></editor-dashboards>
          </el-form-item>
        </div>

        <div v-if="localValue.dashboard.dashboardIdSource === 'source'">
          <el-form-item :label="$t('interface_editor.component_editor.button_action.dashboard_source_type.id_from_source')">
            <editor-select
              :value="localValue.dashboard.id"
              @change="changeDhId($event, 'source')"
              :options="{
                multiple: false,
                clearable: true,
                options: registryAttrs
              }"
            ></editor-select>
          </el-form-item>
        </div>

        <div v-if="localValue.dashboard.dashboardIdSource === 'component'">
          <el-form-item :label="$t('interface_editor.component_editor.button_action.dashboard_source_type.id_from_component')">
            <el-select
              :value="localValue.dashboard.id"
              @change="changeDhId($event, 'component')"
              clearable
            >
              <el-option
                v-for="(item, index) in components.filter((cmp) => cmp.properties.name)"
                :key="index"
                :label="(item.name.match(/attr_(.*?)_/g) || []).length ? `${item.name}` : `${item.name} ${item.properties.name}`"
                :value="item.properties.name"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form-item>

      <el-form-item>
        <template slot="label">
          {{ $t('interface_editor.component_editor.editor_open_card.container') }}

          <el-tooltip placement="top-start" :open-delay="400">
            <div slot="content" v-html="$t('interface_editor.component_editor.editor_open_card.tooltip.container')"></div>
            <i class="el-icon-question"></i>
          </el-tooltip>
        </template>

        <el-select v-model="localValue.dashboard.containerAlias" clearable>
          <el-option
            v-for="(item, index) in containers"
            :key="index"
            :label="item.alias"
            :value="item.alias"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label="$t('interface_editor.component_editor.button_action.frame')">
        <el-select v-model="localValue.dashboard.frameGuid" clearable>
          <el-option
            v-for="(item, index) in components.filter((item) => item.name === 'basic/a-frame')"
            :key="index"
            :label="item.name"
            :value="item.guid"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <editor-filters
          :value="localValue.dashboard.fields"
          :registry-attrs="registryAttrs"
          @change="localValue.dashboard.fields = $event"
          :title="$t('main.fields.default')"
          :show-xref-option="false"
        />
      </el-form-item>

      <el-form-item>
        <el-checkbox v-model="localValue.dashboard.breadcrumbByButton">
          {{ $t('interface_editor.component_editor.button_action.breadcrumb') }}
        </el-checkbox>
      </el-form-item>

      <el-form-item>
        <el-checkbox v-model="localValue.dashboard.isNewTab">
          {{ $t('interface_editor.component_editor.button_action.is_new_tab') }}
        </el-checkbox>
      </el-form-item>

      <el-form-item v-show="localValue.dashboard.isNewTab" label="Название вкладки">
        <el-input v-model="localValue.dashboard.newTabName" />
      </el-form-item>

      <el-form-item :label="$t('interface_editor.component_editor.button_action.window_title')">
        <el-input v-model="localValue.dashboard.window_title"/>
      </el-form-item>

      <el-form-item>
        <el-checkbox v-model="localValue.dashboard.isFullscreen">
          {{ $t('etl_editor.tooltip.full_screen') }}
        </el-checkbox>
      </el-form-item>

      <el-form-item  :label="$t('interface_editor.component_editor.button_action.window_width')">
        <editor-select
          :value="localValue.dashboard.window_width"
          @change="localValue.dashboard.window_width = $event"
          :options="{
            multiple: false,
            options: [
              { id: '25', name:'25%' },
              { id: '50', name:'50%' },
              { id: '75', name:'75%' },
              { id: '100', name:'100%' },
            ]
          }"
        ></editor-select>

        <el-form-item>
          <template slot="label">
            {{ $t('interface_editor.component_editor.button_action.window_height') }} (ex: 400px)
          </template>

          <el-input :value="localValue.dashboard.windowHeight" @input="localValue.dashboard.windowHeight = $event; localValue.card.windowHeight = $event"></el-input>
        </el-form-item>
      </el-form-item>
    </template>

    <el-form-item v-if="localValue.type === 'save_card'">
      <el-checkbox v-model="localValue.saveCard.isCloseAfterSave">
        {{ $t('interface_editor.component_editor.button_action.close_card') }}
      </el-checkbox>

      <el-form-item :label="$t('interface_editor.component_editor.button_action.card_close_type')">
        <editor-select
          :value="localValue.saveCard.close_type"
          @change="localValue.saveCard.close_type = $event"
          :options="{
            multiple: false,
            options: [
              { id: 'current_card', name: $t('interface_editor.component_editor.button_action.current_card') },
              { id: 'parent_card', name: $t('interface_editor.component_editor.button_action.parent_card') }
            ]
          }"
        ></editor-select>
      </el-form-item>
    </el-form-item>

    <el-form-item
      v-if="localValue.type === 'open_url'"
      :label="$t('interface_editor.component_editor.button_action.url_attr')"
    >
      <el-select v-model="localValue.url.urlAttr" clearable>
        <el-option
          v-for="(item, index) in components"
          :key="index"
          :label="item.name"
          :value="item.properties.name"
        >
          <span style="float: left">{{ item.initialType }}</span>
          <span style="float: right; color: #8492a6; font-size: 13px">{{ item.group === 'basic' ? item.properties.editorAlias : item.properties.name }}</span>
        </el-option>
      </el-select>

      <el-form-item :label="$t('interface_editor.component_editor.button_action.url')">
        <el-input v-model="localValue.url.url"/>
      </el-form-item>

      <el-form-item :label="$t('interface_editor.component_editor.button_action.url_open_type')">
        <el-select v-model="localValue.url.openType" clearable>
          <el-option
            v-for="(item, index) in urlOpenOptions"
            :key="index"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form-item>

    <el-form-item v-if="localValue.type === 'refresh_card'">
      <!-- Пока хз, нужен ли этот функционал, по умолчанию скрыто -->
      <el-form-item v-show="false">
        <el-checkbox v-model="localValue.refreshCard.isSaveCardBeforeRefresh">
          {{ $t('interface_editor.component_editor.button_action.is_save_card_before_refresh') }}
        </el-checkbox>
      </el-form-item>

      <el-form-item :label="$t('interface_editor.component_editor.button_action.warn_message.on_edit')">
        <el-input v-model="localValue.refreshCard.warnMessage.onEdit"/>
      </el-form-item>

      <el-form-item :label="$t('interface_editor.component_editor.button_action.warn_message.on_read')">
        <el-input v-model="localValue.refreshCard.warnMessage.onRead"/>
      </el-form-item>
    </el-form-item>

    <el-form-item v-if="localValue.type === 'etl_export'">
      <el-select v-model="localValue.etl_export.task_id" clearable filterable>
        <el-option
          v-for="(item, index) in etl_tasks"
          :key="index"
          :label="item.name"
          :value="item.id"
        >
          <span style="float: left">{{ item.name }}</span>
          <span style="float: right; color: #030303">id: {{ item.id }}</span>
        </el-option>
      </el-select>

      <editor-filters
        :value="localValue.etl_export.filters"
        @change="localValue.etl_export.filters = $event"
        :title="$t('main.fields.default')"
        :show-xref-option="false"/>
    </el-form-item>

    <template v-if="localValue.type === 'open_window'">
      <el-form-item :label="$t('interface_editor.component_editor.button_action.window_settings.choose')">
        <el-select v-model="localValue.window.guid" clearable filterable>
          <el-option
            v-for="(item) in windows"
            :key="item.guid"
            :label="item.name"
            :value="item.guid"
          >
            <span style="float: left">{{ item.name }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">
              {{ `${item.width.value}${item.width.type} x ${item.height.value}${item.height.type}` }}
            </span>
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label="$t('interface_editor.component_editor.button_action.window_settings.title')">
        <el-input v-model="localValue.window.title"/>
      </el-form-item>

      <el-form-item>
        <el-checkbox v-model="localValue.window.popover">
          {{ $t('interface_editor.component_editor.button_action.window_settings.popover') }}
        </el-checkbox>
      </el-form-item>

      <el-form-item>
        <el-checkbox v-model="localValue.window.isClearWindowModel">
          {{ $t('interface_editor.component_editor.button_action.window_settings.clear_window_model') }}
        </el-checkbox>

        <el-form-item v-if="localValue.window.isClearWindowModel">
          <template slot="label">
            {{ $t('interface_editor.component_editor.button_action.window_settings.components_attr') }}
            <el-tooltip placement="top-start" :open-delay="400" style="color: black">
              <div slot="content">
                {{ $t('interface_editor.component_editor.button_action.window_settings.clear_window_model_tooltip') }}
              </div>
              <i class="el-icon-question"></i>
            </el-tooltip>
          </template>

          <el-input v-model="localValue.window.componentsAttr"/>
        </el-form-item>
      </el-form-item>
    </template>

    <template v-if="localValue.type === 'refresh_replication'">
      <el-form-item :label="$t('interface_editor.component_editor.button_action.refresh_replication_blocks')">
        <el-select v-model="localValue.refreshReplication.blocks" multiple clearable>
          <el-option
            v-for="(item, index) in replicationBlocks"
            :key="index"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
    </template>

    <template v-if="localValue.type === 'etl_import'">
      <el-form-item :label="$t('interface_editor.component_editor.button_action.execute_import')">
        <el-form-item>
          <el-select v-model="localValue.etl_import.task_id" clearable filterable>
            <el-option
              v-for="(item, index) in etl_tasks"
              :key="index"
              :label="item.name"
              :value="item.id"
            >
              <span style="float: left">{{ item.name }}</span>
              <span style="float: right; color: #030303">id: {{ item.id }}</span>
            </el-option>
          </el-select>

          <el-form-item :label="$t('etl_editor.header.constant_fields_filter_title')">
            <editor-filters
              :value="localValue.etl_import.fields"
              @change="localValue.etl_import.fields = $event"
              :title="$t('main.fields.default')"
              :show-xref-option="false"
              :is-import="true"
            />
          </el-form-item>
        </el-form-item>

        <el-form-item :label="$t('interface_editor.component_editor.button_action.etl_import_commands.on_success_import')">
          <el-select
            :value="localValue.etl_import.commandIdAfterSuccess"
            value-key="id"
            filterable
            @change="localValue.etl_import.commandIdAfterSuccess = $event"
          >
            <el-option
              v-for="(item, index) in commands"
              :key="index"
              :label="item.name"
              :value="item.id"
            >
              <span style="float: left">{{ item.name + ` (id: ${item.id})` }}</span>
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item :label="$t('interface_editor.component_editor.button_action.etl_import_commands.on_failure_import')">
          <el-select
            :value="localValue.etl_import.commandIdAfterFailure"
            value-key="id"
            filterable
            @change="localValue.etl_import.commandIdAfterFailure = $event"
          >
            <el-option
              v-for="(item, index) in commands"
              :key="index"
              :label="item.name"
              :value="item.id"
            >
              <span style="float: left">{{ item.name + ` (id: ${item.id})` }}</span>
            </el-option>
          </el-select>
        </el-form-item>
      </el-form-item>
    </template>

    <template v-if="localValue.type === 'open_tab_in_block'">
      <el-form-item :label="$t('interface_editor.component_editor.button_action.block')">
        <el-select v-model="localValue.open_tab_in_block.block" clearable>
          <el-option
            v-for="(item) in allBlocks"
            :key="item.guid"
            :label="item.alias"
            :value="item.guid"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label="$t('interface_editor.component_editor.button_action.tab')">
        <el-select v-model="localValue.open_tab_in_block.tab" clearable>
          <el-option
            v-for="(item) in block_tabs"
            :key="item.guid"
            :label="item.name"
            :value="item.guid"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-checkbox v-model="localValue.open_tab_in_block.executeTabAction">
          {{ $t('interface_editor.component_editor.button_action.execute_tab_action') }}
        </el-checkbox>
      </el-form-item>
    </template>

    <template v-if="localValue.type === 'refresh_components'">
      <el-select v-model="localValue.refresh_components.components" filterable size="mini" multiple clearable>
        <el-option
          v-for="(item, index) in components.filter((cmp) => cmp.properties.name)"
          :key="index"
          :label="`${item.name} (${item.properties.name})`"
          :value="item.guid"
        ></el-option>
      </el-select>
      <div><em>**Если выбран компонент "реестр", "аналитика" или "таблица" и в фильтрах есть <b>"отложенный фильтр"</b>, то он применится при нажатии.</em></div>
    </template>

    <template v-if="localValue.type === 'delete_records'">
      <el-radio-group v-model="recordsDeleteType" size="mini" @change="localValue.delete_records.deleteType = $event">
        <el-radio-button label="component">{{ $t('interface_editor.component_editor.button_action.delete_records_hints.component') }}</el-radio-button>
        <el-radio-button label="current_record">{{ $t('interface_editor.component_editor.button_action.delete_records_hints.current_record') }}</el-radio-button>
      </el-radio-group>

      <el-form-item v-if="recordsDeleteType === 'component'">
        <template slot="label">
          {{ $t('interface_editor.component_editor.button_action.dashboard_source_type.component') }}

          <el-tooltip placement="top-start" :open-delay="400">
            <div slot="content">
              {{ $t('interface_editor.component_editor.button_action.delete_records_hints.allowed_components_hint') }}

              <p>{{ $t('interface_editor.component_editor.button_action.delete_records_hints.fields_hint') }}</p>
            </div>

            <i class="el-icon-question"></i>
          </el-tooltip>
        </template>

        <el-select v-model="localValue.delete_records.componentGuid" filterable size="mini" clearable>
          <el-option
            v-for="(item, index) in components.filter((cmp) => deleteRecordsAllowedComponents.includes(cmp.initialType))"
            :key="index"
            :label="`${item.name} (${item.properties.name})`"
            :value="item.guid"
          ></el-option>
        </el-select>

        <el-form-item>
          <template slot="label">
            {{ $t('interface_editor.component_editor.editor_open_card.source_table_attrs') }}

            <el-tooltip placement="top-start" :open-delay="400">
              <div slot="content">
                {{ $t('interface_editor.component_editor.button_action.delete_records_hints.attrs_hint') }}
              </div>

              <i class="el-icon-question"></i>
            </el-tooltip>
          </template>

          <editor-select
            ref="delete_record_id"
            :value="localValue.delete_records.recordId"
            @change="localValue.delete_records.recordId = $event"
            :options="{
              multiple: false,
              clearable: true,
              options: registryAttrs
            }"
          ></editor-select>
        </el-form-item>

        <el-form-item :label="$t('interface_editor.component_editor.editor_calendar.registry_id')">
          <editor-select
            ref="delete_registry_id"
            :value="localValue.delete_records.registryId"
            @change="localValue.delete_records.registryId = $event"
            :options="{
              multiple: false,
              clearable: true,
              options: registryAttrs
            }"
          ></editor-select>
        </el-form-item>
      </el-form-item>
    </template>

    <el-form-item
      v-if="localValue.type === 'copy_to_clipboard'"
      :label="$t('interface_editor.component_editor.button_action.dashboard_source_type.component')"
    >
      <el-select v-model="localValue.copy_to_clipboard.componentGuid" filterable size="mini" clearable>
        <el-option
          v-for="(item, index) in components.filter((cmp) => !copyToClipboardExcludedComponents.includes(cmp.initialType))"
          :key="index"
          :label="`${item.name} (${item.properties.name})`"
          :value="item.guid"
        ></el-option>
      </el-select>
    </el-form-item>

    <el-form-item
      v-if="localValue.type === 'execute_listener'"
      :label="$t('interface_editor.component_editor.button_action.execute_listener')"
    >
      <el-input v-model="localValue.listeners.listenerName"></el-input>
    </el-form-item>

    <el-form-item
      v-if="localValue.type === 'complex'"
      :label="$t('interface_editor.component_editor.button_action.complex')"
    >
      <div class="complex-action">
        <el-tooltip class="item" effect="dark" content="Добавить" placement="top" :open-delay="500">
          <el-button
            type="success"
            icon="el-icon-plus"
            class="el-button--square complex-action__add"
            @click="addAction"
          ></el-button>
        </el-tooltip>

        <draggable
          v-model="localValue.complex.actions"
          v-bind="dragOptions"
          @start="drag = true"
          @end="drag = false"
        >
          <transition-group type="transition" :name="!drag ? 'flip-list' : null">
            <div
              v-for="(action, index) in localValue.complex.actions"
              :key="index"
              class="complex-action-item"
            >
              <div class="complex-action-item__label">
                <div style="display: flex;align-items: center;">
                  <el-tooltip class="item" effect="dark" content="Зажмите и перетащите элемент" placement="top" :open-delay="500">
                    <span class="el-icon-menu"></span>
                  </el-tooltip>

                  <span style="margin-left: 10px;">{{ getActionLabel(action.type) }}</span>
                </div>

                <div style="display: flex;align-items: center;">
                  <el-tooltip class="item" effect="dark" content="Скрыть / Раскрыть" placement="top" :open-delay="500">
                    <el-button
                      type="default"
                      :icon="complexVisible[index] ? 'el-icon-caret-bottom' : 'el-icon-caret-right'"
                      class="el-button--square"
                      @click="toggleAction(index)"
                    ></el-button>
                  </el-tooltip>

                  <el-tooltip class="item" effect="dark" content="Удалить" placement="top" :open-delay="500">
                    <el-button
                      type="danger"
                      icon="el-icon-delete"
                      class="el-button--square"
                      @click="removeAction(index)"
                    ></el-button>
                  </el-tooltip>
                </div>
              </div>

              <div class="complex-action-item__content" ref="complexActions" v-show="complexVisible[index]">
                <button-action-form
                  v-model="localValue.complex.actions[index]"
                  :actions="actions"
                  :sourceId="sourceId"
                  :isHeaders="isHeaders"
                  :sourceType="sourceType"
                  :isButton="isButton"
                ></button-action-form>
              </div>
            </div>
          </transition-group>
        </draggable>
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
import Vue from 'vue'
import { cloneObject } from '@/helpers'
import EditorPlugins from '../editor-plugins.vue'
import EditorSelect from '../editor-select.vue'
import EditorDashboards from '../editor-dashboards.vue'
import EditorFilters from '../editor-filters.vue'
import EditorOpenCard from '../editor-open-card.vue'
import EditorDocument from '../editor-document.vue'
import EditorReport from '../editor-report.vue'
import EditorPrintPage from '../editor-print-page.vue'
import EditorAutofillFields from '../editor-autofill-fields.vue'
import Registry from '../../../../Models/EntityModel'
import Icons from '../../../../MenuEditor/components/el-icons.json'
import draggable from 'vuedraggable'

export default Vue.extend({
  name: 'ButtonActionForm',

  components: {
    EditorPlugins,
    EditorSelect,
    EditorDashboards,
    EditorFilters,
    EditorOpenCard,
    EditorDocument,
    EditorReport,
    EditorPrintPage,
    EditorAutofillFields,
    draggable
  },

  inject: {
    getRegistryId: {
      default: () => null
    },

    getComponents: {
      default: () => {
        return []
      }
    },

    getWindows: {
      default: () => () => []
    },

    getContainersStore: {
      default: () => () => null
    }
  },

  props: {
    value: Object,

    actions: {
      type: Array,
      default () {
        return []
      }
    },

    sourceId: [Number, String],
    isHeaders: Boolean,
    sourceType: String,
    isButton: Boolean
  },

  watch: {
    value: {
      handler: function (value) {
        if (JSON.stringify(value) !== JSON.stringify(this.localValue)) {
          this.localValue = cloneObject(value)
        }
      },
      deep: true
    },

    localValue: {
      handler: function (value) {
        if (JSON.stringify(value) !== JSON.stringify(this.value)) {
          this.$emit('input', cloneObject(value))
        }
      },
      deep: true
    },

    'localValue.type': {
      handler (value) {
        this.$set(this.localValue, 'filters', [])

        if (value === 'execute_command') {
          this.$set(this.localValue, 'command', {
            id: null,
            success_text: null,
            failure_text: null,
            is_card_close: null,
            close_type: null,
            is_save_card: null,
            is_reopen_card: null,
            componentsGuid: [],
            is_save_card_before_command: false
          })
        } else if (value === 'open_dashboard') {
          this.$set(this.localValue, 'dashboard', {
            id: null,
            isFullscreen: false,
            window_width: 25,
            windowHeight: null,
            window_title: null,
            breadcrumbByButton: true,
            dashboardIdSource: null
          })
        } else if (value === 'execute_plugin') {
          this.$set(this.localValue, 'command', {
            is_card_close: null
          })
        } else if (value === 'open_card') {
          this.$set(this.localValue, 'card', {
            registryId: null,
            recordId: null,
            type: null,
            fieldId: null,
            queryRegistry: null,
            queryCard: null,
            queryRecord: null,
            constantRecordId: null,
            cardId: null,
            isWindow: false,
            windowWidth: 25,
            windowTitle: null,
            defaults: [],
            isFastCard: false,
            componentsGuid: []
          })
          this.$set(this.localValue, 'refreshReplication', {
            blocks: []
          })
        } else if (value === 'open_report') {
          this.$set(this.localValue, 'reports', {
            id: null,
            guid: null,
            name: null
          })
        } else if (value === 'open_document') {
          this.$set(this.localValue, 'reports', {
            id: null,
            guid: null,
            name: null,
            formatType: null,
            viewType: null,
            registryId: null,
            assocFieldId: null,
            openSavedVersion: false,
            reportName: null,
            componentsGuid: []
          })
        } else if (value === 'open_xml') {
          this.$set(this.localValue, 'reports', {
            id: null,
            guid: null,
            name: null,
            formatType: 'xml',
            viewType: null,
            registryId: null,
            assocFieldId: null,
            openSavedVersion: false
          })
        } else if (value === 'open_json') {
          this.$set(this.value, 'reports', {
            id: null,
            guid: null,
            name: null,
            formatType: 'json',
            viewType: null,
            registryId: null,
            assocFieldId: null,
            openSavedVersion: false
          })
        } else if (value === 'print_page') {
          this.$set(this.localValue, 'printSettings', {
            id: null,
            guid: null,
            name: null
          })
        } else if (value === 'autofill_fields') {
          this.$set(this.localValue, 'autofillSettings', {
            id: null,
            guid: null,
            name: null
          })
        } else if (value === 'saveCard') {
          if (!('saveCard' in this.localValue)) {
            this.$set(this.localValue, 'saveCard', {
              isCloseAfterSave: false,
              saveCard: null
            })
          }
        } else if (value === 'open_url') {
          if (!('url' in this.localValue)) {
            this.$set(this.localValue, 'url', {
              url: null,
              openType: null
            })
          }
        } else if (value === 'refresh_card') {
          if (!('refreshCard' in this.localValue)) {
            this.$set(this.localValue, 'refreshCard', {
              warnMessage: {
                onEdit: null,
                onRead: null
              },
              isSaveCardBeforeRefresh: false
            })
          }
        } else if (value === 'etl_export') {
          if (!('etl_export' in this.localValue)) {
            this.$set(this.localValue, 'etl_export', {
              etl_export: {
                task_id: null,
                tasks: [],
                filters: []
              }
            })
          }
        } else if (value === 'open_window') {
          if (!('window' in this.localValue)) {
            this.$set(this.localValue, 'window', {
              guid: null
            })
          }
        } else if (value === 'refresh_replication') {
          if (!('window' in this.localValue)) {
            this.$set(this.localValue, 'refreshReplication', {
              blocks: []
            })
          }
        } else if (value === 'etl_import') {
          this.$set(this.localValue, 'etl_import', {
            task_id: null,
            fields: [],
            componentsGuid: [],
            commandIdAfterSuccess: null,
            commandIdAfterFailure: null,
            pluginAfterImport: null
          })
        } else if (value === 'open_tab_in_block') {
          this.$set(this.localValue, 'open_tab_in_block', {
            block: null,
            tab: null,
            executeTabAction: false
          })
        } else if (value === 'refresh_components') {
          this.$set(this.localValue, 'refresh_components', {
            components: []
          })
        } else if (value === 'delete_records') {
          this.$set(this.localValue, 'delete_records', {
            isBulkDelete: false,
            recordId: null,
            registryId: null,
            componentGuid: null
          })
        } else if (value === 'copy_to_clipboard') {
          this.$set(this.localValue, 'copy_to_clipboard', {
            componentGuid: null
          })
        } else if (value === 'execute_listener') {
          this.$set(this.localValue, 'listeners', {
            listenerName: ''
          })
        } else if (value === 'complex') {
          this.complexVisible = []

          this.$set(this.localValue, 'complex', {
            actions: []
          })
        }
      }
    },

    'localValue.etl_import.task_id': {
      handler: function (value) {
        if (value !== null && typeof value === 'number') {
          this.$http.get(`${this.$config.api}/etleditor/task_objects/${value}`)
            .then((response) => {
              if (response.data.loader_object_id) {
                this.$http.get(`${this.$config.api}/v2/logiceditor/logic?entity_id=${response.data.loader_object_id}`)
                  .then((response) => {
                    if (response.data[0]) {
                      this.$http.get(`${this.$config.api}/v2/logiceditor/commands?logic_id=${response.data[0].id}`)
                        .then((res) => {
                          this.commands = res.data
                        })
                    }
                  })
              }
            })
        }
      }
    },

    'localValue.open_tab_in_block.block': {
      handler: function (guid) {
        const blockWithTabs = this.getContainersStore().getByGuid(guid)
        this.$set(this.localValue.open_tab_in_block, 'tab', null)

        if (typeof blockWithTabs === 'undefined' || typeof blockWithTabs.tabs === 'undefined') {
          this.block_tabs = []
        } else {
          this.block_tabs = blockWithTabs.tabs.list
        }
      }
    },

    'localValue.dashboard.dashboardIdSource': {
      handler: function () {
        this.$set(this.localValue.dashboard, 'id', null)
      }
    },

    'localValue.delete_records.componentGuid': {
      handler () {
        this.$set(this.localValue.delete_records, 'recordId', null)
        this.$set(this.localValue.delete_records, 'registryId', null)

        if (this.$refs.delete_record_id || this.$refs.delete_registry_id) {
          this.$refs.delete_record_id.clearValue()
          this.$refs.delete_registry_id.clearValue()
        }

        this.determineDeleteRecordsActionEntitySource(this.localValue.delete_records)
      }
    }
  },

  computed: {
    containers () {
      if (!this.containersStore) {
        return
      }

      return this.containersStore.getFlat().filter(container => container.alias != null)
    },

    replicationBlocks () {
      if (!this.getContainersStore()) {
        return []
      }

      const store = this.getContainersStore()

      const blocks = store.getFlat()

      return blocks.reduce((array, block) => {
        if (block.replication && block.replication !== 'None') {
          array.push({
            name: block.alias || block.guid,
            value: block.guid
          })
        }

        return array
      }, [])
    },

    allBlocks () {
      if (!this.getContainersStore()) {
        return []
      }

      const store = this.getContainersStore()

      return store.getFlat()
    },

    dragOptions () {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    }
  },

  data () {
    return {
      drag: false,
      complexVisible: [],
      localValue: cloneObject(this.value),
      components: this.getComponents(),
      containersStore: this.getContainersStore(),
      commands: [],
      allCommands: [],
      windows: this.getWindows(),
      icons: Icons.map(el => ({ value: el, label: el })),
      urlOpenOptions: [
        {
          name: 'Новая вкладка',
          value: 'newTab'
        },
        {
          name: 'Текущая вкладка',
          value: 'currentTab'
        },
        {
          name: 'Новое окно',
          value: 'newWindow'
        }
      ],
      etl_tasks: null,
      registryAttrs: [],
      block_tabs: [],
      executePluginAllowedActions: [
        'execute_command',
        'execute_plugin'
      ],
      recordsDeleteType: this.value?.delete_records?.deleteType ?? 'current_record',
      deleteRecordsAllowedComponents: ['AnalyticTable/index', 'Registry'],
      copyToClipboardExcludedComponents: ['AnalyticTable/index', 'Registry']
    }
  },

  created () {
    if (this.localValue.type === 'complex') {
      this.complexVisible = this.localValue.complex.actions.map(() => false)
    }

    this.restoreInvalidDataStructure()
  },

  async mounted () {
    if (this.getRegistryId()) {
      this.$http.get(`${this.$config.api}/v2/logiceditor/logic?entity_id=${this.getRegistryId()}`)
        .then((response) => {
          if (response.data[0]) {
            this.$http.get(`${this.$config.api}/v2/logiceditor/commands?logic_id=${response.data[0].id}`)
              .then((res) => {
                this.commands = res.data
              })
          }
        })
    } else {
      this.$http.get(`${this.$config.api}/v2/logiceditor/commands`)
        .then((res) => {
          this.commands = res.data
          this.allCommands = res.data
        })
    }
    this.$http.get(`${this.$config.api}/etleditor/tasks?order=name:asc`)
      .then((response) => {
        this.etl_tasks = response.data
      })

    await this.determineEntitySourceType(this.sourceId, this.sourceType)

    if (this.localValue.type === 'open_tab_in_block') {
      let blockWithTabs = this.getContainersStore().getByGuid(this.localValue.open_tab_in_block.block)

      if (typeof blockWithTabs === 'undefined' || typeof blockWithTabs.tabs === 'undefined') {
        this.block_tabs = []
      } else {
        this.block_tabs = blockWithTabs.tabs.list
      }
    }

    if (this.localValue.type === 'delete_records') {
      await this.determineDeleteRecordsActionEntitySource(this.localValue.delete_records)
    }
  },

  methods: {
    restoreInvalidDataStructure () {
      if (Array.isArray(this.localValue)) {
        this.$emit('input', Object.assign({}, this.localValue))
      }

      console.log('!(\'saveCard\' in this.localValue)', this.localValue, this.value)

      if (!('saveCard' in this.localValue)) {
        this.$set(this.localValue, 'saveCard', {
          isCloseAfterSave: false
        })
      }
      if (this.localValue.type === 'open_card') {
        if (!('refreshReplication' in this.localValue)) {
          this.$set(this.localValue, 'refreshReplication', {
            blocks: []
          })
        }
      }
      if (this.localValue.type === 'open_dashboard') {
        if (!this.localValue.dashboard.dashboardIdSource) {
          this.$set(this.localValue.dashboard, 'dashboardIdSource', 'dashboard')
        }
      }
    },

    async getRegistryAttrsRecursive (entities) {
      let data = []
      let me = this

      entities.map(async function (entity) {
        if (entity.has_children && entity.entity_type_id === 'field_group') {
          let temp = await me.getRegistryAttrsRecursive(entity.children)
          temp.map(function (item) {
            data.push(item)
          })
        } else {
          let attr = `attr_${entity.id}_`
          data.push({
            id: attr,
            name: attr
          })
        }
      })

      data.filter(item => {
        return item !== undefined
      })

      return data
    },

    /**
     * Хелпер действия delete_records
     * @returns {Promise<void>}
     */
    async determineDeleteRecordsActionEntitySource (action) {
      const sourceComponent = this.components.find((item) => item.guid === action.componentGuid)
      let sourceId, sourceType

      if (sourceComponent) {
        this.$set(this.localValue.delete_records, 'componentType', sourceComponent.initialType)
      }

      if (sourceComponent?.properties?.hasOwnProperty('registryId') && sourceComponent.properties.registryId) {
        sourceId = sourceComponent.properties.registryId
        sourceType = 'registry'
        console.log('delete type - registry')
      } else if (sourceComponent?.properties?.hasOwnProperty('source') && sourceComponent.properties.source.type === 'query') {
        sourceId = sourceComponent.properties.source.entityId
        sourceType = sourceComponent.properties.source.type
        console.log('delete type - query')
      } else if (sourceComponent?.properties?.hasOwnProperty('source') && sourceComponent.properties.source.type === 'extended_object') {
        sourceId = sourceComponent.properties.source.entityId
        sourceType = sourceComponent.properties.source.type
        console.log('delete type - extended_object')
      }

      await this.determineEntitySourceType(sourceId, sourceType)
    },

    changeDhId (event, source) {
      this.localValue.dashboard.id = event
      this.localValue.dashboard.dashboardIdSource = source
    },

    getActionLabel (type) {
      const action = this.actions.find(a => a.id === type)
      if (action) {
        return action.name
      }

      return 'Не задано'
    },

    addAction () {
      console.log('addAction', this.localValue)

      if (this.localValue.type === 'complex') {
        if (typeof this.localValue.complex === 'undefined') {
          this.complexVisible = []
          this.localValue.complex = {
            actions: []
          }
        }

        this.complexVisible = this.complexVisible.map(() => false)

        this.complexVisible.push(true)

        this.localValue.complex.actions.push({
          type: 'execute_command',
          command: {
            id: null,
            success_text: null,
            failure_text: null,
            is_card_close: null,
            close_type: null,
            is_save_card: null,
            is_reopen_card: null,
            componentsGuid: [],
            is_save_card_before_command: false
          },
          filters: []
        })

        this.$forceUpdate()
      }
    },

    toggleAction (index) {
      if (this.complexVisible[index] === false) {
        this.complexVisible = this.complexVisible.map(() => false)
      }

      this.complexVisible[index] = !this.complexVisible[index]

      this.$forceUpdate()
    },

    removeAction (index) {
      if (this.localValue.type === 'complex') {
        if (typeof this.localValue.complex === 'undefined') {
          this.complexVisible = []
          this.localValue.complex = {
            actions: []
          }
        } else {
          this.localValue.complex.actions.splice(index, 1)
          this.complexVisible.splice(index, 1)
        }

        this.$forceUpdate()
      }
    },

    /**
     * @param sourceId <number>
     * @param sourceType <string>
     * @returns {Promise<void>}
     */
    async determineEntitySourceType (sourceId, sourceType) {
      if (typeof sourceType === 'string') {
        sourceType = sourceType.toLowerCase()
      }
      if (sourceId && sourceType === 'registry') {
        const response = await Registry.params({ parent_id: sourceId, show_children: true }).$get()
        this.registryAttrs = await this.getRegistryAttrsRecursive(response)
      } else if (sourceId && sourceType === 'query') {
        await this.$http.post(
          `${this.$config.api}/datawarehouseservice/query/${sourceId}`,
          { limit: 1 },
          { hideNotification: true }
        ).then(response => {
          this.registryAttrs = Object.entries(
            response.data[0]
          ).map(function (item) {
            return { id: item[0], name: item[0] }
          }).filter(item => {
            return item !== undefined
          })
        })
      } else if (sourceId && sourceType === 'extended_object') {
        const response = await this.$http.get(`${this.$config.api}/bieditor/extended_objects/${sourceId}`,
          null,
          { hideNotification: true }
        )
        this.registryAttrs = response.data.extended_object_fields.map((item) => ({ id: item.name, name: item.name }))
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.button-action-form {
  .el-select .el-input__inner {
    width: 100%;
  }
}

.complex-action {
  position: relative;
}

.complex-action-item {
  position: relative;
  margin-top: -0.67px;

  &__label, &__content {
    border: 1px solid #D2D6DA;
    padding: 10px;
    box-sizing: border-box;
  }

  &__label {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__content {
    border-top: none;
    margin-bottom: 10px;
  }
}

.complex-action__add {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: -37px;
}

.flip-list-move {
  transition: transform 0.5s;
}
</style>
